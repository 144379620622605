$(document).ready(function(){

	$(".ts-modal-launch").on("click", function(e){

		$(".close-modal").remove();
		$(".modal-title-bar, .modal-title").remove();

		var target = $("#" + $(this).data("for"));	// data-for="id_goes_here" is required
		target.show();

		// optional data-title=""
		if($(this).data("title")){
			let supportLink = "";
			if($(this).data("support-link")){
			 	supportLink = '<div class="support-link-modal"><a href="' + $(this).data("support-link") + '" target="_blank"><i class="fad fa-life-ring"></i></a></div>';
			}

			$(".modal-content").prepend('<div class="modal-title-bar"><div class="modal-title">' + $(this).data("title") + '</div>' + supportLink + '</div>');
		}

		// optional data-close-corner="" for X in upper right corner
		let useCloseCorner = true; // display by default unless data-close-corner="false"
		if(typeof $(this).data("close-corner") !== "undefined" && !$(this).data("close-corner")){
			useCloseCorner = false;
		}
		// Add X button in upper right corner
		if(useCloseCorner){
			$(".modal-content").prepend('<span class="close close-modal"><i class="fas fa-x font-gray"></i></span>');
		}

		let useCloseButton = true; // overriden by data-close-button="false"
		if(typeof $(this).data("close-button") !== "undefined" && !$(this).data("close-button")){
			useCloseButton = false;
		}
		// Add "Close" button under modal content
		if(useCloseButton){
			if($(".button-bar").length){
				$(".button-bar").remove();
			}
			$(".modal-content").append('<div class="button-bar"><button class="close-modal">' + getTranslation("Content.Btn.Close") + '</button></div>');
		}

		// Close modal on X corner button or Close button
		//     unless overriden by data-close-corner="false" or data-close-button="false"
		$(".close-modal").on("click", function(){
			closeModal();
		});

		// Close modal on user defined close button
		// Any clickable element with class="custom-close-modal" will close without further action
		$(".custom-close-modal").on("click", function(){
			closeModal();
		});

		// Close modal with "esc" key, unless overriden by data-close-esc="false"
		if(typeof $(this).data("close-esc") === "undefined" || $(this).data("close-esc")){
			$(document).keyup(function(e){
				if(e.key == "Escape"){
					closeModal();
				}
			});
		}

		// Local clean up and close the modal
		function closeModal(){
			$(".close-modal").remove();
			$(".modal-title").remove();
			target.hide();
		}
	}); // EO on launch
});

/**
 * "Global" clean up and close modal: hide modal and remove actions
 */
function closeTSModal(){
	$(".close-modal").remove();
	$(".modal-title").remove();
	$(".ts-modal").hide();
}


/*
Template for using the TS Modal:

<button type="button" class="ts-modal-launch" data-for="id_of_your_modal_goes_here" data-title="Title Goes Here" data-close-corner="false" data-close-esc="false" data-close-button="false">Launch Modal</button>
<div id="id_of_your_modal_goes_here" class="ts-modal ts-modal-container noPrint">
	<div class="modal-content">
		<p>Whatever content.... </p>
		<div class="buttons"><!--- optional --->
			<button onclick="$('#id_of_your_modal_goes_here').hide()">You can make your own close button if you need to by hiding inline</button>
			<a href="##" class="custom-close-modal">Close me link</a><!--- or by setting class="custom-close-modal" in any clickable element --->
		</div>
	</div>
</div>

*/