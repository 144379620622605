g.hostname = window.location.hostname;
g.cb = Math.floor( Math.random() * 1000000 );

// window (timeclock)
g.windowIsFocused              = true;
g.maxComboOptions              = 0;
g.monthNames                   = {"Jan":"01","Feb":"02","Mar":"03","Apr":"04","May":"05","Jun":"06","Jul":"07","Aug":"08","Sep":"09","Oct":"10","Nov":"11","Dec":"12"};  // for tablesorter parser
g.ajaxUpdateObj                = new Object();
g.ajaxUpdateObj.itemsRequested = new Array();
g.ajaxUpdateObj.projectTimers  = new Array();
g.ajaxUpdateObj.interval       = 0;
g.ajaxUpdateObj.ms             = 1000 * 60 * 3;                                                                                                                          // 3 min default. However, this should be overwritten by js code in header
g.tablesorterWaitInt           = 0;
g.pleaseWaitInt                = 0;
g.hrsWorkedTodayInt            = 0;
g.hrsSelected                  = false;                                                                                                                                  // important variable for use in time control
g.timeControlTimeout           = 0;
g.timeControlShowTimeout       = 0;
g.inputFocusValue              = "";
g.inputFocusName               = "";
g.dateArrowInt                 = 0;
g.dateArrowN                   = 0;
g.dateArrowNms                 = 1000;
g.jsID                         = 0;
g.formClone                    = new Object();
g.isIE                         = false;
g.isIEVerLTE8                  = false;
g.isIOSSafari                  = false;
g.onResizeScrollID             = null;
g.onResizeScrollArrayID        = null;
g.onResizeScrollArray          = new Array();
g.preventScroll                = false;
g.lastScrollPosition           = 0;
g.lastUTCms                    = 0;
g.section                      = "";
g.baseApiUrl                   = "/api/private/v1";

g.clientSettingsObj = new Object();
g.clientSettingsObj.numberDisplayFormat = "decimal";
g.clientSettingsObj.tableDisplay   = "regularFormat";
g.clientSettingsObj.menuDisplay = "menuAutomatic";
g.clientSettingsObj.tableSorting = "grouped";

/* ------------------------------------------ Utility Functions ------------------------------ */

/* ------------------------------------------ Array ------------------------------ */

// gets min
Array.min = function( array ){
	return Math.min.apply( Math, array );
};

// gets max
Array.max = function( array ){
	return Math.max.apply( Math, array );
};

// removes dupes
Array.prototype.removeDuplicates = function() {
	return this.reduce(function(accum, cur) {
	if (accum.indexOf(cur) === -1) accum.push(cur);
		return accum;
	}, [] );
}

// polyfill for ("reduce")
if ( 'function' !== typeof Array.prototype.reduce ) {
	Array.prototype.reduce = function( callback /*, initialValue*/ ) {
    'use strict';
    if ( null === this || 'undefined' === typeof this ) {
		throw new TypeError(
		'Array.prototype.reduce called on null or undefined' );
    }
    if ( 'function' !== typeof callback ) {
		throw new TypeError( callback + ' is not a function' );
    }
    var t = Object( this ), len = t.length >>> 0, k = 0, value;
    if ( arguments.length >= 2 ) {
		value = arguments[1];
    } else {
		while ( k < len && ! k in t ) k++;
	if ( k >= len )
        throw new TypeError('Reduce of empty array with no initial value');
		value = t[ k++ ];
    }
    for ( ; k < len ; k++ ) {
		if ( k in t ) {
			value = callback( value, t[k], k, t );
		}
    }
    return value;
	};
}

// gets index
function getIndexOfValueInArray(v,a) {
	for ( n = 0; n < a.length; n++ ) {
		if ( a[n] == v ) return n;
	}
	return -1;
}

// gets/ensures unique
function getUnique(a) {
	var a = new Array();
	for (var n; n < this.length; n++) {
		if ($.inArray(this[n], a) == -1) a.push(el);
	}
	return a;
}

function createBaseApiHeaders(apikey,token){
	return {
		"apikey": apikey,
		"x-ts-authorization": token
	}
}

/* ------------------------------------------ Compatibility Functions ------------------------------ */

function getIEVersion() {
	var v = 0,
		r = "";
	if (navigator.userAgent.indexOf('MSIE') != -1)
		r = /MSIE (\d+\.\d+);/ //test for MSIE x.x
	else
		r = /Trident.*rv[ :]*(\d+\.\d+)/ //test for rv:x.x or rv x.x where Trident string exists
	if (r.test(navigator.userAgent)) //if some form of IE
		v = parseFloat(RegExp.$1) // capture x.x portion and store as a number
	return (v==0) ? false : v;
	//userAgent in IE7 WinXP returns: Mozilla/4.0 (compatible; MSIE 7.0; Windows NT 5.1; .NET CLR 2.0.50727)
	//userAgent in IE11 Win7 returns: Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko
}

function isIOS() {
	return !!navigator.userAgent.match(/(iPad|iPod|iPhone)/i);
}
function isWebKit() {
	return !!navigator.userAgent.match(/WebKit/i);
}
function isIOSSafari() {
	return isIOS() && isWebKit() && !navigator.userAgent.match(/CriOS/i); // not Chrome
}

function isFF() {
	return navigator.userAgent.indexOf("Firefox") != -1;
}

// replicates the functionality of the ColdFusion Fix() function
function js_fix(dateObj) {

	// MS in a day = 1000 * 60 * 60 * 24;
	var _MS_PER_DAY = 86400000;

	// PRECALCULATED MS OFFSET
	var utc1 = -2209161600000;

	// calculate the same value in ms for our target date
	var utc2 = Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()); // getDate returns the DAY from 1-31 where getDay returns 0-6...fuckers..

	// calculate the difference in days on the timeline for our targer date
	// this should be identicle to the value returned by ColdFusions Fix() method for a date
	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}


/* ------------------------------------------ Utility Date ------------------------------ */
// used in calendar
Date.prototype.addDays = function(days) {
	this.setDate(this.getDate() + days);
	return this;
};

// converts day to (number)
function dayNameToNumber(day) {
	day = day.toUpperCase();
	switch(day){
		case getTranslation("JavaScript.Day.SUN"): return 1;	break;
		case getTranslation("JavaScript.Day.MON"): return 2;	break;
		case getTranslation("JavaScript.Day.TUE"): return 3;	break;
		case getTranslation("JavaScript.Day.WED"): return 4;	break;
		case getTranslation("JavaScript.Day.THU"): return 5;	break;
		case getTranslation("JavaScript.Day.FRI"): return 6;	break;
		case getTranslation("JavaScript.Day.SAT"): return 7;	break;
	}
}

// converts number to (day)
function dayNumberToName(dayNum) {
	switch(dayNum){
		case "1": return getTranslation("JavaScript.Day.SUN");	break;
		case "2": return getTranslation("JavaScript.Day.MON");	break;
		case "3": return getTranslation("JavaScript.Day.TUE");	break;
		case "4": return getTranslation("JavaScript.Day.WED");	break;
		case "5": return getTranslation("JavaScript.Day.THU");	break;
		case "6": return getTranslation("JavaScript.Day.FRI");	break;
		case "7": return getTranslation("JavaScript.Day.SAT");	break;
	}
}

// gets hrs returns formatted <span>hrs label</span>
function decHrs(hrs) {
	var hrsLabel = getTranslation("Content.Label.HoursAbbrev"),
			minsLabel = getTranslation("Content.Label.MinutesAbbrev"),
			hmHint = "",
			h, m, neg;
	hrs = $.trim(hrs.replace(/,/g,""));
	neg = hrs.indexOf("-") != -1 || (hrs.indexOf("(") != -1 && hrs.indexOf(")") != -1);
	if (hrs == "")
		return null;
	else {
		hrs = hrs.replace("-","");
		hrs = parseFloat(stripAllButNumbers(hrs));
		if (!isNaN(hrs)) {
			h = Math.floor(hrs);
			if (h > 0)
				m = hrs % h;
			else
				m = hrs;
			m = Math.round((m*100)*(60/100));
			if (h == 0 && m == 0.01) m = 1;
			if (h == 1) hrsLabel = getTranslation("Content.Label.HoursAbbrev");
			if (m == 1) minLabel = getTranslation("Content.Label.MinutesAbbrev");
			if (h > 0)
				hmHint += h + "<span class='dayOfWeek'>"+hrsLabel+"</span> ";
			if (m > 0 || h == 0 && m == 0)
				hmHint += m + "<span class='dayOfWeek'>"+minsLabel+"</span>";
			if (neg)
				hmHint = "-" + hmHint;
			return hmHint;
		}
		else
			return null;
	}
}

// gets today and shifts (adds) to it
function getDateToday(shiftDays) {
	var dateObj = new Date();
	if (typeof shiftDays === "undefined") {
		shiftDays = 0;
	}
	dateObj.setDate(dateObj.getDate() + shiftDays);
	return dateObj;
}

// returns time difference between objects
function getTimeDiffObj(d,h,m,ampm,ddiff,hdiff,mdiff) {
	var da = new Array(),
		dateObj = new Date(),
		r = new Object();
	da = d.split("-");
	da[0] = parseInt(da[0],10) - 1; // month is 0-11
	da[1] = parseInt(da[1],10);
	da[2] = parseInt(da[2],10);
	h = parseInt(h,10);
	m = parseInt(m,10);
	if (h == 12)
		h = 0;
	if (ampm.toUpperCase() == 'PM')
		h += 12;
	dateObj.setFullYear(da[2],da[0],da[1]);
	dateObj.setHours(h);
	dateObj.setMinutes(m);
	dateObj.setSeconds(0);
	if (typeof ddiff !== "undefined")
		dateObj.setDate(dateObj.getDate()+ddiff);
	if (typeof hdiff !== "undefined")
		dateObj.setHours(dateObj.getHours()+hdiff);
	if (typeof mdiff !== "undefined")
		dateObj.setMinutes(dateObj.getMinutes()+mdiff);
	r.d = formatDate(dateObj);
	r.h = dateObj.getHours();
	r.m = dateObj.getMinutes();
	r.ampm = "AM";
	if (r.h >= 12) {
		r.ampm = "PM";
		r.h -= 12;
	}
	if (r.h == 0)
		r.h = 12;
	return r;
}

// formats date to (month-day-year)
function formatDate(dateObj) {
	var day, month, year;
	if (typeof dateObj === "string")
		dateObj = stringToDateObj(dateObj);
	if (typeof shiftDays === "undefined")
		shiftDays = 0;
	day = dateObj.getDate();
	if (day < 10)
		day = '0' + day;
	month = dateObj.getMonth() + 1;
	year = dateObj.getFullYear();
	if (month > 12) {
		month = month - 12;
		year = year + 1;
	}
	if (month < 10)
		month = '0' + month;
	return month + '-' + day + '-' + year;
}

// formats a date
function getDateFormatted(shiftDays) {
	if (typeof shiftDays === "undefined")	shiftDays = 0;
	return formatDate(getDateToday(shiftDays));
}

// sets hour to decimal
function HMtoDec(h,m,inc) {
	var hours;
	if (typeof inc == "undefined" || isNaN(inc) ) inc = 0;
	if (isNaN(h)) h=0;
	if (isNaN(m)) m=0;
	hours = ( (h * 60) + Math.floor(m) ) / 60; // calculate number of hours
	hours = toFixed(hours,2); // fix to two decimals without rounding
	return RoundProjectHours(hours,inc);
}

// Replaced makeAMPM by removing styling/toggle and converting to standard form radios
function makeAMPM(inputid) {
	var $i = $("#"+inputid),
			$c = $("#"+inputid+"Control"),
			ampm = $i.val();
	$i.val(ampm == "PM" ? "AM" : "PM");
}

function RoundProjectHours(hours,minuteInc) {
	var r = hours,
		hourInc;

	if (hours > 0 && minuteInc > 0 && Math.round(hours) != hours) {
		hourInc = minuteInc / 60;
		if (hours > hourInc)
			r = hourInc * Math.ceil(hours / hourInc);
		else
			r = hourInc;
	}

	r = parseFloat(r).toFixed(2);

	return r;
}

function updateHrsWorkedToday() {
	var $e1 = $("#ClockInLocalAjax");
	if ($e1.length == 1) {
		$e1.text( $e1.text() );
	}
	var $e2 = $("#HoursWorkedCurrentShiftAjax");
	if ($e2.length == 1) {
		$e2.text( parseFloat($e2.text()).toFixed(2) );
	}
	var $e3 = $("#HoursWorkedTodayAjax");
	if ($e3.length == 1) {
		$e3.text( parseFloat($e3.text()).toFixed(2) );
	}
}

function validateTime(e,type,targetID,onChangeJS) {

	var msgValueHasBeenReset = getTranslation("JavaScript.Validation.ValueHasBeenReset");
	var msgHoursMustBeNumeric = getTranslation("JavaScript.Validation.ValueHasBeenReset");
	var msgMinutesMustBeNumeric = getTranslation("JavaScript.Validation.MinutesMustBeNumeric");
	var msgHoursMustBeBetween0and24 = getTranslation("JavaScript.Validation.HoursMustBeBetween0and24");
	var msgMinutesMustBeBetween0and59 = getTranslation("JavaScript.Validation.MinutesMustBeBetween0and59");

	if ($.trim(e.value.toString()) == "" || !isJustNumbers(e.value.toString())) {
		if (type=="hours" || type=="nonstd")
			resetInputValueAlertAndFocus(e,msgHoursMustBeNumeric+"\n"+msgValueHasBeenReset);
		else if (type=="minutes")
			resetInputValueAlertAndFocus(e,msgMinutesMustBeNumeric+"\n"+msgValueHasBeenReset);
	}
	else if (type=="hours" && g.inputFocusValue != "00" && (e.value < 1 || e.value > 12)) {
		resetInputValueAlertAndFocus(e,msgHoursMustBeBetween1and12+"\n"+msgValueHasBeenReset);
	}
	else if (type=="nonstd" && (e.value < 0 || e.value > 24)) {
		resetInputValueAlertAndFocus(e,msgHoursMustBeBetween0and24+"\n"+msgValueHasBeenReset);
	}
	else if (type=="minutes" && (e.value < 0 || e.value > 59)) {
		resetInputValueAlertAndFocus(e,msgMinutesMustBeBetween0and59+"\n"+msgValueHasBeenReset);
	}
	else {
		if (type=="hours") {
			if (e.value != 0)
				$("#"+targetID).val(convert12to0(parseFloat(e.value)));
		}
		else
			$("#"+targetID).val(parseFloat(e.value));
		e.value = formatAsTwoDigits(e.value);
		//ignore:eval
		eval(onChangeJS);
		g.inputFocusValue = "";
	}
}

// turns a string into an obj
function stringToDateObj(s) {
	var a;
	if (s.search("{ts '") != -1) {
		s = s.replace("{ts '","");
		s = s.replace("'}","");
		a = s.split(/[- :]/);
		return new Date(a[0], a[1]-1, a[2], a[3], a[4], a[5]);
	}
	else {
		if (s.split("-").length == 3 || s.split("/").length == 3)
			return new Date(s + "00:00:00");
		else
			return new Date(s);
	}
}


/* ------------------------------------------ Utility Money ------------------------------ */

// used everyhere
function formatCurrency(num) {
	num = num.toString().replace(/\$|\,/g,'');
	if(isNaN(num))
	num = "0";
	sign = (num == (num = Math.abs(num)));
	num = Math.floor(num*100+0.50000000001);
	cents = num%100;
	num = Math.floor(num/100).toString();
	if(cents<10)
	cents = "0" + cents;
	for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
	num = num.substring(0,num.length-(4*i+3))+','+
	num.substring(num.length-(4*i+3));
	return (((sign)?'':'-') + '$' + num + '.' + cents);
}

// used everyhere
Number.prototype.formatMoney = function(c, d, t){
	var n = this,
			c = isNaN(c = Math.abs(c)) ? 2 : c,
			d = d == undefined ? "." : d,
			t = t == undefined ? "," : t,
			s = n < 0 ? "-" : "",
			i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
			j = (j = i.length) > 3 ? j % 3 : 0;
	return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

// turns string into money format
function formatMoney(n) {
	if (parseFloat(n) < 0)
		return "($" + n.formatMoney() + ")";
	else
		return "$" + n.formatMoney();
}

// unformats money string
function unformatMoney(str) {
	var minus = (str.indexOf("($") > -1 || str.indexOf("-") > -1);
	str = parseFloat(str.replace("$","").replace("(","").replace(")","").replace("-","").replace(/,/g,""));
	str = minus ? -str : str;
	return str;
}

/* ------------------------------------------ General Functions ------------------------------ */

// converts number 12 to 0
function convert12to0(n) {
	if (n==12)
		return 0;
	else
		return n;
}

function changeFormFieldAndSubmit(formName,formField,value){
	$("form[name="+formName+"]")
		.find("input[name="+formField+"]")
		.val(value)
		.end()
		.trigger("submit");
}

// formats two digits
function formatAsTwoDigits(v) {
	var newv =	v+"";
	newv = newv.replace(" ","");
	if (newv.length == 1)
		newv = "0" + newv;
	return newv;
}

// gets (id)
function getById(theID) {
	return document.getElementById(theID);
}

// gets uniqueID
function getUniqueID() {
	g.jsID++;
	return "jsID" + g.jsID;
}

// gets new uuid
function getUUID() {
	var d = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-xxxx-xxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (d + Math.random()*16)%16 | 0;
		d = Math.floor(d/16);
		return (c=='x' ? r : (r&0x3|0x8)).toString(16);
	});
	return uuid;
}

// gets string, ensures just numbers
function isJustNumbers(str) {
	if (isNaN(str))
		return false;
	else if (str != Math.round(str) || str.indexOf(".") != -1)
		return false;
	else
		return true;
}

/**
 * Make sure numbers follow the ##.## format only
 */
function validateCurrencyNumber(ev, val) {
	// console.log("Global::validateCurrencyNumber(ev, val) = ", ev, val);
	let hasPeriod = false;
	if ((val+"").indexOf(".") != -1){
		hasPeriod = true;
	}
	if ((isNaN(ev.key) && ev.key !== ".") || (ev.key == "." && hasPeriod) || (hasPeriod && ((val+"").length - (val+"").indexOf(".") > 2))) {
		ev.preventDefault();
		ev.stopPropagation();
	}
}

function limitInputToNumbers(ev,val,allowMinusParam) {
	var c = ev.keyCode || ev.which || 0,
			hasPeriod = false,
			hasMinus = false,
			allowMinus = false,
			isFirstCharacter = false,
			ok = false,
			keyArray = [37,39,46,9,8,46];
	// Keys in array: left 37, right 39, delete 46, tab 9, backspace 8, period 46
	// Keys tested separately: numbers 48-57, minus 45
	if (typeof allowMinusParam !== "undefined")
		allowMinus = allowMinusParam;
	if ((val+"").indexOf(".") != -1)
		hasPeriod = true;
	if ((val+"").indexOf("-") != -1)
		hasMinus = true;
	isFirstCharacter = ev.target.selectionStart == 0;
	ok = ok || ($.inArray(c,keyArray) != -1) || (c >=48 && c <=57) || (c == 45 && allowMinusParam && isFirstCharacter);
	if (!ok || (c == 45 && hasMinus) || (c == 46 && hasPeriod)) {
		ev.preventDefault();
		ev.stopPropagation();
	}
}

// returns position of first instance of an element in a list, else returns -1, like indexOf()
function listFind(list,el,delim) {
	if (typeof delim == "undefined")
		delim = ",";
	return list.split(delim).indexOf(el);
}

// pluralizes
function plural(string,n) {
	if (n!=1) return string + "s";
	else return string;
}

// redirect functionality
function redirect(url) {
	clearTimeout(redirectTimerID);
	document.location = url;
}

// function that calls itself
function resizeAllMaps() {
	if (typeof maps !== "undefined")
		maps.resizeAllMaps();
}

// rounds to specific decimal
function roundNumToPlaces(num, dec) {
	return Math.round(num*Math.pow(10,dec))/Math.pow(10,dec);
}

// strips everything but numbers
function stripAllButNumbers(s) {
	return s.match(/\d+\.?\d*/g)
}

// pads with zeroes
function zeroPad(n, count) {
	var zp = n + '';
	while(zp.length < count) {
		zp = "0" + zp;
	}
	return zp;
}

// fixes a number to the specified decimals without rounding
function toFixed(num, fixed) {
	var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
	return num.toString().match(re)[0];
}

/**
 * Toggle site-wide dark mode on and off
 *
 * @param box {Object} - the dolled up checkbox element, checked or unchecked
 *
 */
function toggleDarkMode(box){
	var url = new URL( window.location.href );
	// Slider in UI: right = "checked" = dark mode
	var screenMode = (box.checked) ? "dark" : "light";
	url.searchParams.set("screenMode",screenMode);
		// console.log("toggleDarkMode(), reloadUrl = ", url.toString());
	window.location.href = url.toString();	// reload the same page with screenMode query string & let CF handle the work
}

/**
 * Toggle site-wide dark mode on and off on the Admin site
 */
function toggleDarkModeAdmin(){
	console.log("toggleDarkModeAdmin(): toggle this");	// TBD
}


/* ------------------------------------------ General DOM Manipulation Functions ------------------------------ */

/* **************************** General **************************** */

// button reverse
$.fn.reverse = [].reverse;

// append outer rule
$.fn.outerHTML = function() {
    return $('<div>').append( this.eq(0).clone() ).html();
};

// disables buttons
function disableButtons() {
	$("input[type=button],input[type=submit],input[type=reset],button").not(".noStyle").filter(":visible").enableButton(false);
}

// prevent-default (input 13 aka enter)
function noenter(e) {
	var keycode;
	if (window.event) keycode = window.event.keyCode;
	else if (e)	keycode = e.which;
	else return false;
	if (keycode != 13) return true;
	else return false;
}

// prints the page
function printWindow() {
	window.setTimeout(function(){
		window.print();
	},100);
}

function startTimeControlHide(){
	clearTimeout(g.timeControlTimeout);
	clearTimeout(g.timeControlShowTimeout);
	g.timeControlTimeout = setTimeout(function(){
		$('.timeDropDown').hide();
	},250);
}

function stopTimeControlHide(){
	clearTimeout(g.timeControlTimeout);
}

// Legacy version to show & hide param list
// Should be replaced with the listener right below this func. if/when it is flagged as a problem
function showHideReportParamList(id,lnk,closedLabel,openedLabel) {
	if(this.event){
	  this.event.preventDefault ? this.event.preventDefault() : (this.event.returnValue = false);
	  this.event.stopPropagation ? this.event.stopPropagation() : (this.event.cancelBubble = true);
	}

	if ($("#"+id).is(":hidden")) {
	  $("#"+id).slideDown(250,setZebraStripes);
	  $("#"+lnk).html(openedLabel);
	}
	else {
	  $("#"+id).slideUp(250);
	  $("#"+lnk).html(closedLabel);
	}
  }

/* **************************** Scrolling **************************** */


$.expr.pseudos.almostonscreen = function(el) {
	var $w = $(window),
			bottomPad = 500,
			vTop = $w.scrollTop(),
			vHeight = $w.height(),
			vBottom = vTop + vHeight + bottomPad,
			t = $(el).offset().top,
			h = $(el).height(),
			b = t + h,
			returnVar =
				(t >= vTop && t < vBottom)
				|| (b > vTop && b <= vBottom)
				|| (h > vHeight && t <= vTop && b >= vBottom);
	return returnVar;
};

// scrolling from bottom of window (jquery element only)
function getDistanceFromBottomOfWindow(el) {
	return ($(window).scrollTop() + $(window).height()) - (el.offset().top + el.outerHeight());
}

// prevent larger scroll on floated tabs
function onResizeScrollAction() {
	setZebraStripes();

	$("div.tabs:almostonscreen").each(function(){
		var contentWidth = 0;
		$(this).find("div.tab").each(function(){
			contentWidth += $(this).outerWidth();
		});
		if (contentWidth > $(this).outerWidth())
			$(this).width(contentWidth);
	});
}

// scrolls at speed
function scrollUpDown(n,ms) {
	if (typeof ms === "undefined") ms = 250;
	$("html,body").animate({scrollTop:$(window).scrollTop()+(n)},ms);
}

// scroll prevention
function scrollPrevent(b) {
	var o = "auto",
			p = "relative",
			h = "auto",
			t = $(window).scrollTop();

	if (b) {
		p = "fixed";
		h = $(window).height()+"px";
		$(window).scrollTop(t);
	}

	$("html,body")
		.css({
			overflow: o,
			position: p
		});

	$("body")
		.css({
			height: h
		});
}

// anchor replacement with scroll functionality
function scrollToHashAnchor() {
	if (window.location.hash) {
		hash = window.location.hash.substr(1,window.location.hash.length);
		var aTag = $("a[name='"+ hash +"']");
		if(aTag.length == 1){
			$('html,body').animate({scrollTop: aTag.offset().top},'slow');
		}
	}
}

// scrollsTo
$.scrollTo = function( sel, duration, opts ) {
	opts = $.extend({}, {
		'offset':0,
		'callback':function(){}
	}, opts || {} );
	if (typeof duration === "undefined") duration = 250;
	if (duration == 0) {
		$("html,body").css({ scrollTop: ($(sel).position().top + opts.offset) + "px" });
		opts.callback.call();
	}
	else {
		$("html,body").animate({
			scrollTop: ($(sel).position().top + opts.offset) + "px"
		},duration,function(){
			opts.callback.call();
		});
	}
};

/* **************************** Forms and Inputs **************************** */

// gets height of textarea
function getTextAreaHeight($el) {
	var h = $el.outerHeight();
	if ($el.css("border-top-width") != "")
		h += parseInt($el.css("border-top-width"),10);
	if ($el.css("border-bottom-width") != "")
		h += parseInt($el.css("border-bottom-width"),10);
	return h;
}

// used with onKeyPress="hitEnter(event)"
function hitEnter(e) {
	var keycode;
	if (window.event)	keycode = window.event.keyCode;
	else if (e)	keycode = e.which;
	else return false;
	if (keycode == 13) return true;
	else return false;
}

// expands textarea
function initTextAreaExpansion() {
	$("textarea")
		.each(function(){
			if ($(this).is(":visible"))
				$(this).attr("data-height", getTextAreaHeight($(this)));
			setTextAreaHasMore($(this));
		})
		.on("blur",function(ev){
			var delay = 1;
			if ($(ev.relatedTarget).is("button,:button,:submit,:reset"))
				delay = 500;
			setTimeout($.proxy(function(){
				$(this)
					.stop(true,true)
					.animate({
						height: (parseInt($(this).attr("data-height"),10)) + "px"
					},{
						duration: 500,
						easing: 'easeOutExpo',
						complete: function(){
							setTextAreaHasMore($(this));
							$(this)
								.css({overflow: "auto"})
								.animate({
									scrollTop: 0
								},{
									duration: 500,
									easing: 'easeOutExpo'
								});
						}
					});
			},this),delay);
		})
		.on("focus keyup",function(ev){
			var boxHeight,
					maxHeight = 300;
			if (!$(this).is("[data-height]"))
				$(this).attr("data-height", getTextAreaHeight($(this)));

				setTextAreaHasMore($(this));
			/*
			Removed for TIM-1897 On Time entries, Notes field goes back to default size when you start typing.
			J: if we have to bring it back or somebody complains, we'll let you know...
			$(this)
				.stop(true,true)
				.height(0)
				.height(Math.min( Math.max(this.scrollHeight,parseInt($(this).attr("data-height"),10))-8, maxHeight ))
				.css("overflow", this.scrollHeight > maxHeight ? "auto" : "hidden");
			*/

		});
}

// expands textarea
function setTextAreaHasMore($el) {
	$el.toggleClass("hasMore", $el[0].scrollHeight > getTextAreaHeight($el))
}

// submit form with method
function submitFormWithMethod(formName, methodName) {
	$("form[name="+formName+"]")
		.find("input[name=Method]").val(methodName).end()
		.submit();
}

// set form submit events for button disabler
function setFormButtonDisableSubmitHandler() {
	var sel = "input[type=submit],input[type=button],input[type=reset],input[type=image],button";
	$("form:has("+sel+")")
		.each(function(){
			if ($(this).find("input[name=enableSubmitButtons][value=true]").length == 0) {
				$(this).on("submit",function(ev){
					if ($(this).is("[data-disabled]")) {
						ev.preventDefault();
						ev.stopPropagation();
					}
					if (!ev.isDefaultPrevented() && !ev.isPropagationStopped() && !ev.isImmediatePropagationStopped()) {
						$(this)
							.toggleClass("loading",true)
							.find(sel)
							.filter(":visible:not(.noStyle)")
							.each(function(){
								$(this).enableButton(false);
							});
					}
				});
			}
		})
		.on("click",sel,function(ev){
			if ($(this).closest("form").is("[data-disabled]")) {
				ev.preventDefault();
				ev.stopPropagation();
				return false;
			}
		});
}

/* **************************** Page Flow **************************** */

// called at load ensuring no overflow
function resetOverflows() {
	var $o = $(".overflow");
	$o.filter(":visible")
		.hide()
		.toggleClass("overflowProcessing", true);
	$o.each(function(){
		var $t = $(this),
				p = parseInt($t.css("paddingLeft"),10) + parseInt($t.css("paddingRight"), 10),
				w = $t.parent().outerWidth() - p;
		$(this).css("max-width",w+"px");
	});
	$(".overflowProcessing")
		.show()
		.toggleClass("overflowProcessing", false);
}

// equal box heights (css)
function equalHeight(items) {
	var h = 0;
	items
		.css("min-height","")
		.each(function(){
			h = Math.max(h,$(this).outerHeight());
		})
		.css("min-height",h+"px");
}

function equalizeContentHeights() {
	if (!$("#pageWrapper").is(".external"))
		equalHeight($("#contentWrapper,#sidemenu"));
}

/* ------------------------------------------ Ready Function ------------------------------ */
$(document).ready(function () {

	$(".plusMinusSlideToggle").on("click", function(e){
		e.preventDefault();
		e.stopPropagation();

		var targetSelector = $(this).data("target-selector");

		$(targetSelector).slideToggle(50);
		$(this).find(".far").toggleClass("fa-minus-circle").toggleClass("fa-plus-circle");
	});

	// notification
	$('body').on("click", ".announcementHandler", function () {
		var target = $(this).find(".msgHandler");

		var reducedHeight = target.height();
		target.css('height', 'auto');

		var fullHeight = target.height();
		target.height(reducedHeight);

		target.animate({ height: fullHeight }, 500);
		$(this).removeClass("hover");
	});

	/* Replacement for slideToggle() from onclick
	*	use data-duration="" for duration time
	*	use data-selector="" for selector target
	*/
	$(".slideToggle").on("click", function(e){
		e.preventDefault();
		e.stopPropagation();
		var duration = $(this).data("duration");
		var selector = $(this).data("selector");
		$("#"+selector).slideToggle(50);

	});


	//	Create a listener & warning if a user has the Caps Lock key on when entering a Password
	const passwordFields = document.querySelectorAll('input[type="password"]');	// get all the password fields on a page
	for(var i = 0; i < passwordFields.length; i++){		// loop through fields and add keyup listeners to catch caps lock
		passwordFields[i].addEventListener("keyup", function(event){
			let warningElement = document.getElementById("warning_"+i);	// get the element with the warning message if it exists

			if(event instanceof(KeyboardEvent) && event.getModifierState("CapsLock")){
				// console.log("%cbusted! CAPS lock is on for ", "color: red;", this.name);

				if($(".pw-warning.static").length > 0){		// if there is a hard-coded warning element, show it
					$(".pw-warning.static").show();
				}
				else {
					// If we don't have a warning element we will create & populate
					if(!warningElement){
						// append warning div after the current element (password input)
						let warningMessage = getTranslation("Content.Label.CapsLockEnabled");
						this.insertAdjacentHTML("afterend", '<div class="pw-warning" id="warning_' + i + '">' + warningMessage + '</div>');
					}
				}
			}
			else {
				$(".pw-warning.static").hide();
				if(warningElement){
					warningElement.remove();	// remove the warning element if it exists, otherwise ignore
				}
			}
		});
	}





	/* ------------------------------------------ Global Visual Functions ------------------------------ */
	$.fn.extend({

		autoCellClick: function() {
			$(this).find(":checkbox,:radio").not(":disabled")
				.each(function(){
					var $t = $(this);
					if ($t.is(":checkbox")) {
						if ($t.is(":checkbox:checked"))
							$t.prop("checked",false);
						else if ($t.is(":checkbox"))
							$t.prop("checked",true);
					}
					else if ($t.is(":radio")) {
						if (!$t.is(":checked"))
							$t.prop("checked",true);
					}
				});
		},

		recalculateExpenseTotals: function() {
			var amount = 0;
			$(this).find("tbody > tr:not(.notes) > td input[name=ExpenseID]:checked").each(function(){
				var num = $(this).closest("tr").find("td.Hours").text();
				amount += unformatMoney(num);
			});
			$(this)
				.find(".SelectedTotal")
				.toggleClass("redText",amount < 0)
				.text(formatMoney(amount));
			$(this).parent().calculateAllExpenseTotals();
			return $(this);
		},

		calculateAllExpenseTotals: function() {
			var amount = 0;
			$(this).find(".SelectedTotal").each(function(){
				amount += unformatMoney($(this).text());
			});
			$(this)
				.find("#allExpenseTotalsWrapper").show()
				.find(".largeText")
				.toggleClass("redText",amount < 0)
				.text(formatMoney(amount));
			return $(this);
		},

		enableButton: function(b) {
			if (typeof b === "undefined")
				b = true;
			return this.each(function(){
				if (b)
					$(this).removeAttr("data-disabled");
				else
					$(this).attr("data-disabled","true");
			});
		},

		floatBorder: function(opts) {
			return this.each(function(){
				$(this).toggleClass("scaleIn",true);
				setTimeout($.proxy(function(){
					$(this).toggleClass("scaleIn",false);
					},this),250);
			});
		},

		isOffScreen: function() {
			var $t = $(this),
				$w = $(window),
				padTopBottom = 10,
				offset = $t.offset(),
				oLeft = offset.left,
				oRight = oLeft + $t.outerWidth(),
				oTop = offset.top + padTopBottom,
				oBottom = oTop + $t.outerHeight() - padTopBottom,
				winWidth = $w.innerWidth(),
				winHeight = $w.innerHeight(),
				winTop = $w.scrollTop(),
				winBottom = $w.scrollTop() + winHeight;
			return (
				oLeft < 0
				|| oRight > winWidth
				|| oTop < winTop
				|| oBottom > winBottom
			);
		},
		cloneForm: function() {
			var n = $(this).attr("name");
			g.formClone[n] = new Object();
			$(this).find("input[type=text][name]:not([class*=ignoreChanges]),input[type=hidden][name][class!=ignoreChanges]").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).val();
			});
			$(this).find("input[type=checkbox][name]:not([class*=ignoreChanges])").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).is(":checked");
			});
			$(this).find("input[type=radio][name]:checked:not([class*=ignoreChanges])").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).val();
			});
			$(this).find("select[name]:not([class*=ignoreChanges])").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).find("option:selected").val();
			});
			$(this).find("textarea[name]:not([class*=ignoreChanges])").each(function(){
				g.formClone[n][$(this).attr("name")] = $(this).val();
			});
			//console.log(g.formClone);
		},
		isSameAsClone: function(isSubmit) {
			var changed = false,
					n = $(this).attr("name");
			if (typeof isSubmit === "undefined") // if submit, gives warning if nothing changed
				isSubmit = true;
			$(this).find("input[type=text][name]:not([class*=ignoreChanges]),input[type=hidden][name][class!=ignoreChanges]").each(function(){
				if ($(this).val() != g.formClone[n][$(this).attr("name")]) changed = true;
				//console.log($(this).attr("name") + " : " + $(this).val() + " == " + g.formClone[n][$(this).attr("name")]);
			});
			$(this).find("input[type=checkbox][name]:not([class*=ignoreChanges])").each(function(){
				if ($(this).is(":checked") != g.formClone[n][$(this).attr("name")]) changed = true;
				//console.log($(this).attr("name") + " : " + $(this).is(":checked") + " == " + g.formClone[n][$(this).attr("name")]);
			});
			$(this).find("input[type=radio][name]:checked:not([class*=ignoreChanges])").each(function(){
				if ($(this).val() != g.formClone[n][$(this).attr("name")]) changed = true;
				//console.log($(this).attr("name") + " : " + $(this).val() + " == " + g.formClone[n][$(this).attr("name")]);
			});
			$(this).find("select[name]:not([class*=ignoreChanges])").each(function(){
				if ($(this).find("option:selected").val() != g.formClone[n][$(this).attr("name")]) changed = true;
				//console.log($(this).attr("name") + " : " + $(this).val() + " == " + g.formClone[n][$(this).attr("name")]);
			});
			$(this).find("textarea[name]:not([class*=ignoreChanges])").each(function(){
				if ($(this).val() != g.formClone[n][$(this).attr("name")]) changed = true;
				//console.log($(this).attr("name") + " : " + $(this).val() + " == " + g.formClone[n][$(this).attr("name")]);
			});

			//console.log("changed: "+changed);
			if (!changed) {
				if (isSubmit) {
					alertDialog({
						msg: getTranslation("JavaScript.NoChangesFound")
					});
				}
				return true;
			}
			else {
				return false;
			}
		},

		// serializes an array
		serializeJSON: function() {
			var json = {};
			jQuery.map($(this).serializeArray(), function(n, i){
				if (typeof json[n['name']] === "undefined")
					json[n['name']] = n['value'];
				else
					json[n['name']] += "," + n['value'];
			});
			return json;
		},

		// toggleAttr("attr","value") - toggles attr="value", adding or removing based on whether it is present
		// toggleAttr("attr","value",true) - adds attr="value" to an element
		// toggleAttr("attr",false) - removes attr from element
		toggleAttr: function(attr,val,bool) {
			var toggle = false,
					on = false;
			if (typeof val === "boolean" && val === false)
				on = false;
			else if (typeof bool === "undefined")
				toggle = true;
			else if (bool)
				on = true;
			return this.each(function(){
				if (toggle)
					on = !$(this).is("["+attr+"]");
				if (on)
					$(this).attr(attr,val);
				else {
					$(this).removeAttr(attr);
				}
			});
		}

	});

});

// jquery event handlers for 3rd party libraries (passive event listeners)
function patchScrollBlockingListeners() {
	let supportsPassive = false;
	const x = document.createElement("x");
	x.addEventListener("cut", () => 1, {
	  get passive() { supportsPassive = true; return !!1 }
	});
	x.remove();
	if (supportsPassive) {
	  const originalFn = EventTarget.prototype.addEventListener;
	  EventTarget.prototype.addEventListener = function(...args) {
		if (
		  ['scroll', 'touchmove', 'touchstart'].includes(args[0]) &&
		  (typeof args[2] !== 'object' || args[2].passive === undefined)
		) {
		  args[2] = {
			...(typeof args[2] === 'object' ? args[2] : {}),
			passive: false
		  };
		}
		originalFn.call(this, ...args);
	  }
	}
}

// Remove this from being invoked if it causes issues
// patchScrollBlockingListeners();