/* ------------------------------------------ Ready and Init ------------------------------ */
// auto confirmation
function initAutoFormConfirms() {
	$("form[data-confirm-msg]").each(function() {
		var sel = "form[name="+$(this).attr("name")+"]";
		$(sel).on("submit.confirmation",function(ev) {
			ev.preventDefault();
			confirmHandler(sel);
		});
	});
}

// initialize buttons
function initButtons() {
	$("div,span")
		.filter(".buttonset,.ui-dialog-buttonset")
		.buttonset();
	$("div,span")
		.filter(".buttonset,.ui-dialog-buttonset")
		.find("input")
		.filter(":checkbox,:radio")
		.button();
}

// create dialog
function initDialog() {
	$("<div />").attr("id","dialog").appendTo("body");
	$("#dialog").dialog( {
		autoOpen:false,
		show: false,
		hide: false,
		closeText: "Hide",
		modal: false
	});

	$("<div />").attr("id","download-dialog").appendTo("body");
	$("#download-dialog").dialog( {
		autoOpen:false,
		show: false,
		hide: false,
		closeText: "Hide",
		modal: false
	});

	$("<div />").attr("id","download-dialog-unavailable").appendTo("body");
	$("#download-dialog-unavailable").dialog( {
		autoOpen:false,
		show: false,
		hide: false,
		closeText: "Hide",
		modal: false
	});
}

function launchProjectDialog() {
	var title = $("#ProjectID").find('option:selected').text();
	$("#projectDescription").dialog({
		minWidth: 400,
		maxWidth: 500,
		maxHeight: 500,
		title: title,
	});
}

/* ------------------------------------------ Confirm Functions ------------------------------ */

/* ------------------ Confirm Dialog ------------------ */
function confirmDialog(opts) {

	var defaultTitle = getTranslation("JavaScript.Title.ConfirmationRequired");
	var defaultMsg = getTranslation("JavaScript.ContinueWithThisAction");
	var btnOk = ( "okayBtnTxt" in opts ) ? opts.okayBtnTxt : getTranslation("Content.Btn.Okay");
	var btnCancel = ( "cancelBtnTxt" in opts ) ? opts.cancelBtnTxt : getTranslation("Content.Btn.Cancel");

	opts = $.extend({}, {
		title: defaultTitle,
		msg: defaultMsg,
		width: 480,
		height: 260,
		stayOpen: true,
		okay: null,
		cancel: null,
		callback: null,
		resizable: false,
		draggable: true,
		modal: true,
		dialogClass: "confirmationWrapper",
		okayButtonId: "confirmationOkayBtn",
		okayButtonRemove: false,
		cancelButtonId: "confirmationCancelBtn",
		cancelButtonRemove: false,
		position: {my: "center center", at: "center center", of: window}
	},
		opts || {} );

	var buttonArray = [];
	if( !opts.okayButtonRemove ){
		buttonArray.push({
			text: btnOk,
			id: opts.okayButtonId,
			click: function(e, ui) {
				if ($.isFunction(opts.okay)) {
					opts.okay.call();
				}
				if (opts.stayOpen) {
					$(this)
						.closest(".ui-dialog")
						.find(".ui-dialog-title").html('<div class="ajaxLoader ajaxLoaderIcon"></div>'+getTranslation("JavaScript.ProcessingPleaseWait")).end()
						.find(".ui-dialog-content").css({color:"gray"}).end()
						.find("button").button("disable");
				}
				else {
					$(this).dialog("close");
				}
			}
		});
	}
	if( !opts.cancelButtonRemove ){
		buttonArray.push({
			text: btnCancel,
			id: opts.cancelButtonId,
			click: function(e, ui) {
				if ($.isFunction(opts.cancel)) {
					opts.cancel.call();
				}
				$(this).dialog("close");
			}
		});
	}

	if ( "additionalBtns" in opts ){
		opts.additionalBtns.forEach( function(btn){
			buttonArray.push({
				id: btn.id,
				text: btn.txt,
				click: function(e, ui) {
					if ($.isFunction(btn.click)) {
						btn.click.call();
					}
					$(this).dialog("close");
				}
			});
		});
	}

	// creates wrapper
	$('body').append('<div id="confirmDialog" style="display:none;"></div>');

	// creates dialog
	$("#confirmDialog")
		.html(opts.msg.replace(/\\n/g, "<br />").replace(/\n/g, "<br />"))
		.dialog({
			autoOpen: true,
			title: opts.title,
			width: opts.width,
			height: opts.height,
			resizable: opts.resizable,
			draggable: opts.draggable,
			position: opts.position,
			modal: opts.modal,
			dialogClass: opts.dialogClass,
			closeOnEscape: false,
			buttons: buttonArray,
			show: {
				effect: "fadeIn",
				easing: "easeInElastic",
				duration: 150
				},
			hide: {
				effect: "drop",
				direction: "down",
				easing: "easeOutCubic",
				duration: 150
				},
			open: function() {
				$("#confirmDialog")
					.dialog("widget")
					.attr("id","confirmationWrapper")
					.find("button.ui-dialog-titlebar-close")
					.attr("id","confirmationCancelBtn");
				g.onResizeScrollArray.push(function(){
					$(".ui-dialog.ui-widget:visible").position( $("#dialog").dialog("option","position") );
				});
				if ($.isFunction(opts.callback))
					opts.callback.call();
				},
			close: function() {
				g.onResizeScrollArray.pop();
				$(this).remove();
				}
		});

	// closes dialog
	$("#confirmDialog")
		.parent()
		.on('keydown', function(e, ui) {
			if (e.which === $.ui.keyCode.ESCAPE)
				$("#confirmDialog").dialog('close');
			e.stopPropagation();
		});

	return false;
}

function infoDialog(opts) {

	var defaultTitle = getTranslation("JavaScript.Title.Information");
	var btnClose = getTranslation("Content.Btn.Close");

	opts = $.extend({}, {
		title: defaultTitle,
		msg: defaultTitle,
		width: 480,
		height: 260,
		stayOpen: true,
		okay: null,
		cancel: null,
		callback: null,
		resizable: false,
		draggable: true,
		modal: true,
		dialogClass: "confirmationWrapper",
		closeButtonId: "infoDialogCloseBtn",
		position: {my: "center center", at: "center center", of: window}
	},
		opts || {} );

	// creates wrapper
	$('body').append('<div id="infoDialog" style="display:none;"></div>');

	// creates dialog
	$("#infoDialog")
		.html('<span class="noPrint">' + opts.msg.replace(/\\n/g, "<br />").replace(/\n/g, "<br />") + "</span>")
		.dialog({
			autoOpen: true,
			title: opts.title,
			width: opts.width,
			height: opts.height,
			resizable: opts.resizable,
			draggable: opts.draggable,
			position: opts.position,
			modal: opts.modal,
			dialogClass: opts.dialogClass,
			closeOnEscape: false,
			buttons: [{
				text: btnClose,
				id: opts.closeButtonId,
				click: function(e, ui) {
					if ($.isFunction(opts.cancel)) {
						opts.cancel.call();
					}
					$(this).dialog("close");
				}
			}],
			show: {
				effect: "fadeIn",
				easing: "easeInElastic",
				duration: 150
				},
			hide: {
				effect: "drop",
				direction: "down",
				easing: "easeOutCubic",
				duration: 150
				},
			open: function() {
				$("#infoDialog")
					.dialog("widget")
					.attr("id","confirmationWrapper")
					.find("button.ui-dialog-titlebar-close")
					.attr("id","confirmationCancelBtn");
				g.onResizeScrollArray.push(function(){
					$(".ui-dialog.ui-widget:visible").position( $("#dialog").dialog("option","position") );
				});
				if ($.isFunction(opts.callback))
					opts.callback.call();
				},
			close: function() {
				g.onResizeScrollArray.pop();
				$(this).remove();
				}
		});

	// closes dialog
	$("#infoDialog")
		.parent()
		.on('keydown', function(e, ui) {
			if (e.which === $.ui.keyCode.ESCAPE)
				$("#infoDialog").dialog('close');
			e.stopPropagation();
		});

	return false;
}

/* ------------------ Choose Orientation Dialog ------------------ */
function chooseOrientation(opts) {
	var msgMakeSelection = getTranslation("JavaScript.Title.MakeASelection");
	var msgPageOrientation = getTranslation("JavaScript.Title.PageOrientation");
	var msgPageOrientation = getTranslation("JavaScript.Title.PageOrientation");
	var btnPortrait = getTranslation("Content.Btn.Portrait");
	var btnLandscape = getTranslation("Content.Btn.Landscape");

	opts = $.extend({}, {
		selector: "form input[name=Orientation]",
		title: msgMakeSelection,
		msg: msgPageOrientation,
		width: 300,
		height: 270,
		maxWidth: false,
		maxHeight: false,
		modal: true,
		show: true,
		callback: null,
		close: null,
		okay: null,
		resizable: true,
		draggable: true,
		position: {my: "center center", at: "center center", of: window},
		html: true
	}, opts || {} );

	var orientationText = "";
	// this is usually empty: if not add this html
	if(opts.msg) {
		orientationText = "<div align='center' id='orientationText' style='padding-top: 26px; font-size: 18px;'><b>"+opts.msg+"</b><div>";
	}
	else {
		opts.height = 0; // get rid of wasted space in jq dialog
	}

	// clear
	$("#download-dialog").html("");

	// create dialog
	$("#download-dialog")
		.html(orientationText)
		.dialog({
			autoOpen: true,
			title: opts.title,
			width: opts.width,
			minWidth: 300,
			height: opts.height,
			maxWidth: opts.maxWidth,
			maxHeight: opts.maxHeight,
			draggable: opts.draggable,
			resizable: opts.resizable,
			position: opts.position,
			modal: opts.modal,
			buttons: [
					{
						text: btnPortrait,
						id: 'portraitButton',
						click: function(){
							$(opts.selector).val("PORTRAIT");
							closeDialog();
							if (typeof opts.callback !== null){
								opts.callback.call();
							}
						}
					},
					{
						text: btnLandscape,
						id: 'landscapeButton',
						click: function(){
							$(opts.selector).val("LANDSCAPE");
							closeDialog();
							if (typeof opts.callback !== null){
								opts.callback.call();
							}
						}
					}
				],
			open: function() {
					closeDialog();
					closeToolTips();
					g.onResizeScrollArray.push(function(){
						$(".ui-dialog.ui-widget:visible").position( $("#download-dialog").dialog("option","position") );
					});
				},
			close: function() {
					closeDialog();
					$("#download-dialog").dialog("option", "buttons", {});
					g.onResizeScrollArray.pop();
					if (opts.close !== null)
						opts.close.call();
				}
		});
}

/* ------------------ Please Wait Dialog ------------------ */
function pleaseWait(opts) {

	var defaultTitle = getTranslation("JavaScript.ProcessingPleaseWait");
	var btnOkay = getTranslation("Content.Btn.Okay");

	opts = $.extend({}, {
		title: defaultTitle,
		msg: "",
		width: 500,
		height: 190,
		maxWidth: false,
		maxHeight: false,
		modal: false,
		show: true,
		callback: null,
		close: null,
		okay: null,
		resizable: true,
		draggable: true,
		position: {my: "center center", at: "center center", of: window},
		html: false,
		dialogClass: null,
		alertMode: false,
		okayButtonId: "alertOkayBtn"
	}, opts || {} );

	// replace or logout
	if ( !opts.html )
		opts.msg = opts.msg.replace(/\\n/g, "<br />").replace(/\n/g, "<br />");
	if (opts.msg.indexOf( "logoutCounter") != -1 ) {
		opts.modal = true;
		opts.draggable = false;
		}

	$("#download-dialog").html("");

	// create dialog
	$("#download-dialog")
		.html(opts.msg)
		.dialog({
			autoOpen: true,
			show: opts.show,
			title: opts.title,
			width: opts.width,
			minWidth: 300,
			height: opts.height,
			maxWidth: opts.maxWidth,
			maxHeight: opts.maxHeight,
			draggable: opts.draggable,
			resizable: opts.resizable,
			position: opts.position,
			dialogClass: opts.dialogClass,
			modal: opts.modal,
			closeOnEscape: false,
			open: function() {
					if (opts.alertMode) {
						$("#download-dialog")
							.dialog("widget")
							.attr("id","alertWrapper")
							.find("button.ui-dialog-titlebar-close")
							.attr("id","alertCancelBtn");
						$("#download-dialog").dialog("option", "buttons", [{
								text: btnOkay,
								id: opts.okayButtonId,
								click: function(e, ui) {
									if (opts.okay !== null)
										opts.okay.call();
									closeDialog();
								}
						}]);
						closeToolTips();
						if (opts.callback !== null)
							opts.callback.call();
					}
					else {
						$("#download-dialog")
							.dialog("option", "title", opts.title)
							.dialog("widget")
							.attr("id","pleaseWaitWrapper")
							.find("button.ui-dialog-titlebar-close")
							.attr("id","pleaseWaitCancelBtn");
						if (opts.callback !== null)
							setTimeout(function(){opts.callback.call();},10);
					}
					initButtons();
					g.onResizeScrollArray.push(function(){
						$(".ui-dialog.ui-widget:visible").position( $("#download-dialog").dialog("option","position") );
					});
				},
			close: function() {
				g.onResizeScrollArray.pop();
				if (opts.close !== null)
					opts.close.call();
				}
			});

	// closes dialog
	$("#download-dialog")
		.parent()
		.on('keydown', function(e, ui) {
			if (e.which === $.ui.keyCode.ESCAPE)
				$("#download-dialog").dialog('close');
			e.stopPropagation();
		});
}

/* ------------------------------------------ Extension Functions ------------------------------ */

// alertDialog()
// instantiates pleaseWait();
function alertDialog(opts) {

	var defaultTitle = getTranslation("JavaScript.Title.Attention");

	opts = $.extend({}, {
		title: defaultTitle,
		msg: "",
		width: 300,
		height: "auto",
		maxWidth: false,
		maxHeight: false,
		callback: null,
		okay: null,
		close: null,
		modal: false,
		resizable: false,
		draggable: true,
		dialogClass: 'alertWrapper'
	}, opts || {} );

	pleaseWait({
		title: opts.title,
		msg: opts.msg,
		alertMode: true,
		width: opts.width,
		height: opts.height,
		maxWidth: opts.maxWidth,
		maxHeight: opts.maxHeight,
		modal: opts.modal,
		callback: opts.callback,
		okay: opts.okay,
		close: opts.close,
		modal: opts.modal,
		resizable: opts.resizable,
		draggable: opts.draggable,
		position: opts.position,
		dialogClass: opts.dialogClass
	});
}

/* ------------------------------------------ Alert Functions ------------------------------ */

// used in main functions
function resetInputValueAlertAndFocus(el,alertMsg) {
	el.value = g.inputFocusValue;
	alertDialog({
		msg: alertMsg,
		okay: function(){
			$(el).trigger("select").floatBorder();
		}
	});
}

// called directly from main pages
// called ExpenseReportForm.cfm, HourlyReportForm.cfm, ProjectReportForm.cfm
function pleaseWaitTimeout(opts, timeout) {
	if (timeout == undefined) {
		var timeout = 1000;
	}
	setTimeout(
		function(){
			pleaseWait(opts);
		}, timeout);
}

/* ------------------------------------------ Utility Functions ------------------------------ */

// called from doNotificationAlert()
function ajaxIntervalUpdateRestoreMS() {
	clearInterval(g.ajaxUpdateObj.interval);
	g.ajaxUpdateObj.interval = setInterval(ajaxIntervalUpdate,g.ajaxUpdateObj.ms);
}

// called from initAutoFormConfirms()
function confirmHandler(sel) {
	confirmDialog({
		msg: $(sel).attr("data-confirm-msg"),
		okay: function() {
			$(sel).off("submit.confirmation");
			$(sel).trigger("submit");
		}
	});
}

// called universally from various functions
function closeDialog() {
	// make sure the dialog exists and is open before closing
	if ($("#download-dialog").dialog("instance") && $("#download-dialog").dialog("isOpen")) {
		$("#download-dialog").dialog("close");
	}
}