/*
*   Sets the dialog (mini-modal) for print/pdf settings
*   Sets options for print/pdf output
*   Cleanup after printing
*/
$(document).ready(function(){

  // Watch to see when the browser print modal has opened and closed
  if (window.matchMedia) {
    var mediaQueryList = window.matchMedia('print');

    mediaQueryList.addListener( function(mlist){
      if(mlist.matches){
        // console.log("ElectronicallySigned input = ", $("#ElectronicallySigned")); // will we need this?
        // console.log("ElectronicallySigned input val() = ", $("#ElectronicallySigned").val()); // will we need this?
      }
    });
  }

  // When done printing/saving or closing the browser print modal clean up and restore
  window.onafterprint = function(){
    console.log("window.onafterprint????");
    closeAllNotesRows();
    toggleAllDetails(false);
    // Make sure we only show one legend on screen
    $(".multiLegend").css("display", "none");
    $(".singleLegend").css("display", "block");
    // Make sure the signature section doesn't show on screen
    $(".report-signature").addClass("hideFromPrint").removeClass("showOnPrint");
    $(".report-signature").addClass("hideFromScreen").removeClass("showOnScreen");
  }

  $('[name="FileTypeSelect"]').change(function(event){
    // report uuid
    var uid = this.dataset.id; // from data-id in the <select> tag

    let selectedIndex = $('#FileTypeSelect_'+uid)[0].options.selectedIndex; // extract the index of the option selected
    let selected = $("#opt_"+selectedIndex);
    if(selected.hasClass("unAvailable")){
      $('#FileTypeSelect_'+uid).val('default');
      $("#download-dialog-unavailable").html("");
      $("#download-dialog-unavailable").append(selected.attr("data-message")); // extract the message from the data-message attribute
      $("#download-dialog-unavailable").dialog({
        title: getTranslation("JavaScript.Title.Unsupported"),
        height: "auto", // auto resize to fit text/html
        width: 350,
        autoOpen: true,
        modal: true,
        position: {
          my: "center center",
          at: "center center",
          of: window },
        buttons: [
          {
            text: getTranslation("Content.Btn.Okay"),
            click: function(){
              $(this).closest('.ui-dialog-content').dialog('close');
            }
          }
        ],
        open: function() { }
      });
    } else {

      // wait message thing...
      $('#FileType_'+uid).val( $(this).val() );
      $('#FileTypeSelect_'+uid).val('default');

      // clear
      $("#download-dialog").html("");

      // trigger wait and method
      waitDialog({
        callbackfile: downloadFileType
      });

      // API Download
      function downloadFileType() {
        var url = g.baseApiUrl + '/report/download/' + uid;
        var data = $("#DownloadReportForm_"+uid).serialize();
        var ResultMsg = '';
        var FilePath = '';
        var RedirectUrl = '';
        setTimeout(function(){
          $.ajax({
              url: url,
              type: "POST",
              dataType: "html",
              headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
              data: data,
              success: function(res){
                res = JSON.parse(res);
                ResultMsg = getTranslation("JavaScript.FileReadyForDownloadClickHere");

                if ( res.hasOwnProperty("data") && res.data.hasOwnProperty("FileURL") ){
                  FilePath = res.data.FileURL;
                  RedirectUrl = res.data.RedirectURL;
                }
                if ( res.MsgType === 'ERROR' || FilePath === '' ) {
                  ResultMsg = getTranslation("JavaScript.FileError");
                  $("span.ui-dialog-title").text(getTranslation("JavaScript.Title.FileError"));
                  $('#download-dialog').dialog({ height: "auto" }).html(createErrorDialog(ResultMsg));
                }
                else {
                  $("span.ui-dialog-title").text(getTranslation("JavaScript.Title.FileReady"));
                  $('#download-dialog').dialog({ height: "auto" }).html(createDownloadDialog(FilePath, RedirectUrl, ResultMsg));
                }
              },
              error: function(e){
                ResultMsg = getTranslation("JavaScript.FileError");
                $("span.ui-dialog-title").text(getTranslation("JavaScript.Title.FileError"));
                $('#download-dialog').dialog({ height: "auto" }).html(createErrorDialog(ResultMsg));
              }
          });
        }, 1000);
      }
    }
    // Actions all handled by _ReportPrint.js
  });

});

/**
 * Create dialog box content with open, email, copy for user downloads
 *
 * @param {String} FilePath -
 * @param {String} ResultMsg -
 * @param {Object} opts - properties may contain email address, subject, etc. Not currently in use, but matches payroll _Downloader.js
 * @return {String} html - html in the form of a long String with icon, message, buttons
 */
function createDownloadDialog(FilePath, RedirectUrl, ResultMsg, opts={}){
  console.log("createDownloadDialog(FilePath, RedirectUrl, ResultMsg) ", FilePath, RedirectUrl, ResultMsg);
  let btnEmailTxt = getTranslation("JavaScript.Btn.EmailLink");
  let btnCopyTxt = getTranslation("JavaScript.Btn.CopyLink");
  let orTxt = getTranslation("JavaScript.Or");
  let emailFileUrl = RedirectUrl.replaceAll("?","%3F");
  emailFileUrl = RedirectUrl.replaceAll("&","%26");

  let emailTo = (opts.emailTo) ? opts.emailTo : "";
  let emailSub = (opts.emailSub) ? opts.emailSub : getTranslation("JavaScript.FileDownload.EmailSubject");
  let emailBody = getTranslation("JavaScript.FileDownload.EmailBody.LinkToFile") + '\n\n'
                  + emailFileUrl + '\n\n'
                  + getTranslation("JavaScript.FileDownload.EmailBody.FileAvailability");
  emailBody = encodeURIComponent(emailBody);

  let emailButton =
          `<button id="linkEmailBtn" onclick="window.location.href='`
          + `mailto:` + emailTo
          + `?subject=` + emailSub
          + `&body=` + emailBody
          + `'">` + btnEmailTxt + `</button>`;

  let copyLinkButton = `<button id="linkCopyBtn" onclick="copyToClipboardAsync('` + RedirectUrl + `')">`+ btnCopyTxt + `</button>`;
  let faCircleCheck = '<i class="fa-regular fa-circle-check font-green-dark fa-7x"></i>';
  let html = '<div class="downloaderIcon">'
            + faCircleCheck
            + '</div>'
            + `<p class="downloadLink"><a href="${FilePath}" target="_blank">`+ ResultMsg + `</a></p>`
            + '<p class="orText">--&nbsp;' + orTxt + '&nbsp;--</p>'
            + '<div class="downloadButtons"><div class="eButton">' + emailButton + '</div><div class="lButton">' + copyLinkButton + '<div id="clipboardText"></div></div></div>';
  return html;
}

/**
 * Create error dialog message
 *
 * @param {String} ResultMsg - unfortunate bad news nobody wants to hear
 * @return {String} html - html in the form of a long String with icon, message
 */
function createErrorDialog(ResultMsg){
  let TryAgainMsg = getTranslation('JavaScript.PleaseTryAgain');
  let html =  '<div class="downloaderIcon"><i class="fa-regular fa-triangle-exclamation font-red fa-7x"></i></div>' +
              '<p class="downloadLink">' + ResultMsg + '<br />' + TryAgainMsg + '</p>';
  return html;
}

// Change the pagebreak checkbox as needed with the signature section
function adjustPageBreak(){
  console.log("adjustPageBreak()");
  // If the signature section is turned on make sure page breaks are on (checked) and don't let them be turned off
  if($("#show-signature").is(":checked")){
    $("#page-breaks").prop("checked", true);
    $("#page-breaks").prop("disabled", true);
  }
  // if signature is turned off all the page break checkbox to be used
  if(!$("#show-signature").is(":checked")){
    $("#page-breaks").prop("disabled", false);
  }
}


/**
 * Sets parameters and opens a new page for printing, also updates print settings on the server
 */
function openPrintableReport() {
  let queryString = $("#qString").val();
  // console.log("openPrintableReport(), qString = ", queryString);

  let tableSortOrder = "";
  if($("#TableSortOrder").length > 0){
    tableSortOrder = "&TableSortOrder=" + $("#TableSortOrder").val();
  }

  let paramPageBreaks = "";
  if($("#page-breaks").length > 0){
    paramPageBreaks = ($("#page-breaks").prop("checked")) ? "&AddPageBreaks=true" : "&AddPageBreaks=false";
    updateClientSetting("PDF_PRINT_OPTION_INCLUDE_PAGE_BREAKS", $("#page-breaks").prop("checked").toString());
  }

  let paramExtData = "";
  if($("#include-extdata").length > 0){
    paramExtData = ($("#include-extdata").prop("checked")) ? "&IncludeExtendedData=true" : "&IncludeExtendedData=false";
    updateClientSetting("PDF_PRINT_OPTION_INCLUDE_EXTENDED_DATA", ($("#include-extdata").prop("checked")).toString());
  }

  let paramSignature = "";
  if($('#show-signature').length > 0){
    let selSig = $('#show-signature').find(":selected").text();
    paramSignature = (selSig) ? "&AddSignatures=" + selSig : "&AddSignatures=none";
    updateClientSetting("PDF_PRINT_OPTION_INCLUDE_SIGS", selSig);
  }

  let paramLegend = "";
  if($('#show-legend').length > 0){
    let selLegend = $('#show-legend').find(":selected").text();
    paramLegend = (selLegend) ? "&AddLegend=" + selLegend : "&AddLegend=none";
    updateClientSetting("PDF_PRINT_OPTION_INCLUDE_LEGENDS", selLegend);
  }

  let printSize = "";
  if($("#printSize").length > 0){
    printSize = "&PrintSize=" + $("#printSize").val();
    updateClientSetting("PDF_PRINT_OPTION_PRINT_SIZE", $("#printSize").val());
  }
  window.open(queryString + paramPageBreaks + paramExtData + paramSignature + paramLegend + printSize + tableSortOrder + "&_blank");
  closeTSModal();
}



// ****************************************************
// For use with grid-based "tables" to match the behavior of older standard tables with hide/show notes buttons

var detailsVisible = false; // are all details rows open?
/**
 * Toggle all details tables open or closed when using a GRID layout for the outer table
 *    does not work on old tables
 *
 *  @param {boolean} forceOpen - force all rows open/closed, optional when printing
 */
function toggleAllDetails(forceOpen){
  if(forceOpen !== undefined){
    detailsVisible = !forceOpen;
  }

  if(detailsVisible){
    $(".g-wide").hide();
    $(".g-heading").hide();  // to make sure individually toggled rows close completly

    $(".control-col span i.plus").removeClass("hide").addClass("show"); // change all icons to plus
    $(".control-col span i.minus").removeClass("show").addClass("hide");
    $(".g-heading").addClass("showOnPrint");
    $(".g-heading").removeClass("hideFromPrint");
    $(".g-head-1").show();   // except the first row still needs the heading showing
  }
  else {
    $(".g-wide").show();
    // change all icons to minus
    $(".control-col span i.plus").removeClass("show").addClass("hide");
    $(".control-col span i.minus").removeClass("hide").addClass("show");
  }

  if($("#show-notes").prop("checked")){
    $(".g-heading").removeClass("hideFromPrint");
    $(".g-heading").addClass("showOnPrint");
  }
  else {
    $(".g-heading").addClass("hideFromPrint");
    $(".g-heading").removeClass("showOnPrint");
    $(".g-heading").hide();
    $(".g-head-1").show();
  }

  detailsVisible = !detailsVisible;
}

/**
 * Toggles a specific details table open or closed when using a GRID layout for the outer table
 *
 *  @param {int} index - an index for the clicked on, row created when the page is rendered by CF
 */
function toggleDetails(index) {
  let opener = $("#control-"+index+" i.plus");
  let closer = $("#control-"+index+" i.minus");
  if(opener.hasClass("show")){
    opener.removeClass("show").addClass("hide");
    closer.removeClass("hide").addClass("show");
  }
  else if(closer.hasClass("show")){
    opener.removeClass("hide").addClass("show");
    closer.removeClass("show").addClass("hide");
  }
  $(".g-head-"+index).toggle();
  $(".detail-table-"+index).toggle();
  $(".g-head-1").show();   // except the first row
}


/** ------------------------------------------------------
 *
 * For check all/none on Preliminary Payroll Reports
 */
$(document).ready(function(){


  if(typeof $("#prelimUserIdList").val() !== "undefined"){  // make sure the user id list exists, if not ignore all this

    // set the list of all available users to include in payroll operation
    let initialUserIdArray = $("#prelimUserIdList").val().split(",");

    // set the list of default users to include in payroll operation
    var userIdArray = initialUserIdArray;

    // console.log("setSubmitPayrollData, initial: ", userIdArray);

    /**
     * Fired when the check all/none checkbox changes. Updates the array of selected User IDs
     *
     * @param {object} event
     */
    $("#checkAllPayrollData").on("change", function(event){
      if ( event.target.checked == true ){
        userIdArray = initialUserIdArray; // reset to original
        $(".userCheckbox").prop("checked", true);
      }
      else {
        userIdArray = []; // empty the array as nobody is selected
        $(".userCheckbox").prop("checked", false);
      }
    });

    /**
     * Update the list of selected User IDs when individual user rows are selected
     *
     * @param {object} event
     */
    $(".userCheckbox").on("change", function(event){

      let userId = event.target.value;

      // console.log("%c registerPayrollData(userId and event): ", "color:yellow;background-color:blue", "userId="+userId, event);
      if ( event.target.checked == true ){
        userIdArray.push( userId );
      }
      else {
        var userIdx = userIdArray.indexOf( userId );
        if ( userIdx > -1 ){
          userIdArray.splice( userIdx , 1 );
        }
      }
    });

    $("#setSubmitPayrollData").on("click", function(){
      // console.log("setSubmitPayrollData, set this: ", userIdArray);
      document.getElementById("FormUserID").value = userIdArray;
    });

  }
});


/**
 *  When a submit button is disabled by default and waiting to be enabled
 *    this will check when the data has been rendered,
 *    then enable the submit button
 */
$(document).ready(function(){
  // if a submit button with the id of "report-submit-delayed" is set we'll start a timer
  if($("#report-submit-delayed").length){

    var reportInterval = setInterval(function(){

      if($("#table-complete").val() == "true"){  // #table-complete *should* be "true" when the table has fully rendered
        $("#report-submit-delayed").removeAttr("disabled"); // enable the button
        clearInterval(reportInterval);
      }
    }, 2000);
  }
});


/**
 * Open/close all notes on the page for printing
 *    repurposed and streamlined from _Notes.js
 */
function reportNotesControlAll() {
  var $parentTable = $("table"); // grab all the tables in the current report

  if (!$(this).is(".expanded")) {
    $(this).toggleClass("expanded",true);
    $parentTable
      .find("tr > td > div.notesControl:not(.expanded)").toggleClass("expanded",true).end()
      .find("tr.notes:not(.expanded,.hiddenRow),tr.hideShowAttached:not(.expanded)").show().toggleClass("expanded",true).end()
      .find("tr > td span.hideOnCollapse.noDisplay").toggleClass("noDisplay",false).end();
  }
  else {
    $(this).removeClass("expanded");
    $parentTable
      .find("tr > td > div.notesControl.expanded").toggleClass("expanded",false).end()
      .find("tr.notes:not(.hiddenRow),tr.hideShowAttached").hide().toggleClass("expanded",false).end()
      .find("tr > td span.hideOnCollapse:not(.noDisplay)").toggleClass("noDisplay",true);
  }
  return true;
}
