var lang_en = lang_en || {};
lang_en['Content.Btn.AcceptSelected'] = 'Accept Selected';
lang_en['Content.Btn.ActivateFreeAccount'] = 'Activate My Free Account';
lang_en['Content.Btn.Add'] = 'Add';
lang_en['Content.Btn.Allow'] = 'Allow';
lang_en['Content.Btn.AllowAndApprove'] = 'Allow and Approve';
lang_en['Content.Btn.AllowDenyDisabledAutoClockOut'] = 'Allow and Deny Buttons disabled due to automatic clock out';
lang_en['Content.Btn.AllowDenyDisabledInEdit'] = 'Allow and Deny Buttons disabled during an edit';
lang_en['Content.Btn.AllowDenyDisabledPermission'] = 'Allow and Deny Buttons permission disabled for this account';
lang_en['Content.Btn.AllowSelected'] = 'Allow Selected';
lang_en['Content.Btn.Annotate'] = 'Annotate';
lang_en['Content.Btn.Apply'] = 'Apply';
lang_en['Content.Btn.ApplyRates'] = 'Apply Rates';
lang_en['Content.Btn.Approve'] = 'Approve';
lang_en['Content.Btn.ApproveSelected'] = 'Approve Selected';
lang_en['Content.Btn.Archive'] = 'Archive';
lang_en['Content.Btn.ArchiveSelected'] = 'Archive Selected';
lang_en['Content.Btn.ArchiveUser'] = 'Archive User';
lang_en['Content.Btn.ArchiveUsers'] = 'Archive Users';
lang_en['Content.Btn.BackToAccount'] = 'Back To Account';
lang_en['Content.Btn.BackToMenu'] = 'Back to Menu';
lang_en['Content.Btn.Browse'] = 'Browse';
lang_en['Content.Btn.Cancel'] = 'Cancel';
lang_en['Content.Btn.CancelAccount'] = 'Cancel Account';
lang_en['Content.Btn.CancelDeleteAccount'] = 'Cancel and Delete Account';
lang_en['Content.Btn.Clear'] = 'Clear';
lang_en['Content.Btn.ClearRecentlyModified'] = 'Clear Recently Modified';
lang_en['Content.Btn.ClockIn'] = 'Clock In';
lang_en['Content.Btn.ClockInOnlyBetween'] = 'Clock In is only allowed between';
lang_en['Content.Btn.ClockInOutNotAtLocation'] = 'Clock In/Out not allowed from this location';
lang_en['Content.Btn.ClockInOutNotWithPhoto'] = 'Clock In/Out is not allowed because photo timestamp security is enabled for your account';
lang_en['Content.Btn.ClockOut'] = 'Clock Out';
lang_en['Content.Btn.Close'] = 'Close';
lang_en['Content.Btn.CompanyWall'] = 'Company Wall';
lang_en['Content.Btn.CompanyWallUpdate'] = 'Update';
lang_en['Content.Btn.ConfirmAndPay'] = 'Confirm and Pay';
lang_en['Content.Btn.Continue'] = 'Continue';
lang_en['Content.Btn.ContinueAndReadLater'] = 'Continue And Read Later';
lang_en['Content.Btn.ContinueSelectOptions'] = 'Continue to Select Options';
lang_en['Content.Btn.ContinueToCheckout'] = 'Continue to Checkout';
lang_en['Content.Btn.Copy'] = 'Copy';
lang_en['Content.Btn.CopyRateToAll'] = 'Copy Rate To All';
lang_en['Content.Btn.CopyThisWeekToNextWeek'] = 'Copy This Week To Next Week';
lang_en['Content.Btn.CopyWithDefaultValues'] = 'Copy With Default Values';
lang_en['Content.Btn.CreateNewPassword'] = 'Create New Password';
lang_en['Content.Btn.Crop'] = 'Crop';
lang_en['Content.Btn.DeclineSelected'] = 'Decline Selected';
lang_en['Content.Btn.DefaultRates'] = 'Default Rates';
lang_en['Content.Btn.Delete'] = 'Delete';
lang_en['Content.Btn.DeleteCurrentPhoto'] = 'Delete Current Photo';
lang_en['Content.Btn.DeleteKeys'] = 'Delete Keys';
lang_en['Content.Btn.DeleteSelected'] = 'Delete Selected';
lang_en['Content.Btn.Deny'] = 'Deny';
lang_en['Content.Btn.DenySelected'] = 'Deny Selected';
lang_en['Content.Btn.DisableCutOff'] = 'Disable Cutoff';
lang_en['Content.Btn.DisableLogin'] = 'Disable Login';
lang_en['Content.Btn.DisconnectQBO'] = 'Disconnect from QuickBooks Online';
lang_en['Content.Btn.DismissAnnouncement'] = 'Dismiss Announcement';
lang_en['Content.Btn.DoNotCopy'] = 'Do Not Copy';
lang_en['Content.Btn.Download'] = 'Download';
lang_en['Content.Btn.EnableCutOff'] = 'Enable Cutoff';
lang_en['Content.Btn.EnableLogin'] = 'Enable Login';
lang_en['Content.Btn.ExpenseEntry'] = 'Expense Entry';
lang_en['Content.Btn.ExportSelected'] = 'Export Selected';
lang_en['Content.Btn.FilterByDate'] = 'Filter by Date';
lang_en['Content.Btn.FinishRegistration'] = 'Finish Registration';
lang_en['Content.Btn.GenerateAPIKeys'] = 'Generate API Key';
lang_en['Content.Btn.GenerateAuthToken'] = 'Generate Auth Token';
lang_en['Content.Btn.GenerateCredentials'] = 'Generate Credentials';
lang_en['Content.Btn.GetStarted'] = 'Get Started';
lang_en['Content.Btn.Go'] = 'Go';
lang_en['Content.Btn.HideWall'] = 'Hide Wall';
lang_en['Content.Btn.History'] = 'History';
lang_en['Content.Btn.HourlyEntry'] = 'Hourly Entry';
lang_en['Content.Btn.HourlyTime'] = 'Hourly Time';
lang_en['Content.Btn.Import'] = 'Import';
lang_en['Content.Btn.Landscape'] = 'Landscape';
lang_en['Content.Btn.Login'] = 'Login';
lang_en['Content.Btn.LoginAgain'] = 'Login Again';
lang_en['Content.Btn.LoginAsX'] = 'Login as {1}';
lang_en['Content.Btn.Logout'] = 'Logout';
lang_en['Content.Btn.LogoutOnAllDevices'] = 'Logout On All Devices';
lang_en['Content.Btn.MarkAllAsRead'] = 'Mark All as Read';
lang_en['Content.Btn.MarkAsBillable'] = 'Mark as Billable';
lang_en['Content.Btn.MarkAsUnbillable'] = 'Mark as Unbillable';
lang_en['Content.Btn.MarkSelectedAsBillable'] = 'Mark Selected As Billable';
lang_en['Content.Btn.MarkSelectedAsUnbillable'] = 'Mark Selected As Unbillable';
lang_en['Content.Btn.Merge'] = 'Merge';
lang_en['Content.Btn.Next'] = 'Next';
lang_en['Content.Btn.No'] = 'No';
lang_en['Content.Btn.NotifySupervisorOfClosedPayrollPeriod'] = 'Notify My Supervisor I Have Closed A Payroll Period';
lang_en['Content.Btn.NotifySupervisorOfRecordsApproved'] = 'Notify My Supervisor I Have Approved Time';
lang_en['Content.Btn.NotifySupervisorOfRecordsArchived'] = 'Notify My Supervisor I Have Archived Time';
lang_en['Content.Btn.NotifySupervisorOfRecordsOpened'] = 'Notify My Supervisor I Have Opened Expenses';
lang_en['Content.Btn.NotifySupervisorOfRecordsReconciled'] = 'Notify My Supervisor I Have Reconciled Expenses';
lang_en['Content.Btn.NotifySupervisorTimesheetIsReady'] = 'Notify My Supervisor My Timesheet is Ready';
lang_en['Content.Btn.Okay'] = 'Okay';
lang_en['Content.Btn.Open'] = 'Open';
lang_en['Content.Btn.OpenFile'] = 'Open File';
lang_en['Content.Btn.OpenSelected'] = 'Open Selected';
lang_en['Content.Btn.PartnerLogin'] = 'Partner Login';
lang_en['Content.Btn.Pause'] = 'Pause';
lang_en['Content.Btn.PauseAllTimers'] = 'Pause All Timers';
lang_en['Content.Btn.PermanentlyDeleteSelected'] = 'Permanently Delete Selected';
lang_en['Content.Btn.Portrait'] = 'Portrait';
lang_en['Content.Btn.Previous'] = 'Previous';
lang_en['Content.Btn.Print'] = 'Print';
lang_en['Content.Btn.Print.ExtendedData'] = 'Extended Data';
lang_en['Content.Btn.Print.Legend'] = 'Legend';
lang_en['Content.Btn.Print.Notes'] = 'Notes';
lang_en['Content.Btn.Print.PageBreaks'] = 'Page Breaks';
lang_en['Content.Btn.Print.PrintPDF'] = 'Print / PDF';
lang_en['Content.Btn.Print.PrintPDFOptions'] = 'Print / PDF Options';
lang_en['Content.Btn.Print.PrintSize'] = 'Print Size';
lang_en['Content.Btn.Print.SignatureBlocks'] = 'Signature Blocks';
lang_en['Content.Btn.ProjectEntry'] = 'Project Entry';
lang_en['Content.Btn.ProjectTimers'] = 'Project Timers';
lang_en['Content.Btn.Publish'] = 'Publish';
lang_en['Content.Btn.Reconcile'] = 'Renconcile';
lang_en['Content.Btn.ReconcileSelected'] = 'Reconcile Selected';
lang_en['Content.Btn.Refresh'] = 'Refresh';
lang_en['Content.Btn.RemoveCode'] = 'Remove Code';
lang_en['Content.Btn.Reset'] = 'Reset';
lang_en['Content.Btn.ResetPWsResendEmail'] = 'Resend Email';
lang_en['Content.Btn.Restart'] = 'Restart';
lang_en['Content.Btn.Restore'] = 'Restore';
lang_en['Content.Btn.RestoreSelected'] = 'Restore Selected';
lang_en['Content.Btn.ReturnToHomepage'] = 'Return To Homepage';
lang_en['Content.Btn.ReturnToLogin'] = 'Return To Login';
lang_en['Content.Btn.Save'] = 'Save';
lang_en['Content.Btn.SaveAndContinue'] = 'Save And Continue';
lang_en['Content.Btn.SaveChanges'] = 'Save Changes';
lang_en['Content.Btn.SaveClosePayrollReport'] = 'Save and Close the Payroll Report';
lang_en['Content.Btn.SaveNote'] = 'Save Note';
lang_en['Content.Btn.Schedules'] = 'Schedules';
lang_en['Content.Btn.Search'] = 'Search';
lang_en['Content.Btn.SelectAll'] = 'Select All';
lang_en['Content.Btn.Send'] = 'Send';
lang_en['Content.Btn.SendReferral'] = 'Send Referral';
lang_en['Content.Btn.SetTimeEntryCutoffToX'] = 'Set Time Entry Cutoff To {1}';
lang_en['Content.Btn.SignSelectedRecords'] = 'Sign Selected Records';
lang_en['Content.Btn.StartAllTimers'] = 'Start All Timers';
lang_en['Content.Btn.StartNewTimer'] = 'Start New Timer';
lang_en['Content.Btn.Stop'] = 'Stop';
lang_en['Content.Btn.StopAllTimers'] = 'Stop All Timers';
lang_en['Content.Btn.Submit'] = 'Submit';
lang_en['Content.Btn.TakePhoto'] = 'Take Photo';
lang_en['Content.Btn.TimetoDecimal'] = 'Toggle Time Format';
lang_en['Content.Btn.Unapprove'] = 'Unapprove';
lang_en['Content.Btn.UnapproveSelected'] = 'Unapprove Selected';
lang_en['Content.Btn.Unarchive'] = 'Unarchive';
lang_en['Content.Btn.UnarchiveSelected'] = 'Unarchive Selected';
lang_en['Content.Btn.Unpublish'] = 'Unpublish';
lang_en['Content.Btn.Update'] = 'Update';
lang_en['Content.Btn.UpdateContinueAccount'] = 'Update and Continue To My Account';
lang_en['Content.Btn.UpdatePayrollReport'] = 'Update Payroll Report';
lang_en['Content.Btn.UpdateView'] = 'Update View';
lang_en['Content.Btn.Upload'] = 'Upload';
lang_en['Content.Btn.UserRatesIndividualFiles'] = 'User Override Rates (Individual Files)';
lang_en['Content.Btn.UserRatesSingleFile'] = 'User Override Rates (Single File)';
lang_en['Content.Btn.View'] = 'View';
lang_en['Content.Btn.XMinBreak'] = '{1} min. Break';
lang_en['Content.Btn.Yes'] = 'Yes';
lang_en['Content.Checkbox.AddNew'] = 'Add New';
lang_en['Content.Checkbox.AllUsersWhereApplicable'] = 'Apply the selected settings to all users where applicable';
lang_en['Content.Checkbox.AllowAccountCodeToBeUsedByAnyEmployee'] = 'Allow this Account Code to be used by any User';
lang_en['Content.Checkbox.AllowAllUserToPostToCompanyMessageBoard'] = 'Allow all users to post to the company message board';
lang_en['Content.Checkbox.AllowCustomerToBeUsedByAnyEmployee'] = 'Allow this Customer to be used by any User';
lang_en['Content.Checkbox.AllowCustomerToBeUsedWithAnyProject'] = 'Allow this Customer to be used with any Project';
lang_en['Content.Checkbox.AllowProjectToBeUsedByAnyEmployee'] = 'Allow this Project to be used by any User';
lang_en['Content.Checkbox.AllowProjectToBeUsedWithAnyAssignedCustomer'] = 'Allow this Project to be used with any Customer assigned to me';
lang_en['Content.Checkbox.AllowProjectToBeUsedWithAnyCustomer'] = 'Allow this Project to be used with any Customer';
lang_en['Content.Checkbox.ApprovedRecords'] = 'Approved Records';
lang_en['Content.Checkbox.ArchivedRecords'] = 'Archived Records';
lang_en['Content.Checkbox.ArchivedTime'] = 'Archived Time';
lang_en['Content.Checkbox.BillRates'] = 'Bill Rates';
lang_en['Content.Checkbox.BillableTime'] = 'Billable Time';
lang_en['Content.Checkbox.CostRates'] = 'Cost Rates';
lang_en['Content.Checkbox.Delete'] = 'Delete';
lang_en['Content.Checkbox.DeleteReceipt'] = 'Delete Receipt';
lang_en['Content.Checkbox.Detailed'] = 'Detailed';
lang_en['Content.Checkbox.HourlyTimeForPayroll'] = 'Hourly Time for Payroll';
lang_en['Content.Checkbox.IAcceptThe'] = 'I accept the';
lang_en['Content.Checkbox.IncludeAllDatesEvenWhenNoShiftsWereWorked'] = 'Include all dates even when no shifts were worked';
lang_en['Content.Checkbox.IncludeApprovedRecords'] = 'Include Approved Records';
lang_en['Content.Checkbox.IncludeArchivedRecords'] = 'Include Archived Records';
lang_en['Content.Checkbox.IncludeArchivedTime'] = 'Include Archived Time';
lang_en['Content.Checkbox.IncludeBillRates'] = 'Include Bill Rates';
lang_en['Content.Checkbox.IncludeBillableTime'] = 'Include Billable Time';
lang_en['Content.Checkbox.IncludeCostRates'] = 'Include Cost Rates';
lang_en['Content.Checkbox.IncludeGeoLocationInfo'] = 'Include geo-location information';
lang_en['Content.Checkbox.IncludeLocationAndDistanceInfo'] = 'Include location and distance information';
lang_en['Content.Checkbox.IncludeNewRecords'] = 'Include New Records';
lang_en['Content.Checkbox.IncludeNewTime'] = 'Include New Time';
lang_en['Content.Checkbox.IncludeOnlyRecordsWithAlerts'] = 'Include only records with alerts';
lang_en['Content.Checkbox.IncludeOpenExpenses'] = 'Include Open Expenses';
lang_en['Content.Checkbox.IncludeOpenRecords'] = 'Include Open Records';
lang_en['Content.Checkbox.IncludePaidRecords'] = 'Include Paid Records';
lang_en['Content.Checkbox.IncludePayRates'] = 'Include Pay Rates';
lang_en['Content.Checkbox.IncludePreviouslyProcessedRecords'] = 'Include previously processed records';
lang_en['Content.Checkbox.IncludeReconciledExpenses'] = 'Include Reconciled Expenses';
lang_en['Content.Checkbox.IncludeRecordsWithPendingAlerts'] = 'Include Records With Pending Alerts';
lang_en['Content.Checkbox.IncludeSignedRecords'] = 'Include Signed Records';
lang_en['Content.Checkbox.IncludeUnapprovedRecords'] = 'Include Unapproved Records';
lang_en['Content.Checkbox.IncludeUnbillableTime'] = 'Include Unbillable Time';
lang_en['Content.Checkbox.IncludeUnsignedRecords'] = 'Include Unsigned Records';
lang_en['Content.Checkbox.Items.CheckForDuplicates'] = 'Check for duplicate items before adding.';
lang_en['Content.Checkbox.Items.CheckForDuplicatesSub'] = 'If checking for duplicates and a duplicate exists, none of the values will be imported.';
lang_en['Content.Checkbox.Items.CheckForHeaderRow'] = 'Check if file includes header row. If checked, the first line in the file will be omitted from import.';
lang_en['Content.Checkbox.Items.CheckForHeaderRowSub'] = 'Leave this selected if using the template file.';
lang_en['Content.Checkbox.MakeCalendarOnlyVisibleToMe'] = 'Make this calendar visible only to me';
lang_en['Content.Checkbox.MakeEventsOnCalendarOnlyEditableByMe'] = 'Make events on this calendar editable only by me';
lang_en['Content.Checkbox.MileageAndExpenseTracking'] = 'Mileage and Expense Tracking';
lang_en['Content.Checkbox.NewRecords'] = 'New Records';
lang_en['Content.Checkbox.NewTime'] = 'New Time';
lang_en['Content.Checkbox.OpenExpenses'] = 'Open Expenses';
lang_en['Content.Checkbox.OpenRecords'] = 'Open Records';
lang_en['Content.Checkbox.PaidRecords'] = 'Paid Records';
lang_en['Content.Checkbox.PauseRunningTimers'] = 'Pause currently running timers?';
lang_en['Content.Checkbox.PayRates'] = 'Pay Rates';
lang_en['Content.Checkbox.ProjectTimeForBilling'] = 'Project Time for Billing or Job Costing';
lang_en['Content.Checkbox.RatesAppliedFromFollowingDate'] = 'When updating user override rates, this tool can apply the new rates to existing time records beginning on the following date. (Feature not available when updating Default Rates).';
lang_en['Content.Checkbox.ReconciledExpenses'] = 'Reconciled Expenses';
lang_en['Content.Checkbox.RecordsExceedingXContinuous'] = 'Identify records exceeding {1} continuous hours worked';
lang_en['Content.Checkbox.RecordsWithPendingAlerts'] = 'Records With Pending Alerts';
lang_en['Content.Checkbox.SelectAll'] = 'Select All';
lang_en['Content.Checkbox.SelectCustomersAllowedToUseProject'] = 'Select Customers allowed to use this Project.';
lang_en['Content.Checkbox.SelectDeselectAll'] = 'Select/Deselect All';
lang_en['Content.Checkbox.SelectEmployeesAllowedToUseAccountCode'] = 'Select Users allowed to use this Account Code.';
lang_en['Content.Checkbox.SelectEmployeesAllowedToUseCustomer'] = 'Select Users allowed to use this Customer.';
lang_en['Content.Checkbox.SelectEmployeesAllowedToUseProject'] = 'Select Users allowed to use this Project.';
lang_en['Content.Checkbox.SelectProjectsAllowedToUseCustomer'] = 'Select Projects allowed to use this Customer.';
lang_en['Content.Checkbox.SelectUsersToUpdateUpdateRecords'] = 'Select users whose records should be updated.';
lang_en['Content.Checkbox.SelectWhichUsersAreAllowedToPostToCompanyMessageBoard'] = 'Select which users are allowed to post to the company message board';
lang_en['Content.Checkbox.ShareCalendarAndAllowEventsToBeEditedBy'] = 'Share this calendar and allow events to be edited by the following users';
lang_en['Content.Checkbox.ShareThisCalendarWithTheFollowingUsers'] = 'Share this calendar with the following users';
lang_en['Content.Checkbox.ShowGroupsEvenIfNoRecords'] = 'Show groups even if there are no records (0 hours) within the selected range';
lang_en['Content.Checkbox.ShowPreviouslyApprovedRecords'] = 'Show previously approved records when viewing records to approve';
lang_en['Content.Checkbox.SignedRecords'] = 'Signed Records';
lang_en['Content.Checkbox.Summary'] = 'Summary';
lang_en['Content.Checkbox.TZPreserveTimestamp'] = 'Preserve the relative timestamp values of standard and salaried time records';
lang_en['Content.Checkbox.TZUpdateUserSettings'] = 'Update user settings to new time zone';
lang_en['Content.Checkbox.UnapprovedRecords'] = 'Unapproved Records';
lang_en['Content.Checkbox.UnbillableTime'] = 'Unbillable Time';
lang_en['Content.Checkbox.UnsignedRecords'] = 'Unsigned Records';
lang_en['Content.Checkbox.UseIndividualEmployeeSchedule'] = 'Use individual employee schedule';
lang_en['Content.Checkbox.UseIndividualEmployeeShiftSettings'] = 'Use individual employee shift settings';
lang_en['Content.Checkbox.UseThisCompanyWideShiftSetting'] = 'Use this company wide shift setting';
lang_en['Content.Checkbox.UserPWRequirement'] = 'Require users to change their password every 90 days.';
lang_en['Content.Checkbox.Users.SendNewUserListToAdmin'] = 'Email a copy of login credentials to:';
lang_en['Content.Checkbox.Users.SendWelcomeEmailToNewUsers'] = 'Send welcome emails to new users.';
lang_en['Content.Checkbox.Users.ValidateImportFileOnly'] = 'Validate file format only. Do not import new users.';
lang_en['Content.Checkbox.ViewSelectCustomersAllowedToUseProject'] = 'View and/or select which Customers are allowed to use this Project.';
lang_en['Content.Checkbox.ViewSelectEmployeesAllowedToUseAccountCode'] = 'View and/or select which Users are allowed to use this Account Code.';
lang_en['Content.Checkbox.ViewSelectEmployeesAllowedToUseCustomer'] = 'View and/or select which Users are allowed to use this Customer.';
lang_en['Content.Checkbox.ViewSelectEmployeesAllowedToUseProject'] = 'View and/or select which Users are allowed to use this Project.';
lang_en['Content.Checkbox.ViewSelectProjectsAllowedToUseCustomer'] = 'View and/or select which Projects are allowed to use this Customer.';
lang_en['Content.Clock.AutoClockOutMsg'] = 'YOU WILL BE CLOCKED OUT AUTOMATICALLY AT {1} TODAY AND ARE NOT AUTHORIZED TO WORK PAST THAT TIME. BE PREPARED TO LEAVE WORK AT THAT TIME. IF YOU ARE UNABLE TO LEAVE WORK ON TIME, USE THE TIME ENTRY FORM TO ADD YOUR UNAUTHORIZED TIME BEFORE YOU LEAVE.';
lang_en['Content.Clock.AutoClockOutTitle'] = 'AUTOMATIC CLOCK OUT AT {1}';
lang_en['Content.Clock.NewTimeWorked'] = 'New Time Worked';
lang_en['Content.Clock.ProjectTimersPaused'] = 'Project Timers Paused';
lang_en['Content.Clock.ProjectTimersStopped'] = 'Project Timers Stopped';
lang_en['Content.Clock.TimeWorked'] = 'Time Worked';
lang_en['Content.Clock.XClockedInOnXAtZ'] = '{1} clocked in on {2} at {3}';
lang_en['Content.Clock.XClockedOutOnXAtZ'] = '{1} clocked out on {2} at {3}';
lang_en['Content.Day.Full.Friday'] = 'Friday';
lang_en['Content.Day.Full.Monday'] = 'Monday';
lang_en['Content.Day.Full.Saturday'] = 'Saturday';
lang_en['Content.Day.Full.Sunday'] = 'Sunday';
lang_en['Content.Day.Full.Thursday'] = 'Thursday';
lang_en['Content.Day.Full.Tuesday'] = 'Tuesday';
lang_en['Content.Day.Full.Wednesday'] = 'Wednesday';
lang_en['Content.Day.Short.Friday'] = 'Fri';
lang_en['Content.Day.Short.Monday'] = 'Mon';
lang_en['Content.Day.Short.Saturday'] = 'Sat';
lang_en['Content.Day.Short.Sunday'] = 'Sun';
lang_en['Content.Day.Short.Thursday'] = 'Thu';
lang_en['Content.Day.Short.Tuesday'] = 'Tue';
lang_en['Content.Day.Short.Wednesday'] = 'Wed';
lang_en['Content.Desc.Page'] = 'Page';
lang_en['Content.Desc.ResultsPerPage'] = 'Results Per Page';
lang_en['Content.File.Download.BillingBreakdown'] = 'Billing Breakdown';
lang_en['Content.File.Download.PDFExpenseReceipts'] = 'Receipts (PDF)';
lang_en['Content.File.Download.PDFExpenseReceipts.AdditionalInfo'] = 'There must be one or more receipts in the report data.';
lang_en['Content.File.Download.Paychex'] = 'Paychex Payroll';
lang_en['Content.File.Download.PayrollByAccountCode'] = 'Payroll By Account Code';
lang_en['Content.File.Download.QuickBooksCSV'] = 'QuickBooks CSV';
lang_en['Content.File.Download.QuickBooksIIF'] = 'QuickBooks IIF';
lang_en['Content.File.Download.TextCSV'] = 'Text/CSV';
lang_en['Content.Input.BragsPlaceholder'] = 'Share something with your team';
lang_en['Content.Input.Everyone'] = 'Everyone';
lang_en['Content.Input.SigInstruction'] = 'Leave blank for default text. Up to 255 characters.';
lang_en['Content.Label.1pt0Hours'] = '1.0 Hours';
lang_en['Content.Label.1pt5Hours'] = '1.5 Hours';
lang_en['Content.Label.2pt0Hours'] = '2.0 Hours';
lang_en['Content.Label.AIChat'] = 'AI Chat';
lang_en['Content.Label.APIHeader'] = 'API Header';
lang_en['Content.Label.APIKey'] = 'API Key';
lang_en['Content.Label.Aborted'] = 'Aborted';
lang_en['Content.Label.Absent'] = 'Absent';
lang_en['Content.Label.AccessLevel'] = 'Access Level';
lang_en['Content.Label.AccessLimit'] = 'Access Limit';
lang_en['Content.Label.AccessPeriod'] = 'Access Period';
lang_en['Content.Label.AccountAlerts'] = 'Account Alerts';
lang_en['Content.Label.AccountCode'] = 'Account Code';
lang_en['Content.Label.AccountCodes'] = 'Account Codes';
lang_en['Content.Label.AccountNumber'] = 'Account Number';
lang_en['Content.Label.AccountType'] = 'Account Type';
lang_en['Content.Label.Accounts'] = 'Accounts';
lang_en['Content.Label.AccrualDate'] = 'Accrual Date';
lang_en['Content.Label.AccrualRate'] = 'Accrual Rate';
lang_en['Content.Label.AccrualRate.ByHoursWorked'] = 'By Hours Worked';
lang_en['Content.Label.AccrualRate.Daily'] = 'Daily';
lang_en['Content.Label.AccrualRate.EveryTwoWeeks'] = 'Every Two Weeks';
lang_en['Content.Label.AccrualRate.EveryWeek'] = 'Every Week';
lang_en['Content.Label.AccrualRate.FirstOfTheMonth'] = 'First of the Month';
lang_en['Content.Label.AccrualRate.None'] = 'None';
lang_en['Content.Label.AccrualRate.OnceYearly'] = 'Once Yearly';
lang_en['Content.Label.AccrualRate.TwiceAMonth'] = 'Twice a Month (1st & 15th)';
lang_en['Content.Label.AccrualRate.Unknown'] = 'Unknown';
lang_en['Content.Label.AccrualSettings'] = 'Accrual Settings';
lang_en['Content.Label.Accrued'] = 'Accrued';
lang_en['Content.Label.AccruedPerYear'] = 'Accrued/Yr';
lang_en['Content.Label.AccruedX'] = 'Accrued {1}';
lang_en['Content.Label.Accuracy'] = 'Accuracy';
lang_en['Content.Label.AccuracyInMeters'] = 'Accuracy (m)';
lang_en['Content.Label.Action'] = 'Action';
lang_en['Content.Label.Active'] = 'Active';
lang_en['Content.Label.ActiveUsers'] = 'Active Users';
lang_en['Content.Label.AddNewCalendar'] = 'Add New Calendar';
lang_en['Content.Label.AddNewListItem'] = 'Add New List Item';
lang_en['Content.Label.AdditionalDetails'] = 'Additional Details';
lang_en['Content.Label.AdditionalInformation'] = 'Additional Information';
lang_en['Content.Label.Address'] = 'Address';
lang_en['Content.Label.Address1'] = 'Address 1';
lang_en['Content.Label.Address2'] = 'Address 2';
lang_en['Content.Label.Admin'] = 'Admin';
lang_en['Content.Label.Administrator'] = 'Administrator';
lang_en['Content.Label.Administrators'] = 'Administrators';
lang_en['Content.Label.Affiliate'] = 'Affiliate';
lang_en['Content.Label.AffiliateStatus.Active'] = 'Active';
lang_en['Content.Label.AffiliateStatus.Archived'] = 'Archived';
lang_en['Content.Label.AffiliateStatus.Deleted'] = 'Deleted';
lang_en['Content.Label.Alert'] = 'Alert';
lang_en['Content.Label.AlertsFor'] = 'Alerts for';
lang_en['Content.Label.AllSelectedTotal'] = 'All Selected Total';
lang_en['Content.Label.AllowAccess'] = 'Allow Access';
lang_en['Content.Label.Amount'] = 'Amount';
lang_en['Content.Label.AmountBilled'] = 'Amount Billed';
lang_en['Content.Label.AndXMore'] = 'and {1} more ...';
lang_en['Content.Label.Announcement'] = 'Announcement';
lang_en['Content.Label.AnnualReview'] = 'Annual Review';
lang_en['Content.Label.AnnualXtime'] = 'Annual {1} Time';
lang_en['Content.Label.Answer'] = 'Answer';
lang_en['Content.Label.Any'] = 'Any';
lang_en['Content.Label.Applied'] = 'Applied';
lang_en['Content.Label.ApproveArchive'] = 'Approve and Archive';
lang_en['Content.Label.ApproveOnly'] = 'Approve Only';
lang_en['Content.Label.ApproveRecord'] = 'Approve Record';
lang_en['Content.Label.Approved'] = 'Approved';
lang_en['Content.Label.ApprovedAbbrv'] = 'Apr';
lang_en['Content.Label.ApproxUsers'] = 'Approximate Users';
lang_en['Content.Label.ApproxUsers.Freelancer'] = 'Freelancer (free account)';
lang_en['Content.Label.ApproxUsers.SixToFifteen'] = '6 - 15';
lang_en['Content.Label.ApproxUsers.UpToFive'] = 'Up to 5 Users';
lang_en['Content.Label.Archive'] = 'Archive';
lang_en['Content.Label.Archived'] = 'Archived';
lang_en['Content.Label.ArchivedUsers'] = 'Archived Users';
lang_en['Content.Label.At'] = 'At';
lang_en['Content.Label.AtLocation'] = 'At Location';
lang_en['Content.Label.AuditTrail'] = 'Audit Trail {1,number,#}';
lang_en['Content.Label.AutoDeduct.Break'] = 'Break';
lang_en['Content.Label.AutoDeduct.Meal'] = 'Meal';
lang_en['Content.Label.AutoDeduct.None'] = 'None';
lang_en['Content.Label.AutoDeduct.Unknown'] = 'Unknown';
lang_en['Content.Label.AutoDeductType'] = 'Automatic Deduction Type';
lang_en['Content.Label.Available'] = 'Available';
lang_en['Content.Label.AvailableDownloads'] = 'Available Downloads';
lang_en['Content.Label.AvailableItems'] = 'Available Items';
lang_en['Content.Label.AvailableUntil'] = 'Available Until';
lang_en['Content.Label.Balances'] = 'Balances';
lang_en['Content.Label.BaseLocation'] = 'Base Location';
lang_en['Content.Label.Bill'] = 'Bill';
lang_en['Content.Label.BillRate'] = 'Bill Rate';
lang_en['Content.Label.BillRateHour'] = 'Bill Rate/Hour';
lang_en['Content.Label.BillRateOverride'] = 'Bill Rate Override';
lang_en['Content.Label.BillTotal'] = 'Bill Total';
lang_en['Content.Label.Billable'] = 'Billable';
lang_en['Content.Label.BillingAddress1'] = 'Billing Address 1';
lang_en['Content.Label.BillingAddress2'] = 'Billing Address 2';
lang_en['Content.Label.BillingMethod'] = 'Billing Method';
lang_en['Content.Label.BillingNameAddress'] = 'Billing Name and Address';
lang_en['Content.Label.BillingReceiptForDate'] = 'Billing Receipt For {1}';
lang_en['Content.Label.BillingType.Annual'] = 'Annual';
lang_en['Content.Label.BillingType.Free'] = 'Free';
lang_en['Content.Label.BillingType.Monthly'] = 'Monthly';
lang_en['Content.Label.BillingType.None'] = 'None';
lang_en['Content.Label.BillingType.Unknown'] = 'Unknown';
lang_en['Content.Label.Break'] = 'Break';
lang_en['Content.Label.BreakDuration'] = 'Break Duration';
lang_en['Content.Label.BreakStart'] = 'BreakStart';
lang_en['Content.Label.BreakStartsAt'] = 'Break Starts At';
lang_en['Content.Label.BreakTakenAt'] = 'Break Taken At';
lang_en['Content.Label.BusinessEmail'] = 'Business Email';
lang_en['Content.Label.BusinessEmailAddress'] = 'Business Email Address';
lang_en['Content.Label.BusinessFax'] = 'Business Fax';
lang_en['Content.Label.BusinessPhone'] = 'Business Phone';
lang_en['Content.Label.Calendar'] = 'Calendar';
lang_en['Content.Label.Calendar.OtherStaff'] = 'Other Staff';
lang_en['Content.Label.Calendar.YourSchedule'] = 'Your Schedule';
lang_en['Content.Label.CalendarCategory.CompanyCalendars'] = 'Company Calendars';
lang_en['Content.Label.CalendarCategory.HumanResources'] = 'Human Resources';
lang_en['Content.Label.CalendarCategory.Reports'] = 'Reports';
lang_en['Content.Label.CalendarCategory.Schedules'] = 'Schedules';
lang_en['Content.Label.CalendarCategory.Unknown'] = 'Unknown';
lang_en['Content.Label.CalendarCategory.YourCalendars'] = 'Your Calendars';
lang_en['Content.Label.CalendarType.Private'] = 'Private';
lang_en['Content.Label.CalendarType.Public'] = 'Public';
lang_en['Content.Label.CalendarType.Shared'] = 'Shared';
lang_en['Content.Label.CalendarType.Unknown'] = 'Unknown';
lang_en['Content.Label.Calendars'] = 'Calendars';
lang_en['Content.Label.CallUs'] = 'Call us';
lang_en['Content.Label.CancellationReason.Complexity'] = 'The service is too complicated.';
lang_en['Content.Label.CancellationReason.DoesntMeedNeeds'] = 'The service does not meet our needs.';
lang_en['Content.Label.CancellationReason.NoNeed'] = 'We no longer have employees that need to use the service.';
lang_en['Content.Label.CancellationReason.Other'] = 'For a reason not listed here (please include your comments below).';
lang_en['Content.Label.CancellationReason.OutOfBusiness'] = 'We went out of business.';
lang_en['Content.Label.CancellationReason.Price'] = 'The service is too expensive.';
lang_en['Content.Label.CancellationReason.SitePerformance'] = 'The service runs too slowly.';
lang_en['Content.Label.CancellationReason.SwitchServices'] = 'We have chosen to use different service provider.';
lang_en['Content.Label.CancellationReason.Unknown'] = 'Unknown';
lang_en['Content.Label.CancellationType.Immediate'] = 'Immediate';
lang_en['Content.Label.CancellationType.Standard'] = 'Standard';
lang_en['Content.Label.CancellationType.Unknown'] = 'Unknown';
lang_en['Content.Label.Capable'] = 'Capable';
lang_en['Content.Label.CapableNot'] = 'Not Capable';
lang_en['Content.Label.CapsLockEnabled'] = 'Caps Lock Enabled';
lang_en['Content.Label.Change'] = 'Change';
lang_en['Content.Label.ChangeAccountPassword'] = 'Change Account Password';
lang_en['Content.Label.CheckForMealBreaks'] = 'Check for Meal Breaks';
lang_en['Content.Label.ChooseReportCriteria'] = 'Choose Report Criteria';
lang_en['Content.Label.City'] = 'City';
lang_en['Content.Label.Classes'] = 'Classes';
lang_en['Content.Label.Classification'] = 'Classification';
lang_en['Content.Label.ClearedOn'] = 'Cleared On';
lang_en['Content.Label.Click-Throughs'] = 'Click-throughs';
lang_en['Content.Label.ClockIn'] = 'Clock In';
lang_en['Content.Label.ClockInAccuracy'] = 'Clock In Accuracy';
lang_en['Content.Label.ClockInDate'] = 'Clock In Date';
lang_en['Content.Label.ClockInIPAddress'] = 'Clock In IP Address';
lang_en['Content.Label.ClockInLatitude'] = 'Clock In Latitude';
lang_en['Content.Label.ClockInLongitude'] = 'Clock In Longitude';
lang_en['Content.Label.ClockInOutMap'] = 'Clock In/Out Map';
lang_en['Content.Label.ClockInTime'] = 'Clock In Time';
lang_en['Content.Label.ClockOut'] = 'Clock Out';
lang_en['Content.Label.ClockOutAccuracy'] = 'Clock Out Accuracy';
lang_en['Content.Label.ClockOutDate'] = 'Clock Out Date';
lang_en['Content.Label.ClockOutIPAddress'] = 'Clock Out IP Address';
lang_en['Content.Label.ClockOutLatitude'] = 'Clock Out Latitude';
lang_en['Content.Label.ClockOutLink'] = 'Clock Out Link';
lang_en['Content.Label.ClockOutLongitude'] = 'Clock Out Longitude';
lang_en['Content.Label.ClockOutTime'] = 'Clock Out Time';
lang_en['Content.Label.ClockPhoto.Blurred'] = 'Blurred';
lang_en['Content.Label.ClockPhoto.CameraDisabled'] = 'Camera Disabled';
lang_en['Content.Label.ClockPhoto.Confidence'] = 'Confidence';
lang_en['Content.Label.ClockPhoto.Emotion'] = 'Emotion';
lang_en['Content.Label.ClockPhoto.Face'] = 'Face';
lang_en['Content.Label.ClockPhoto.FacesFound'] = 'Faces Found';
lang_en['Content.Label.ClockPhoto.Headwear'] = 'Headwear';
lang_en['Content.Label.ClockPhoto.NoData'] = 'No Data';
lang_en['Content.Label.ClockPhoto.NoPhoto'] = 'No Photo';
lang_en['Content.Label.ClockPhoto.Underexposed'] = 'Underexposed';
lang_en['Content.Label.ClockTimeDate'] = 'Clock Out Time';
lang_en['Content.Label.ClockedIn'] = 'Clocked In';
lang_en['Content.Label.ClockedOut'] = 'Clocked Out';
lang_en['Content.Label.ClosedBy'] = 'Closed By';
lang_en['Content.Label.ClosedOn'] = 'Closed On';
lang_en['Content.Label.Color'] = 'Color';
lang_en['Content.Label.Commendation'] = 'Commendation';
lang_en['Content.Label.Comments'] = 'Comments';
lang_en['Content.Label.Commission'] = 'Commission';
lang_en['Content.Label.Commission.PaymentAmount'] = 'Commission Payment Amount';
lang_en['Content.Label.Commission.PaymentDate'] = 'Commission Payment Date';
lang_en['Content.Label.Commission.PaymentDate.Approx'] = 'Approximate payment date';
lang_en['Content.Label.Commission.Pending'] = 'Pending';
lang_en['Content.Label.Commission.Suspended'] = 'Suspended';
lang_en['Content.Label.Company'] = 'Company';
lang_en['Content.Label.CompanyID'] = 'Company ID';
lang_en['Content.Label.CompanyName'] = 'Company Name';
lang_en['Content.Label.CompanyName.Placeholder'] = 'Your Company, Inc.';
lang_en['Content.Label.CompanyStatus.Active'] = 'Active';
lang_en['Content.Label.CompanyStatus.Cancelled'] = 'Cancelled';
lang_en['Content.Label.CompanyStatus.Delinquent'] = 'Delinquent';
lang_en['Content.Label.CompanyStatus.Expired'] = 'Expired';
lang_en['Content.Label.CompanyStatus.InTrial'] = 'In Trial';
lang_en['Content.Label.CompanyStatus.Other'] = 'Other';
lang_en['Content.Label.CompanyStatus.Unknown'] = 'Unknown';
lang_en['Content.Label.Complete'] = 'Complete';
lang_en['Content.Label.Completed'] = 'Completed';
lang_en['Content.Label.ConfirmPassword'] = 'Confirm Password';
lang_en['Content.Label.Contact'] = 'Contact';
lang_en['Content.Label.ContactEmail'] = 'Contact Email';
lang_en['Content.Label.ContactName'] = 'Contact Name';
lang_en['Content.Label.ContactPhone'] = 'Contact Phone';
lang_en['Content.Label.ContactUs'] = 'Contact Us';
lang_en['Content.Label.ConversionPercent'] = 'Conversion %';
lang_en['Content.Label.ConversionType'] = 'Conversion Type';
lang_en['Content.Label.CostRate'] = 'Cost Rate';
lang_en['Content.Label.CostRateHour'] = 'Cost Rate/Hour';
lang_en['Content.Label.CostRateOverride'] = 'Cost Rate Override';
lang_en['Content.Label.CostTotal'] = 'Cost Total';
lang_en['Content.Label.Country'] = 'Country';
lang_en['Content.Label.CreateANewDocument'] = 'Create a new document';
lang_en['Content.Label.CreateAsPreset'] = 'Create As Preset';
lang_en['Content.Label.CreatePassword'] = 'Create New Password';
lang_en['Content.Label.CreatedBy'] = 'Created By';
lang_en['Content.Label.CreatedOn'] = 'Created On';
lang_en['Content.Label.CroppedPhoto'] = 'Cropped Photo';
lang_en['Content.Label.Current'] = 'Current';
lang_en['Content.Label.CurrentBillRate'] = 'Current Bill Rate';
lang_en['Content.Label.CurrentJobTitle'] = 'Current Job Title';
lang_en['Content.Label.CurrentLogo'] = 'Current Logo';
lang_en['Content.Label.CurrentMinimumTimeIncrement'] = 'Minimum Time Increment';
lang_en['Content.Label.CurrentPassword'] = 'Current Password';
lang_en['Content.Label.CurrentPayRate'] = 'Current Pay Rate';
lang_en['Content.Label.CurrentSupervisor'] = 'Current Supervisor';
lang_en['Content.Label.CurrentlyAvailable'] = 'Currently Available';
lang_en['Content.Label.CustomMessage'] = 'Custom Message';
lang_en['Content.Label.CustomSigningStatementForSuper1'] = 'Custom Signing Statement for Supervisor One';
lang_en['Content.Label.CustomSigningStatementForSuper2'] = 'Custom Signing Statement for Supervisor Two';
lang_en['Content.Label.CustomSigningStatementForUser'] = 'Custom Signing Statement for User';
lang_en['Content.Label.Customer'] = 'Customer';
lang_en['Content.Label.CustomerNumber'] = 'Customer Number';
lang_en['Content.Label.CustomerService'] = 'Customer Service';
lang_en['Content.Label.Customers'] = 'Customers';
lang_en['Content.Label.Date'] = 'Date';
lang_en['Content.Label.DateApplied'] = 'Date Applied';
lang_en['Content.Label.DateOfBirth'] = 'Date Of Birth';
lang_en['Content.Label.DateOfHire'] = 'Date Of Hire';
lang_en['Content.Label.DateRangeCustom'] = 'Custom Date Range';
lang_en['Content.Label.DateSpan'] = 'Date Span';
lang_en['Content.Label.DateTimeControls.Date'] = 'Date';
lang_en['Content.Label.DateTimeControls.EndDate'] = 'End Date';
lang_en['Content.Label.DateTimeControls.EndTime'] = 'End Time';
lang_en['Content.Label.DateTimeControls.HoursAndMinutes'] = 'Hours and Minutes';
lang_en['Content.Label.DateTimeControls.SameAsX'] = 'Same as {1}';
lang_en['Content.Label.DateTimeControls.StartDate'] = 'Start Date';
lang_en['Content.Label.DateTimeControls.StartTime'] = 'Start Time';
lang_en['Content.Label.DateTimeControls.UseX'] = 'Use {1}';
lang_en['Content.Label.Day'] = 'Day';
lang_en['Content.Label.DayName.Eighteenth'] = 'Eighteenth';
lang_en['Content.Label.DayName.Eighth'] = 'Eighth';
lang_en['Content.Label.DayName.Eleventh'] = 'Eleventh';
lang_en['Content.Label.DayName.Fifteenth'] = 'Fifteenth';
lang_en['Content.Label.DayName.Fifth'] = 'Fifth';
lang_en['Content.Label.DayName.First'] = 'First';
lang_en['Content.Label.DayName.Fourteenth'] = 'Fourteenth';
lang_en['Content.Label.DayName.Fourth'] = 'Fourth';
lang_en['Content.Label.DayName.Nineteenth'] = 'Nineteenth';
lang_en['Content.Label.DayName.Ninth'] = 'Ninth';
lang_en['Content.Label.DayName.Second'] = 'Second';
lang_en['Content.Label.DayName.Seventeenth'] = 'Seventeenth';
lang_en['Content.Label.DayName.Seventh'] = 'Seventh';
lang_en['Content.Label.DayName.Sixteenth'] = 'Sixteenth';
lang_en['Content.Label.DayName.Sixth'] = 'Sixth';
lang_en['Content.Label.DayName.Tenth'] = 'Tenth';
lang_en['Content.Label.DayName.Third'] = 'Third';
lang_en['Content.Label.DayName.Thirteenth'] = 'Thirteenth';
lang_en['Content.Label.DayName.Thirtieth'] = 'Thirtieth';
lang_en['Content.Label.DayName.ThirtyFirst'] = 'Thirty First';
lang_en['Content.Label.DayName.Twelveth'] = 'Twelveth';
lang_en['Content.Label.DayName.Twentieth'] = 'Twentieth';
lang_en['Content.Label.DayName.TwentyEighth'] = 'Twenty Eighth';
lang_en['Content.Label.DayName.TwentyFifth'] = 'Twenty Fifth';
lang_en['Content.Label.DayName.TwentyFirst'] = 'Twenty First';
lang_en['Content.Label.DayName.TwentyFourth'] = 'Twenty Fourth';
lang_en['Content.Label.DayName.TwentyNinth'] = 'Twenty Ninth';
lang_en['Content.Label.DayName.TwentySecond'] = 'Twenty Second';
lang_en['Content.Label.DayName.TwentySeventh'] = 'Twenty Seventh';
lang_en['Content.Label.DayName.TwentySixth'] = 'Twenty Sixth';
lang_en['Content.Label.DayName.TwentyThird'] = 'Twenty Third';
lang_en['Content.Label.DeductMinutes'] = 'Deduct Minutes';
lang_en['Content.Label.DeductedHours'] = 'Deducted Hours';
lang_en['Content.Label.DeductionThreshold'] = 'Deduction Threshold';
lang_en['Content.Label.Deductions'] = 'Deductions';
lang_en['Content.Label.DeductionsAbbrev'] = 'Deduct';
lang_en['Content.Label.Default'] = 'Default';
lang_en['Content.Label.DefaultBillRate'] = 'Default Bill Rate';
lang_en['Content.Label.DefaultBillRateHour'] = 'Default Bill Rate/Hour';
lang_en['Content.Label.DefaultCostRate'] = 'Default Cost Rate';
lang_en['Content.Label.DefaultCostRateHour'] = 'Default Cost Rate/Hour';
lang_en['Content.Label.DefaultCustomer'] = 'Default Customer';
lang_en['Content.Label.DefaultExpenseCode'] = 'Default Expense Code';
lang_en['Content.Label.DefaultHourlyCode'] = 'Default Hourly Code';
lang_en['Content.Label.DefaultProject'] = 'Default Project';
lang_en['Content.Label.DefaultProjectCode'] = 'Default Project Code';
lang_en['Content.Label.DefaultRate'] = 'Default Rate';
lang_en['Content.Label.DefaultStatement'] = 'Default Statement';
lang_en['Content.Label.DeletePhoto'] = 'Delete Photo';
lang_en['Content.Label.Department'] = 'Department';
lang_en['Content.Label.Dependability'] = 'Dependability';
lang_en['Content.Label.Description'] = 'Description';
lang_en['Content.Label.DescriptionOfIncident'] = 'Description of Incident and Witness Accounts';
lang_en['Content.Label.Disabled'] = 'Disabled';
lang_en['Content.Label.DisplayingXofYUsers'] = 'Displaying {1} of {2,number,#} Users';
lang_en['Content.Label.DistanceInMeters'] = 'Distance (m)';
lang_en['Content.Label.Division'] = 'Division';
lang_en['Content.Label.Doubletime'] = 'Doubletime';
lang_en['Content.Label.Download'] = 'Download';
lang_en['Content.Label.DownloadImportFile'] = 'Download Import File';
lang_en['Content.Label.EarningCodes'] = 'Earning Codes';
lang_en['Content.Label.Edit'] = 'Edit';
lang_en['Content.Label.EditCalendar'] = 'Edit Calendar';
lang_en['Content.Label.EditExpense'] = 'Edit Expense';
lang_en['Content.Label.EditHourlyTime'] = 'Edit Hourly Time';
lang_en['Content.Label.EditProjectTime'] = 'Edit Project Time';
lang_en['Content.Label.EditRequest'] = 'Edit Request';
lang_en['Content.Label.EmailAddress'] = 'Email Address';
lang_en['Content.Label.EmailAddress.Placeholder'] = 'name@company.com';
lang_en['Content.Label.EmailOrUserID'] = 'Email Or UserID';
lang_en['Content.Label.Employee'] = 'Employee';
lang_en['Content.Label.EmployeeComments'] = 'Employee Comments';
lang_en['Content.Label.EmployeeNumber'] = 'Employee Number';
lang_en['Content.Label.EmployeeType.Contractor'] = 'Contractor';
lang_en['Content.Label.EmployeeType.Employee'] = 'Employee';
lang_en['Content.Label.EmployeeType.Other'] = 'Other';
lang_en['Content.Label.EmployeeType.Student'] = 'Student';
lang_en['Content.Label.EmployeeType.Unknown'] = 'Unknown';
lang_en['Content.Label.EmployeeType.Volunteer'] = 'Volunteer';
lang_en['Content.Label.Employees'] = 'Employees';
lang_en['Content.Label.Enabled'] = 'Enabled';
lang_en['Content.Label.End'] = 'End';
lang_en['Content.Label.EndDate'] = 'End Date';
lang_en['Content.Label.EndTime'] = 'End Time';
lang_en['Content.Label.Enter'] = 'Enter';
lang_en['Content.Label.EnterExpense'] = 'Enter Expense';
lang_en['Content.Label.EnterHourlyTime'] = 'Enter Hourly Time';
lang_en['Content.Label.EnterProjectTime'] = 'Enter Project Time';
lang_en['Content.Label.EnterReasonForRequest'] = 'Enter a reason for the request.';
lang_en['Content.Label.EstimatedTotalsForPayroll'] = 'Estimated Totals For Payroll';
lang_en['Content.Label.EstimatedTotalsForPayrollPeriod'] = 'Estimated Totals For Payroll Period';
lang_en['Content.Label.EstimatedTotalsForPayrollPeriodWithDates'] = 'Estimated Totals For Payroll Period {1} to {2}';
lang_en['Content.Label.EstimatedTotalsForXDayPeriod'] = 'Estimated Totals For {1} Day Period';
lang_en['Content.Label.EstimatedUsers'] = 'Estimated Users';
lang_en['Content.Label.Event'] = 'Event';
lang_en['Content.Label.Event.PlaceholderAddNew'] = 'Add new...';
lang_en['Content.Label.EventLog'] = 'Event Log';
lang_en['Content.Label.Events'] = 'Events';
lang_en['Content.Label.Exempt'] = 'Exempt';
lang_en['Content.Label.Expense'] = 'Expense';
lang_en['Content.Label.ExpenseReports'] = 'Expense Reports';
lang_en['Content.Label.ExpenseStatus.Open'] = 'Open';
lang_en['Content.Label.ExpenseStatus.Reconciled'] = 'Reconciled';
lang_en['Content.Label.ExpenseStatus.Unknown'] = 'Unknown';
lang_en['Content.Label.ExpensedBy'] = 'Expensed By';
lang_en['Content.Label.Expenses'] = 'Expenses';
lang_en['Content.Label.ExportAsBillable'] = 'Export As Billable';
lang_en['Content.Label.ExportCalendar'] = 'Export Calendar';
lang_en['Content.Label.ExportCode'] = 'Export Code';
lang_en['Content.Label.ExportMessage'] = 'Export Message';
lang_en['Content.Label.ExportedBy'] = 'Exported By';
lang_en['Content.Label.Failed'] = 'Failed';
lang_en['Content.Label.File'] = 'File';
lang_en['Content.Label.Files'] = 'Files';
lang_en['Content.Label.FilterBy'] = 'Filter By';
lang_en['Content.Label.FilterByLastName'] = 'Filter By Last Name';
lang_en['Content.Label.FindUser'] = 'Find User';
lang_en['Content.Label.FindUsers'] = 'Find Users';
lang_en['Content.Label.Finished'] = 'Finished';
lang_en['Content.Label.FirstName'] = 'First Name';
lang_en['Content.Label.FixedHourlyRate'] = 'Fixed Hourly Rate';
lang_en['Content.Label.FixedPay'] = 'Fixed Pay';
lang_en['Content.Label.FixedPayTotal'] = 'Fixed Pay Total';
lang_en['Content.Label.FixedRate'] = 'Fixed Rate';
lang_en['Content.Label.FontSize.ExtraLarge'] = 'Extra Large';
lang_en['Content.Label.FontSize.ExtraSmall'] = 'Extra Small';
lang_en['Content.Label.FontSize.Large'] = 'Large';
lang_en['Content.Label.FontSize.Medium'] = 'Medium';
lang_en['Content.Label.FontSize.Small'] = 'Small';
lang_en['Content.Label.ForgotPassword'] = 'Forgot Password?';
lang_en['Content.Label.Format'] = 'Format';
lang_en['Content.Label.FreeTrial'] = 'Free Trial';
lang_en['Content.Label.Frequency'] = 'Frequency';
lang_en['Content.Label.From'] = 'From';
lang_en['Content.Label.FromSchedule'] = 'From Schedule';
lang_en['Content.Label.FromX'] = 'From {1}';
lang_en['Content.Label.Future'] = 'Future';
lang_en['Content.Label.Geolocation'] = 'Geolocation';
lang_en['Content.Label.GracePeriod'] = 'Grace Period';
lang_en['Content.Label.GrandTotal'] = 'Grand Total';
lang_en['Content.Label.GrossPayPerPeriod'] = 'Gross Pay Per Period';
lang_en['Content.Label.GroupReportBy'] = 'Group Report By';
lang_en['Content.Label.GroupTotal'] = 'Group Total';
lang_en['Content.Label.GroupedBy'] = 'Grouped By';
lang_en['Content.Label.GuidedTutorial'] = 'Guided Tutorial';
lang_en['Content.Label.HRReport.CommendationLetter'] = 'Commendation Letter';
lang_en['Content.Label.HRReport.DisciplineReport'] = 'Discipline Report';
lang_en['Content.Label.HRReport.EmployeeTerminationLetter'] = 'Employee Termination Letter';
lang_en['Content.Label.HRReport.IncidentReport'] = 'Incident Report';
lang_en['Content.Label.HRReport.NotSpecified'] = 'Not Specified';
lang_en['Content.Label.HRReport.PerformanceReview'] = 'Performance Review';
lang_en['Content.Label.HRReport.TerminationLetter'] = 'Termination Letter';
lang_en['Content.Label.HidesIn'] = 'Hides in';
lang_en['Content.Label.Hostname'] = 'Hostname';
lang_en['Content.Label.Hourly'] = 'Hourly';
lang_en['Content.Label.HourlyPayRate'] = 'Hourly Pay Rate';
lang_en['Content.Label.HourlyPayTotal'] = 'Fixed Pay Total';
lang_en['Content.Label.HourlyRate'] = 'Hourly Rate';
lang_en['Content.Label.HourlyReports'] = 'Hourly Reports';
lang_en['Content.Label.HourlyTime'] = 'Hourly Time';
lang_en['Content.Label.Hours'] = 'Hours';
lang_en['Content.Label.HoursAbbrev'] = 'hrs';
lang_en['Content.Label.HoursAbbrev2'] = 'h';
lang_en['Content.Label.HoursRequested'] = 'Hours Requested';
lang_en['Content.Label.HoursSummary'] = 'Hours Summary';
lang_en['Content.Label.HoursThisShift'] = 'Hours This Shift';
lang_en['Content.Label.HoursToday'] = 'Hours Today';
lang_en['Content.Label.ICSFile'] = 'ICS File';
lang_en['Content.Label.ID'] = 'ID';
lang_en['Content.Label.IPAddress'] = 'IP Address';
lang_en['Content.Label.IPAddress.ClockIn'] = 'Clock In IP Address';
lang_en['Content.Label.IPAddress.ClockOut'] = 'Clock Out IP Address';
lang_en['Content.Label.IPLockExempt'] = 'IP Lock Exempt';
lang_en['Content.Label.ImportCalendar'] = 'Import Calendar';
lang_en['Content.Label.In'] = 'In';
lang_en['Content.Label.IncludeUsers'] = 'Include These Users';
lang_en['Content.Label.IncludeUsers.AllUsers'] = 'All Users';
lang_en['Content.Label.IncludeUsers.ByGroup'] = 'By Group';
lang_en['Content.Label.IncludeUsers.OnlyHourly'] = 'Only Hourly';
lang_en['Content.Label.IncludeUsers.OnlySalary'] = 'Only Salary';
lang_en['Content.Label.IncludeUsers.SelectSpecific'] = 'Select Specific';
lang_en['Content.Label.Incomplete'] = 'Incomplete';
lang_en['Content.Label.Initiative'] = 'Initiative';
lang_en['Content.Label.InternationalFormat'] = 'International Format';
lang_en['Content.Label.InvalidLocation'] = 'Invalid Location';
lang_en['Content.Label.Items'] = 'Items';
lang_en['Content.Label.JobKnowledge'] = 'Job Knowledge';
lang_en['Content.Label.JobTitle'] = 'Job Title';
lang_en['Content.Label.JumpTo'] = 'Jump To';
lang_en['Content.Label.Lang.English'] = 'English';
lang_en['Content.Label.Lang.Spanish'] = 'Spanish';
lang_en['Content.Label.Lang.en_US'] = 'English';
lang_en['Content.Label.Lang.es_MX'] = 'Spanish';
lang_en['Content.Label.LastEdit'] = 'Last Edit';
lang_en['Content.Label.LastName'] = 'Last Name';
lang_en['Content.Label.LastUpdate'] = 'Last Updated';
lang_en['Content.Label.Latest'] = 'Latest';
lang_en['Content.Label.LinkedAccount'] = 'Linked Account';
lang_en['Content.Label.LiveChat'] = 'Live Chat';
lang_en['Content.Label.LiveSupport'] = 'Live Support';
lang_en['Content.Label.Loading'] = 'Loading...';
lang_en['Content.Label.Location'] = 'Location';
lang_en['Content.Label.LocationAddress'] = 'Location Address';
lang_en['Content.Label.LocationCaptured'] = 'Location Captured';
lang_en['Content.Label.LocationNotCaptured'] = 'Location NOT Captured';
lang_en['Content.Label.Login'] = 'Login';
lang_en['Content.Label.LoginID'] = 'Login';
lang_en['Content.Label.LoginStatus'] = 'Login Status';
lang_en['Content.Label.Logo'] = 'Logo';
lang_en['Content.Label.LogoExists'] = 'Logo Exists';
lang_en['Content.Label.ManagementAbility'] = 'Management Ability';
lang_en['Content.Label.MaxDistance'] = 'Max Distance';
lang_en['Content.Label.Memo'] = 'Memo';
lang_en['Content.Label.Memos'] = 'Memos';
lang_en['Content.Label.MergeAllRecordsFromXTo'] = 'Merge All Records From \'\'{1}\'\' To:';
lang_en['Content.Label.Message'] = 'Message';
lang_en['Content.Label.MessageBoard'] = 'Message Board';
lang_en['Content.Label.MessageCount'] = 'Message Count';
lang_en['Content.Label.MessageTitle'] = 'Message Title';
lang_en['Content.Label.Messages'] = 'Messages';
lang_en['Content.Label.Meters'] = 'Meters';
lang_en['Content.Label.Mileage'] = 'Mileage';
lang_en['Content.Label.MileageMultiplier'] = 'Mileage Multiplier';
lang_en['Content.Label.Miles'] = 'Miles';
lang_en['Content.Label.MinimumTimeIncrement'] = 'Minimum Time Increment';
lang_en['Content.Label.Minutes'] = 'Minutes';
lang_en['Content.Label.MinutesAbbrev'] = 'mins';
lang_en['Content.Label.MinutesAbbrev2'] = 'm';
lang_en['Content.Label.MinutesTardy'] = 'Minutes Tardy';
lang_en['Content.Label.Missing'] = 'missing...';
lang_en['Content.Label.Mobile'] = 'Mobile';
lang_en['Content.Label.ModifiedBy'] = 'Modified By';
lang_en['Content.Label.Multiplier'] = 'Multiplier';
lang_en['Content.Label.Name'] = 'Name';
lang_en['Content.Label.NameThisReport'] = 'Name this report';
lang_en['Content.Label.New'] = 'New';
lang_en['Content.Label.NewBillRate'] = 'New Bill Rate';
lang_en['Content.Label.NewClockInDate'] = 'New Clock In Date';
lang_en['Content.Label.NewClockInTime'] = 'New Clock In Time';
lang_en['Content.Label.NewClockOutDate'] = 'New Clock Out Date';
lang_en['Content.Label.NewClockOutTime'] = 'New Clock Out Time';
lang_en['Content.Label.NewHourlyPayRate'] = 'New Hourly Pay Rate';
lang_en['Content.Label.NewHours'] = 'New Hours';
lang_en['Content.Label.NewMinimumTimeIncrement'] = 'New Minimum Time Increment';
lang_en['Content.Label.NewPassword'] = 'New Password';
lang_en['Content.Label.NewPayRate'] = 'New Pay Rate';
lang_en['Content.Label.NewRecords'] = 'New Records';
lang_en['Content.Label.NewSalaryPayRate'] = 'New Salary Pay Rate';
lang_en['Content.Label.No'] = 'No';
lang_en['Content.Label.NoAccess'] = 'No Access';
lang_en['Content.Label.NoCreditCardRequired'] = 'No Card Required';
lang_en['Content.Label.NoDescription'] = 'No description';
lang_en['Content.Label.NoHistory'] = 'No History';
lang_en['Content.Label.NoInformation'] = 'No information.';
lang_en['Content.Label.None'] = 'None';
lang_en['Content.Label.NotApplicable'] = 'N/A';
lang_en['Content.Label.NotEnabled'] = 'Not Enabled';
lang_en['Content.Label.NotRequired'] = 'Not Required';
lang_en['Content.Label.NotValidated'] = 'Not Validated';
lang_en['Content.Label.Note'] = 'Note';
lang_en['Content.Label.NoteStatus.Closed'] = 'Closed';
lang_en['Content.Label.NoteStatus.Open'] = 'Open';
lang_en['Content.Label.NoteStatus.Unknown'] = 'Unknown';
lang_en['Content.Label.NoteType.BillingRelated'] = 'Billing Related';
lang_en['Content.Label.NoteType.BugReport'] = 'Bug Report';
lang_en['Content.Label.NoteType.CustomerComment'] = 'Customer Comment';
lang_en['Content.Label.NoteType.CustomerSuggestion'] = 'Customer Suggestion';
lang_en['Content.Label.NoteType.DemoComment'] = 'Demo Comment';
lang_en['Content.Label.NoteType.EnrollmentInfo'] = 'Enrollment Info';
lang_en['Content.Label.NoteType.General'] = 'General';
lang_en['Content.Label.NoteType.IncidentReport'] = 'Incident Report';
lang_en['Content.Label.NoteType.NoSale'] = 'No Sale';
lang_en['Content.Label.NoteType.ReadMe'] = 'README';
lang_en['Content.Label.NoteType.Retention'] = 'Retention';
lang_en['Content.Label.NoteType.SpecialOffer'] = 'Special Offer';
lang_en['Content.Label.NoteType.TrialComment'] = 'Trial Comment';
lang_en['Content.Label.NoteType.Unknown'] = 'Unknown';
lang_en['Content.Label.Notes'] = 'Notes';
lang_en['Content.Label.Notes.OptionalPlaceholder'] = 'Optional';
lang_en['Content.Label.Notifications'] = 'Notifications';
lang_en['Content.Label.Off'] = 'Off';
lang_en['Content.Label.Offline'] = 'Offline';
lang_en['Content.Label.Okay'] = 'Okay';
lang_en['Content.Label.On'] = 'On';
lang_en['Content.Label.OnBreak'] = 'On Break';
lang_en['Content.Label.Optional'] = 'Optional';
lang_en['Content.Label.OptionalFields'] = 'Optional Fields';
lang_en['Content.Label.Original'] = 'Original';
lang_en['Content.Label.OriginalPhoto'] = 'Original Photo';
lang_en['Content.Label.OriginalRecord'] = 'Original Record';
lang_en['Content.Label.Origination'] = 'Origination';
lang_en['Content.Label.OriginationType.Clock'] = 'Clock';
lang_en['Content.Label.OriginationType.MobileClock'] = 'Mobile Clock';
lang_en['Content.Label.OriginationType.MobileTimeEntry'] = 'Mobile Time Entry';
lang_en['Content.Label.OriginationType.MobileTimer'] = 'Mobile Timer';
lang_en['Content.Label.OriginationType.TimeEntry'] = 'Time Entry';
lang_en['Content.Label.OriginationType.Timer'] = 'Timer';
lang_en['Content.Label.OriginationType.Unknown'] = 'Unknown';
lang_en['Content.Label.Out'] = 'Out';
lang_en['Content.Label.OutNotClockedIn'] = 'Out';
lang_en['Content.Label.OverallPerformanceRating'] = 'Overall Performance Rating';
lang_en['Content.Label.OverlappingRecords'] = 'Overlapping Records';
lang_en['Content.Label.Overtime'] = 'Overtime';
lang_en['Content.Label.OvertimePolicy'] = 'Overtime Policy';
lang_en['Content.Label.OvertimePolicy.Daily'] = 'Daily';
lang_en['Content.Label.OvertimePolicy.None'] = 'None';
lang_en['Content.Label.OvertimePolicy.Unknown'] = 'Unknown';
lang_en['Content.Label.OvertimePolicy.Weekly'] = 'Weekly';
lang_en['Content.Label.Paid'] = 'Paid';
lang_en['Content.Label.Password'] = 'Password';
lang_en['Content.Label.Password.Placeholder'] = 'your secure password';
lang_en['Content.Label.PasswordRequirements'] = 'Password requirements...';
lang_en['Content.Label.PauseCurrentlyRunningTimers'] = 'Pause currently running timers?';
lang_en['Content.Label.Paused'] = 'Paused';
lang_en['Content.Label.PayAnnually'] = 'Pay Annually';
lang_en['Content.Label.PayAnnuallyInfo'] = 'Automatically billed each year on anniversary date.';
lang_en['Content.Label.PayFreelancer'] = 'Freelancer Account';
lang_en['Content.Label.PayFreelancerInfo'] = 'Free service - track your own data at no charge.';
lang_en['Content.Label.PayMonthly'] = 'Pay Monthly';
lang_en['Content.Label.PayMonthlyInfo'] = 'Automatically billed each month on the 1st.';
lang_en['Content.Label.PayPeriod'] = 'Pay Period';
lang_en['Content.Label.PayPeriod.Bi-Monthly'] = 'Bi-Monthly';
lang_en['Content.Label.PayPeriod.Bi-Weekly'] = 'Bi-Weekly';
lang_en['Content.Label.PayPeriod.Monthly'] = 'Monthly';
lang_en['Content.Label.PayPeriod.None'] = 'None';
lang_en['Content.Label.PayPeriod.Unknown'] = 'Unknown';
lang_en['Content.Label.PayPeriod.Weekly'] = 'Weekly';
lang_en['Content.Label.PayRate'] = 'Pay Rate';
lang_en['Content.Label.PayTotal'] = 'Pay Total';
lang_en['Content.Label.PayType'] = 'Pay Type';
lang_en['Content.Label.PayType.Hourly'] = 'Hourly';
lang_en['Content.Label.PayType.Salary'] = 'Salary';
lang_en['Content.Label.PayType.Unknown'] = 'Unknown';
lang_en['Content.Label.PaymentAmount'] = 'Payment Amount';
lang_en['Content.Label.PaymentDate'] = 'Payment Date';
lang_en['Content.Label.PaymentDetails'] = 'Payment Details';
lang_en['Content.Label.PaymentInfo'] = 'Payment Information';
lang_en['Content.Label.Payroll.1pt0X'] = '1.0x';
lang_en['Content.Label.Payroll.1pt5X'] = '1.5x';
lang_en['Content.Label.Payroll.2pt0X'] = '2.0x';
lang_en['Content.Label.Payroll.AccrualPeriod'] = 'Accrual Period';
lang_en['Content.Label.Payroll.AccruedNextPeriod'] = 'Accrued Next Period';
lang_en['Content.Label.Payroll.AccruedThisPeriod'] = 'Accrued This Period';
lang_en['Content.Label.Payroll.AccruedYTD'] = 'Accrued YTD';
lang_en['Content.Label.Payroll.Add'] = 'Add';
lang_en['Content.Label.Payroll.AdditionPolicy'] = 'Addition Policy';
lang_en['Content.Label.Payroll.Aprvd'] = 'Approved';
lang_en['Content.Label.Payroll.Credited'] = 'Credited';
lang_en['Content.Label.Payroll.DTHours'] = 'Overtime 2.0x Hours';
lang_en['Content.Label.Payroll.Deducted'] = 'Deduct';
lang_en['Content.Label.Payroll.DeductionPolicy'] = 'Deduction Policy';
lang_en['Content.Label.Payroll.EligibleForCredit'] = 'Eligible For Credit';
lang_en['Content.Label.Payroll.EstCredit'] = 'Estimated Credit';
lang_en['Content.Label.Payroll.EstCurrentBalance'] = 'Estimated Current Balance';
lang_en['Content.Label.Payroll.EstFinalBalance'] = 'Estimated Final Balance';
lang_en['Content.Label.Payroll.FinalAccrualBalance'] = 'Final Accrual Balance';
lang_en['Content.Label.Payroll.Hours'] = 'Hours';
lang_en['Content.Label.Payroll.HoursPerYear'] = 'Hours Per Year';
lang_en['Content.Label.Payroll.HoursTowardAccrual'] = 'Total Hours Worked Toward Accrual';
lang_en['Content.Label.Payroll.MaxHours'] = 'Max Hours';
lang_en['Content.Label.Payroll.NewAccruals'] = 'New Accruals';
lang_en['Content.Label.Payroll.OTHours'] = 'Overtime 1.5x Hours';
lang_en['Content.Label.Payroll.OptimizationsApplied'] = 'Optimizations Applied';
lang_en['Content.Label.Payroll.PayrollEndDate'] = 'Payroll End Date';
lang_en['Content.Label.Payroll.PayrollStartDate'] = 'Payroll Start Date';
lang_en['Content.Label.Payroll.Policies'] = 'Policies';
lang_en['Content.Label.Payroll.PreviousDaysWorked'] = 'Previous days worked this week';
lang_en['Content.Label.Payroll.PreviouslyPaidThisWeek'] = 'Previously paid standard hours for this week';
lang_en['Content.Label.Payroll.RatePerHour'] = 'Rate Per Hour';
lang_en['Content.Label.Payroll.RecordsCrossingDateline'] = 'Records Crossing Dateline';
lang_en['Content.Label.Payroll.RecordsExceedingHrsThreshold'] = 'Records Exceeding Hours Threshold';
lang_en['Content.Label.Payroll.RecordsExceedingXHrsThreshold'] = 'Records Exceeding {1} Hours Threshold';
lang_en['Content.Label.Payroll.RecordsProcessed'] = 'Total Records Processed';
lang_en['Content.Label.Payroll.RecordsShiftDifferential'] = 'Records Qualifying for Shift Differential';
lang_en['Content.Label.Payroll.RoundingIntervalApplied'] = 'Rounding Interval Applied';
lang_en['Content.Label.Payroll.RoundingIntervalApplied.NearestXMin'] = 'nearest {1} min';
lang_en['Content.Label.Payroll.Salary'] = 'Salary';
lang_en['Content.Label.Payroll.ShiftDifferentials'] = 'Shift Differentials';
lang_en['Content.Label.Payroll.ShiftDifferentials.FixedPercent'] = 'Fixed Percentage';
lang_en['Content.Label.Payroll.ShiftDifferentials.One'] = 'Shift Differential One';
lang_en['Content.Label.Payroll.ShiftDifferentials.Time'] = 'Time';
lang_en['Content.Label.Payroll.ShiftDifferentials.Two'] = 'Shift Differential Two';
lang_en['Content.Label.Payroll.ShiftDifferentials.VariableAcctCode'] = 'Variable Account Code';
lang_en['Content.Label.Payroll.Sig'] = 'Signed';
lang_en['Content.Label.Payroll.Standard'] = 'Standard';
lang_en['Content.Label.Payroll.StandardHours'] = 'Standard Hours';
lang_en['Content.Label.Payroll.TimeIn'] = 'Time In';
lang_en['Content.Label.Payroll.TimeOut'] = 'Time Out';
lang_en['Content.Label.Payroll.TotalAllHours'] = 'Total of All Hours';
lang_en['Content.Label.Payroll.TotalDeducted'] = 'Total Deducted Hours';
lang_en['Content.Label.Payroll.TotalFixedPay'] = 'Total Fixed Pay';
lang_en['Content.Label.Payroll.TotalHoursAdded'] = 'Total Hours Added';
lang_en['Content.Label.Payroll.TotalHoursDeducted'] = 'Total Hours Deducted';
lang_en['Content.Label.Payroll.TotalOfAllHours'] = 'Total of All Hours';
lang_en['Content.Label.Payroll.TotalPaidHours'] = 'Total Paid Hours';
lang_en['Content.Label.Payroll.TotalPayable'] = 'Total Payable Hours';
lang_en['Content.Label.Payroll.TotalPayroll'] = 'Total Payroll';
lang_en['Content.Label.Payroll.TotalReportPayroll'] = 'Total Report Payroll';
lang_en['Content.Label.Payroll.TotalVariablePay'] = 'Total Variable Pay';
lang_en['Content.Label.Payroll.UsersProcessed'] = 'Users Processed';
lang_en['Content.Label.Payroll.WeekOf'] = 'Week of';
lang_en['Content.Label.Payroll.WorkedNextPeriod'] = 'Worked Next Period';
lang_en['Content.Label.Payroll.WorkedThisPeriod'] = 'Worked This Period';
lang_en['Content.Label.Payroll.WorkedYTD'] = 'Worked YTD';
lang_en['Content.Label.PayrollPeriod'] = 'Payroll Period';
lang_en['Content.Label.PayrollPeriods'] = 'Payroll Periods';
lang_en['Content.Label.PayrollSpecializedTools'] = 'Specialized Tools';
lang_en['Content.Label.PayrollSpecializedTools.SeparateQueries'] = 'Execute Separate Data Queries Without Union';
lang_en['Content.Label.Pending'] = 'Pending';
lang_en['Content.Label.PerformanceDocuments'] = 'Performance Documents';
lang_en['Content.Label.PeriodEnd'] = 'Period End';
lang_en['Content.Label.PeriodStart'] = 'Period Start';
lang_en['Content.Label.Phone'] = 'Phone';
lang_en['Content.Label.PhoneExt'] = 'Ext.';
lang_en['Content.Label.Photo'] = 'Photo';
lang_en['Content.Label.PhotoNoImage'] = 'No Image';
lang_en['Content.Label.PhotoTimestampExempt'] = 'Photo Timestamp Exempt';
lang_en['Content.Label.Photos'] = 'Photos';
lang_en['Content.Label.PleaseWait'] = 'Please Wait';
lang_en['Content.Label.PostDated'] = 'Post Dated';
lang_en['Content.Label.Posted'] = 'Posted';
lang_en['Content.Label.Primary'] = 'Primary';
lang_en['Content.Label.PrimaryAccountAdmin'] = 'Primary Account Admin';
lang_en['Content.Label.Print'] = 'Print';
lang_en['Content.Label.Print.ExtendedData'] = 'Extended Data';
lang_en['Content.Label.Print.Legend'] = 'Legend';
lang_en['Content.Label.Print.Notes'] = 'Notes';
lang_en['Content.Label.Print.PageBreaks'] = 'Page Breaks';
lang_en['Content.Label.Print.PrintPDF'] = 'Print / PDF';
lang_en['Content.Label.Print.PrintPDFOptions'] = 'Print / PDF Options';
lang_en['Content.Label.Print.PrintSize'] = 'Print Size';
lang_en['Content.Label.Print.SignatureBlocks'] = 'Signature Blocks';
lang_en['Content.Label.Private'] = 'Private';
lang_en['Content.Label.ProfileHistory'] = 'Profile History';
lang_en['Content.Label.ProfileID'] = 'Profile ID';
lang_en['Content.Label.ProfilePhoto'] = 'Profile Photo';
lang_en['Content.Label.Project'] = 'Project';
lang_en['Content.Label.ProjectDescription'] = 'Project Description';
lang_en['Content.Label.ProjectReports'] = 'Project Reports';
lang_en['Content.Label.ProjectReset'] = 'Project Reset!';
lang_en['Content.Label.ProjectTime'] = 'Project Time';
lang_en['Content.Label.ProjectTimers'] = 'Project Timers';
lang_en['Content.Label.ProjectTimersActive'] = 'Active Timers';
lang_en['Content.Label.ProjectTimersPaused'] = 'Project Timers Paused';
lang_en['Content.Label.ProjectTimersStopped'] = 'Project Timers Stopped';
lang_en['Content.Label.Projects'] = 'Projects';
lang_en['Content.Label.Public'] = 'Public';
lang_en['Content.Label.QualityOfWork'] = 'Quality Of Work';
lang_en['Content.Label.QuantityOfWork'] = 'Quantity Of Work';
lang_en['Content.Label.Question'] = 'Question';
lang_en['Content.Label.Questions'] = 'Questions?';
lang_en['Content.Label.Rate'] = 'Rate';
lang_en['Content.Label.RateOverride'] = 'Rate Override';
lang_en['Content.Label.RateUsed'] = 'Rate Used';
lang_en['Content.Label.Rating'] = 'Rating';
lang_en['Content.Label.Read'] = 'Read';
lang_en['Content.Label.ReadOnly'] = 'Read Only';
lang_en['Content.Label.Reason'] = 'Reason';
lang_en['Content.Label.ReasonForDisicplinaryAction'] = 'Reason for Disciplinary Action and Action Taken';
lang_en['Content.Label.ReasonForEdit'] = 'Reason For Edit';
lang_en['Content.Label.ReasonForTermination'] = 'Reason For Termination';
lang_en['Content.Label.Receipt'] = 'Receipt';
lang_en['Content.Label.ReceiptSubmitted'] = 'Receipt Submitted';
lang_en['Content.Label.RecordAction.Deleted'] = 'Deleted';
lang_en['Content.Label.RecordAction.Edit'] = 'Edit';
lang_en['Content.Label.RecordAction.New'] = 'New';
lang_en['Content.Label.RecordAction.Request'] = 'Request';
lang_en['Content.Label.RecordAction.Unknown'] = 'Unknown';
lang_en['Content.Label.RecordApproved.Alert'] = 'Alert';
lang_en['Content.Label.RecordApproved.Approved'] = 'Approved';
lang_en['Content.Label.RecordApproved.Unapproved'] = 'Unapproved';
lang_en['Content.Label.RecordApproved.Unknown'] = 'Unknown';
lang_en['Content.Label.RecordBillable.Billable'] = 'Billable';
lang_en['Content.Label.RecordBillable.Unbillable'] = 'Unbillable';
lang_en['Content.Label.RecordBillable.Unknown'] = 'Unknown';
lang_en['Content.Label.RecordExpenseStatus.Open'] = 'Open';
lang_en['Content.Label.RecordExpenseStatus.Reconciled'] = 'Reconciled';
lang_en['Content.Label.RecordExpenseStatus.Unknown'] = 'Unknown';
lang_en['Content.Label.RecordHourlyStatus.Archived'] = 'Archived';
lang_en['Content.Label.RecordHourlyStatus.Open'] = 'Open';
lang_en['Content.Label.RecordHourlyStatus.Paid'] = 'Paid';
lang_en['Content.Label.RecordHourlyStatus.Split'] = 'Split';
lang_en['Content.Label.RecordHourlyStatus.Unknown'] = 'Unknown';
lang_en['Content.Label.RecordProjectStatus.Archived'] = 'Archived';
lang_en['Content.Label.RecordProjectStatus.New'] = 'New';
lang_en['Content.Label.RecordProjectStatus.Open'] = 'Open';
lang_en['Content.Label.RecordProjectStatus.Unknown'] = 'Unknown';
lang_en['Content.Label.RecordSigned.Signed'] = 'Signed';
lang_en['Content.Label.RecordSigned.Unknown'] = 'Unknown';
lang_en['Content.Label.RecordSigned.Unsigned'] = 'Unsigned';
lang_en['Content.Label.RecordXMinBreak'] = 'Record {1} Minute Paid Break';
lang_en['Content.Label.Records'] = 'Records';
lang_en['Content.Label.RecordsWithAlerts'] = 'Records With Alerts';
lang_en['Content.Label.Referral'] = 'Referral';
lang_en['Content.Label.RemainingX'] = 'Remaining {1}';
lang_en['Content.Label.RemainingXTime'] = 'Remaining {1} Time';
lang_en['Content.Label.RememberMe'] = 'Remember Me';
lang_en['Content.Label.RememberMyUserID'] = 'Remember My User ID';
lang_en['Content.Label.Removed'] = 'Removed';
lang_en['Content.Label.ReportDate'] = 'Report Date';
lang_en['Content.Label.ReportGenerated'] = 'Report Generated';
lang_en['Content.Label.ReportRange'] = 'Report Range';
lang_en['Content.Label.ReportType'] = 'Report Type';
lang_en['Content.Label.Reports.ActiveAccountCodes'] = 'Active Account Codes';
lang_en['Content.Label.Reports.ActiveCustomers'] = 'Active Customers';
lang_en['Content.Label.Reports.ActiveEvents'] = 'Active Events';
lang_en['Content.Label.Reports.ActiveProjects'] = 'Active Projects';
lang_en['Content.Label.Reports.ActiveVendors'] = 'Active Vendors';
lang_en['Content.Label.Reports.AllAccountCodes'] = 'All Account Codes';
lang_en['Content.Label.Reports.AllActiveUsers'] = 'All Active Users';
lang_en['Content.Label.Reports.AllActiveUsersWithArchivedNote'] = 'All Active Users (open to select archived Users)';
lang_en['Content.Label.Reports.AllCustomers'] = 'All Customers';
lang_en['Content.Label.Reports.AllEvents'] = 'All Events';
lang_en['Content.Label.Reports.AllProjects'] = 'All Projects';
lang_en['Content.Label.Reports.AllVendors'] = 'All Vendors';
lang_en['Content.Label.Reports.ArchivedAccountCodes'] = 'Archived Account Codes';
lang_en['Content.Label.Reports.ArchivedCustomers'] = 'Archived Customers';
lang_en['Content.Label.Reports.ArchivedEvents'] = 'Archived Events';
lang_en['Content.Label.Reports.ArchivedProjects'] = 'Archived Projects';
lang_en['Content.Label.Reports.ArchivedVendors'] = 'Archived Vendors';
lang_en['Content.Label.Reports.ExpenseReport'] = 'Expense Report';
lang_en['Content.Label.Reports.HideX'] = 'Hide {1}';
lang_en['Content.Label.Reports.HourlyTimeReport'] = 'Hourly Time Report';
lang_en['Content.Label.Reports.HoursSummary'] = 'Hours Summary';
lang_en['Content.Label.Reports.IncludeAllAccountCodes'] = 'Include All Account Codes';
lang_en['Content.Label.Reports.IncludeAllActiveUsers'] = 'Include All Active Users (uncheck to include archived)';
lang_en['Content.Label.Reports.IncludeAllActiveUsersNoArchived'] = 'Include All Active Users';
lang_en['Content.Label.Reports.IncludeAllCustomers'] = 'Include All Customers';
lang_en['Content.Label.Reports.IncludeAllEvents'] = 'Include All Events';
lang_en['Content.Label.Reports.IncludeAllProjects'] = 'Include All Projects';
lang_en['Content.Label.Reports.IncludeAllVendors'] = 'Include All Vendors';
lang_en['Content.Label.Reports.LocationReport'] = 'Location Report';
lang_en['Content.Label.Reports.OverlappingRecordsReport'] = 'Overlapping Records Report';
lang_en['Content.Label.Reports.ProjectTimeReport'] = 'Project Time Report';
lang_en['Content.Label.Reports.ShowX'] = 'Show {1}';
lang_en['Content.Label.Reports.ViewXAccountCodes'] = 'View {1,number,#} Account Codes';
lang_en['Content.Label.Reports.ViewXCustomers'] = 'View {1,number,#} Customers';
lang_en['Content.Label.Reports.ViewXEmployees'] = 'View {1,number,#} Employees';
lang_en['Content.Label.Reports.ViewXEvents'] = 'View {1,number,#} Events';
lang_en['Content.Label.Reports.ViewXProjects'] = 'View {1,number,#} Projects';
lang_en['Content.Label.Reports.ViewXTimeClasses'] = 'View {1} Time Classes';
lang_en['Content.Label.Reports.ViewXVendors'] = 'View {1,number,#} Vendors';
lang_en['Content.Label.Reports.XAccountCodes'] = '{1,number,#} Account Codes';
lang_en['Content.Label.Reports.XCustomers'] = '{1,number,#} Customers';
lang_en['Content.Label.Reports.XEmployees'] = '{1,number,#} Employees';
lang_en['Content.Label.Reports.XEvents'] = '{1,number,#} Events';
lang_en['Content.Label.Reports.XProjects'] = '{1,number,#} Projects';
lang_en['Content.Label.Reports.XTimeClasses'] = '{1} Time Classes';
lang_en['Content.Label.Reports.XVendors'] = '{1,number,#} Vendors';
lang_en['Content.Label.Requested'] = 'Requested';
lang_en['Content.Label.Requests'] = 'Requests';
lang_en['Content.Label.Required'] = 'Required';
lang_en['Content.Label.RestrictedFields'] = 'Restricted Fields';
lang_en['Content.Label.Result'] = 'Result';
lang_en['Content.Label.ResultsFor'] = 'Results for';
lang_en['Content.Label.Retry'] = 'Retry';
lang_en['Content.Label.ReviewType'] = 'Review Type';
lang_en['Content.Label.ReviewedBy'] = 'Reviewed By';
lang_en['Content.Label.Revision'] = 'Revision';
lang_en['Content.Label.RunInBackground'] = 'Running a large report?  Run in background';
lang_en['Content.Label.Running'] = 'Running';
lang_en['Content.Label.SalaryPayRate'] = 'Salary Pay Rate';
lang_en['Content.Label.SalesStatus.Closed'] = 'Closed';
lang_en['Content.Label.SalesStatus.Ghost'] = 'Ghost';
lang_en['Content.Label.SalesStatus.NoContact'] = 'No Contact';
lang_en['Content.Label.SalesStatus.None'] = 'None';
lang_en['Content.Label.SalesStatus.Post-Demo'] = 'Post-Demo';
lang_en['Content.Label.SalesStatus.Pre-Demo'] = 'Pre-Demo';
lang_en['Content.Label.SalesStatus.Tracking'] = 'Tracking';
lang_en['Content.Label.SalesStatus.Unknown'] = 'Unknown';
lang_en['Content.Label.SameAsX'] = 'Same as {1}';
lang_en['Content.Label.ScheduledXTimeNotYetTaken'] = 'Scheduled {1} Time Not Yet Taken';
lang_en['Content.Label.SearchKeywords'] = 'Search Keywords';
lang_en['Content.Label.SearchNotesOnKeywords'] = 'Search Notes on Keywords';
lang_en['Content.Label.SearchResults'] = 'Search Results';
lang_en['Content.Label.SearchResultsFor'] = 'Search results for';
lang_en['Content.Label.SecurityCode'] = 'Security Code';
lang_en['Content.Label.SecurityQuestion'] = 'Security Question';
lang_en['Content.Label.SelectAFormat'] = 'Select a format:';
lang_en['Content.Label.SelectAccountToLink'] = 'Select Account To Link';
lang_en['Content.Label.SelectListItems'] = 'Select List Items';
lang_en['Content.Label.SelectPayrollPeriod'] = 'Select Payroll Period';
lang_en['Content.Label.SelectPayrollPeriod.AppendUsers'] = 'Append selected users to an existing payroll report';
lang_en['Content.Label.SelectPayrollPeriod.ScheduledPeriods'] = 'Scheduled Periods';
lang_en['Content.Label.SelectShiftToUseWithReport'] = 'Select Shift to Use with Report';
lang_en['Content.Label.SelectUsersToAssignToSupervisor'] = 'Select users to assign to this supervisor.';
lang_en['Content.Label.SelectedItems'] = 'Selected Items';
lang_en['Content.Label.SelectedTotal'] = 'Selected Total';
lang_en['Content.Label.SendAMemo'] = 'Send A Memo';
lang_en['Content.Label.SendClockOutLink'] = 'Send Clock Out Link';
lang_en['Content.Label.SendTo'] = 'Send To';
lang_en['Content.Label.SentBy'] = 'Sent By';
lang_en['Content.Label.Settings'] = 'Settings';
lang_en['Content.Label.Shared'] = 'Shared';
lang_en['Content.Label.SharedWith'] = 'Shared With';
lang_en['Content.Label.Shift'] = 'Shift';
lang_en['Content.Label.ShiftEnd'] = 'Shift End';
lang_en['Content.Label.ShiftRestricted'] = 'Shift Restricted';
lang_en['Content.Label.ShiftRestriction'] = 'Shift Restriction';
lang_en['Content.Label.ShiftStart'] = 'Shift Start';
lang_en['Content.Label.ShippingInfo'] = 'Shipping Information';
lang_en['Content.Label.ShowReportFor'] = 'Show Report For';
lang_en['Content.Label.ShowingXMostRecentChangesToTimesheet'] = 'Showing the {1} most recent changes to the timesheet.';
lang_en['Content.Label.SignUps'] = 'Sign Ups';
lang_en['Content.Label.Signature.AllRecordsSignedBySupervisor'] = 'All records were electronically approved by the supervisor';
lang_en['Content.Label.Signature.AllRecordsSignedByWorked'] = 'All records were electronically signed by this worker';
lang_en['Content.Label.Signature.ContractorSignature'] = 'Contractor Signature';
lang_en['Content.Label.Signature.EmployeeSignature'] = 'Employee Signature';
lang_en['Content.Label.Signature.OtherSignature'] = 'Other Signature';
lang_en['Content.Label.Signature.Signature'] = 'Signature';
lang_en['Content.Label.Signature.StudentSignature'] = 'Student Signature';
lang_en['Content.Label.Signature.SupervisorSignature'] = 'Supervisor Signature';
lang_en['Content.Label.Signature.VolunteerSignature'] = 'Volunteer Signature';
lang_en['Content.Label.Signed'] = 'Signed';
lang_en['Content.Label.SignedAbbrv'] = 'Sig';
lang_en['Content.Label.Size'] = 'Size';
lang_en['Content.Label.Snapshot'] = 'Snapshot';
lang_en['Content.Label.SplitHourlyTime'] = 'Split Hourly Time';
lang_en['Content.Label.SplitRecord'] = 'Split One Record Into Two Records';
lang_en['Content.Label.SplitRecord.EarlierAcctCode'] = 'Earlier record account code';
lang_en['Content.Label.SplitRecord.EarlierEnd'] = 'Earlier record end';
lang_en['Content.Label.SplitRecord.EarlierStart'] = 'Earlier record start';
lang_en['Content.Label.SplitRecord.LaterAcctCode'] = 'Later record account code';
lang_en['Content.Label.SplitRecord.LaterEnd'] = 'Later record end';
lang_en['Content.Label.SplitRecord.LaterStart'] = 'Later record start';
lang_en['Content.Label.Standard'] = 'Standard';
lang_en['Content.Label.Start'] = 'Start';
lang_en['Content.Label.StartDate'] = 'Start Date';
lang_en['Content.Label.StartTime'] = 'Start Time';
lang_en['Content.Label.Started'] = 'Started';
lang_en['Content.Label.State'] = 'State';
lang_en['Content.Label.Status'] = 'Status';
lang_en['Content.Label.StatusReport.Daily'] = 'Daily';
lang_en['Content.Label.StatusReport.Monthly'] = 'Monthly';
lang_en['Content.Label.StatusReport.Never'] = 'Never';
lang_en['Content.Label.StatusReport.Weekly'] = 'Weekly';
lang_en['Content.Label.Subdomain'] = 'Subdomain';
lang_en['Content.Label.Subscribed'] = 'Subscribed';
lang_en['Content.Label.Subscription'] = 'Subscription';
lang_en['Content.Label.Supervisor'] = 'Supervisor';
lang_en['Content.Label.SupervisorSignature'] = 'Supervisor Signature';
lang_en['Content.Label.Support'] = 'Support';
lang_en['Content.Label.SupportCenter'] = 'Support Center';
lang_en['Content.Label.Suspended'] = 'Suspended';
lang_en['Content.Label.SystemMessage'] = 'System Message';
lang_en['Content.Label.SystemNote'] = 'System Note';
lang_en['Content.Label.TZ'] = 'TZ';
lang_en['Content.Label.TakePhoto'] = 'Take Photo';
lang_en['Content.Label.Teamwork'] = 'Teamwork';
lang_en['Content.Label.TerminationDate'] = 'Termination Date';
lang_en['Content.Label.TerminationLetter'] = 'Termination Letter';
lang_en['Content.Label.TheFollowingUsersAreIncludedInThisReport'] = 'The following users are included in this report:';
lang_en['Content.Label.ThereWasOneRecentChangeToTimesheet'] = 'There was one recent change to the timesheet.';
lang_en['Content.Label.ThereWereXRecentChangeToTimesheet'] = 'There were {1} recent changes to the timesheet.';
lang_en['Content.Label.Time'] = 'Time';
lang_en['Content.Label.TimeClass'] = 'Time Class';
lang_en['Content.Label.TimeClasses'] = 'Time Classes';
lang_en['Content.Label.TimeClassification'] = 'Time Classification';
lang_en['Content.Label.TimeClassifications'] = 'Time Classifications';
lang_en['Content.Label.TimeEntry'] = 'Time Entry';
lang_en['Content.Label.TimeRequest'] = 'Time Request';
lang_en['Content.Label.TimeZone'] = 'Time Zone';
lang_en['Content.Label.TimeZoneAbbrev'] = 'TZ';
lang_en['Content.Label.Total'] = 'Total';
lang_en['Content.Label.TotalBill'] = 'Total Bill';
lang_en['Content.Label.TotalCost'] = 'Total Cost';
lang_en['Content.Label.TotalExpenses'] = 'Total Expenses';
lang_en['Content.Label.TotalFixedPay'] = 'Total Fixed Pay';
lang_en['Content.Label.TotalHours'] = 'Total Hours';
lang_en['Content.Label.TotalHoursAdded'] = 'Total Hours Added';
lang_en['Content.Label.TotalHoursDeducted'] = 'Total Hours Deducted';
lang_en['Content.Label.TotalMileage'] = 'Total Mileage';
lang_en['Content.Label.TotalOfAllHours'] = 'Total Of All Hours';
lang_en['Content.Label.TotalPay'] = 'Total Pay';
lang_en['Content.Label.TotalPayableHours'] = 'Total Payable Hours';
lang_en['Content.Label.TotalPayroll'] = 'Total Payroll';
lang_en['Content.Label.TotalRecords'] = 'Total Records';
lang_en['Content.Label.TotalTime'] = 'Total Time';
lang_en['Content.Label.TotalVariablePay'] = 'Total Variable Pay';
lang_en['Content.Label.Totals'] = 'Totals';
lang_en['Content.Label.TrackBalancesAnotherWay'] = 'No, I plan to track time-off balances another way.';
lang_en['Content.Label.TrackBalancesInTimesheets'] = 'Yes, I will track accrued time off balances in Timesheets.com.';
lang_en['Content.Label.TransactionDate'] = 'Transaction Date';
lang_en['Content.Label.TransactionID'] = 'Transaction ID';
lang_en['Content.Label.TrialExpiration'] = 'Trial Expiration';
lang_en['Content.Label.Type'] = 'Type';
lang_en['Content.Label.Unapproved'] = 'Unapproved';
lang_en['Content.Label.UniqueIdentifier'] = 'Unique Identifier';
lang_en['Content.Label.Unknown'] = 'Unknown';
lang_en['Content.Label.Unread'] = 'Unread';
lang_en['Content.Label.UnscheduledShift'] = 'Unscheduled Shift';
lang_en['Content.Label.UpdateAccountCode'] = 'Update Account Code';
lang_en['Content.Label.Updated'] = 'Updated';
lang_en['Content.Label.UploadImportFile'] = 'Upload Import File';
lang_en['Content.Label.UploadPhoto'] = 'Upload Photo';
lang_en['Content.Label.UploadReceipt'] = 'Upload Receipt';
lang_en['Content.Label.UploadReceipt.JPGPDF'] = 'JPG or PDF';
lang_en['Content.Label.UseDefaultRate'] = 'Use Default Rate';
lang_en['Content.Label.UseWith'] = 'Use with';
lang_en['Content.Label.User'] = 'User';
lang_en['Content.Label.UserAccess.Administrator'] = 'Administrator';
lang_en['Content.Label.UserAccess.Employee'] = 'Employee';
lang_en['Content.Label.UserAccess.SiteAdmin'] = 'SysAdmin';
lang_en['Content.Label.UserAccess.Supervisor'] = 'Supervisor';
lang_en['Content.Label.UserAccess.Unknown'] = 'Unknown';
lang_en['Content.Label.UserComments'] = 'User Comments';
lang_en['Content.Label.UserID'] = 'User ID';
lang_en['Content.Label.UserInfo'] = 'User Info';
lang_en['Content.Label.UserLicenses'] = 'User Licenses';
lang_en['Content.Label.UserNumber'] = 'User Number';
lang_en['Content.Label.UserStatus.Active'] = 'Active';
lang_en['Content.Label.UserStatus.Archived'] = 'Archived';
lang_en['Content.Label.UserStatus.Unknown'] = 'Unknown';
lang_en['Content.Label.Username'] = 'Username';
lang_en['Content.Label.Users'] = 'Users';
lang_en['Content.Label.UsersByName'] = 'Users By Name';
lang_en['Content.Label.UsersBySupervisor'] = 'Users By Supervisor';
lang_en['Content.Label.Validated'] = 'Validated';
lang_en['Content.Label.VariablePay'] = 'Variable Pay';
lang_en['Content.Label.VariablePayRate'] = 'Variable Pay Rate';
lang_en['Content.Label.VariablePayTotal'] = 'Variable Pay Total';
lang_en['Content.Label.VariableRate'] = 'Variable Rate';
lang_en['Content.Label.Vendor'] = 'Vendor';
lang_en['Content.Label.Vendors'] = 'Vendors';
lang_en['Content.Label.Version'] = 'Version';
lang_en['Content.Label.ViewExpenses'] = 'View Expenses';
lang_en['Content.Label.ViewHourlyTimesheet'] = 'View Hourly Timesheet';
lang_en['Content.Label.ViewProjectTimers'] = 'View Project Timers';
lang_en['Content.Label.ViewProjectTimesheet'] = 'View Project Timesheet';
lang_en['Content.Label.ViewRecordsFrom'] = 'View records from';
lang_en['Content.Label.ViewSettings'] = 'View Settings';
lang_en['Content.Label.WorkDate'] = 'Work Date';
lang_en['Content.Label.WorkWeekDelineator'] = 'Work Week Delineator';
lang_en['Content.Label.WorkersCompJobCode'] = 'Workers Comp Job Code';
lang_en['Content.Label.WritePermission'] = 'Write Permission';
lang_en['Content.Label.XActive'] = '{1,number,#} Active';
lang_en['Content.Label.XActiveUsers'] = '{1,number,#} Active Users';
lang_en['Content.Label.XPayrollReports'] = '{1,number,#} Payroll Report(s)';
lang_en['Content.Label.Yes'] = 'Yes';
lang_en['Content.Label.YesLimitTo'] = 'Yes, Limit to';
lang_en['Content.Label.Your'] = 'Your';
lang_en['Content.Label.YourContactPhone'] = 'Your Contact Phone';
lang_en['Content.Label.YourKey'] = 'Your Key';
lang_en['Content.Label.YourSupervisor'] = 'Your Supervisor';
lang_en['Content.Label.YourToken'] = 'Your Token';
lang_en['Content.Label.ZipCode'] = 'Zip Code';
lang_en['Content.Legend'] = 'Legend';
lang_en['Content.Legend.AccountAdmin'] = 'Account Admin';
lang_en['Content.Legend.ActiveAccount'] = 'Active Account';
lang_en['Content.Legend.ActiveAccountEmployeeLoginDisabled'] = 'Active account with employee log in disabled';
lang_en['Content.Legend.ActiveAccountUserLoginDisabled'] = 'Active account with user log in disabled';
lang_en['Content.Legend.Add'] = 'Add';
lang_en['Content.Legend.AddRecord'] = 'Add Record';
lang_en['Content.Legend.Approved'] = 'Approved';
lang_en['Content.Legend.Archive'] = 'Archive';
lang_en['Content.Legend.ArchiveAllItems'] = 'Archive All Items';
lang_en['Content.Legend.Archived'] = 'Archived';
lang_en['Content.Legend.ArchivedUser'] = 'Archived User';
lang_en['Content.Legend.Billable'] = 'Billable';
lang_en['Content.Legend.ClockUserOut'] = 'Clock User Out';
lang_en['Content.Legend.Contractor'] = 'Contractor';
lang_en['Content.Legend.CurrentlyClockedIn'] = 'Currently Clocked In';
lang_en['Content.Legend.DashTotalsText'] = 'Totals include unarchived/unpaid records within the last 90 days.';
lang_en['Content.Legend.DashboardTotalsText'] = 'Totals include unarchived/unpaid records within the last 90 days.';
lang_en['Content.Legend.DefaultItem'] = 'Default Item cannot be deleted, archived, or restricted.';
lang_en['Content.Legend.Delete'] = 'Delete';
lang_en['Content.Legend.DeleteAllItems'] = 'Delete All Items';
lang_en['Content.Legend.Deleted'] = 'Deleted';
lang_en['Content.Legend.DisabledDueToFutureDateRestriction'] = 'Disabled due to Future Date Restriction';
lang_en['Content.Legend.DisabledDueToTimeEntryCutoff'] = 'Disabled due to Time Entry Cut-Off';
lang_en['Content.Legend.Download'] = 'Download';
lang_en['Content.Legend.DownloadItemList'] = 'Download Item List';
lang_en['Content.Legend.Early'] = 'Early';
lang_en['Content.Legend.Edit'] = 'Edit';
lang_en['Content.Legend.EditItem'] = 'Edit Item';
lang_en['Content.Legend.EditRequest'] = 'Edit Request';
lang_en['Content.Legend.Employee'] = 'Employee';
lang_en['Content.Legend.EmployeeClockedIn'] = 'User is clocked in';
lang_en['Content.Legend.EmployeeClockedIn8Hours'] = 'User clocked in 8+ hours';
lang_en['Content.Legend.EmployeeClockedInTimerOpen'] = 'User is clocked in / has open project timers';
lang_en['Content.Legend.EmployeeOpenTimer'] = 'User has open timers';
lang_en['Content.Legend.Export'] = 'Export';
lang_en['Content.Legend.ExportReport'] = 'Export Report';
lang_en['Content.Legend.GenericDelete'] = 'Delete';
lang_en['Content.Legend.GenericEdit'] = 'Edit';
lang_en['Content.Legend.GenericMerge'] = 'Merge';
lang_en['Content.Legend.GenericView'] = 'View';
lang_en['Content.Legend.HasReceipt'] = 'Has Receipt';
lang_en['Content.Legend.Hint'] = 'Hint';
lang_en['Content.Legend.Holiday'] = 'Holiday (HOL)';
lang_en['Content.Legend.Import'] = 'Import';
lang_en['Content.Legend.Late'] = 'Late';
lang_en['Content.Legend.Legend'] = 'Legend';
lang_en['Content.Legend.Locked'] = 'Locked';
lang_en['Content.Legend.ManualClockIn'] = 'Clock user in';
lang_en['Content.Legend.ManualClockOut'] = 'Manual Clock Out';
lang_en['Content.Legend.MarkAllAsRead'] = 'Mark All as Read';
lang_en['Content.Legend.Merge'] = 'Merge';
lang_en['Content.Legend.MergeProject'] = 'Merge Project';
lang_en['Content.Legend.New'] = 'New';
lang_en['Content.Legend.Next'] = 'Next';
lang_en['Content.Legend.None'] = 'None';
lang_en['Content.Legend.NotAvailable'] = 'Not Available';
lang_en['Content.Legend.NotRequired'] = 'Not Required';
lang_en['Content.Legend.NotSignedNotApproved'] = 'One or more records not signed/approved';
lang_en['Content.Legend.OnBreak'] = 'User is on break';
lang_en['Content.Legend.OnTime'] = 'On Time';
lang_en['Content.Legend.Other'] = 'Other';
lang_en['Content.Legend.Paid'] = 'Paid';
lang_en['Content.Legend.PauseTimer'] = 'Pause Timer';
lang_en['Content.Legend.Paused'] = 'Paused';
lang_en['Content.Legend.PausedTimer'] = 'Paused Timer';
lang_en['Content.Legend.PayrollAppendedDate'] = '[added {1}]';
lang_en['Content.Legend.PendingAlert'] = 'Pending Alert';
lang_en['Content.Legend.PendingAlertEdit'] = 'Edit Pending Alert';
lang_en['Content.Legend.PhotoStamp'] = 'Photo Stamp';
lang_en['Content.Legend.PostDated'] = 'Post Dated';
lang_en['Content.Legend.Previous'] = 'Previous';
lang_en['Content.Legend.PrimaryAccountAdmin'] = 'Primary Account Admin';
lang_en['Content.Legend.Print.Admin'] = 'Adm=Admin';
lang_en['Content.Legend.Print.Contractor'] = 'C=Contractor';
lang_en['Content.Legend.Print.Employee'] = 'E=Employee';
lang_en['Content.Legend.Print.Hourly'] = 'Hrly=Hourly';
lang_en['Content.Legend.Print.Other'] = 'O=Other';
lang_en['Content.Legend.Print.Salaried'] = 'Sal=Salaried';
lang_en['Content.Legend.Print.Student'] = 'S=Student';
lang_en['Content.Legend.Print.Supervisor'] = 'Sup=Supervisor';
lang_en['Content.Legend.Print.Volunteer'] = 'Volunteer';
lang_en['Content.Legend.ReOpenPayrollPeriod'] = 'Reopen Payroll Period';
lang_en['Content.Legend.ReactivateUser'] = 'Reactivate User';
lang_en['Content.Legend.Read'] = 'Read';
lang_en['Content.Legend.RecentlyModifiedBySupervisor'] = 'Recently Modified By Supervisor';
lang_en['Content.Legend.Reconciled'] = 'Reconciled';
lang_en['Content.Legend.Refresh'] = 'Refresh';
lang_en['Content.Legend.RequestedChange'] = 'Requested Change';
lang_en['Content.Legend.RestoreAllItems'] = 'Restore All Items';
lang_en['Content.Legend.Running'] = 'Running';
lang_en['Content.Legend.RunningTimer'] = 'Running Timer';
lang_en['Content.Legend.Salaried'] = 'Salaried';
lang_en['Content.Legend.Settings'] = 'Settings';
lang_en['Content.Legend.Split'] = 'Split';
lang_en['Content.Legend.SplitPaid'] = 'Split Paid';
lang_en['Content.Legend.StartTimer'] = 'Start Timer';
lang_en['Content.Legend.StopTimer'] = 'Stop Timer';
lang_en['Content.Legend.Student'] = 'Student';
lang_en['Content.Legend.SupervisorManager'] = 'Supervisor/Manager';
lang_en['Content.Legend.SupervisorSettings.PermissionIsDependent'] = 'Access to this permission is dependent on other permissions on this page.';
lang_en['Content.Legend.SupervisorSettings.PermissionIsDisabled'] = 'This permission is disabled because of restrictions at a higher management level.';
lang_en['Content.Legend.SupervisorSettings.PermissionIsDisabledForSubs'] = 'This permission, when disabled, will also be disabled for this user\'s subordinate supervisors.';
lang_en['Content.Legend.Title.HourlyTimesheetSubTitle'] = 'Some hourly day totals may be <i class="font-red">shown in red</i> to help prevent overtime.';
lang_en['Content.Legend.Unarchive'] = 'Unarchive';
lang_en['Content.Legend.Unread'] = 'Unread';
lang_en['Content.Legend.UserClockedIn'] = 'User is clocked in';
lang_en['Content.Legend.UserClockedIn8Hours'] = 'User clocked in 8+ hours';
lang_en['Content.Legend.UserOpenTimer'] = 'User has open timers';
lang_en['Content.Legend.UserSigned'] = 'User Signed';
lang_en['Content.Legend.View'] = 'View';
lang_en['Content.Legend.ViewDetails'] = 'View Details/Notes';
lang_en['Content.Legend.ViewRecord'] = 'View Record';
lang_en['Content.Legend.ViewReport'] = 'View Report';
lang_en['Content.Legend.Volunteer'] = 'Volunteer';
lang_en['Content.Link.AddMany'] = 'add many...';
lang_en['Content.Link.Back'] = 'Back';
lang_en['Content.Link.BackToCalendarsAndSchedules'] = 'Back to Calendars & Schedules';
lang_en['Content.Link.BackToDashboard'] = 'Back To Dashboard';
lang_en['Content.Link.BackToListing'] = 'Back To Listing';
lang_en['Content.Link.BackToLogin'] = 'Back To Login';
lang_en['Content.Link.BackToReports'] = 'Back To Reports';
lang_en['Content.Link.BackToSettings'] = 'Back To Settings';
lang_en['Content.Link.BackToTimesheet'] = 'Back To Timesheet';
lang_en['Content.Link.Cancel'] = 'Cancel';
lang_en['Content.Link.Close'] = 'Close';
lang_en['Content.Link.CloseAll'] = 'Close All';
lang_en['Content.Link.ContactExternal'] = 'Contact';
lang_en['Content.Link.ContactUs'] = 'Contact Us';
lang_en['Content.Link.DeselectAll'] = 'Deselect All';
lang_en['Content.Link.DesktopView'] = 'Desktop View';
lang_en['Content.Link.DownloadImportFile'] = 'Download Import File';
lang_en['Content.Link.DownloadTemplateFileWithHeader'] = 'Download Template File With Header';
lang_en['Content.Link.EditThisRecord'] = 'Edit This Record';
lang_en['Content.Link.ExitSetupWizard'] = 'Exit Setup Wizard';
lang_en['Content.Link.GetAnAccount'] = 'Get an Account';
lang_en['Content.Link.Go'] = 'Go';
lang_en['Content.Link.ImportFile'] = 'Import File';
lang_en['Content.Link.LearnMore'] = 'Learn More';
lang_en['Content.Link.LoginToCustomerAccts'] = 'Login to Customer Accounts';
lang_en['Content.Link.OpenAll'] = 'Open All';
lang_en['Content.Link.PartnerAgreement'] = 'Partner Agreement';
lang_en['Content.Link.PartnerEnroll'] = 'Enroll in the Partner Program';
lang_en['Content.Link.PartnerInfo'] = 'Partners';
lang_en['Content.Link.Password'] = 'Password';
lang_en['Content.Link.QBOReviewListItems'] = 'Review List Items';
lang_en['Content.Link.RemoveFailed'] = 'Remove Failed';
lang_en['Content.Link.ReportClosedPayroll'] = 'Closed Payroll Period Reports';
lang_en['Content.Link.ReportCoCalendar'] = 'Company Calendar';
lang_en['Content.Link.ReportCustExpense'] = 'Customizable Reports';
lang_en['Content.Link.ReportCustHourly'] = 'Customizable Timesheet Report';
lang_en['Content.Link.ReportCustHourlyProcessed'] = 'Customizable Processed Timesheet Report';
lang_en['Content.Link.ReportCustProject'] = 'Customizable Reports';
lang_en['Content.Link.ReportEmpInfo'] = 'User Info';
lang_en['Content.Link.ReportLocationTardy'] = 'Location and Tardy Reports';
lang_en['Content.Link.ReportOverlappingRecords'] = 'Overlapping Records';
lang_en['Content.Link.ReportPreviewPayroll'] = 'Preview Current Period (with overtime)';
lang_en['Content.Link.ReportQBOExpense'] = 'Expenses';
lang_en['Content.Link.ReportQBOHourly'] = 'Hourly Timesheets';
lang_en['Content.Link.ReportQBOProject'] = 'Project Timesheets';
lang_en['Content.Link.ReportRecordHistory'] = 'Record History Report';
lang_en['Content.Link.Resend'] = 'Resend';
lang_en['Content.Link.Reset'] = 'Reset';
lang_en['Content.Link.Retry'] = 'Retry';
lang_en['Content.Link.ReturnToHomepage'] = 'Return To Homepage';
lang_en['Content.Link.ReturnToLogin'] = 'Return To Login';
lang_en['Content.Link.SelectAll'] = 'Select All';
lang_en['Content.Link.SelectAllGroups'] = 'Select All Groups';
lang_en['Content.Link.Timesheet'] = 'Timesheet';
lang_en['Content.Link.UpdatePW'] = 'Update Password';
lang_en['Content.Link.UseGlobalEntry'] = 'Use Global Entry';
lang_en['Content.Link.UseSingleEntry'] = 'Use Single Entry';
lang_en['Content.Link.VisitSupport'] = 'Visit our support and help documentation for the most up-to-date information.';
lang_en['Content.Misc.AccountAccessIsDisabled'] = 'Account access is currently disabled.';
lang_en['Content.Misc.AccountDeletion.Confirmed'] = 'Your account has been confirmed for deletion and all data will be permanently deleted no later than 30 days after the termination of your service. Thank you.';
lang_en['Content.Misc.AccountDeletion.NotConfirmed'] = 'The confirmation code provided was not correct. If you believe this was in error please contact customer support. Thank you.';
lang_en['Content.Misc.And'] = 'And';
lang_en['Content.Misc.CharsLeft'] = 'characters left';
lang_en['Content.Misc.ClickToViewImage'] = 'Click to view image';
lang_en['Content.Misc.ConflictingPaidRecords'] = 'Conflicting Paid Records';
lang_en['Content.Misc.ConflictingUnpaidRecords'] = 'Conflicting Unpaid Record IDs';
lang_en['Content.Misc.Contact.BusinessHourTimes'] = '{1} - {2} Pacific';
lang_en['Content.Misc.Contact.BusinessHours'] = 'Business Hours';
lang_en['Content.Misc.Contact.QuestionsCommentsSuggestions'] = 'Questions, Comments or Suggestions';
lang_en['Content.Misc.Contact.ScheduleATimeToMeet'] = 'Schedule a time to meet with a representative.';
lang_en['Content.Misc.Contact.ScheduleAnAppointment'] = 'Schedule An Appointment';
lang_en['Content.Misc.Contact.SendToSupervisor'] = 'Send this message to my supervisor.';
lang_en['Content.Misc.Contact.SendToSupport'] = 'Send this message to {1} customer support.';
lang_en['Content.Misc.Contact.SendUsAnEmail'] = 'Send Us An Email';
lang_en['Content.Misc.Contact.YourAccountRep'] = 'Your personal account representative is {1}.';
lang_en['Content.Misc.DefaultSignatureText'] = 'By signing above I acknowledge and certify that the information contained on this document is true and accurate.';
lang_en['Content.Misc.FirstTimeLogin.ChooseATour'] = 'Choose the tour that applies to you:';
lang_en['Content.Misc.FirstTimeLogin.WonderingWhatToDo'] = 'Wondering what to do first? Take the 2 minute tour!';
lang_en['Content.Misc.FutureLineInfo'] = 'The future line marks 12:00am tomorrow.';
lang_en['Content.Misc.Item.AccountCode.CannotBeArchived'] = 'The Account Code has new hours. It cannot be archived until the hours are billed.';
lang_en['Content.Misc.Item.AccountCode.CannotBeDeleted'] = 'The Account Code has hours billed to it and cannot be deleted. It can only be archived after the hours are billed.';
lang_en['Content.Misc.Item.AccountCode.IsDefaultItem'] = 'This is the default Account Code. It is always available and cannot be deleted or archived.';
lang_en['Content.Misc.Item.Customer.CannotBeArchived'] = 'The Customer has new hours. It cannot be archived until the hours are billed and any open expenses assigned to it are reconciled.';
lang_en['Content.Misc.Item.Customer.CannotBeDeleted'] = 'The Customer has hours billed to it and cannot be deleted. It can only be archived after the hours are billed and any open expenses assigned to it are reconciled.';
lang_en['Content.Misc.Item.Customer.IsDefaultItem'] = 'This is the default Customer. It is always available and cannot be deleted or archived.';
lang_en['Content.Misc.Item.Event.CannotBeArchived'] = 'This Event has unreconciled expenses. It cannot be archived until those expenses are reconciled.';
lang_en['Content.Misc.Item.Event.CannotBeDeleted'] = 'This Event cannot be deleted because there are previously archived expenses.';
lang_en['Content.Misc.Item.Event.IsDefaultItem'] = 'This is the default Event. It is always available and cannot be deleted or archived.';
lang_en['Content.Misc.Item.Project.CannotBeArchived'] = 'The Project has new hours. It cannot be archived until the hours are billed and any open expenses assigned to it are reconciled.';
lang_en['Content.Misc.Item.Project.CannotBeDeleted'] = 'The Project has hours billed to it and cannot be deleted. It can only be archived after the hours are billed and any open expenses assigned to it are reconciled.';
lang_en['Content.Misc.Item.Project.IsDefaultItem'] = 'This is the default Project. It is always available and cannot be deleted or archived.';
lang_en['Content.Misc.Item.Vendor.CannotBeArchived'] = 'This Vendor has unreconciled expenses. It cannot be archived until those expenses are reconciled.';
lang_en['Content.Misc.Item.Vendor.CannotBeDeleted'] = 'This Vendor cannot be deleted because there are previously archived expenses.';
lang_en['Content.Misc.Item.Vendor.IsDefaultItem'] = 'This is the default Vendor. It is always available and cannot be deleted or archived.';
lang_en['Content.Misc.ItemOrSelectionCurrentlyUnavailable'] = 'This item or selection is currently unavailable.';
lang_en['Content.Misc.JPGorPDF'] = 'jpg or pdf';
lang_en['Content.Misc.Or'] = 'Or';
lang_en['Content.Misc.PayrollSummaryUnavailable'] = 'Payroll Summary Currently Unavailable';
lang_en['Content.Misc.ProjectWeekCopySelection.Part1'] = 'You are about to copy these records into the next week. Archived time, time that would exceed daily limits, and records with alerts will not be copied.';
lang_en['Content.Misc.ProjectWeekCopySelection.Part2'] = 'How would you like to handle copying any records with customers or other selections that are no longer valid choices?';
lang_en['Content.Misc.QBO.AccountDisconnectedSuccessfully'] = 'Timesheets.com was successfully disconnected from QuickBooks Online.';
lang_en['Content.Misc.Questions'] = 'Questions?';
lang_en['Content.Misc.ReferralProgramTagline'] = 'Referrals will receive an email mentioning your name and inviting them to try Timesheets.com. You will receive a $25 Amazon gift card 90 days after the referral becomes a paying customer as long as the client remains with us during the 90 day period.';
lang_en['Content.Misc.Setting.AutoAdjustForDST'] = 'Automatically adjust for Daylight Saving Time?';
lang_en['Content.Misc.VisitOurSupportSite'] = 'Visit our support site for full program details.';
lang_en['Content.Misc.WantToTestSampleAccount'] = 'Want to test a sample account?';
lang_en['Content.Misc.WeAppreciateYourEfforts'] = 'Earn $25 for every referral';
lang_en['Content.Month.Full.April'] = 'April';
lang_en['Content.Month.Full.August'] = 'August';
lang_en['Content.Month.Full.December'] = 'December';
lang_en['Content.Month.Full.February'] = 'February';
lang_en['Content.Month.Full.January'] = 'January';
lang_en['Content.Month.Full.July'] = 'July';
lang_en['Content.Month.Full.June'] = 'June';
lang_en['Content.Month.Full.March'] = 'March';
lang_en['Content.Month.Full.May'] = 'May';
lang_en['Content.Month.Full.November'] = 'November';
lang_en['Content.Month.Full.October'] = 'October';
lang_en['Content.Month.Full.September'] = 'September';
lang_en['Content.Month.Short.April'] = 'Apr';
lang_en['Content.Month.Short.August'] = 'Aug';
lang_en['Content.Month.Short.December'] = 'Dec';
lang_en['Content.Month.Short.February'] = 'Feb';
lang_en['Content.Month.Short.January'] = 'Jan';
lang_en['Content.Month.Short.July'] = 'Jul';
lang_en['Content.Month.Short.June'] = 'Jun';
lang_en['Content.Month.Short.March'] = 'Mar';
lang_en['Content.Month.Short.May'] = 'May';
lang_en['Content.Month.Short.November'] = 'Nov';
lang_en['Content.Month.Short.October'] = 'Oct';
lang_en['Content.Month.Short.September'] = 'Sep';
lang_en['Content.Notification.1.Description'] = 'Sends a notification when any user that reports directly to you clocks out.';
lang_en['Content.Notification.1.Title'] = 'Users directly reporting to me clock out';
lang_en['Content.Notification.10.Description'] = 'Sends a notification to you after you have been clocked in for more than your default shift settings.';
lang_en['Content.Notification.10.Title'] = 'I am clocked in for longer than my shift';
lang_en['Content.Notification.11.Description'] = 'Sends a notification to you when an assigned employee elects to notify you they have completed an action like approving, archiving, or reconciling records.';
lang_en['Content.Notification.11.Title'] = 'A user completes an elective action';
lang_en['Content.Notification.2.Description'] = 'Sends a notification when a new hourly time alert has been created by a user that reports directly to you.';
lang_en['Content.Notification.2.Title'] = 'New hourly alerts are created';
lang_en['Content.Notification.3.Description'] = 'Sends a notification to you when a new project time alert has been created by a user that reports directly to you.';
lang_en['Content.Notification.3.Title'] = 'New project alerts are created';
lang_en['Content.Notification.4.Description'] = 'This feature allows you and your supervisor(s) the ability to send a link to clock out without having to login. Please note this feature may not work if certain security settings are in place.';
lang_en['Content.Notification.4.Title'] = 'I am clocked in to receive a quick clock out link';
lang_en['Content.Notification.5.Description'] = 'Sends a notification when any user that reports directly to you clocks in.';
lang_en['Content.Notification.5.Title'] = 'Users directly reporting to me clock in';
lang_en['Content.Notification.6.Description'] = 'Sends a notification when any user that reports directly to you clocks out and created a record which exceeds their work shift.';
lang_en['Content.Notification.6.Title'] = 'Users directly reporting to me exceed their work shift when clocking out';
lang_en['Content.Notification.7.Description'] = 'Sends a notification when you are automatically clocked out for exceeding your work shift. Only enabled when automatic clock out is enabled for the company account.';
lang_en['Content.Notification.7.Title'] = 'I am clocked out automatically for exceeding my work shift';
lang_en['Content.Notification.8.Description'] = 'Sends a notification when any user that reports directly to you is automatically clocked out for exceeding your work shift. Only enabled when automatic clock out is enabled for the company account.';
lang_en['Content.Notification.8.Title'] = 'Users directly reporting to me are clocked out automatically for exceeding their work shift';
lang_en['Content.Notification.9.Description'] = 'Sends a notification to you on the last day of the current payroll period. Only enabled if the company account has been configured to use payroll periods.';
lang_en['Content.Notification.9.Title'] = 'It is the last day of the current payroll period';
lang_en['Content.Pagination.CurrentDate'] = 'Current Date';
lang_en['Content.Pagination.DisplayingXToYOfZ'] = 'Displaying {1} {2} to {3} of {4}';
lang_en['Content.Pagination.LabelX-YOfZ'] = '{1} {2} - {3} of {4}';
lang_en['Content.Pagination.LastWeek'] = 'Last Week';
lang_en['Content.Pagination.Next'] = 'Next';
lang_en['Content.Pagination.NextDate'] = 'Next Date';
lang_en['Content.Pagination.NextWeek'] = 'Next Week';
lang_en['Content.Pagination.NextXY'] = 'Next {1} {2}';
lang_en['Content.Pagination.Page'] = 'Page';
lang_en['Content.Pagination.PageXofY'] = 'Page {1} of {2}';
lang_en['Content.Pagination.PerPage'] = 'Per Page';
lang_en['Content.Pagination.Previous'] = 'Previous';
lang_en['Content.Pagination.PreviousDate'] = 'Previous Date';
lang_en['Content.Pagination.PreviousXY'] = 'Previous {1} {2}';
lang_en['Content.Pagination.Show'] = 'Show';
lang_en['Content.Pagination.StartOfWeek'] = 'Start Of Week';
lang_en['Content.Pagination.XOfYRecordsProcessed'] = '{1} / {2} records processed';
lang_en['Content.Pagination.XY'] = '{1} {2}';
lang_en['Content.Report.Name.PayrollReport'] = 'Payroll Report';
lang_en['Content.Report.Name.ProcessedPayrollReportHours'] = 'Processed Payroll Hours Report';
lang_en['Content.ReportFileFormats.GroupType'] = 'Group Type';
lang_en['Content.ReportFileFormats.GroupType.AccountCode'] = 'Account Code';
lang_en['Content.ReportFileFormats.GroupType.Customer'] = 'Customer';
lang_en['Content.ReportFileFormats.GroupType.Employee'] = 'Employee';
lang_en['Content.ReportFileFormats.GroupType.Event'] = 'Event';
lang_en['Content.ReportFileFormats.GroupType.None'] = 'None';
lang_en['Content.ReportFileFormats.GroupType.Project'] = 'Project';
lang_en['Content.ReportFileFormats.GroupType.Vendor'] = 'Vendor';
lang_en['Content.ReportFileFormats.Instruction'] = 'The file format \'\'{1}\'\' is unavailable. To download a file in this format generate a report using following parameters:';
lang_en['Content.ReportFileFormats.ReportFormat'] = 'Format';
lang_en['Content.ReportFileFormats.ReportFormat.Detailed'] = 'Detailed';
lang_en['Content.ReportFileFormats.ReportFormat.Summary'] = 'Summary';
lang_en['Content.ReportFileFormats.Title.Unsupported'] = 'Unsupported';
lang_en['Content.Select'] = 'Select';
lang_en['Content.Select.AccountCode'] = 'Account Code';
lang_en['Content.Select.Administrators'] = 'Administrators';
lang_en['Content.Select.Customer'] = 'Customer';
lang_en['Content.Select.Date'] = 'Date';
lang_en['Content.Select.DirectReportsOnly'] = 'Direct Reports Only';
lang_en['Content.Select.DoNotLink'] = 'DO NOT LINK';
lang_en['Content.Select.Dollars'] = 'Dollars';
lang_en['Content.Select.Downloads'] = '-- Downloads --';
lang_en['Content.Select.Employee'] = 'Employee';
lang_en['Content.Select.Employees'] = 'Employees';
lang_en['Content.Select.Event'] = 'Event';
lang_en['Content.Select.Every'] = 'Every';
lang_en['Content.Select.Everyone'] = 'Everyone';
lang_en['Content.Select.JobTitle'] = 'Job Title';
lang_en['Content.Select.LocateUser'] = 'Locate User';
lang_en['Content.Select.Mileage'] = 'Mileage';
lang_en['Content.Select.None'] = 'None';
lang_en['Content.Select.One'] = 'One';
lang_en['Content.Select.Payroll.RunOnXForYToZ'] = 'Run On {1} For {2} To {3}';
lang_en['Content.Select.PrimaryAccountAdmin'] = 'Primary Account Admin';
lang_en['Content.Select.Project'] = 'Project';
lang_en['Content.Select.SelectOne'] = '-- Select One --';
lang_en['Content.Select.SelectUser'] = '-- Select User --';
lang_en['Content.Select.Supervisor'] = 'Supervisor';
lang_en['Content.Select.Supervisors'] = 'Supervisors';
lang_en['Content.Select.User'] = 'User';
lang_en['Content.Select.Vendor'] = 'Vendor';
lang_en['Content.Tabs'] = 'Project Time';
lang_en['Content.Tabs.AccountAlerts'] = 'Account Alerts';
lang_en['Content.Tabs.AccountCodes'] = 'Account Codes';
lang_en['Content.Tabs.AchievementDocs'] = 'Achievement Docs';
lang_en['Content.Tabs.Active'] = 'Active';
lang_en['Content.Tabs.AddMany'] = 'Add Many';
lang_en['Content.Tabs.AddOne'] = 'Add One';
lang_en['Content.Tabs.AddReferral'] = 'Add Referral';
lang_en['Content.Tabs.All'] = 'All';
lang_en['Content.Tabs.AllActive'] = 'All Active';
lang_en['Content.Tabs.AnnouncementsFromTimesheets'] = 'Announcements from Timesheets.com';
lang_en['Content.Tabs.AnnouncementsToEmployees'] = 'Announcements to Employees';
lang_en['Content.Tabs.AnnouncementsToUsers'] = 'Company Announcements';
lang_en['Content.Tabs.Archive'] = 'Archive';
lang_en['Content.Tabs.Archived'] = 'Archived';
lang_en['Content.Tabs.ArchivedUsers'] = 'Archived Users';
lang_en['Content.Tabs.AssignedEmployees'] = 'Assigned Users';
lang_en['Content.Tabs.Audits'] = 'Audits';
lang_en['Content.Tabs.Balances'] = 'Balances';
lang_en['Content.Tabs.Cancelled'] = 'Cancelled';
lang_en['Content.Tabs.ClosedPayrollPeriods'] = 'Closed Payroll Periods';
lang_en['Content.Tabs.ClosedPayrollPeriodsForUserX'] = 'Closed Payroll Periods For {1}';
lang_en['Content.Tabs.Commissions'] = 'Commissions';
lang_en['Content.Tabs.CompanyWallAll'] = 'All';
lang_en['Content.Tabs.CompanyWallStarredOnly'] = 'Starred Only';
lang_en['Content.Tabs.Customers'] = 'Customers';
lang_en['Content.Tabs.DataExport'] = 'Data Export';
lang_en['Content.Tabs.Expense'] = 'Expense';
lang_en['Content.Tabs.ExpenseEntry'] = 'Expense Entry';
lang_en['Content.Tabs.Expenses'] = 'Expenses';
lang_en['Content.Tabs.Expired'] = 'Expired';
lang_en['Content.Tabs.Features'] = 'Features';
lang_en['Content.Tabs.Files'] = 'Files';
lang_en['Content.Tabs.Finished'] = 'Finished';
lang_en['Content.Tabs.Forms'] = 'Forms';
lang_en['Content.Tabs.Free'] = 'Free';
lang_en['Content.Tabs.HRDocs'] = 'HR Docs';
lang_en['Content.Tabs.Hourly'] = 'Hourly';
lang_en['Content.Tabs.HourlyTime'] = 'Hourly Time';
lang_en['Content.Tabs.HourlyTimeEntriesEdits'] = 'Hourly Time Entries and Edits';
lang_en['Content.Tabs.HumanResources'] = 'Human Resources';
lang_en['Content.Tabs.InTrial'] = 'In Trial';
lang_en['Content.Tabs.InactiveUsers'] = 'Inactive Users';
lang_en['Content.Tabs.Info'] = 'Info';
lang_en['Content.Tabs.InvitedX'] = 'Invited ({1})';
lang_en['Content.Tabs.Lists'] = 'Lists';
lang_en['Content.Tabs.Management'] = 'Management';
lang_en['Content.Tabs.Memos'] = 'Memos';
lang_en['Content.Tabs.MessageBoard'] = 'Message Board';
lang_en['Content.Tabs.NewExport'] = 'New Export';
lang_en['Content.Tabs.Notes'] = 'Notes';
lang_en['Content.Tabs.Notifications'] = 'Notifications';
lang_en['Content.Tabs.OpenExpenses'] = 'Open Expenses';
lang_en['Content.Tabs.Options'] = 'Options';
lang_en['Content.Tabs.PayStubs'] = 'Pay Stubs';
lang_en['Content.Tabs.Payroll'] = 'Payroll';
lang_en['Content.Tabs.Pending'] = 'Pending';
lang_en['Content.Tabs.PerformanceDocuments'] = 'Performance Documents';
lang_en['Content.Tabs.PreviousExports'] = 'Previous Exports';
lang_en['Content.Tabs.Private'] = 'Private';
lang_en['Content.Tabs.Profile'] = 'Profile';
lang_en['Content.Tabs.Project'] = 'Project';
lang_en['Content.Tabs.ProjectTime'] = 'Project Time';
lang_en['Content.Tabs.ProjectTimeEntriesEdits'] = 'Project Time Entries and Edits';
lang_en['Content.Tabs.Projects'] = 'Projects';
lang_en['Content.Tabs.Public'] = 'Public';
lang_en['Content.Tabs.Reconciled'] = 'Reconciled';
lang_en['Content.Tabs.Security'] = 'Security';
lang_en['Content.Tabs.Settings'] = 'Settings';
lang_en['Content.Tabs.SharedByMe'] = 'Shared By Me';
lang_en['Content.Tabs.Subscribed'] = 'Subscribed';
lang_en['Content.Tabs.SupervisorSettings'] = 'Supervisor Settings';
lang_en['Content.Tabs.TimeAndExpense'] = 'Time & Expense';
lang_en['Content.Tabs.TimeEntry'] = 'Time Entry';
lang_en['Content.Tabs.TimeOff'] = 'Time Off';
lang_en['Content.Tabs.TimeOffEntriesRequests'] = 'Time-Off Entries and Requests';
lang_en['Content.Tabs.Timers'] = 'Timers';
lang_en['Content.Tabs.Timesheet'] = 'Timesheet';
lang_en['Content.Tabs.TrackReferrals'] = 'Track Referrals';
lang_en['Content.Tabs.WeekView'] = 'Week View';
lang_en['Content.TimeClass.DoubleTime'] = 'Double Time';
lang_en['Content.TimeClass.DoubleTimeAbbrev'] = 'DT';
lang_en['Content.TimeClass.Holiday'] = 'Holiday';
lang_en['Content.TimeClass.HolidayAbbrev'] = 'HOL';
lang_en['Content.TimeClass.Other1'] = 'Other1';
lang_en['Content.TimeClass.Other1Abbrev'] = 'OR1';
lang_en['Content.TimeClass.Other2'] = 'Other2';
lang_en['Content.TimeClass.Other2Abbrev'] = 'OR2';
lang_en['Content.TimeClass.Overtime'] = 'Overtime';
lang_en['Content.TimeClass.OvertimeAbbrev'] = 'OT';
lang_en['Content.TimeClass.PTO'] = 'PTO';
lang_en['Content.TimeClass.PTOAbbrev'] = 'PTO';
lang_en['Content.TimeClass.Salaried'] = 'Unpayable/Salaried';
lang_en['Content.TimeClass.SalariedAbbrev'] = 'UnP/Sal';
lang_en['Content.TimeClass.Sick'] = 'Sick';
lang_en['Content.TimeClass.SickAbbrev'] = 'SIC';
lang_en['Content.TimeClass.Standard'] = 'Standard';
lang_en['Content.TimeClass.StandardAbbrev'] = 'STD';
lang_en['Content.TimeClass.Unclassified'] = 'Unclassified';
lang_en['Content.TimeClass.Vacation'] = 'Vacation';
lang_en['Content.TimeClass.VacationAbbrev'] = 'VAC';
lang_en['Content.UIControls.DisplaySupervisors'] = 'Display Supervisors';
lang_en['Content.UIControls.DisplaySupervisors.All'] = 'All';
lang_en['Content.UIControls.DisplaySupervisors.Once'] = 'Once';
lang_en['Content.UIControls.GuidedTours'] = 'Guided Tours Launcher';
lang_en['Content.UIControls.GuidedTours.Hide'] = 'Hide';
lang_en['Content.UIControls.GuidedTours.Show'] = 'Show';
lang_en['Content.UIControls.ItemOrdering'] = 'Item Ordering';
lang_en['Content.UIControls.ItemOrdering.Alphabetical'] = 'Alphabetical';
lang_en['Content.UIControls.ItemOrdering.MostRecent'] = 'Most Recent';
lang_en['Content.UIControls.ItemSelection'] = 'Item Selection';
lang_en['Content.UIControls.ItemSelection.Checkboxes'] = 'Checkboxes';
lang_en['Content.UIControls.ItemSelection.Lists'] = 'Lists';
lang_en['Content.UIControls.ScreenMode'] = 'Dark Mode';
lang_en['Content.UIControls.SideMenu'] = 'Side Menu';
lang_en['Content.UIControls.SideMenu.Automatic'] = 'Automatic';
lang_en['Content.UIControls.SideMenu.Closed'] = 'Closed';
lang_en['Content.UIControls.SideMenu.Open'] = 'Open';
lang_en['Content.UIControls.SwitchToMobileView'] = 'Switch To Mobile View';
lang_en['Content.UIControls.TableSize'] = 'Table Size';
lang_en['Content.UIControls.TableSize.Compact'] = 'Compact';
lang_en['Content.UIControls.TableSize.Medium'] = 'Medium';
lang_en['Content.UIControls.TableSize.Regular'] = 'Regular';
lang_en['Content.UIControls.TableSorting'] = 'Table Sorting';
lang_en['Content.UIControls.TableSorting.Grouped'] = 'Grouped';
lang_en['Content.UIControls.TableSorting.Individual'] = 'Individual';
lang_en['Content.UIControls.UserSelection'] = 'User Selection';
lang_en['Content.UIControls.UserSelection.Alphabetical'] = 'Alphabetical';
lang_en['Content.UIControls.UserSelection.Supervisor'] = 'By Supervisor';
lang_en['HelpLinks.Affiliate.Click-Throughs'] = 'https://support2.timesheets.com/knowledge-base/finding-and-using-the-tracking-code/';
lang_en['HelpLinks.Affiliate.Dashboard'] = 'https://support2.timesheets.com/knowledge-base/understanding-the-partner-dashboard/';
lang_en['HelpLinks.Affiliate.ReferralsSingle'] = 'https://support2.timesheets.com/knowledge-base/referring-clients/';
lang_en['HelpLinks.Guides.AddingUsers'] = 'NOT_USED';
lang_en['HelpLinks.Guides.ApprovingTimesheets'] = 'NOT_USED';
lang_en['HelpLinks.Guides.ClosingPayrollPeriodReport'] = 'NOT_USED';
lang_en['HelpLinks.Guides.CreatingLists'] = 'NOT_USED';
lang_en['HelpLinks.Guides.DisablingArchivingUsers'] = 'NOT_USED';
lang_en['HelpLinks.Guides.ExpensesSheet'] = 'NOT_USED';
lang_en['HelpLinks.Guides.HourlyClockSecurity'] = 'NOT_USED';
lang_en['HelpLinks.Guides.HourlyTimesheet'] = 'NOT_USED';
lang_en['HelpLinks.Guides.ProjectTimesheet'] = 'NOT_USED';
lang_en['HelpLinks.Guides.RunningReports'] = 'NOT_USED';
lang_en['HelpLinks.Guides.Schedule'] = 'NOT_USED';
lang_en['HelpLinks.Guides.SettingUpTimeOffAndAccruals'] = 'NOT_USED';
lang_en['HelpLinks.Guides.TourTheDashboard'] = 'NOT_USED';
lang_en['HelpLinks.Sys.AccountCodeAssignment'] = 'https://support2.timesheets.com/knowledge-base/account-codes-tab/';
lang_en['HelpLinks.Sys.AlertsList'] = 'https://support2.timesheets.com/knowledge-base/hourly-time-alerts/';
lang_en['HelpLinks.Sys.AnnouncementsCreate'] = 'https://support2.timesheets.com/knowledge-base/memos-and-announcements/#announcements';
lang_en['HelpLinks.Sys.ArchiveUser'] = 'https://support2.timesheets.com/knowledge-base/disable-deactivate-terminate-and-reactivate-users/';
lang_en['HelpLinks.Sys.ArchiveUsers'] = 'https://support2.timesheets.com/knowledge-base/disable-deactivate-terminate-and-reactivate-users/';
lang_en['HelpLinks.Sys.AssignedEmployees'] = 'https://support2.timesheets.com/knowledge-base/assigned-employees-tab/';
lang_en['HelpLinks.Sys.BulkAddAccountCodes'] = 'https://support2.timesheets.com/knowledge-base/setting-up-account-codes/#account-code-import';
lang_en['HelpLinks.Sys.BulkAddCustomers'] = 'https://support2.timesheets.com/knowledge-base/setting-up-customers/#customer-import';
lang_en['HelpLinks.Sys.BulkAddEvents'] = 'https://support2.timesheets.com/knowledge-base/setting-up-events/#event-import';
lang_en['HelpLinks.Sys.BulkAddProjects'] = 'https://support2.timesheets.com/knowledge-base/setting-up-projects/#project-import';
lang_en['HelpLinks.Sys.BulkAddVendors'] = 'https://support2.timesheets.com/knowledge-base/setting-up-vendors/#vendor-import';
lang_en['HelpLinks.Sys.Calendars'] = 'https://support2.timesheets.com/knowledge-base/creating-and-editing-calendars/';
lang_en['HelpLinks.Sys.CompanyDirectory'] = 'https://support2.timesheets.com/knowledge-base/features-tab/#directory';
lang_en['HelpLinks.Sys.CustomerAssignment'] = 'https://support2.timesheets.com/knowledge-base/customers-tab/';
lang_en['HelpLinks.Sys.DashboardCompanyOverview'] = 'https://support2.timesheets.com/knowledge-base/understanding-the-dashboard/';
lang_en['HelpLinks.Sys.DataExport.QBO'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-online-setup-instructions/';
lang_en['HelpLinks.Sys.DisplaySettings'] = 'https://support2.timesheets.com/knowledge-base/the-user-icon-submenu/';
lang_en['HelpLinks.Sys.DownloadHistory'] = 'TBD';
lang_en['HelpLinks.Sys.EditAccountCode'] = 'https://support2.timesheets.com/knowledge-base/setting-up-account-codes/';
lang_en['HelpLinks.Sys.EditCustomer'] = 'https://support2.timesheets.com/knowledge-base/setting-up-customers/';
lang_en['HelpLinks.Sys.EditEvent'] = 'https://support2.timesheets.com/knowledge-base/setting-up-events/';
lang_en['HelpLinks.Sys.EditProject'] = 'https://support2.timesheets.com/knowledge-base/setting-up-projects/';
lang_en['HelpLinks.Sys.EditVendor'] = 'https://support2.timesheets.com/knowledge-base/setting-up-vendors/';
lang_en['HelpLinks.Sys.EventAssignment'] = 'https://support2.timesheets.com/knowledge-base/setting-up-events/';
lang_en['HelpLinks.Sys.EventLog'] = 'https://support2.timesheets.com/knowledge-base/audit-trails-the-profile-history-the-event-log-and-the-accrual-history/';
lang_en['HelpLinks.Sys.ExpenseExportToQBO'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-online-import-instructions/';
lang_en['HelpLinks.Sys.ExpenseReconcile'] = 'https://support2.timesheets.com/knowledge-base/reconciling-expenses/';
lang_en['HelpLinks.Sys.ExpensesSheet'] = 'https://support2.timesheets.com/knowledge-base/introduction-to-the-expense-sheet/';
lang_en['HelpLinks.Sys.GenerateAPIKeys'] = 'https://support2.timesheets.com/knowledge-base/getting-started/';
lang_en['HelpLinks.Sys.HRPerformanceDocs'] = 'https://support2.timesheets.com/knowledge-base/performance-documents/';
lang_en['HelpLinks.Sys.Hint.CustomerProjectSelectors'] = 'https://support2.timesheets.com/knowledge-base/project-time-entry-methods/#using-lists-with-project-time';
lang_en['HelpLinks.Sys.Hint.DefaultListItem'] = 'https://support2.timesheets.com/knowledge-base/introduction-to-lists/#access-restrictions';
lang_en['HelpLinks.Sys.Hint.EmployeeInvalidLocation'] = 'https://support2.timesheets.com/knowledge-base/invalid-location-warning/';
lang_en['HelpLinks.Sys.Hint.ExpenseReceiptUpload'] = 'https://support2.timesheets.com/knowledge-base/entering-mileage-and-expenses/';
lang_en['HelpLinks.Sys.Hint.HourlyApprovalHelp'] = 'https://support2.timesheets.com/knowledge-base/hourly-time-entry-methods/#time-entry-form';
lang_en['HelpLinks.Sys.Hint.ProjectApprovalHelp'] = 'https://support2.timesheets.com/knowledge-base/project-time-entry-methods/#time-entry-form';
lang_en['HelpLinks.Sys.Hint.ProjectRestrictedFields'] = 'https://support2.timesheets.com/knowledge-base/bill-and-cost-rates/#restricted-fields';
lang_en['HelpLinks.Sys.Hint.RestrictedProjectTimeColumns'] = 'https://support2.timesheets.com/knowledge-base/bill-and-cost-rates/#restricted-fields';
lang_en['HelpLinks.Sys.Hint.TeamWorkStatus'] = 'https://support2.timesheets.com/knowledge-base/section-6-schedule-sharing/#teams-work-status';
lang_en['HelpLinks.Sys.Hint.UserInvalidLocation'] = 'https://support2.timesheets.com/knowledge-base/invalid-location-warning/';
lang_en['HelpLinks.Sys.HourlyPayrollTimeExportToQBO'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-online-import-instructions/';
lang_en['HelpLinks.Sys.HourlyTimesheet'] = 'https://support2.timesheets.com/knowledge-base/introduction-to-the-hourly-timesheet/';
lang_en['HelpLinks.Sys.ImportUsers'] = 'https://support2.timesheets.com/knowledge-base/adding-new-users/#import';
lang_en['HelpLinks.Sys.LinkToAProPartner'] = 'https://support2.timesheets.com/knowledge-base/accessing-referred-client-accounts/';
lang_en['HelpLinks.Sys.MergeAccountCodes'] = 'https://support2.timesheets.com/knowledge-base/merging-archiving-and-deleting-list-items/';
lang_en['HelpLinks.Sys.MergeCustomers'] = 'https://support2.timesheets.com/knowledge-base/merging-archiving-and-deleting-list-items/';
lang_en['HelpLinks.Sys.MergeEvents'] = 'https://support2.timesheets.com/knowledge-base/merging-archiving-and-deleting-list-items/';
lang_en['HelpLinks.Sys.MergeProjects'] = 'https://support2.timesheets.com/knowledge-base/merging-archiving-and-deleting-list-items/';
lang_en['HelpLinks.Sys.MergeVendors'] = 'https://support2.timesheets.com/knowledge-base/merging-archiving-and-deleting-list-items/';
lang_en['HelpLinks.Sys.MessageCenter'] = 'https://support2.timesheets.com/knowledge-base/message-center/';
lang_en['HelpLinks.Sys.MyAccount.MyAccountSettings'] = 'https://support2.timesheets.com/knowledge-base/my-account-settings/';
lang_en['HelpLinks.Sys.PayrollFormats.ADP.Payforce'] = 'https://support2.timesheets.com/knowledge-base/adp-pay-force/';
lang_en['HelpLinks.Sys.PayrollFormats.ADP.Run'] = 'https://support2.timesheets.com/knowledge-base/adp-run/';
lang_en['HelpLinks.Sys.PayrollFormats.Ceridian'] = 'https://support2.timesheets.com/knowledge-base/data-export-tab/';
lang_en['HelpLinks.Sys.PayrollFormats.Heartland'] = 'https://support2.timesheets.com/knowledge-base/data-export-tab/';
lang_en['HelpLinks.Sys.PayrollFormats.PaychexPreview'] = 'https://support2.timesheets.com/knowledge-base/data-export-tab/';
lang_en['HelpLinks.Sys.PayrollFormats.PayrollCS'] = 'https://support2.timesheets.com/knowledge-base/data-export-tab/';
lang_en['HelpLinks.Sys.PayrollFormats.PayrollRelief'] = 'https://support2.timesheets.com/knowledge-base/data-export-tab/';
lang_en['HelpLinks.Sys.PayrollFormats.Polaris'] = 'https://support2.timesheets.com/knowledge-base/data-export-tab/';
lang_en['HelpLinks.Sys.PayrollPeriodReports'] = 'https://support2.timesheets.com/knowledge-base/pay-period-reports/';
lang_en['HelpLinks.Sys.PendingAlertsList'] = 'https://support2.timesheets.com/knowledge-base/time-off-requests/';
lang_en['HelpLinks.Sys.PreviouslyGeneratedReports'] = 'https://support2.timesheets.com/knowledge-base/previously-generated-reports/';
lang_en['HelpLinks.Sys.PrintPDFOptions'] = 'https://support2.timesheets.com/knowledge-base/printing_and_exporting_reports/';
lang_en['HelpLinks.Sys.PrintingReports'] = 'https://support2.timesheets.com/knowledge-base/printing_and_exporting_reports/';
lang_en['HelpLinks.Sys.ProjectAssignment'] = 'https://support2.timesheets.com/knowledge-base/projects-tab/';
lang_en['HelpLinks.Sys.ProjectTimeApprove'] = 'https://support2.timesheets.com/knowledge-base/approving-project-time/';
lang_en['HelpLinks.Sys.ProjectTimeArchive'] = 'https://support2.timesheets.com/knowledge-base/archiving-project-time/';
lang_en['HelpLinks.Sys.ProjectTimeBillableUpdate'] = 'https://support2.timesheets.com/knowledge-base/billable-unbillable-project-time-converter/';
lang_en['HelpLinks.Sys.ProjectTimeExportToQBO'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-online-import-instructions/';
lang_en['HelpLinks.Sys.ProjectTimesheet'] = 'https://support2.timesheets.com/knowledge-base/introduction-to-the-project-timesheet/';
lang_en['HelpLinks.Sys.QBDSetupTutorialDesktopApp'] = 'https://support2.timesheets.com/article-categories/quickbooks-desktop/';
lang_en['HelpLinks.Sys.QBOSetupTutorialIIF'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-desktop-iif-export/';
lang_en['HelpLinks.Sys.ReportApproveHourlyTime'] = 'https://support2.timesheets.com/knowledge-base/approving-hourly-time-for-payroll/';
lang_en['HelpLinks.Sys.ReportArchiveHourlyTime'] = 'https://support2.timesheets.com/knowledge-base/archiving-hourly-time/';
lang_en['HelpLinks.Sys.ReportClosePayrollPeriod'] = 'https://support2.timesheets.com/knowledge-base/closing-a-payroll-period/';
lang_en['HelpLinks.Sys.ReportExpenses'] = 'https://support2.timesheets.com/knowledge-base/customizable-expense-report/';
lang_en['HelpLinks.Sys.ReportHourlyPostPayroll'] = 'https://support2.timesheets.com/knowledge-base/customizable-processed-timesheet-report/';
lang_en['HelpLinks.Sys.ReportHourlyPrePayroll'] = 'https://support2.timesheets.com/knowledge-base/customizable-timesheet-report/';
lang_en['HelpLinks.Sys.ReportHourlyRecordHistory'] = 'https://support2.timesheets.com/knowledge-base/hourly-record-history-report/';
lang_en['HelpLinks.Sys.ReportLocationAndTardy'] = 'https://support2.timesheets.com/knowledge-base/location-and-tardy-reports/';
lang_en['HelpLinks.Sys.ReportOverlappingRecords'] = 'https://support2.timesheets.com/knowledge-base/overlapping-records/';
lang_en['HelpLinks.Sys.ReportPreliminaryPayroll'] = 'https://support2.timesheets.com/knowledge-base/preview-current-period/';
lang_en['HelpLinks.Sys.ReportProjectTime'] = 'https://support2.timesheets.com/knowledge-base/customizable-project-timesheet-report/';
lang_en['HelpLinks.Sys.ReportsAndExports'] = 'https://support2.timesheets.com/article-categories/reports/';
lang_en['HelpLinks.Sys.ResetPasswordsAndWelcomeEmails'] = 'https://support2.timesheets.com/knowledge-base/re-send-welcome-emails/';
lang_en['HelpLinks.Sys.SecurityGeolocation'] = 'https://support2.timesheets.com/knowledge-base/geolocation-security-settings/';
lang_en['HelpLinks.Sys.SecurityIPAddressList'] = 'https://support2.timesheets.com/knowledge-base/ip-address-lock-down-list/';
lang_en['HelpLinks.Sys.SecurityMessageBoard'] = 'https://support2.timesheets.com/knowledge-base/message-board-security/';
lang_en['HelpLinks.Sys.SecurityPhotoTimestamp'] = 'https://support2.timesheets.com/knowledge-base/photo-security-settings/';
lang_en['HelpLinks.Sys.SecuritySettings'] = 'https://support2.timesheets.com/article-categories/security-tab/';
lang_en['HelpLinks.Sys.SecurityTimeEntryCutoff'] = 'https://support2.timesheets.com/knowledge-base/time-entry-cutoff/';
lang_en['HelpLinks.Sys.TimeOffAndAccruals'] = 'https://support2.timesheets.com/knowledge-base/introduction-to-time-off/';
lang_en['HelpLinks.Sys.Tools.CompTimeConverter'] = 'https://support2.timesheets.com/knowledge-base/comp-time-converter/';
lang_en['HelpLinks.Sys.UpdateBrandLogo'] = 'https://support2.timesheets.com/knowledge-base/setting-up-your-private-brand/';
lang_en['HelpLinks.Sys.UserAccrualImport'] = 'https://support2.timesheets.com/knowledge-base/import-export-accrual-balances/';
lang_en['HelpLinks.Sys.UserAddNew'] = 'https://support2.timesheets.com/knowledge-base/adding-new-users/';
lang_en['HelpLinks.Sys.UserGlobalProfileUpdate'] = 'https://support2.timesheets.com/knowledge-base/update-multiple-user-profiles/';
lang_en['HelpLinks.Sys.UserProfileHistory'] = 'https://support2.timesheets.com/knowledge-base/audit-trails-the-profile-history-the-event-log-and-the-accrual-history/';
lang_en['HelpLinks.Sys.VendorAssignment'] = 'https://support2.timesheets.com/knowledge-base/setting-up-vendors/';
lang_en['HelpLinks.Sys.ViewAccountCodes'] = 'https://support2.timesheets.com/knowledge-base/setting-up-account-codes/';
lang_en['HelpLinks.Sys.ViewCustomers'] = 'https://support2.timesheets.com/knowledge-base/setting-up-customers/';
lang_en['HelpLinks.Sys.ViewEvents'] = 'https://support2.timesheets.com/knowledge-base/setting-up-events/';
lang_en['HelpLinks.Sys.ViewProjects'] = 'https://support2.timesheets.com/knowledge-base/setting-up-projects/';
lang_en['HelpLinks.Sys.ViewVendors'] = 'https://support2.timesheets.com/knowledge-base/setting-up-vendors/';
lang_en['PageTitles.Admin.AdvertisingSummary'] = 'Advertising Summary';
lang_en['PageTitles.Admin.ApplicationSummary'] = 'Application Summary';
lang_en['PageTitles.Admin.BrandAdministration'] = 'Brand Administration';
lang_en['PageTitles.Admin.CommissionPayments'] = 'Commission Payments';
lang_en['PageTitles.Admin.DatabaseIntegrityTests'] = 'Database Integrity Tests';
lang_en['PageTitles.Admin.DatabaseStatistics'] = 'Database Statistics';
lang_en['PageTitles.Admin.InvalidSiteAdminLogins'] = 'INVALID SITE ADMINISTRATOR LOGINS';
lang_en['PageTitles.Admin.LicenseStats'] = 'License Statistics';
lang_en['PageTitles.Admin.LoginHomepage'] = 'Site Administrator Login';
lang_en['PageTitles.Admin.LoginMessage'] = 'Login System Message';
lang_en['PageTitles.Admin.Maintain-AdminPermissions'] = 'Maintain Administrator Permissions';
lang_en['PageTitles.Admin.Maintain-ArchivedUsersUnpaidRecords'] = 'Archived Users With Unpaid Records';
lang_en['PageTitles.Admin.Maintain-DeactivatedUsersUnpaidRecords'] = 'Deactivated Users With Unpaid Records';
lang_en['PageTitles.Admin.Maintain-DuplicatePayroll'] = 'Duplicate Payroll Maintenance';
lang_en['PageTitles.Admin.Maintain-InvalidLoginAttempts'] = 'Currently Invalid Login Attempts';
lang_en['PageTitles.Admin.Maintain-SupervisorPermissions'] = 'Maintain Supervisor Permissions';
lang_en['PageTitles.Admin.Maintain-UserDisplayPreference'] = 'Individual User Display Preference Maintenance';
lang_en['PageTitles.Admin.ManageAccountAlerts'] = 'Manage Account Alerts';
lang_en['PageTitles.Admin.ManageAnnouncements'] = 'Manage Announcements';
lang_en['PageTitles.Admin.ManageSiteAdminAccounts'] = 'Site Administrator Accounts';
lang_en['PageTitles.Admin.PhotoRecordSets'] = 'Validate Hourly Photo Record Set';
lang_en['PageTitles.Admin.PricingStructure'] = 'Pricing Structure';
lang_en['PageTitles.Admin.PromoCodes'] = 'Promo Codes';
lang_en['PageTitles.Admin.ReportAccountCreations'] = 'Account Creation Report';
lang_en['PageTitles.Admin.ReportAccountNotes'] = 'Account Notes Report';
lang_en['PageTitles.Admin.ReportAdministrativeTask'] = 'Administrative Task Report';
lang_en['PageTitles.Admin.ReportAlerts'] = 'Alerts Report';
lang_en['PageTitles.Admin.ReportCancellations'] = 'Cancellations Report';
lang_en['PageTitles.Admin.ReportCustomQuery'] = 'Custom Report Query';
lang_en['PageTitles.Admin.ReportDailyBilling'] = 'Daily Billing Summary Report';
lang_en['PageTitles.Admin.ReportLastPayroll'] = 'Last Payroll Date Report';
lang_en['PageTitles.Admin.ReportPageviews'] = 'Pageviews Report';
lang_en['PageTitles.Admin.ReportPromoCodeUsage'] = 'Promo Code Usage Report';
lang_en['PageTitles.Admin.ReportSystemLogMessages'] = 'System Log Message Report';
lang_en['PageTitles.Admin.ReportTotalUserHours'] = 'User Hours Report';
lang_en['PageTitles.Admin.ServerSessionStatus'] = 'Server Session Status';
lang_en['PageTitles.Admin.Snapshot'] = 'Snapshot';
lang_en['PageTitles.Admin.StatusReportEmailTest'] = 'Status Report Email Test';
lang_en['PageTitles.Admin.UserAccountNotification'] = 'Insert User Account Notification';
lang_en['PageTitles.Admin.UserAccountSettings'] = 'User Account Settings';
lang_en['PageTitles.Admin.ViewAccounts'] = 'View Accounts';
lang_en['PageTitles.Admin.ViewAccruals'] = 'View Accruals';
lang_en['PageTitles.Admin.ViewAffiliates'] = 'View Affiliates';
lang_en['PageTitles.Admin.ViewUserLoginHistory'] = 'View User Login History';
lang_en['PageTitles.Admin.ViewUserRequestHistory'] = 'View User Request History';
lang_en['PageTitles.Admin.ViewUsers'] = 'View Users';
lang_en['PageTitles.Affiliate.AccountProfile'] = 'My Profile';
lang_en['PageTitles.Affiliate.AccountRegistration'] = 'Register for our ProPartner Program';
lang_en['PageTitles.Affiliate.BannerAds'] = 'Tracking Code';
lang_en['PageTitles.Affiliate.Click-Throughs'] = 'Click-throughs';
lang_en['PageTitles.Affiliate.Commissions'] = 'Commissions';
lang_en['PageTitles.Affiliate.Contact'] = 'Contact Us';
lang_en['PageTitles.Affiliate.Dashboard'] = 'Dashboard';
lang_en['PageTitles.Affiliate.Help'] = 'Help';
lang_en['PageTitles.Affiliate.MyAccount'] = 'My Account';
lang_en['PageTitles.Affiliate.MyProfile'] = 'My Profile';
lang_en['PageTitles.Affiliate.ProPartnerProgram'] = 'ProPartner Program';
lang_en['PageTitles.Affiliate.ReferralData'] = 'Referral Data';
lang_en['PageTitles.Affiliate.ReferralsMultiple'] = 'Send Multiple Referrals';
lang_en['PageTitles.Affiliate.ReferralsSingle'] = 'Add a Client';
lang_en['PageTitles.Affiliate.SandboxAccount'] = 'Create A Sandbox Account';
lang_en['PageTitles.Affiliate.TrackingCode'] = 'Tracking Code';
lang_en['PageTitles.Affiliate.Training'] = 'Training';
lang_en['PageTitles.Common.DataProcessingAgreement'] = 'Data Processing Agreement';
lang_en['PageTitles.Common.PrivacyStatement'] = 'Privacy Statement';
lang_en['PageTitles.Common.ResetAccountActivation'] = 'Reset Account Activation';
lang_en['PageTitles.Common.TermsOfUse'] = 'Terms Of Use';
lang_en['PageTitles.Sys.AccountCodeEdit'] = 'Edit Account Code';
lang_en['PageTitles.Sys.AccountCodeList'] = 'Account Code List';
lang_en['PageTitles.Sys.AccountContactInfoUpdate'] = 'Contact Info Confirmation';
lang_en['PageTitles.Sys.AccountCreated'] = 'Account Created!';
lang_en['PageTitles.Sys.AccountDetails'] = 'Account Details';
lang_en['PageTitles.Sys.AccountDetailsAndPaymentHistory'] = 'Account Details and Payment History';
lang_en['PageTitles.Sys.AccountPasswordUpdate'] = 'Change Your Password';
lang_en['PageTitles.Sys.AccountSetupWizard'] = 'Company Settings';
lang_en['PageTitles.Sys.AccountSummary'] = 'Account Summary';
lang_en['PageTitles.Sys.ActivateAccountSubscription'] = 'Activate Account Subscription';
lang_en['PageTitles.Sys.AlertsList'] = 'Alerts Requiring Your Attention';
lang_en['PageTitles.Sys.AnnouncementsCreate'] = 'Create A Company Announcement';
lang_en['PageTitles.Sys.AnnouncementsRecent'] = 'Recent Announcements';
lang_en['PageTitles.Sys.ArchiveUser'] = 'Archive User';
lang_en['PageTitles.Sys.ArchiveUsers'] = 'Archive Users';
lang_en['PageTitles.Sys.BillingReport'] = 'Billing Report';
lang_en['PageTitles.Sys.CalendarsAndSchedules'] = 'Calendars & Schedules';
lang_en['PageTitles.Sys.CancellingService'] = 'Cancelling Service';
lang_en['PageTitles.Sys.ClockInOut'] = 'Clock In/Out';
lang_en['PageTitles.Sys.ClockStatus'] = 'Clock Status';
lang_en['PageTitles.Sys.ClockUserIn'] = 'Clock User In';
lang_en['PageTitles.Sys.ClockUserOut'] = 'Clock User Out';
lang_en['PageTitles.Sys.ClockedIn'] = 'Clocked In';
lang_en['PageTitles.Sys.ClockedOut'] = 'Clocked Out';
lang_en['PageTitles.Sys.CompTimeConverter'] = 'Comp Time Converter';
lang_en['PageTitles.Sys.CompanyDirectory'] = 'Company Directory';
lang_en['PageTitles.Sys.CompanySettings'] = 'Company Settings';
lang_en['PageTitles.Sys.CompanyTimeZoneSettings'] = 'Company Time Zone Settings';
lang_en['PageTitles.Sys.CreateTrialAccount'] = 'Create A Trial Account';
lang_en['PageTitles.Sys.CustomerEdit'] = 'Edit Customer';
lang_en['PageTitles.Sys.CustomerList'] = 'Customer List';
lang_en['PageTitles.Sys.CustomerService'] = 'Customer Service Form';
lang_en['PageTitles.Sys.Dashboard'] = 'Dashboard';
lang_en['PageTitles.Sys.DeactivateUsers'] = 'Deactivate Users';
lang_en['PageTitles.Sys.DownloadHistory'] = 'Download History';
lang_en['PageTitles.Sys.ErrorOops'] = 'Oops...';
lang_en['PageTitles.Sys.EventEdit'] = 'Edit Event';
lang_en['PageTitles.Sys.EventList'] = 'Event List';
lang_en['PageTitles.Sys.EventLog'] = 'Event Log';
lang_en['PageTitles.Sys.ExpenseExportToQBO'] = 'Export Expenses To QuickBooks Online';
lang_en['PageTitles.Sys.ExpenseReceipt'] = 'Expense Receipt';
lang_en['PageTitles.Sys.ExpenseReconcile'] = 'Reconcile Expenses';
lang_en['PageTitles.Sys.ExternalServices'] = 'External Services';
lang_en['PageTitles.Sys.FreeTrialEnd'] = 'Your Free Trial Period Has Ended';
lang_en['PageTitles.Sys.GenerateAPIKeys'] = 'Generate API Keys';
lang_en['PageTitles.Sys.HourlyPayrollTimeExportToQBO'] = 'Export Hourly Time To QuickBooks Online';
lang_en['PageTitles.Sys.HourlyPrintableTimesheets'] = 'Hourly Printable Timesheets';
lang_en['PageTitles.Sys.HourlyRecordTimeZoneUpdate'] = 'Update Time Zone Of Hourly Records';
lang_en['PageTitles.Sys.HowToManual'] = 'How To Manual';
lang_en['PageTitles.Sys.HumanResourcePerformanceDocuments'] = 'Human Resource Performance Documents';
lang_en['PageTitles.Sys.ImportAccountCodePayRates'] = 'Import Account Code Cost Rates';
lang_en['PageTitles.Sys.ImportProjectBillRates'] = 'Import Project Bill Rates';
lang_en['PageTitles.Sys.ImportUsers'] = 'Import Users';
lang_en['PageTitles.Sys.LegalAdvice'] = 'Ask A Legal Expert';
lang_en['PageTitles.Sys.LinkToAProPartner'] = 'Link To A ProPartner';
lang_en['PageTitles.Sys.LoggedOut'] = 'Logged out';
lang_en['PageTitles.Sys.MergeItems'] = 'Merge All Records From This Item Into Another Item';
lang_en['PageTitles.Sys.MessageBoard'] = 'The Water Cooler';
lang_en['PageTitles.Sys.MessageCenter'] = 'Message Center';
lang_en['PageTitles.Sys.MyAccount'] = 'My Account';
lang_en['PageTitles.Sys.MyAccount.AccountOptionsAndPaymentDetails'] = 'Account Options and Payment Details';
lang_en['PageTitles.Sys.MyAccount.AddOrRemoveLicenses'] = 'Add or Remove Licenses or Premium Services';
lang_en['PageTitles.Sys.MyAccount.AddOrRemoveLicensesSub'] = 'Right-size your account and manage account upgrades.';
lang_en['PageTitles.Sys.MyAccount.CancelMyAccount'] = 'Cancel My Account';
lang_en['PageTitles.Sys.MyAccount.CancelMyAccountSub'] = 'Cancel your free user account.';
lang_en['PageTitles.Sys.MyAccount.CancelYourAccount'] = 'Cancel Your Account';
lang_en['PageTitles.Sys.MyAccount.CancelYourAccountSub'] = 'Cancel your service at the end of current billing period.';
lang_en['PageTitles.Sys.MyAccount.ChangeMyPassword'] = 'Change My Password';
lang_en['PageTitles.Sys.MyAccount.ChangeMyPasswordSub'] = 'Change your login password.';
lang_en['PageTitles.Sys.MyAccount.MyAccountSettings'] = 'My Account Settings';
lang_en['PageTitles.Sys.MyAccount.MyAccountSettingsSub'] = 'Modify settings specific to your account here.';
lang_en['PageTitles.Sys.MyAccount.PaymentHistoryDetails'] = 'Payment History Details';
lang_en['PageTitles.Sys.MyAccount.PaymentHistoryDetailsSub'] = 'View your current subscription status and payment history.';
lang_en['PageTitles.Sys.MyAccount.ReferAFriend'] = 'Refer A Friend';
lang_en['PageTitles.Sys.MyAccount.ReferAFriendSub'] = 'Receive a gift card for every referral that becomes a paying customer.';
lang_en['PageTitles.Sys.MyAccount.UpdatePaymentInformation'] = 'Update Your Payment Information';
lang_en['PageTitles.Sys.MyAccount.UpdatePaymentInformationSub'] = 'Update your current billing information.';
lang_en['PageTitles.Sys.MyAccount.UploadCustomLogo'] = 'Upload Custom Logo';
lang_en['PageTitles.Sys.MyAccount.UploadCustomLogoSub'] = 'Upload your custom logo as part of your private branding upgrade.';
lang_en['PageTitles.Sys.MyAccount.YourPersonalRepIs'] = 'Your personal account representative is {1}.';
lang_en['PageTitles.Sys.MyTeamsStatus'] = 'My Team\'s Status';
lang_en['PageTitles.Sys.NewAccountVerification'] = 'New Account Verification';
lang_en['PageTitles.Sys.NewTimer'] = 'New Timer';
lang_en['PageTitles.Sys.OpenExpensesSummary'] = 'Open Expenses Summary';
lang_en['PageTitles.Sys.PageNotFound'] = 'Page Not Found!';
lang_en['PageTitles.Sys.PayAndActivateAccount'] = 'Pay And Activate Your Account';
lang_en['PageTitles.Sys.PaymentAndBillingInformation'] = 'Payment And Billing Information';
lang_en['PageTitles.Sys.PayrollPeriodSummary'] = 'Payroll Period Summary';
lang_en['PageTitles.Sys.PendingAlertsList'] = 'Pending Records';
lang_en['PageTitles.Sys.Presets'] = 'Presets';
lang_en['PageTitles.Sys.PreviouslyGeneratedReports'] = 'Previously Generated Reports';
lang_en['PageTitles.Sys.ProjectEdit'] = 'Edit Project';
lang_en['PageTitles.Sys.ProjectList'] = 'Project List';
lang_en['PageTitles.Sys.ProjectPrintableTimesheets'] = 'Project Printable Timesheets';
lang_en['PageTitles.Sys.ProjectTimeApprove'] = 'Approve Project Time';
lang_en['PageTitles.Sys.ProjectTimeArchive'] = 'Archive Project Time';
lang_en['PageTitles.Sys.ProjectTimeBillableUpdate'] = 'Update Billable Project Time';
lang_en['PageTitles.Sys.ProjectTimeExportToQBO'] = 'Export Project Time To QuickBooks Online';
lang_en['PageTitles.Sys.PurchaseHistory'] = 'Purchase History';
lang_en['PageTitles.Sys.QBOExport'] = 'QuickBooks Online Export';
lang_en['PageTitles.Sys.QuickBooksTutorial'] = 'QuickBooks&reg; Tutorial';
lang_en['PageTitles.Sys.QuickClockOut'] = 'Quick Clock Out';
lang_en['PageTitles.Sys.RecurringPaymentHistory'] = 'Recurring Payment History';
lang_en['PageTitles.Sys.ReferAFriend'] = 'Refer A Friend';
lang_en['PageTitles.Sys.RemoteLogin'] = 'Login To Your Account From Your Own Site';
lang_en['PageTitles.Sys.ReportApproveHourlyTime'] = 'Approve Hourly Time';
lang_en['PageTitles.Sys.ReportArchiveHourlyTime'] = 'Archive Hourly Time';
lang_en['PageTitles.Sys.ReportClosePayrollPeriod'] = 'Close Payroll Period';
lang_en['PageTitles.Sys.ReportExpenses'] = 'Expense Reports';
lang_en['PageTitles.Sys.ReportHourlyPayrollTime'] = 'Payroll Reports For Hourly Timesheets';
lang_en['PageTitles.Sys.ReportHourlyPrePayrollTime'] = 'Reports For Hourly Timesheets';
lang_en['PageTitles.Sys.ReportHourlyRecordHistory'] = 'Hourly Record History Report';
lang_en['PageTitles.Sys.ReportLocationAndTardy'] = 'Location & Tardy Reports';
lang_en['PageTitles.Sys.ReportOverlappingRecords'] = 'Overlapping Records Report';
lang_en['PageTitles.Sys.ReportPayrollPeriod'] = 'Payroll Reports';
lang_en['PageTitles.Sys.ReportPreliminaryPayroll'] = 'Preliminary Payroll Reports';
lang_en['PageTitles.Sys.ReportProjectTime'] = 'Project Time Reports';
lang_en['PageTitles.Sys.ReportsAndExports'] = 'Reports & Exports';
lang_en['PageTitles.Sys.ResetAccountPassword'] = 'Reset Your Account Password';
lang_en['PageTitles.Sys.ResetPasswordsAndWelcomeEmails'] = 'Re-Send Welcome Email';
lang_en['PageTitles.Sys.SecurityGeolocation'] = 'Geolocation Security Settings';
lang_en['PageTitles.Sys.SecurityIPAddressList'] = 'IP Address Lockdown List';
lang_en['PageTitles.Sys.SecurityMessageBoard'] = 'Message Board Security';
lang_en['PageTitles.Sys.SecurityPhotoTimestamp'] = 'Photo Timestamp Settings';
lang_en['PageTitles.Sys.SecuritySettings'] = 'Security Settings';
lang_en['PageTitles.Sys.SecurityTimeEntryCutoff'] = 'Time Entry Cutoff';
lang_en['PageTitles.Sys.SecurityUserPW'] = 'User Password Security';
lang_en['PageTitles.Sys.SessionCookieTest'] = 'Cookie Test';
lang_en['PageTitles.Sys.ShiftStatus'] = 'Shift Status';
lang_en['PageTitles.Sys.SignatureStatements'] = 'Signature Statements';
lang_en['PageTitles.Sys.SubscribingTo'] = 'Subscribing To';
lang_en['PageTitles.Sys.SubscriptionOptions'] = 'Subscription Options';
lang_en['PageTitles.Sys.SystemUnavailable'] = 'System Unavailable';
lang_en['PageTitles.Sys.TimesheetDateSpan'] = 'Timesheet For: {1} TO {2}';
lang_en['PageTitles.Sys.Tools'] = 'Tools';
lang_en['PageTitles.Sys.Tools.APIAccess'] = 'API Access';
lang_en['PageTitles.Sys.Tools.APIAccessSub'] = 'Generate API keys for programmatic access to your account.';
lang_en['PageTitles.Sys.Tools.BillableProjectTimeConverter'] = 'Billable/Unbillable Project Time Converter';
lang_en['PageTitles.Sys.Tools.BillableProjectTimeConverterSub'] = 'Update the billable status of multiple project records.';
lang_en['PageTitles.Sys.Tools.CompTimeConverter'] = 'Comp Time Converter';
lang_en['PageTitles.Sys.Tools.CompTimeConverterSub'] = 'Convert overtime to comp time.';
lang_en['PageTitles.Sys.Tools.CompanyAnnouncements'] = 'Company Announcements';
lang_en['PageTitles.Sys.Tools.CompanyAnnouncementsSub'] = 'Create announcements that will display in all user accounts.';
lang_en['PageTitles.Sys.Tools.GlobalHourlyTimeEntry'] = 'Global Hourly Time Entry';
lang_en['PageTitles.Sys.Tools.GlobalProjectTimeEntry'] = 'Global Project Time Entry';
lang_en['PageTitles.Sys.Tools.GlobalTimeEntry'] = 'Global Time Entry';
lang_en['PageTitles.Sys.Tools.ImporExportAccrualBalances'] = 'Import/Export Accrual Balances';
lang_en['PageTitles.Sys.Tools.ImporExportAccrualBalancesSub'] = 'Change accrual settings and balances for multiple users at once.';
lang_en['PageTitles.Sys.Tools.LinkToProPartner'] = 'Link to a ProPartner';
lang_en['PageTitles.Sys.Tools.LinkToProPartnerSub'] = 'Link a user account to your ProPartner.';
lang_en['PageTitles.Sys.Tools.ResendWelcomeEmails'] = 'Re-send Welcome Emails';
lang_en['PageTitles.Sys.Tools.ResendWelcomeEmailsSub'] = 'Re-send original welcome emails with a link to login.';
lang_en['PageTitles.Sys.Tools.UpdateMultipleUserProfiles'] = 'Update Multiple User Profiles';
lang_en['PageTitles.Sys.Tools.UpdateMultipleUserProfilesSub'] = 'Change profile settings for multiple users at once.';
lang_en['PageTitles.Sys.Tools.WorkTimeCalculator'] = 'Work Time Calculator';
lang_en['PageTitles.Sys.Tools.WorkTimeCalculatorSub'] = 'Add up hourly time manually.';
lang_en['PageTitles.Sys.Training'] = 'Training';
lang_en['PageTitles.Sys.TrialDaysLeft'] = 'Days Left In Trial Period';
lang_en['PageTitles.Sys.TrialExpired'] = 'Trial Expired';
lang_en['PageTitles.Sys.TrySampleAccount'] = 'Try Our Sample Account';
lang_en['PageTitles.Sys.UpcomingEvents'] = 'Upcoming Events';
lang_en['PageTitles.Sys.UpdateBrandLogo'] = 'Update Brand Logo';
lang_en['PageTitles.Sys.UpdatePaymentInformation'] = 'Update Payment Information';
lang_en['PageTitles.Sys.UserAccrualImport'] = 'User Accrual Import';
lang_en['PageTitles.Sys.UserAddNew'] = 'Add New User';
lang_en['PageTitles.Sys.UserFiles'] = 'User Files';
lang_en['PageTitles.Sys.UserFirstTimeLogin'] = 'First Time Log In';
lang_en['PageTitles.Sys.UserGlobalProfileUpdate'] = 'Update Multiple User Profiles';
lang_en['PageTitles.Sys.UserImport'] = 'User Import';
lang_en['PageTitles.Sys.UserImportSalesforce'] = 'Salesforce User Import';
lang_en['PageTitles.Sys.UserNotes'] = 'User Notes';
lang_en['PageTitles.Sys.UserProfileHistory'] = 'User Profile History';
lang_en['PageTitles.Sys.UserUpdateAccountInfo'] = 'Update Account Information';
lang_en['PageTitles.Sys.VendorEdit'] = 'Vendor Edit';
lang_en['PageTitles.Sys.VendorList'] = 'Vendor List';
lang_en['PageTitles.Sys.ViewCalendars'] = 'Company Calendars';
lang_en['PageTitles.Sys.WatchlistLogin'] = 'Watchlist Login';
lang_en['PageTitles.Sys.WorkTimeCalculatorFastAnswer'] = 'Fast-Answer Work Time Calculator';
lang_en['UIMessages.Accruals.AccruedHoursRange'] = 'Accrued hours must be between -10000 and 10000.';
lang_en['UIMessages.Accruals.CurrentlyAccruedNotGreater'] = 'Currently accrued hours cannot be greater than the max allowed.';
lang_en['UIMessages.Accruals.HourlyAccrualParams'] = 'Hours per hour worked must be less than both hours to accrue per year and the cap for maximum (if no roll-over).';
lang_en['UIMessages.Accruals.HourlyAccrualRange'] = 'Hours per hour worked must be numeric between 0 and 99999.';
lang_en['UIMessages.Accruals.HoursPerYearParams'] = 'Hours per year must be numeric between 0 and 8760.';
lang_en['UIMessages.Accruals.InvalidRate'] = 'Invalid accrual rate';
lang_en['UIMessages.Accruals.MaxAccruedHoursRange'] = 'Max accrued hours must be between 0 and 99999.';
lang_en['UIMessages.Accruals.SalUsersCannotByHoursWorked'] = 'Salaried users cannot accrue by hours worked.';
lang_en['UIMessages.Accruals.StartDateRequired'] = 'An accrual start date is required.';
lang_en['UIMessages.Accruals.StartDateRequiredForX'] = '{1}: An accrual start date is required.';
lang_en['UIMessages.Accruals.UIOLICapInvalid'] = 'The \'Use It Or Lose It\' cap must be between 0 and 99999 (disabled).';
lang_en['UIMessages.Accruals.UIOLIDateInvalid'] = 'A valid date for the \'Use It Or Lose It\' roll over is required.';
lang_en['UIMessages.Affiliate.AcceptAgreement'] = 'You need to accept the Partner Agreement to proceed.';
lang_en['UIMessages.Affiliate.AcctCreated'] = 'ProPartner account created!';
lang_en['UIMessages.Affiliate.AcctDisabled'] = 'Affiliate account has been disabled. Please contact support.';
lang_en['UIMessages.Affiliate.ClientAdded'] = 'Your new client has been added. If you asked us to contact them we will reach out right away.';
lang_en['UIMessages.Affiliate.ErrorCreatingAccount'] = 'There was an error creating the referral account. Please contact customer service.';
lang_en['UIMessages.Affiliate.IDInvalid'] = 'Affiliate ID not valid';
lang_en['UIMessages.Affiliate.Linked'] = 'Affiliate account linked';
lang_en['UIMessages.Affiliate.NoneFound'] = 'No affiliate account found';
lang_en['UIMessages.Affiliate.ReferralRecorded'] = 'A referral has been recorded in our records and an invitation email has been sent! If your referral becomes a paying customer you will be contacted about your referral compensation. You may enter another referral now.';
lang_en['UIMessages.Affiliate.RequiredFields'] = 'Please complete the required fields above.';
lang_en['UIMessages.Affiliate.ResendReferral'] = 'Referral resent';
lang_en['UIMessages.Affiliate.Unlinked'] = 'Affiliate account unlinked';
lang_en['UIMessages.Announcement.AnnouncementAdded'] = 'Announcement added';
lang_en['UIMessages.Calendar.ActionOnlyOnScheduleView'] = 'The action can only be performed in the schedule view.';
lang_en['UIMessages.Calendar.CalendarAdded'] = 'Calendar Added';
lang_en['UIMessages.Calendar.CalendarNotFound'] = 'The calendar could not be found.';
lang_en['UIMessages.Calendar.CalendarNotFoundOrInitialized'] = 'The calendar could not be found or initialized.';
lang_en['UIMessages.Calendar.CalendarUpdated'] = 'Calendar Updated';
lang_en['UIMessages.Calendar.CalendarsArchived'] = 'Calendars Archived';
lang_en['UIMessages.Calendar.CalendarsDeleted'] = 'Calendars Deleted';
lang_en['UIMessages.Calendar.CalendarsRestored'] = 'Calendars Restored';
lang_en['UIMessages.Calendar.CopiedSuccessForEditableCalendars'] = 'The calendar dates successfully copied for all editable calendars.';
lang_en['UIMessages.Calendar.DescriptionCharLimit'] = 'Calendar description is limited to {1} characters';
lang_en['UIMessages.Calendar.EndDateRequired'] = 'An end date is required';
lang_en['UIMessages.Calendar.ErrorImportingICSFile'] = 'There was an error importing the ICS file.';
lang_en['UIMessages.Calendar.EventAvailabilityStatusInvalid'] = 'The event availability status was not valid.';
lang_en['UIMessages.Calendar.EventDatesInvalid'] = 'The event dates are not valid.';
lang_en['UIMessages.Calendar.EventOverlap'] = 'This event will overlap an existing event.';
lang_en['UIMessages.Calendar.EventPublishedStatusInvalid'] = 'The event published status was not valid.';
lang_en['UIMessages.Calendar.EventTitleRequired'] = 'Event title required';
lang_en['UIMessages.Calendar.EventsCannotBeAdded'] = 'Events cannot be added to the selected calendar.';
lang_en['UIMessages.Calendar.InvalidActionTryAgain'] = 'Invalid action. Please refresh the calendar and try again.';
lang_en['UIMessages.Calendar.ModifyingNotSupported'] = 'The selected calendar does not support modifying its properties.';
lang_en['UIMessages.Calendar.NameCharLimit'] = 'Calendar name is limited to {1} characters';
lang_en['UIMessages.Calendar.NameRequired'] = 'Calendar name is required';
lang_en['UIMessages.Calendar.NoPermissionToEditEventsForCalendar'] = 'No permission to edit events for this calendar';
lang_en['UIMessages.Calendar.NoPermissionToViewEventsForCalendar'] = 'No permission to view events for this calendar';
lang_en['UIMessages.Calendar.PropertiesReset'] = 'Calendar properties reset';
lang_en['UIMessages.Calendar.PropertiesUpdated'] = 'Calendar properties updated';
lang_en['UIMessages.Calendar.SelectedDatesInvalid'] = 'The selectected dates were not valid. Please refresh the calendar and try again.';
lang_en['UIMessages.Calendar.StartDateCannotBeBeforeEndDate'] = 'The start date cannot be before the end date.';
lang_en['UIMessages.Calendar.StartDateRequired'] = 'A start date is required';
lang_en['UIMessages.Calendar.ValidICSRequired'] = 'A valid ICS file is required';
lang_en['UIMessages.Calendar.ValidTypeRequired'] = 'A valid calendar type is required';
lang_en['UIMessages.ClockAction.BreakErrorMustBeClockedIn'] = 'Break error. You must be clocked in to start a break.';
lang_en['UIMessages.ClockAction.BreakStartResumeAt'] = 'You are now on your {1} minute break. Your shift will automatically resume at {2}.';
lang_en['UIMessages.ClockAction.CameraDisabled'] = 'Camera Disabled';
lang_en['UIMessages.ClockAction.ClockEditsAreNotEnabled'] = 'Editing clock records is not enabled for this account.';
lang_en['UIMessages.ClockAction.ClockInColorCheckFail'] = 'Poor image quality detected on clock in';
lang_en['UIMessages.ClockAction.ClockInDisabled'] = 'Clock in is disabled because the account is locked.';
lang_en['UIMessages.ClockAction.ClockInFacialDetectFail'] = 'Possible facial detection failure on clock in';
lang_en['UIMessages.ClockAction.ClockInInterrupted'] = 'Clock in attempt interrupted. Please try again.';
lang_en['UIMessages.ClockAction.ClockInPhotoNotValid'] = 'Clock in photo data was corrupt';
lang_en['UIMessages.ClockAction.ClockInTimeChangeRequest'] = 'The clock in edit is pending and will not be reflected until it has been approved/denied/modified by your supervisor.';
lang_en['UIMessages.ClockAction.ClockInTimeChanged'] = 'The clock in edit was saved. It will be reflected on the final shift record at clock out.';
lang_en['UIMessages.ClockAction.ClockInTimeChangedTo'] = 'The clock in time has been changed to {1}';
lang_en['UIMessages.ClockAction.ClockInTimeGreaterThanPresentTime'] = 'Clock in time is greater than the present time';
lang_en['UIMessages.ClockAction.ClockOutColorCheckFail'] = 'Poor image quality detected on clock out';
lang_en['UIMessages.ClockAction.ClockOutDateWillInterfereWithTheShiftBreak'] = 'The clock-out date will interfere with the shift break.';
lang_en['UIMessages.ClockAction.ClockOutFacialDetectFail'] = 'Possible facial detection failure on clock out';
lang_en['UIMessages.ClockAction.ClockOutPhotoNotValid'] = 'Clock out photo data was corrupt';
lang_en['UIMessages.ClockAction.ClockOutTimeChangeRequest'] = 'The clock in edit is pending and will not be reflected until it has been approved/denied/modified by your supervisor.';
lang_en['UIMessages.ClockAction.ClockOutTimeChanged'] = 'The clock out edit was saved. It will be reflected on the final shift record at clock out.';
lang_en['UIMessages.ClockAction.ClockOutTimeChangedTo'] = 'The clock out time has been changed to {1}';
lang_en['UIMessages.ClockAction.ClockedInAt'] = 'Clocked In at {1}';
lang_en['UIMessages.ClockAction.ClockedOutAt'] = 'Clocked Out at {1}';
lang_en['UIMessages.ClockAction.EditsToClockShiftCannotBeGreaterThanCurrentTime'] = 'Edits to a clock shift cannot be made for the future.';
lang_en['UIMessages.ClockAction.EditsToClockShiftCannotBeLaterThanCurrentTime'] = 'Edits to a clock shift cannot be later than the current time.';
lang_en['UIMessages.ClockAction.Error.ClockPhotoError.AnalyzeError'] = 'Error occurred analyzing photo.';
lang_en['UIMessages.ClockAction.Error.ClockPhotoError.ClockInGeneral'] = 'Clock-In Photo Error:';
lang_en['UIMessages.ClockAction.Error.ClockPhotoError.ClockOutGeneral'] = 'Clock-Out Photo Error:';
lang_en['UIMessages.ClockAction.Error.ClockPhotoError.LowConfidence'] = 'Low confidence ({1}%) of a face.';
lang_en['UIMessages.ClockAction.Error.ClockPhotoError.MultipleFacesDetected'] = 'Multiple faces detected.';
lang_en['UIMessages.ClockAction.Error.ClockPhotoError.NoFaceDetected'] = 'No face detected.';
lang_en['UIMessages.ClockAction.Error.ErrorOccurred'] = 'An error has occured. Please try your action again.';
lang_en['UIMessages.ClockAction.Error.ErrorOccurredInfo'] = 'You may need to logout of your account and then log back in to complete this action.';
lang_en['UIMessages.ClockAction.Error.HourlyNotEnabled'] = 'Hourly Time Tracking is not enabled for your account.';
lang_en['UIMessages.ClockAction.Error.HourlyNotEnabledInfo'] = 'You cannot clock in or out because this feature is not enabled for your account. Please see your supervisor if you believe this to be an error.';
lang_en['UIMessages.ClockAction.Error.ProblemClockOut'] = 'There was a problem clocking you out.';
lang_en['UIMessages.ClockAction.Error.ProblemClockOutInfo'] = 'It looks like you were trying to clock out, however you were not previously clocked in.';
lang_en['UIMessages.ClockAction.Error.ProblemClockOutInfoContactSuper'] = 'You can contact your supervisor to have them record your missed time on your behalf.';
lang_en['UIMessages.ClockAction.Error.ProblemClockOutInfoIfEdit'] = 'You may clock in now and then request a change to your clock in time. Later, you can clock out normally.';
lang_en['UIMessages.ClockAction.Error.ProblemClockOutInfoIfTimEntry'] = 'You can use the time entry form on your timesheet to manually enter your work time, if needed.';
lang_en['UIMessages.ClockAction.Error.ProblemClockingIn'] = 'There was a problem clocking you in.';
lang_en['UIMessages.ClockAction.Error.ProblemClockingInInfo'] = 'It looks like you were trying to clock in, but our records show that you are still clocked in from a previous shift. Clock out first, and then you can clock in.';
lang_en['UIMessages.ClockAction.Error.ProblemClockingInInfoIfEdit'] = 'You may request a change to your clock out time as needed and then clock in normally.';
lang_en['UIMessages.ClockAction.FeaturesRequiredForAccount'] = 'The following features are enabled for your account:';
lang_en['UIMessages.ClockAction.FutureError'] = 'Users cannot be clocked in to the future';
lang_en['UIMessages.ClockAction.GeoLocationEnabled'] = 'Geolocation security is enabled for your account, but a location could not be acquired. Please configure your browser to allow location sharing for this site.';
lang_en['UIMessages.ClockAction.HourlyTrackingNotEnabled'] = 'Hourly time tracking is not enabled for this user account.';
lang_en['UIMessages.ClockAction.IPLocationFail'] = 'Clock punches are not allowed for this location. Contact your administrator to add your location.';
lang_en['UIMessages.ClockAction.InvalidClockOutDate'] = 'Invalid clock out date';
lang_en['UIMessages.ClockAction.InvalidLocationNoAction'] = 'A clock action cannot be performed from an invalid location.';
lang_en['UIMessages.ClockAction.LoginBeforeAttemptingClockAction'] = 'You must login to your account to clock in our out.';
lang_en['UIMessages.ClockAction.ModificationErrorInvalidParameters'] = 'Modification error. The time parameters are invalid.';
lang_en['UIMessages.ClockAction.ModificationErrorNotClockedIn'] = 'The time cannot be modified because you are not clocked in.';
lang_en['UIMessages.ClockAction.ModificationNotAllowed'] = 'Modification of the clock time is not allowed. See your supervisor for assistance.';
lang_en['UIMessages.ClockAction.NoActionShiftRestricted'] = 'A clock action cannot be performed on this user because their shift is restricted.';
lang_en['UIMessages.ClockAction.OutDateTimeBeforeIn'] = 'Clock out date and time is before your clock in date and time.';
lang_en['UIMessages.ClockAction.PhotoDisabledDuringIn'] = 'Photo Timestamp was disabled during this Clock In';
lang_en['UIMessages.ClockAction.PhotoDisabledDuringOut'] = 'Photo Timestamp was disabled during this Clock Out';
lang_en['UIMessages.ClockAction.PhotoSecurityEnabled'] = 'Photo security is enabled for your account. You must log in to your account to clock in or out.';
lang_en['UIMessages.ClockAction.Requirement.GeolocationEnabled'] = 'Geolocation Enabled';
lang_en['UIMessages.ClockAction.Requirement.PhotoSecurityEnabled'] = 'Photo Security Enabled';
lang_en['UIMessages.ClockAction.ShiftRestrictionFail'] = 'Clocking in is only allowed between {1}-{2}';
lang_en['UIMessages.ClockAction.UserAlreadyClockedIn'] = 'The user is already clocked in';
lang_en['UIMessages.ClockAction.UserAlreadyClockedOut'] = 'This user is already clocked out';
lang_en['UIMessages.ClockAction.UserClockedIn'] = 'The user has been clocked in';
lang_en['UIMessages.ClockAction.UserClockedOut'] = 'The user has been clocked out';
lang_en['UIMessages.ClockAction.UserIsClockedIn'] = 'The user is currently clocked in';
lang_en['UIMessages.ClockAction.UserIsNotClockedIn'] = 'The user is not clocked in';
lang_en['UIMessages.ClockAction.UserIsShiftRestricted'] = 'Shift restriction allows this user to be clocked in only between {1} and {2}.';
lang_en['UIMessages.Contact.EmailSent'] = 'Email sent';
lang_en['UIMessages.Contact.FeelFree'] = 'Please feel free to contact us anytime.';
lang_en['UIMessages.Contact.FeelFreeStaffReady'] = 'Our staff is ready to assist in setting up your account or with any questions concerning our service.';
lang_en['UIMessages.Contact.NameEmailRequired'] = 'A valid name and email address are required.';
lang_en['UIMessages.Exports.ADPBatchNumber'] = 'The ADP batch number must be a 2 character alphanumeric value.';
lang_en['UIMessages.Exports.ADPCompanyCode'] = 'The ADP company code must be a 3 character alphanumeric value.';
lang_en['UIMessages.Exports.ADPRunCompanyCode'] = 'The ADP Run company code must be a 3 to 15 character alphanumeric value.';
lang_en['UIMessages.Exports.CodeForXTime'] = 'Please enter a code for \'\'{1}\'\' hours.';
lang_en['UIMessages.Exports.IIFVersionNumberTooLong'] = 'The value for IIF Version Number is too long.';
lang_en['UIMessages.Exports.PaychexPreviewClientNumber'] = 'Please enter your Paychex Preview client number.';
lang_en['UIMessages.Exports.PayrollCS.HolidayItemRequired'] = 'The value for holiday hours must be numeric.';
lang_en['UIMessages.Exports.PayrollCS.PersonalItemRequired'] = 'The value for personal hours must be numeric.';
lang_en['UIMessages.Exports.PayrollCS.SickItemRequired'] = 'The value for sick hours must be numeric.';
lang_en['UIMessages.Exports.PayrollCS.VacationItemRequired'] = 'The value for vacation hours must be numeric.';
lang_en['UIMessages.Exports.PayrollRelief.ClientCodeRequired'] = 'The client code is required.';
lang_en['UIMessages.Exports.PayrollRelief.EmployerNameRequired'] = 'The employer name is required.';
lang_en['UIMessages.Exports.PayrollRelief.FirmCodeRequired'] = 'The firm code is required.';
lang_en['UIMessages.Exports.PayrollRelief.FirmRequired'] = 'The firm name is required.';
lang_en['UIMessages.Exports.PayrollRelief.PayScheduleRequired'] = 'The pay schedule description is required.';
lang_en['UIMessages.Exports.QBCompanyFileNo'] = 'The value for QB File Number is too long.';
lang_en['UIMessages.Exports.QBReleaseNumberTooLong'] = 'The value for QB Release Number is too long.';
lang_en['UIMessages.Exports.ValueDoubleTime'] = 'Please specify a value for Double Time.';
lang_en['UIMessages.Exports.ValueOvertime'] = 'Please specify a value for Overtime.';
lang_en['UIMessages.Exports.ValueStandardTime'] = 'Please specify a value for Standard Time.';
lang_en['UIMessages.Exports.ValueStandardTimeNumeric'] = 'The value for standard time must be numeric.';
lang_en['UIMessages.Exports.ValueXTime'] = 'Please specify a value for \'\'{1}\'\'.';
lang_en['UIMessages.File.BlockedScanFailed'] = 'blocked, anti-virus scan failed';
lang_en['UIMessages.File.ChooseValidFormat'] = 'Please choose a valid file format.';
lang_en['UIMessages.File.FileAvailableHere'] = 'The file is available for download {1}here{2}.';
lang_en['UIMessages.File.FileHeaderInvalid'] = 'The file header was invalid.';
lang_en['UIMessages.File.InvalidEvents'] = 'One or more events in the file were not valid. Please check the file and try again or contact support.';
lang_en['UIMessages.File.InvalidKey'] = 'Invalid file key. Please try again.';
lang_en['UIMessages.File.Latest'] = 'Latest';
lang_en['UIMessages.File.Limit10MB'] = 'Files limited to 10 MB';
lang_en['UIMessages.File.LineContainsErrors'] = 'Line {1} contains errors.';
lang_en['UIMessages.File.LineDuplicate'] = 'Line {1} was found to be a duplicate of an existing item.';
lang_en['UIMessages.File.LineInvalidApplicationCode'] = 'Line {1} has an invalid application code.';
lang_en['UIMessages.File.LineInvalidCustomerAssociation'] = 'Line {1} has an invalid customer association value. Value must be Yes or No.';
lang_en['UIMessages.File.LineInvalidMinTimeIncrement'] = 'Line {1} has an invalid minimum time increment.';
lang_en['UIMessages.File.LineInvalidName'] = 'Line {1} has an invalid item name.';
lang_en['UIMessages.File.LineInvalidProjectAssociation'] = 'Line {1} has an invalid project association value. Value must be Yes or No.';
lang_en['UIMessages.File.LineInvalidRate'] = 'Line {1} has an invalid rate. Rates must be between {2} and {3}.';
lang_en['UIMessages.File.LineInvalidUserAssociation'] = 'Line {1} has an invalid user association value. Value must be Yes or No.';
lang_en['UIMessages.File.LineNotSaved'] = 'Line {1} could not be saved.';
lang_en['UIMessages.File.LineTooManyFields'] = 'Line {1} has too many fields.';
lang_en['UIMessages.File.NameLimit135Chars'] = 'The file name is too long. Please limit the file name to 135 characters.';
lang_en['UIMessages.File.NoValidFileUploaded'] = 'No valid file uploaded';
lang_en['UIMessages.File.RateErrorNotUpdated'] = 'There was an unknown error. The rates were not updated.';
lang_en['UIMessages.File.RateUpdateSuccessful'] = 'Rate update successful';
lang_en['UIMessages.File.Scanning'] = 'scanning...';
lang_en['UIMessages.File.TimedOutTryAnotherOrAgain'] = 'The file upload timed out. Please try again with a smaller file or faster connection.';
lang_en['UIMessages.File.UploadedSuccessfully'] = 'File uploaded successfully';
lang_en['UIMessages.General.404NotFound'] = 'The requested page could not be found.';
lang_en['UIMessages.General.ActionCannotBeUndone'] = 'This action cannot be undone.';
lang_en['UIMessages.General.AddSuccessful'] = 'Add successful';
lang_en['UIMessages.General.AdditionalDetails'] = 'Additional Details: {1}';
lang_en['UIMessages.General.CallCustomerService'] = 'Please call customer service for assistance.';
lang_en['UIMessages.General.CopySuccessful'] = 'Copy successful';
lang_en['UIMessages.General.DeleteError'] = 'Delete unsuccessful';
lang_en['UIMessages.General.DeleteSuccessful'] = 'Delete successful';
lang_en['UIMessages.General.EditSuccessful'] = 'Edit successful';
lang_en['UIMessages.General.Error'] = 'There was an error.';
lang_en['UIMessages.General.ErrorCommunicatingWithServer'] = 'There was an error communicating with the server.';
lang_en['UIMessages.General.ErrorGeneratingPDF'] = 'The was an error generating the PDF.';
lang_en['UIMessages.General.ErrorGettingHostNameFromIPAddress'] = 'Error getting hostname from IP address.';
lang_en['UIMessages.General.ErrorLoadingClock'] = 'Error loading clock...';
lang_en['UIMessages.General.ErrorLoadingData'] = 'Error loading data...';
lang_en['UIMessages.General.ErrorTryAgain'] = 'There was an error. Please try again.';
lang_en['UIMessages.General.Failed'] = 'Failed';
lang_en['UIMessages.General.Failure'] = 'Failure';
lang_en['UIMessages.General.FileSizeLimitedToXMB'] = 'File size is limited to {1}MB.';
lang_en['UIMessages.General.FileStorageNotEnabled'] = 'File storage is currently not enabled for your account. Please call customer service.';
lang_en['UIMessages.General.FollowingErrorsOccured'] = 'The following errors occured:';
lang_en['UIMessages.General.ImportCompletedWithErrors'] = 'The import completed with the following errors:';
lang_en['UIMessages.General.InputEmpty'] = 'Please fill out the field.';
lang_en['UIMessages.General.InvalidAction'] = 'Invalid action';
lang_en['UIMessages.General.InvalidSelection'] = 'One or more selections were invalid.';
lang_en['UIMessages.General.MakeSelectionForAction'] = 'Make a selection to complete the action.';
lang_en['UIMessages.General.MoveSuccessful'] = 'Move successful';
lang_en['UIMessages.General.NoData'] = 'No data.';
lang_en['UIMessages.General.NoDataToMap'] = 'There is no data to map.';
lang_en['UIMessages.General.NoLicensesAvailable'] = 'Your account has reached the maximum number of available user licenses. To add a new user, archive an existing user account or have the primary account administrator purchase additional licenses.';
lang_en['UIMessages.General.NoPermissionForAction'] = 'You do not have permission for this action.';
lang_en['UIMessages.General.NoPermissionForActionContactSup'] = 'You do not have permission for this action. Please contact your supervisor.';
lang_en['UIMessages.General.NoPermissionToModifyItem'] = 'You do not have permission to modify the \'\'{1}\'\' setting.';
lang_en['UIMessages.General.NoValidActionSelected'] = 'No valid action was selected.';
lang_en['UIMessages.General.Payment.AccountCancelledEndOfBiillingPeriod'] = 'Your account has been cancelled and will expire at the end of your current billing period.';
lang_en['UIMessages.General.Payment.AccountCancelledImmediately'] = 'Your account has been cancelled effective immediately.';
lang_en['UIMessages.General.Payment.AccountDeletionConfirmationEmailSent'] = 'An email has been sent to confirm the deletion of your account data.';
lang_en['UIMessages.General.Payment.AccountIsActivated'] = 'Your account is activated!';
lang_en['UIMessages.General.Payment.AccountIsUpToDate'] = 'Your account is now up to date.';
lang_en['UIMessages.General.Payment.AccountIsUpdated'] = 'Your account is updated.';
lang_en['UIMessages.General.Payment.AccountMustBeTrialOrExpired'] = 'Your account must be in its trial period or expired.';
lang_en['UIMessages.General.Payment.BillingInfoUpdated'] = 'The billing information has been updated.';
lang_en['UIMessages.General.Payment.BillingInfoUpdatedFailedCharged'] = 'The billing information has been updated but the credit card could not be charged.';
lang_en['UIMessages.General.Payment.CannotCreateSingleUserAccount'] = 'You cannot create a single user account.';
lang_en['UIMessages.General.Payment.ConfirmationEmailSentToYou'] = 'A confirmation email has been sent to you.';
lang_en['UIMessages.General.Payment.ErrorGeneratingReceipt'] = 'There was an error generating a receipt.';
lang_en['UIMessages.General.Payment.FreeAccountCancelled'] = 'Your free account is cancelled. We hope to see you again.';
lang_en['UIMessages.General.Payment.MoreThanOneUserInAccount'] = 'There is more than one active user in the account.';
lang_en['UIMessages.General.Payment.NoChangesMadeToAccount'] = 'No changes have been made to the account. You must change the number of user licenses or account options to continue to checkout.';
lang_en['UIMessages.General.Payment.OnlyPrimaryAdminCanCancelService'] = 'Only the primary account administrator can cancel service.';
lang_en['UIMessages.General.Payment.PaymentMade'] = 'You have made a payment.';
lang_en['UIMessages.General.Payment.PromoCodeApplied'] = 'The promotion code was applied.';
lang_en['UIMessages.General.Payment.PromoCodeInvalid'] = 'The promotion code was invalid.';
lang_en['UIMessages.General.Payment.PromoCodeRemoved'] = 'The promotion code was removed.';
lang_en['UIMessages.General.Payment.ReceiptEmailedTo'] = 'The receipt has been emailed to {1}.';
lang_en['UIMessages.General.Payment.SaveForRecords'] = 'Please save this information for your records.';
lang_en['UIMessages.General.Payment.SingleUserAccountActivated'] = 'Your free single user account has been activated. Enjoy!';
lang_en['UIMessages.General.Payment.SubdomainAlphaNumeric'] = 'The subdomain must be alphanumeric only.';
lang_en['UIMessages.General.Payment.SubdomainInUse'] = 'The subdomain is already in use. Please choose another.';
lang_en['UIMessages.General.Payment.SubdomainRequired'] = 'A valid subdomain is required for private brand option.';
lang_en['UIMessages.General.Payment.SubscriptionLevel'] = 'Please select a subscription level.';
lang_en['UIMessages.General.Payment.ThankYou'] = 'Thank you!';
lang_en['UIMessages.General.Payment.ThereAreXDaysLeftInTrial'] = 'There are {1} days left in your trial period.';
lang_en['UIMessages.General.Payment.TransactionDeclined'] = 'The transaction was declined.';
lang_en['UIMessages.General.Payment.TransactionFlaggedForReview'] = 'The transaction was flagged for review.';
lang_en['UIMessages.General.Payment.TrialPeriodHasExpired'] = 'The trial period has expired.';
lang_en['UIMessages.General.PermanentlyDeleteItem'] = 'Permanently delete this item?';
lang_en['UIMessages.General.PermanentlyDeleteItems'] = 'Permanently delete these items?';
lang_en['UIMessages.General.PleaseContactForAssistance'] = 'Please contact customer service for assistance.';
lang_en['UIMessages.General.PleaseEnterAMessage'] = 'Please enter a message.';
lang_en['UIMessages.General.ReasonForEditRequired'] = 'Reason for Edit is required';
lang_en['UIMessages.General.ReportBeingProcessed'] = 'The report is being processed. You will be notified when it is complete.';
lang_en['UIMessages.General.ReportContainsNoData'] = 'The report contains no data.';
lang_en['UIMessages.General.ReportExpired'] = 'The report has expired. Please try again.';
lang_en['UIMessages.General.SaveSuccessful'] = 'Save successful';
lang_en['UIMessages.General.Success'] = 'Success';
lang_en['UIMessages.General.ThankYou'] = 'Thank you.';
lang_en['UIMessages.General.UnknownErrorOccured'] = 'An unknown error occured.';
lang_en['UIMessages.General.UpdateSuccessful'] = 'Update successful';
lang_en['UIMessages.General.ValueAlreadyExists'] = 'Value already exists';
lang_en['UIMessages.HR.CurrentHourlyRateOrBlank'] = 'Current hourly pay rate must be numeric or left blank';
lang_en['UIMessages.HR.CurrentSalaryRateOrBlank'] = 'Current salary pay rate must be numeric or left blank';
lang_en['UIMessages.HR.InvalidReportID'] = 'Invalid HR Report ID';
lang_en['UIMessages.HR.InvalidUserIDForReport'] = 'Invalid user ID for the HR report ID';
lang_en['UIMessages.HR.NewHourlyRateOrBlank'] = 'New hourly pay rate must be numeric or left blank';
lang_en['UIMessages.HR.NewSalaryRateOrBlank'] = 'New salary pay rate must be numeric or left blank';
lang_en['UIMessages.HR.ReportDateIsRequired'] = 'A valid report date is required.';
lang_en['UIMessages.HR.ReportReviewTypeRequired'] = 'The type of performance review is required.';
lang_en['UIMessages.HR.ReviewerNameRequired'] = 'Reviewer name is required.';
lang_en['UIMessages.HR.UserToReviewRequired'] = 'User to review is required.';
lang_en['UIMessages.Lists.AccessRestrictedToItems'] = 'Access to these items is restricted.';
lang_en['UIMessages.Lists.AcctCodeFeatureRequirement'] = 'The list item must be enabled for at least one feature.';
lang_en['UIMessages.Lists.AcctCodeInvalid'] = 'Account Code is invalid. Please try again.';
lang_en['UIMessages.Lists.AcctCodeRequired'] = 'A valid account code is required.';
lang_en['UIMessages.Lists.BusinessFaxOrBlank'] = 'Please enter a valid business fax or leave it blank.';
lang_en['UIMessages.Lists.BusinessPhoneOrBlank'] = 'Please enter a valid business phone or leave it blank.';
lang_en['UIMessages.Lists.ContactPhoneOrBlank'] = 'Please enter a valid contact phone or leave it blank.';
lang_en['UIMessages.Lists.CustomerProjectMismatch'] = 'The customer and project are not a valid combination.';
lang_en['UIMessages.Lists.CustomerProjectRequired'] = 'A valid customer and project combination is required.';
lang_en['UIMessages.Lists.CustomerRequired'] = 'A valid customer is required.';
lang_en['UIMessages.Lists.EventRequired'] = 'A valid event is required.';
lang_en['UIMessages.Lists.ItemAdded'] = 'Item added.';
lang_en['UIMessages.Lists.ItemArchived'] = 'Item archived.';
lang_en['UIMessages.Lists.ItemArchivedCount'] = 'Items archived: {1,number,#}';
lang_en['UIMessages.Lists.ItemDeleted'] = 'Item deleted.';
lang_en['UIMessages.Lists.ItemDeletedCount'] = 'Items deleted: {1,number,#}';
lang_en['UIMessages.Lists.ItemInaccessibleUserSettings'] = 'The following items are inaccessible based on user settings:';
lang_en['UIMessages.Lists.ItemNameRequired'] = 'Item name required.';
lang_en['UIMessages.Lists.ItemRateRange'] = 'Item rate must be between {1,number,#} and {2,number,#}.';
lang_en['UIMessages.Lists.ItemRateRangeMultiple'] = 'The rates for one or more items were not updated because they exceed the allowable range.';
lang_en['UIMessages.Lists.ItemRestored'] = 'Item restored.';
lang_en['UIMessages.Lists.ItemRestoredCount'] = 'Items restored: {1,number,#}';
lang_en['UIMessages.Lists.ItemUpdated'] = 'Item updated.';
lang_en['UIMessages.Lists.ItemsArchived'] = 'Items archived.';
lang_en['UIMessages.Lists.ItemsCouldNotArchive'] = 'The following items cannot be archived until all records referencing them have been closed or archived:';
lang_en['UIMessages.Lists.ItemsCouldNotDelete'] = 'The following items cannot be deleted because they have been used. Delete all entries referencing the item or archive the item instead:';
lang_en['UIMessages.Lists.ItemsCouldNotRestore'] = 'The following items could not be restored:';
lang_en['UIMessages.Lists.ItemsReadOnly'] = 'The following items are read-only and cannot be actioned:';
lang_en['UIMessages.Lists.ItemsReadOnlyCannotDelete'] = 'The follow items are read-only and cannot be deleted:';
lang_en['UIMessages.Lists.ListImportCancelled'] = 'Import canceled.';
lang_en['UIMessages.Lists.ListImportDoesNotExist'] = 'Import file does not exist.';
lang_en['UIMessages.Lists.ListImported'] = 'List imported!';
lang_en['UIMessages.Lists.MinTimeIncrementRequired'] = 'A valid minimum time increment is required.';
lang_en['UIMessages.Lists.NewItemLimitAtATime'] = 'A maximum of {1} items can be imported at once. Reduce the number of items to be imported and try again.';
lang_en['UIMessages.Lists.NewItemLimitPer24Hours'] = 'The account is currently limited to {1,number,#} new items per 24 hour period. Please wait and try again or contact support.';
lang_en['UIMessages.Lists.OneMoreUsersItemsInvalid'] = 'One or more users and/or items were not valid.';
lang_en['UIMessages.Lists.ProjectRequired'] = 'A valid project is required.';
lang_en['UIMessages.Lists.UserSelectionRequired'] = 'Please make a user selection.';
lang_en['UIMessages.Lists.VendorRequired'] = 'A valid vendor is required.';
lang_en['UIMessages.Memo.MemoCleared'] = 'Memo cleared';
lang_en['UIMessages.Memo.MemoSent'] = 'Memo sent';
lang_en['UIMessages.Memo.MemoUpdated'] = 'Memo updated';
lang_en['UIMessages.MessageBoard.Deleted'] = 'Post deleted';
lang_en['UIMessages.MessageBoard.Posted'] = 'Post updated';
lang_en['UIMessages.Note.NotUpdatedNotFound'] = 'Note could not be updated because it could not be found.';
lang_en['UIMessages.Note.NoteDeleted'] = 'Note deleted';
lang_en['UIMessages.Note.NoteNotFound'] = 'Note not found';
lang_en['UIMessages.Note.NoteSaved'] = 'Note saved';
lang_en['UIMessages.Note.NoteUpdated'] = 'Note updated';
lang_en['UIMessages.Payroll.AccrualsEnabledForFeature'] = 'Accruals must be enabled to use this feature.';
lang_en['UIMessages.Payroll.AccruedHoursNotUpdatedForX'] = 'The accrued hours for the following users could not be updated due to an error';
lang_en['UIMessages.Payroll.AccruedHoursUpdated'] = 'All users\' accrued hours have been updated.';
lang_en['UIMessages.Payroll.AlertsExist'] = 'Alerts exist for one or more users included in this payroll report.';
lang_en['UIMessages.Payroll.AlertsExistInfo'] = 'The payroll period report cannot be saved until the alerts are resolved or users with pending alerts are removed from the report.';
lang_en['UIMessages.Payroll.ArchivedUserReactivated'] = 'There was one archived user reactivated.';
lang_en['UIMessages.Payroll.ArchivedUsersCountReactivated'] = 'There were {1} archived users reactivated.';
lang_en['UIMessages.Payroll.CannotBeEmailedNoAddressOnFile'] = 'The payroll cannot be emailed because there is no recipient email address on file.';
lang_en['UIMessages.Payroll.ContainedInMoreRecentForX'] = 'The payroll report could not be generated because the following users are contained in a more recent closed payroll period';
lang_en['UIMessages.Payroll.ContainedInMoreRecentInfo'] = 'Changing a user\'s time in this period could affect their overtime in a more recent period. In order to add this time to the payroll report you will need to delete the user(s) from any more recent payroll period reports and then try again.';
lang_en['UIMessages.Payroll.DuplicateDeleted'] = 'Duplicate payroll deleted';
lang_en['UIMessages.Payroll.ErrorGeneratingPayrollFile'] = 'There was an error generating the payroll file. Please try again or contact support for further assistance.';
lang_en['UIMessages.Payroll.ExceedMaxContinuousWorked'] = 'This payroll report contains records that exceed the threshold of {1} for maximum continuous hours worked.';
lang_en['UIMessages.Payroll.ExceedMaxContinuousWorkedInfo'] = 'There are time records in this payroll report that exceed the maximum continuous hours worked. These records are indicated in the details below in RED text.';
lang_en['UIMessages.Payroll.IncompatibleTimeZonesForX'] = 'The payroll report could not be generated due to incompatible time zone records for';
lang_en['UIMessages.Payroll.InvalidAccrualSettingsForX'] = 'The payroll report could not be generated because the following users have invalid or incomplete accrual settings';
lang_en['UIMessages.Payroll.InvalidTryAgain'] = 'The selected payroll period report is invalid. Please try again or contact support.';
lang_en['UIMessages.Payroll.LockedTryAgain'] = 'The report is locked and cannot be processed. Please try again or contact support.';
lang_en['UIMessages.Payroll.MultipleTimeZonesForX'] = 'The payroll report could not be generated because the following users have records in multiple time zones for the payroll period';
lang_en['UIMessages.Payroll.NoPayrollDataForUsers'] = 'There is no data for any of your assigned users in this payroll period.';
lang_en['UIMessages.Payroll.NoTimeRecordsTryAgain'] = 'The report does not include any time records and cannot be processed. Please try again or contact support.';
lang_en['UIMessages.Payroll.NoUnpaidRecordsExist'] = 'No unpaid records exist for those dates.';
lang_en['UIMessages.Payroll.NotGeneratedAlertsExistFor'] = 'The payroll report could not be generated because alerts exist for the following users:';
lang_en['UIMessages.Payroll.OverlappingDates'] = 'The payroll period dates overlap an existing closed payroll period.';
lang_en['UIMessages.Payroll.OverlappingDatesInfo'] = 'Overlapping payroll period reports can result in incorrect overtime calculations for the users listed above. You may want to review past payroll period reports to make sure users are only listed in a single report for any given payroll period.';
lang_en['UIMessages.Payroll.OverlappingRecords'] = 'The payroll period report contains overlapping timestamps for one or more users.';
lang_en['UIMessages.Payroll.OverlappingRecordsForX'] = 'The payroll period report contains overlapping timestamps for';
lang_en['UIMessages.Payroll.OverlappingRecordsInfo'] = 'Use the Overlapping Records report to see the records causing the problem. For additional help please contact customer support.';
lang_en['UIMessages.Payroll.PayrollRunTypeIsRequired'] = 'A payroll run type must be specified with valid corresponding dates.';
lang_en['UIMessages.Payroll.PendingAlertsIncluded'] = 'The report includes records with pending alerts and cannot be processed.';
lang_en['UIMessages.Payroll.RecordsChangedTryAgain'] = 'One or more records have changed. Please try the action again.';
lang_en['UIMessages.Payroll.Reopened'] = 'Payroll period reopened';
lang_en['UIMessages.Payroll.ReopenedForSelected'] = 'Payroll period reopened for the selected user';
lang_en['UIMessages.Payroll.ReportExpiredTryAgain'] = 'The report has expired. Please try again.';
lang_en['UIMessages.Payroll.SelectUserToDelete'] = 'You must select a user\'s payroll report to delete.';
lang_en['UIMessages.Payroll.SplitTimeRecordsExist'] = 'This payroll period report contains split time records for one or more users.';
lang_en['UIMessages.Payroll.SplitTimeRecordsExistInfo'] = 'This payroll period report contains split time records for one or more users. A split time record crosses the day start threshhold, and can be a mistake when users forget to clock out. If a record is split on the last day of the payroll period, the record will be marked Split Paid and exist partially in two separate reports. Split records are highlighted in the details below in RED text.';
lang_en['UIMessages.Payroll.UnpaidRecordsExist'] = 'Unpaid time records exist before your selected payroll period start date for one or more users.';
lang_en['UIMessages.Payroll.UnpaidRecordsExistInfo'] = 'There are open, and possibly unpaid, time records prior to the report start date. All records should be closed to prevent not paying a user. To see open time records, run a pre-payroll report for dates prior to the payroll period start date.';
lang_en['UIMessages.Payroll.UserAlreadyIncluded'] = 'A user cannot be added to a payroll report in which they are already included. Please change payroll parameters or contact support.';
lang_en['UIMessages.Payroll.UsersAlreadyIncluded'] = 'One or more users are already included in the payroll period report you are trying to modify.';
lang_en['UIMessages.Payroll.UsersAlreadyIncludedInfo'] = 'To adjust a user\'s hours, you must first delete them from the existing report and then try again.';
lang_en['UIMessages.Payroll.UsersCannotDelete'] = 'The selected users to delete were not valid for this report. Please try again.';
lang_en['UIMessages.Payroll.UsersCannotDeleteMoreRecent'] = 'The payroll report cannot be deleted because it includes users who are included in a more recent payroll report. Delete the users from the more recent reports and try again or contact support.';
lang_en['UIMessages.Payroll.ValidationErrorTryAgain'] = 'There was a validation error. Please try again or contact support.';
lang_en['UIMessages.QBO.APICallFailed'] = 'QBO API call failed';
lang_en['UIMessages.QBO.APICallSuccessful'] = 'QBO API call successful';
lang_en['UIMessages.QBO.AccountInvalid'] = 'The account is not valid for QuickBooks Online export.';
lang_en['UIMessages.QBO.AuthorizationIncomplete'] = 'Authorization with QuickBooks Online could not be completed.';
lang_en['UIMessages.QBO.DisconnectSuccessful'] = 'Disconnect from QuickBooks Online successful.';
lang_en['UIMessages.QBO.ErrorPleaseSignInAgain'] = 'There was an error. Please sign in again.';
lang_en['UIMessages.QBO.ExportDeleted'] = 'Export deleted';
lang_en['UIMessages.QBO.ExportIDInvalid'] = 'Export ID not valid. Please try again.';
lang_en['UIMessages.QBO.ExportInvalid'] = 'The export is not valid.';
lang_en['UIMessages.QBO.ExportRestarted'] = 'Export restarted';
lang_en['UIMessages.QBO.ExportStillPending'] = 'The export is still pending.';
lang_en['UIMessages.QBO.FailedRecordsDeleted'] = 'Failed records deleted';
lang_en['UIMessages.QBO.NotConfigured'] = 'The account is not configured for authorization with QuickBooks Online.';
lang_en['UIMessages.QBO.NotConnected'] = 'Account has not been connected to QuickBooks Online yet.';
lang_en['UIMessages.QBO.OperationCompleteCount'] = 'Operation complete. Added {1} items. Failed to add {2} items.';
lang_en['UIMessages.QBO.QBOSubmitted'] = 'The records have been submitted for export into QuickBooks Online. You will be notified when the process is complete.';
lang_en['UIMessages.QBO.RecordsAlreadyExported'] = 'One or more records marked for export have already been exported.';
lang_en['UIMessages.QBO.TokenRefresh'] = 'Token refresh successful';
lang_en['UIMessages.QBO.YouAreNowSignedIn'] = 'You are now signed into your QuickBooks Online account.';
lang_en['UIMessages.Record.ArchivedCannotBeEdited'] = 'The record has been archived and cannot be edited.';
lang_en['UIMessages.Record.ChangedFromBillableToUnbillable'] = 'The record was changed from billable to unbillable.';
lang_en['UIMessages.Record.ChangedFromUnbillableToBillable'] = 'The record was changed from unbillable to billable.';
lang_en['UIMessages.Record.ReceiptCannotBeGenerated'] = 'The receipt cannot be generated. Please call support for assistance.';
lang_en['UIMessages.Record.RecordCannotBeApprovedOrDenied'] = 'The record cannot be approved or denied.';
lang_en['UIMessages.Record.RecordMustBeEditedByAdminToClearAlert'] = 'The record must be edited by an administrator to clear the alert.';
lang_en['UIMessages.Record.RecordMustBeEditedToClearAlert'] = 'The record must be edited to clear the alert.';
lang_en['UIMessages.Record.RoundedToNearest'] = 'Rounded up to the nearest {1} minutes.';
lang_en['UIMessages.Record.SavedWithHrs'] = 'Record saved with {1} hours.';
lang_en['UIMessages.Record.SavedWithHrsOnDate'] = 'Saved with {1} hours on {2}.';
lang_en['UIMessages.Record.SingleSubmittedForSuperApproval'] = 'Submitted for supervisor approval';
lang_en['UIMessages.Record.Updated'] = 'Record updated';
lang_en['UIMessages.Records.Added'] = 'Record added';
lang_en['UIMessages.Records.AddedMany'] = 'Records added';
lang_en['UIMessages.Records.AddedReceiptError'] = 'Record saved but there was an error saving the receipt. Try editing the record and adding the receipt again. Accepted file types are JPG and PDF.';
lang_en['UIMessages.Records.AlertAllowed'] = 'Request(s) allowed';
lang_en['UIMessages.Records.AlertDeleted'] = 'Request(s) deleted';
lang_en['UIMessages.Records.AlertDeniedDeleted'] = 'Request(s) denied and deleted';
lang_en['UIMessages.Records.AlreadyApprovedForCriteria'] = 'All records for that criteria are already approved.';
lang_en['UIMessages.Records.AmountMileageRequired'] = 'A currency or mileage amount is required.';
lang_en['UIMessages.Records.AmountRange'] = 'The amount was out of valid range.';
lang_en['UIMessages.Records.ApprovedMany'] = 'Records approved';
lang_en['UIMessages.Records.Archived'] = 'Record archived';
lang_en['UIMessages.Records.ArchivedCannotBeEdited'] = 'The record is archived and cannot be edited.';
lang_en['UIMessages.Records.ArchivedMany'] = 'Records archived';
lang_en['UIMessages.Records.BillRateRange'] = 'Bill rate must be between {1} and {2}';
lang_en['UIMessages.Records.BillableStatusRequired'] = 'Billable status required';
lang_en['UIMessages.Records.BreakStartBetweenStartEnd'] = 'The break start time must be between the record start and end time.';
lang_en['UIMessages.Records.CannotBeEditedArchivedUser'] = 'Records of archived users cannot be modified.';
lang_en['UIMessages.Records.CopiedSubsequentWeek'] = 'Records copied to the subsequent week. Note the view is now the subsequent week.';
lang_en['UIMessages.Records.CostRateRange'] = 'Cost rate must be between {1} and {2}';
lang_en['UIMessages.Records.CouldNotBeReassigned'] = 'The records could not be reassigned.';
lang_en['UIMessages.Records.CreatedBySuperAdminCannotEdit'] = 'Records created by supervisors or administrators cannot be edited.';
lang_en['UIMessages.Records.Deleted'] = 'Record deleted';
lang_en['UIMessages.Records.DeletedCount'] = 'Record deleted: {1}';
lang_en['UIMessages.Records.DeletedMany'] = 'Records deleted';
lang_en['UIMessages.Records.Edited'] = 'Record edited';
lang_en['UIMessages.Records.EditedMany'] = 'Records edited';
lang_en['UIMessages.Records.ErrorAddingTimeFollowingUsers'] = 'There was an error adding time for the following users:';
lang_en['UIMessages.Records.ErrorDeleting'] = 'There was an error deleting the record.';
lang_en['UIMessages.Records.ErrorProcessingExpense'] = 'There was an error attempting to process selected expense records.';
lang_en['UIMessages.Records.ExpenseEntryCutoffAllFuture'] = 'Expense entry is restricted for all future dates.';
lang_en['UIMessages.Records.ExpenseEntryCutoffDatesAfter'] = 'Expense entry has been restricted to dates after {1}.';
lang_en['UIMessages.Records.ExpenseEntryCutoffDatesBetween'] = 'Expense entry has been restricted to dates between {1} and now.';
lang_en['UIMessages.Records.IndicatorsForModifiedRecordsCleared'] = 'Indicators for recently modified records have been cleared.';
lang_en['UIMessages.Records.LimitedToXHoursPerDay'] = 'This account is limited to {1} hours per 24 hour work period.';
lang_en['UIMessages.Records.MarkedBillable'] = 'Records marked billable';
lang_en['UIMessages.Records.MarkedUnbillable'] = 'Records marked unbillable';
lang_en['UIMessages.Records.MileageMultiplierRange'] = 'The mileage multiplier range must between 0.00 and 99.99.';
lang_en['UIMessages.Records.MileageRange'] = 'Mileage value must be between 0 and 65000.';
lang_en['UIMessages.Records.NoLongerValidAcctCode'] = 'This account code is no longer a valid selection for creating new records.';
lang_en['UIMessages.Records.NoLongerValidCustomer'] = 'This customer is no longer a valid selection for creating new records.';
lang_en['UIMessages.Records.NoLongerValidProject'] = 'This project is no longer a valid selection for creating new records.';
lang_en['UIMessages.Records.NonStandardCannotDelete'] = 'Only Standard and Unpayable/Salaried time can be deleted. Please revisit your Time Classifications selections to continue.';
lang_en['UIMessages.Records.NoneForCriteria'] = 'No records for that criteria';
lang_en['UIMessages.Records.NoneSelectedInReport'] = 'None of the records selected were in the report.';
lang_en['UIMessages.Records.NotFound'] = 'Record not found.';
lang_en['UIMessages.Records.NotUpdatedForX'] = 'One or more records for {1} could not be updated.';
lang_en['UIMessages.Records.NotUpdatedForXAlerts'] = 'Records for {1} could not be updated because they are on the clock or have pending alerts.';
lang_en['UIMessages.Records.NotificationSentApprovedTime'] = 'A notification was sent to {1} that you have approved time.';
lang_en['UIMessages.Records.NotificationSentArchivedTime'] = 'A notification was sent to {1} that you have archived time.';
lang_en['UIMessages.Records.NotificationSentClosedPayroll'] = 'A notification was sent to {1} that you have closed a payroll period.';
lang_en['UIMessages.Records.NotificationSentOpenedExpense'] = 'A notification was sent to {1} that you have opened expenses.';
lang_en['UIMessages.Records.NotificationSentReconciledExpense'] = 'A notification was sent to {1} that you have reconciled expenses.';
lang_en['UIMessages.Records.NotificationSentTimesheetReady'] = 'A notification was sent to {1} that your timesheet is ready for review.';
lang_en['UIMessages.Records.NumericIDRequired'] = 'A numeric record ID is required';
lang_en['UIMessages.Records.OnlyOpenCanBeDeleted'] = 'Only open records can be deleted';
lang_en['UIMessages.Records.Opened'] = 'Records opened';
lang_en['UIMessages.Records.OpenedMany'] = 'Records opened';
lang_en['UIMessages.Records.PaidCannotDelete'] = 'Paid records cannot be deleted.';
lang_en['UIMessages.Records.PaidCannotEdit'] = 'Paid records cannot be edited.';
lang_en['UIMessages.Records.PendingCannotModify'] = 'The record cannot be modified while it is pending approval.';
lang_en['UIMessages.Records.ReassignedRenameArchiveItem'] = 'The records have been reassigned. You may now re-name or archive this item.';
lang_en['UIMessages.Records.Reconciled'] = 'Record reconciled';
lang_en['UIMessages.Records.ReconciledMany'] = 'Records reconciled';
lang_en['UIMessages.Records.RecordNotAvailable'] = 'The record you were attempting to edit is no longer available because its status has been changed.';
lang_en['UIMessages.Records.SplitEndTimeBetweenStartEnd'] = 'The split end time must be between the original start and end time.';
lang_en['UIMessages.Records.SplitStartTimeBetweenStartEnd'] = 'The split start time must be between the original start and end time.';
lang_en['UIMessages.Records.SplitStartTimeEarlier'] = 'The split start time must be earlier than the split end time.';
lang_en['UIMessages.Records.StandardHoursLimit'] = 'Standard time records must be less than 99.99 hours.';
lang_en['UIMessages.Records.SubmittedForSuperApproval'] = 'Record(s) submitted for supervisor approval';
lang_en['UIMessages.Records.TimEntryCutoffDatesAfter.Hourly'] = 'Record entry is restricted to dates after {1} for Standard Hourly or Unpayable/Salaried time.';
lang_en['UIMessages.Records.TimeClassificationRequired'] = 'A valid time classification is required.';
lang_en['UIMessages.Records.TimeEntryCutoffAllFuture'] = 'Time entry is restricted for all future dates.';
lang_en['UIMessages.Records.TimeEntryCutoffDatesAfter'] = 'Time entry has been restricted to dates after {1}.';
lang_en['UIMessages.Records.TimeEntryCutoffDatesAfter.Hourly'] = 'Time entry has been restricted to dates after {1}.';
lang_en['UIMessages.Records.TimeEntryCutoffDatesBetween'] = 'Time entry has been restricted to dates between {1} and now.';
lang_en['UIMessages.Records.TimeEntryCutoffDatesBetween.Hourly'] = 'Record entry is restricted to dates between now and {1}.';
lang_en['UIMessages.Records.TimeOffLimitedTo24Hours'] = 'Time off entries are limited to a maximum of 24 hours per day.';
lang_en['UIMessages.Records.TimeRoundedToMinInc'] = 'The time has been rounded up to the next {1} minute interval.';
lang_en['UIMessages.Records.UnapprovedMany'] = 'Records unapproved';
lang_en['UIMessages.Records.Unarchived'] = 'Record unarchived';
lang_en['UIMessages.Records.UnarchivedMany'] = 'Records unarchived';
lang_en['UIMessages.Records.UpdatedError'] = 'One or more of the records could not be updated.';
lang_en['UIMessages.Records.XRecordsSigned'] = '{1} record(s) have been signed';
lang_en['UIMessages.Records.XofYAlteredWhenCopied'] = 'There were {1} of {2} records altered when copied because of invalid permissions.';
lang_en['UIMessages.Records.XofYNotCopiedInvalidPermissions'] = 'There were {1} of {2} records not copied because of invalid permissions.';
lang_en['UIMessages.Reports.AlertsExist'] = 'The report could not be generated due to existing alerts for the following users:';
lang_en['UIMessages.Reports.AlreadyApproved'] = 'All records in the report are already approved.';
lang_en['UIMessages.Reports.ApprovedStatusRequired'] = 'Record approved status selection required.';
lang_en['UIMessages.Reports.BillableStatusRequired'] = 'Billable status selection required.';
lang_en['UIMessages.Reports.CannotRunAlertsExist'] = 'The report cannot be run because it includes records with pending alerts.';
lang_en['UIMessages.Reports.GroupByLimitations'] = 'That download only allows group by options for Customer or Project or Account Code.';
lang_en['UIMessages.Reports.GroupByRequired'] = 'Group by selection required.';
lang_en['UIMessages.Reports.InvalidBaseLocationCoords'] = 'Invalid base location coordinates.';
lang_en['UIMessages.Reports.InvalidShiftRestrictionRange'] = 'The shift restriction is not a valid range.';
lang_en['UIMessages.Reports.MaxHoursThreshold'] = 'The maximum hours threshold must be a valid number between 0.01 and 9999.99.';
lang_en['UIMessages.Reports.MaximumDistanceValue'] = 'Please enter a valid value for maixumum distance.';
lang_en['UIMessages.Reports.NoRecordsInPeriod'] = 'No records in the report period.';
lang_en['UIMessages.Reports.OneOrMoreAcctCodesRequired'] = 'One or more account codes must be included in the report.';
lang_en['UIMessages.Reports.OneOrMoreCustomersRequired'] = 'One or more customers must be included in the report.';
lang_en['UIMessages.Reports.OneOrMoreEventsRequired'] = 'One or more events must be included in the report.';
lang_en['UIMessages.Reports.OneOrMoreProjectsRequired'] = 'One or more projects must be included in the report.';
lang_en['UIMessages.Reports.OneOrMoreUsersRequired'] = 'One or more users must be included in the report.';
lang_en['UIMessages.Reports.OneOrMoreUsersRequiredNotSelf'] = 'One or more users must be included in the report. You do not have permission to include yourself.';
lang_en['UIMessages.Reports.OneOrMoreVendorsRequired'] = 'One or more vendors must be included in the report.';
lang_en['UIMessages.Reports.ParametersUnavailable'] = 'A report for those parameters is currently unavailable. Please contact support.';
lang_en['UIMessages.Reports.ReportTimedOut'] = 'The report has timed out. Generate the report again.';
lang_en['UIMessages.Reports.SignedStatusRequired'] = 'Record signed status selection required.';
lang_en['UIMessages.Reports.StatusRequired'] = 'Record status selection required.';
lang_en['UIMessages.Reports.TimeClassRequired'] = 'Time classification selection required.';
lang_en['UIMessages.Reports.ValidGracePeriod'] = 'Please enter a valid grace period.';
lang_en['UIMessages.Reports.ValidTypeRequired'] = 'A valid report type is required.';
lang_en['UIMessages.Settings.CannotDisableLoginForOwnAccount'] = 'You cannot disable your own account.';
lang_en['UIMessages.Settings.CompanyWeekStartInvalid'] = 'The company week start value was not valid.';
lang_en['UIMessages.Settings.CustomBrandNotEnabled'] = 'A custom brand logo is not enabled for this account.';
lang_en['UIMessages.Settings.ExpenseDisabledError'] = 'Expense tracking cannot be disabled while there are open expenses.';
lang_en['UIMessages.Settings.HourlyDisabledError'] = 'Hourly time tracking cannot be disabled while there are users on the clock or open alerts or unpaid hours.';
lang_en['UIMessages.Settings.LoginStatusForPrimaryUserCannotBeChanged'] = 'Login status for the primary user account cannot be changed.';
lang_en['UIMessages.Settings.MaxHoursPerDayMoreThanZero'] = 'The max allowed hours per day must be greater than zero.';
lang_en['UIMessages.Settings.MileageMultiplierOrBlank'] = 'Mileage multiplier must be between 0.001 and 99.99 or left blank.';
lang_en['UIMessages.Settings.MinTimeIncRequired'] = 'A valid minimum time increment is required';
lang_en['UIMessages.Settings.MinWeeklyOTRange'] = 'Minimum weekly overtime hours must be greater than 1.';
lang_en['UIMessages.Settings.MonthlyPayrollFirstDaySetting'] = 'For monthly payroll periods the first day must be between 1 and 28.';
lang_en['UIMessages.Settings.OTCutoffInvalid'] = 'The values for overtime cut-off are not valid.';
lang_en['UIMessages.Settings.PayrollPeriodStartDateRequired'] = 'A payroll period start date is required for that setting.';
lang_en['UIMessages.Settings.PhotoSnapshot'] = 'If you do not see video, either you don\'t have a webcam installed, or you need to allow the browser to use your webcam.';
lang_en['UIMessages.Settings.PhotoSnapshotInfo'] = 'If your webcam isn\'t working properly, the Take Photo button will record a blank image. You should inform your supervisor if you are having difficulty.';
lang_en['UIMessages.Settings.ProjectDisabledError'] = 'Project time tracking cannot be disabled while there are open project timers or alerts or open hours.';
lang_en['UIMessages.Settings.TwiceMonthlyPayrollFirstDaySetting'] = 'For twice monthly payroll periods, the first day must be between 1 and 13.';
lang_en['UIMessages.Settings.ValidDailyOT'] = 'Please enter valid values for daily overtime. Daily 1.5x hours should be less than daily 2.0x hours which should be less than weekly 1.5x hours.';
lang_en['UIMessages.Settings.WeeklyOTValue'] = 'The value for weekly overtime must be a whole number between 2 and 999.';
lang_en['UIMessages.StatusEmail.CannotAddSelf'] = 'If you want to receive this status report visit \'My Account Settings\' and update your personal account settings.';
lang_en['UIMessages.StatusEmail.DeliveryFrequencyInvalid'] = 'The delivery frequency was not valid.';
lang_en['UIMessages.StatusEmail.DistroListForForOutsideUseOnly'] = 'Use this distribution list to add users outside your organization who do not have a Timesheets.com account.';
lang_en['UIMessages.StatusEmail.EmailAdded'] = 'Email address added';
lang_en['UIMessages.StatusEmail.EmailAddressAlreadyExists'] = 'The email address already belongs to a user account or is already receiving the status email.';
lang_en['UIMessages.StatusEmail.EmailDeleted'] = 'Email address deleted';
lang_en['UIMessages.StatusEmail.Sent'] = 'Status email sent';
lang_en['UIMessages.StatusEmail.ValidEmailRequired'] = 'A valid email address is required';
lang_en['UIMessages.Sys.AccountExpired'] = 'Your account has expired.';
lang_en['UIMessages.Sys.AccountExpiresOneDay'] = 'Your account expires in one day.';
lang_en['UIMessages.Sys.AccountExpiresToday'] = 'Your account expires today.';
lang_en['UIMessages.Sys.AccountExpiresXDays'] = 'Your account expires in {1} days.';
lang_en['UIMessages.Sys.AccountUnableToChargeCo'] = 'We were recently unable to charge your organization for our service. Please contact us for assistance.';
lang_en['UIMessages.Sys.AccountUnableToChargeYou'] = 'We were recently unable to charge your credit card.';
lang_en['UIMessages.Sys.AccountUpdatePayment'] = 'Please update your payment information.';
lang_en['UIMessages.Sys.AccountWasCancelled'] = 'Your account has been canceled. Thank you for your business.';
lang_en['UIMessages.Sys.ErrorProcessingPaymentCallCustomerService'] = 'Please try again or call customer service for assistance in completing your transaction.';
lang_en['UIMessages.Sys.ErrorProcessingPaymentInfo'] = 'There was an error processing your payment information.';
lang_en['UIMessages.Sys.Item.ItemIsArchivedCannotBeEdited'] = 'This item is archived and cannot be modified.';
lang_en['UIMessages.Sys.Item.ProjectDescriptionsAreViewable'] = 'Project descriptions are viewable to all users when entering or editing time. This can be modifed in \'Company Settings\'.';
lang_en['UIMessages.Sys.NoPermissionToViewUserAccount'] = 'No permission to view this user account.';
lang_en['UIMessages.Sys.SandboxDisabled'] = 'Sandbox accounts disabled on this server.';
lang_en['UIMessages.Sys.SessionExpired'] = 'Your session has expired. Login and try your action again.';
lang_en['UIMessages.Sys.SessionInvalidated'] = 'Your session has been invalidated. Login and try your action again.';
lang_en['UIMessages.Sys.SessionLoggedOut'] = 'You have been logged out.';
lang_en['UIMessages.Sys.SessionLoggedOutOnError'] = 'A potential error was detected and you were logged out. Please log back in.';
lang_en['UIMessages.Sys.SessionUnknownLogout'] = 'An unexpected error occurred. You have been logged out.';
lang_en['UIMessages.Sys.TrialExpiresOneDay'] = 'Your trial account expires in one day.';
lang_en['UIMessages.Sys.TrialExpiresToday'] = 'Your trial account expires today.';
lang_en['UIMessages.Sys.TrialExpiresXDays'] = 'Your trial account expires in {1} days.';
lang_en['UIMessages.Sys.UnsubscribedFromNotification'] = 'You have been unsubscribed from the notification effective immediately.';
lang_en['UIMessages.Sys.UserAccountInactiveCannotBeViewd'] = 'This user account is inactive and cannot be viewed.';
lang_en['UIMessages.Sys.UserProfileMissingEmail'] = 'This user profile is missing an email address. An email address is required for login. Add an email address {1}here{2}.';
lang_en['UIMessages.Sys.UserProfileMissingEmailNoPerm'] = 'This user profile is missing an email address. Have them add one or alert your account administrator to do so on the user\'s behalf.';
lang_en['UIMessages.Sys.ViewNotEnabledForUser'] = 'This view is not enabled for this user.';
lang_en['UIMessages.Sys.Warning.SchedulePermissionsCorrupt'] = 'WARNING: This user\'s schedule permissions have become corrupt. Please reset them or contact us for assistance.';
lang_en['UIMessages.Sys.Warning.UsersMissingEmailAddress'] = 'Users with an {1} are missing an email address. An email address will soon be required for login.';
lang_en['UIMessages.Sys.YourProfileMissingEmail'] = 'Your profile is missing an email address. An email address will soon be required for login. Add your email address {1} here {2}.';
lang_en['UIMessages.Timer.AllOtherRunningPaused'] = 'All other running timers were paused.';
lang_en['UIMessages.Timer.AllRestarted'] = 'All paused timers restarted.';
lang_en['UIMessages.Timer.ComboExists'] = 'A timer for those parameters already exists.';
lang_en['UIMessages.Timer.InvalidLocationCannotStart'] = 'A timer action cannot be performed from an invalid location.';
lang_en['UIMessages.Timer.InvalidParameters'] = 'Invalid timer parameters';
lang_en['UIMessages.Timer.NoRunning'] = 'No running timers';
lang_en['UIMessages.Timer.OneRunningTimerLimit'] = 'Only one timer may be running at a time';
lang_en['UIMessages.Timer.Paused'] = 'Timer paused';
lang_en['UIMessages.Timer.PausedCount'] = 'Timers paused: {1}';
lang_en['UIMessages.Timer.PresetCreated'] = 'Preset created';
lang_en['UIMessages.Timer.Restarted'] = 'Timer restarted';
lang_en['UIMessages.Timer.Started'] = 'Timer started';
lang_en['UIMessages.Timer.StoppingError'] = 'Error stopping timer {1} {2} {3}';
lang_en['UIMessages.Timer.TimerAlreadyRunning'] = 'Timer is already running.';
lang_en['UIMessages.User.AnnualReviewDateInvalid'] = 'The month and day for the Annual Performance Review Date is invalid.';
lang_en['UIMessages.User.Archived'] = 'User archived';
lang_en['UIMessages.User.ArchivedMany'] = 'Users archived';
lang_en['UIMessages.User.CannotArchiveOnTheClock'] = 'The user is currently on the clock. The user must be clocked out before their account can be archived.';
lang_en['UIMessages.User.CannotArchiveOpenExpenses'] = 'This user has open expenses. The records must be reconciled or deleted before the user account can be archived.';
lang_en['UIMessages.User.CannotArchiveOpenTimers'] = 'The user currently has one or more open project timers. The timers must be stopped before the user account can be archived.';
lang_en['UIMessages.User.CannotArchiveOwn'] = 'Own account cannot be archived.';
lang_en['UIMessages.User.CannotArchivePrimary'] = 'The primary account administrator and cannot be archived.';
lang_en['UIMessages.User.CannotArchiveUnpaidTime'] = 'This user has unpaid hours on their timesheet. The records must be closed in a final payroll report or deleted before the user account can be archived.';
lang_en['UIMessages.User.CannotBeArchivedCriteriaNotMet'] = 'The user cannot be archived because all of the criteria is not met.';
lang_en['UIMessages.User.ChangingLoginStatusExceedsLicenses'] = 'Login status cannot be changed because it will exceed the number of available user licenses.';
lang_en['UIMessages.User.CredentialTypeRequired'] = 'The type of credential to generate is required.';
lang_en['UIMessages.User.CredentialsGenerated'] = 'Credentials generated successfully.';
lang_en['UIMessages.User.DemoteSuperEmpFirst'] = 'To demote an administrator user to a supervisor user they must be first be set as an employee then as a supervisor.';
lang_en['UIMessages.User.ESignNotEnabled'] = 'User signature is not enabled for your account.';
lang_en['UIMessages.User.EmailAddressRequiredAccessLevel'] = 'An email address is required for this access level.';
lang_en['UIMessages.User.ErrorActivating'] = 'There was an error activating the user. Please contact support.';
lang_en['UIMessages.User.ExpenseDisabledError'] = 'Expense Tracking cannot be disabled for this user because they have open records.';
lang_en['UIMessages.User.FileImported'] = 'Users imported!';
lang_en['UIMessages.User.FileValidated'] = 'File validated and can be imported.';
lang_en['UIMessages.User.HourlyDisabledError'] = 'Hourly Time Tracking cannot be disabled for this user because they are clocked in or have unpaid time.';
lang_en['UIMessages.User.IDNumberDeptRequired'] = 'A four digit employee number and a valid department are required.';
lang_en['UIMessages.User.ImageError.ImageTooSmall'] = 'The image was too small. Please crop a larger image.';
lang_en['UIMessages.User.ImageErrorJPEGGIFOnly'] = 'There was an error uploading the image. Only JPEG and GIF file types are accepted.';
lang_en['UIMessages.User.Invalid'] = 'Invalid user';
lang_en['UIMessages.User.LoginDisabled'] = 'User login disabled';
lang_en['UIMessages.User.LoginEnabled'] = 'User login enabled';
lang_en['UIMessages.User.NewUserCreated'] = 'New user account created successfully! You may configure optional user settings below or add another user.';
lang_en['UIMessages.User.NewUserCreated.LoginInfoNotSent'] = 'You can initiate a welcome email with login instructions for this user at your convenience.';
lang_en['UIMessages.User.NewUserCreated.LoginInfoSent'] = 'This user has been sent a welcome email with login instructions.';
lang_en['UIMessages.User.NoPermissionToRecordToCustomerProject'] = 'This user does not have permissions to record time to the selected customer and project.';
lang_en['UIMessages.User.NoPermissionToRecordToProject'] = 'This user does not have permissions to record time to the selected project.';
lang_en['UIMessages.User.PayTypeRequired'] = 'Pay type required';
lang_en['UIMessages.User.PrimaryAdminGrantedAllPermissions'] = 'As the primary administrator this account has all permission settings enabled.';
lang_en['UIMessages.User.ProjectDisabledError'] = 'Project Time Tracking cannot be disabled for this user because they have an open timer or open records.';
lang_en['UIMessages.User.RateRangeHourly'] = 'The hourly rate must be between 0.00 and 99999.99 or left blank.';
lang_en['UIMessages.User.RateRangeSalary'] = 'The salaried rate must be between 0.00 and 999999.99 or left blank';
lang_en['UIMessages.User.Reactivated'] = 'User reactivated';
lang_en['UIMessages.User.SuperCannotReportToSubordinate'] = 'A supervisor cannot report to one of their subordinates.';
lang_en['UIMessages.User.SuperLessPermissionsError'] = 'This supervisor cannot report to the selected supervisor because the selected supervisor has more restrictive access permissions than this supervisor. Please try again or contact support.';
lang_en['UIMessages.User.SuperPermissionCorrupt'] = 'Supervisor permissions are corrupt and need to be repaired. Please contact customer support.';
lang_en['UIMessages.User.SuperPermissionError'] = 'The supervisor could not be assigned because of a permissions error. Please contact customer support.';
lang_en['UIMessages.User.SuperUpdateExceptions'] = 'The supervisor settings have been updated with exceptions.';
lang_en['UIMessages.User.SupervisorRequired'] = 'You must select a supervisor for this user to Report To.';
lang_en['UIMessages.User.TimeZoneNotUpdatedForX'] = 'The time zone for {1} could not be updated.';
lang_en['UIMessages.User.UserAlreadyArchived'] = 'User is already archived.';
lang_en['UIMessages.User.UserTypeChangeAccrualChange'] = 'The user\'s accrual rate was changed as a requirement of updating the pay type. Please review the accrual rate for correctness.';
lang_en['UIMessages.User.UserUpdatedWithErrors'] = 'The user settings have been updated with the following errors:';
lang_en['UIMessages.Validation.AccrualImport.FileImported'] = 'File imported.';
lang_en['UIMessages.Validation.AccrualImport.RowMissingColumns'] = 'A row with UserID {1,number,#} was missing columns.';
lang_en['UIMessages.Validation.AccrualImport.TimeOffMismatch'] = 'The column header "{1}" does not match an existing time off category.';
lang_en['UIMessages.Validation.AccrualImport.UserHourlyNotEnabled'] = 'UserID {1,number,#} on line {2,number,#} does not have hourly time tracking enabled.';
lang_en['UIMessages.Validation.AccrualImport.UserInactive'] = 'UserID {1,number,#} on line {2,number,#} is not curently active.';
lang_en['UIMessages.Validation.AccrualImport.UserNotValid'] = 'UserID {1,number,#} on line {2,number,#} was not a valid user.';
lang_en['UIMessages.Validation.ActionWillExceedUserLicenses'] = 'This action will exceed the number of available user licenses.';
lang_en['UIMessages.Validation.ActionWillExceedUserLicensesAdminInstruction'] = 'To add new users deactivate existing users or contact the primary administrator about adding more licenses to the account.';
lang_en['UIMessages.Validation.ActionWillExceedUserLicensesPrimaryAdminInstruction'] = 'To add new users deactivate existing users or add more licenses to the account.';
lang_en['UIMessages.Validation.ActionWillExceedUserLicensesSupervisorInstruction'] = 'Contact the account administrator about freeing or adding additional licenses.';
lang_en['UIMessages.Validation.AgreeToTermsOfUse'] = 'You must agree to the \'Terms of Use\' agreement.';
lang_en['UIMessages.Validation.AgreeToTermsOfUseWithLink'] = 'You must agree to the <a href="{1}" target="_blank" rel="noopener">\'Terms of Use\'</a>.';
lang_en['UIMessages.Validation.ApplicationTypeRequired'] = 'The proper application type was not specified.';
lang_en['UIMessages.Validation.Billing.Address'] = 'Please enter your address.';
lang_en['UIMessages.Validation.Billing.Address2'] = 'Please enter your extended address.';
lang_en['UIMessages.Validation.Billing.City'] = 'Please enter your city.';
lang_en['UIMessages.Validation.Billing.Country'] = 'Please select your country.';
lang_en['UIMessages.Validation.Billing.CreditCardExpirationMonth'] = 'Please enter your card expiration month.';
lang_en['UIMessages.Validation.Billing.CreditCardExpirationYear'] = 'Please enter your card expiration year.';
lang_en['UIMessages.Validation.Billing.CreditCardNumber'] = 'Please enter a valid credit card number.';
lang_en['UIMessages.Validation.Billing.CreditCardType'] = 'Please select a credit card type.';
lang_en['UIMessages.Validation.Billing.FirstName'] = 'Please enter your first name.';
lang_en['UIMessages.Validation.Billing.LastName'] = 'Please enter your last name.';
lang_en['UIMessages.Validation.Billing.ShippingAddress'] = 'Please enter a shipping address.';
lang_en['UIMessages.Validation.Billing.ShippingAddress2'] = 'Please enter an extended shipping address.';
lang_en['UIMessages.Validation.Billing.ShippingCity'] = 'Please enter a shipping city.';
lang_en['UIMessages.Validation.Billing.ShippingCountry'] = 'Please select your shipping country.';
lang_en['UIMessages.Validation.Billing.ShippingFirstName'] = 'Please enter your shipping first name.';
lang_en['UIMessages.Validation.Billing.ShippingLastName'] = 'Please enter your shipping last name.';
lang_en['UIMessages.Validation.Billing.ShippingState'] = 'Please select your shipping state.';
lang_en['UIMessages.Validation.Billing.ShippingZip'] = 'Please enter your shipping zip code.';
lang_en['UIMessages.Validation.Billing.State'] = 'Please select your state.';
lang_en['UIMessages.Validation.Billing.SubscriptionLevel'] = 'Please select a subscription level.';
lang_en['UIMessages.Validation.Billing.Zip'] = 'Please enter your zip code.';
lang_en['UIMessages.Validation.CompanyIDInvalid'] = 'Company ID not valid';
lang_en['UIMessages.Validation.CompanyNameRequired'] = 'Company name required.';
lang_en['UIMessages.Validation.CompanyNotFound'] = 'Company not found.';
lang_en['UIMessages.Validation.CompanyNotValidForSetting'] = 'The company is not valid for this setting.';
lang_en['UIMessages.Validation.CompleteHighlightedTryAgain'] = 'Please complete highlighted form fields below and submit again.';
lang_en['UIMessages.Validation.CountryRequired'] = 'Country is required.';
lang_en['UIMessages.Validation.DSTTimeDoesNotExist'] = 'Due to daylight savings, 2 AM to 2:59 AM does not exist.';
lang_en['UIMessages.Validation.DateFutureDisabled'] = 'Entering future dates is disabled. Please try again.';
lang_en['UIMessages.Validation.DateOutOfRange'] = 'The date is out of acceptable range. Please try again.';
lang_en['UIMessages.Validation.DateRequired'] = 'A valid date is required.';
lang_en['UIMessages.Validation.DateTime'] = 'A valid date and time is required.';
lang_en['UIMessages.Validation.EmailAddressNotFound'] = 'The email address was not found.';
lang_en['UIMessages.Validation.EmailAddressRequired'] = 'A valid email address is required.';
lang_en['UIMessages.Validation.EmailAddressRequiredToResetCredentials'] = 'An email address for the user is required to reset the account login credentials.';
lang_en['UIMessages.Validation.EmailInUse'] = 'That email address is already being used. Please try another or contact support.';
lang_en['UIMessages.Validation.EndDateAfterStartDate'] = 'The end date must be after the start date.';
lang_en['UIMessages.Validation.EndDateRequired'] = 'The end date is required.';
lang_en['UIMessages.Validation.EndTimeAfterStart'] = 'The end time is the same or earlier than the start time. Please check your entry.';
lang_en['UIMessages.Validation.Enrollment.AccountCreated'] = 'New account created!  You may login now.';
lang_en['UIMessages.Validation.Enrollment.CoWebsiteRequired'] = 'Company website required';
lang_en['UIMessages.Validation.Enrollment.EmployeeLevelRequired'] = 'Please select the number of employees in the company';
lang_en['UIMessages.Validation.Enrollment.ErrorAccountCreated'] = 'There was an error creating your new account. Please contact customer service.';
lang_en['UIMessages.Validation.Enrollment.FirstNameRequired'] = 'First name required';
lang_en['UIMessages.Validation.Enrollment.IndustryType'] = 'Industry type required';
lang_en['UIMessages.Validation.Enrollment.LastNameRequired'] = 'Last name required';
lang_en['UIMessages.Validation.Enrollment.PleaseCorrectTheFollowing'] = 'Please correct the following:';
lang_en['UIMessages.Validation.Enrollment.ReferralRequired'] = 'Please select a referral method.';
lang_en['UIMessages.Validation.Enrollment.TrialExtended'] = 'Account extended. Please log back in.';
lang_en['UIMessages.Validation.EntryOverlapsExisting'] = 'This record will overlap an existing record for the same date and classification.';
lang_en['UIMessages.Validation.FileRequired'] = 'A valid file is required.';
lang_en['UIMessages.Validation.FirstLastNameRequired'] = 'First and last name required';
lang_en['UIMessages.Validation.HoursForTimeClassMustBeBetween'] = 'The time entry must be between one minute and twenty-four hours.';
lang_en['UIMessages.Validation.HoursLessThanX'] = 'Hours must be less than {1,number,#}';
lang_en['UIMessages.Validation.HoursMinutesWholeNumbers'] = 'Hours and minutes must be whole numbers';
lang_en['UIMessages.Validation.HoursNotLessThanZero'] = 'Hours cannot be less than zero';
lang_en['UIMessages.Validation.InvalidResetLink'] = 'Invalid reset link';
lang_en['UIMessages.Validation.InvalidUserID'] = 'Invalid user ID';
lang_en['UIMessages.Validation.LoginFailed'] = 'Login failed.';
lang_en['UIMessages.Validation.LoginFailedCancelled'] = 'Login failed. Your account is currently cancelled. Please contact your account administrator.';
lang_en['UIMessages.Validation.LoginFailedDelinquent'] = 'Login failed. Your account is delinquent. Please contact customer support.';
lang_en['UIMessages.Validation.LoginFailedDisabled'] = 'Access Denied! Login Disabled.';
lang_en['UIMessages.Validation.LoginFailedExpired'] = 'Login failed. Your account has expired. Please contact your account administrator.';
lang_en['UIMessages.Validation.LoginFailedInvalidAccess'] = 'Login Failed. Invalid user access status.';
lang_en['UIMessages.Validation.LoginFailedKey'] = 'Login failed. Account not authorized for access.';
lang_en['UIMessages.Validation.LoginFailedTooManyAttempts'] = 'Login failed. There have been too many attempts to login. The account is temporarily disabled. Contact your supervisor or customer service for assistance.';
lang_en['UIMessages.Validation.LoginFailedTooManyAttemptsInternal'] = 'Access Denied! Too many failed attempts.';
lang_en['UIMessages.Validation.LoginFailedUserNamePasswordMismatch'] = 'Login failed. Invalid credentials.';
lang_en['UIMessages.Validation.MaintenanceLoginError'] = 'The system is currently unavailable for maintenance. Please check back shortly.';
lang_en['UIMessages.Validation.MaxHoursPerRecordThresholdInvalid'] = 'The maximum hours per record threshold was not valid.';
lang_en['UIMessages.Validation.MinutesNotLessThanZero'] = 'Minutes cannot be less than zero';
lang_en['UIMessages.Validation.NoDataInFile'] = 'No valid data was found in the file.';
lang_en['UIMessages.Validation.NonStandardTimeMidnight'] = 'Non-standard time must begin at midnight.';
lang_en['UIMessages.Validation.NotARobot'] = 'Please confirm you are not a robot.';
lang_en['UIMessages.Validation.NoteCannotBeEmpty'] = 'Note cannot be empty.';
lang_en['UIMessages.Validation.NoteStatusInvalid'] = 'Note Status not valid';
lang_en['UIMessages.Validation.NoteTypeInvalid'] = 'Note Type not valid';
lang_en['UIMessages.Validation.NoteUserInvalid'] = 'Note User/AdminUser was not valid';
lang_en['UIMessages.Validation.NumberRequired'] = 'Please enter a number.';
lang_en['UIMessages.Validation.PaidBreakDurationInvalid'] = 'The paid break duration was not valid.';
lang_en['UIMessages.Validation.PasswordFormatRequirement'] = 'Passwords must be between 8 and 64 characters. The password must contain at least one upper case character; at least one lower case character; at least one number; at least one special character (\'!#$%^&*()_+}{"":;?/>.<\'); cannot repeat a character more than five times; and cannot contain the account username.';
lang_en['UIMessages.Validation.PasswordIncorrect'] = 'The password is incorrect. Please try again.';
lang_en['UIMessages.Validation.PasswordMismatchError'] = 'Please ensure that both password entries match.';
lang_en['UIMessages.Validation.PasswordOldIncorrect'] = 'The old password is incorrect. Please try again.';
lang_en['UIMessages.Validation.PasswordResetEmailSent'] = 'User passwords reset and welcome emails resent.';
lang_en['UIMessages.Validation.PasswordResetEmailedTo'] = 'Password reset and emailed to: {1}';
lang_en['UIMessages.Validation.PasswordResetErrorList'] = 'Passwords could not be reset for the following users:';
lang_en['UIMessages.Validation.PasswordUpdateRequired'] = 'A password update is required.';
lang_en['UIMessages.Validation.PasswordUpdated'] = 'Password updated';
lang_en['UIMessages.Validation.PhoneNumberRequired'] = 'A valid phone number is required.';
lang_en['UIMessages.Validation.PhoneNumberValidOrBlank'] = 'The phone number must be valid or blank.';
lang_en['UIMessages.Validation.QuestionAndAnswer'] = 'Please select a security question and provide an answer.';
lang_en['UIMessages.Validation.RequestFailedAccountCancelled'] = 'Your account is currently cancelled. Please contact your account administrator.';
lang_en['UIMessages.Validation.RequestFailedAccountExpired'] = 'Your account has expired. Please contact your account administrator.';
lang_en['UIMessages.Validation.ResetPWInstructionsSent'] = 'Instructions to finish resetting the password will be sent if credentials are valid. Please allow up to ten minutes for the email to arrive.';
lang_en['UIMessages.Validation.SMSNotValidated'] = 'SMS number could not be validated';
lang_en['UIMessages.Validation.SecurityQuestionUpdateRequired'] = 'A security question update is required.';
lang_en['UIMessages.Validation.SessionDurationInvalid'] = 'Session duration value is not valid';
lang_en['UIMessages.Validation.ShiftRestrictionInvalid'] = 'The shift restriction was not valid.';
lang_en['UIMessages.Validation.StartDateAfterEndDate'] = 'The start date is after the end date';
lang_en['UIMessages.Validation.StartDateInFuture'] = 'The start date is in the future';
lang_en['UIMessages.Validation.StartDateOlderThanAllowed'] = 'The start date is older than the allowed date.';
lang_en['UIMessages.Validation.StartDateRequired'] = 'A start date is required';
lang_en['UIMessages.Validation.StartEndDateRequired'] = 'A start and an end date are required';
lang_en['UIMessages.Validation.StartEndDatesExceedRange'] = 'The start and end date cannot be more than {1,number,#} days apart.';
lang_en['UIMessages.Validation.StateOrProvince'] = 'Please select your state or province.';
lang_en['UIMessages.Validation.StateOrProvinceRequired'] = 'State or province is required.';
lang_en['UIMessages.Validation.TimeCannotBeModifiedArchivedUser'] = 'Time cannot be modified for archived users.';
lang_en['UIMessages.Validation.TimeRequired'] = 'A valid time is required.';
lang_en['UIMessages.Validation.TimeZoneCannotBeEdited'] = 'Time zone cannot be edited while the user has unpaid time.';
lang_en['UIMessages.Validation.TimeZoneInvalid'] = 'The time zone was invalid.';
lang_en['UIMessages.Validation.TimeZoneRequired'] = 'Time zone required';
lang_en['UIMessages.Validation.Unsuccessful'] = 'Unsuccessful.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.100'] = 'The permissions for importing users were invalid.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.101'] = 'The import file does not exist.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.102'] = 'The import file is corrupted.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.103'] = 'The file has no user data to import.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.104'] = 'The header columns have been altered. Check the file for the proper format.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.105'] = 'The column at position {2} has been altered. Check the file for the proper format.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.106'] = 'The user at line {1,number,#} was missing columns.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.107'] = 'There are not enough available licenses to import all users.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.108'] = 'The number of users imported at one time will be exceeded.';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.200'] = 'User Row {1,number,#}: Invalid first name';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.201'] = 'User Row {1,number,#}: Invalid last name';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.202'] = 'User Row {1,number,#}: Invalid email address';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.203'] = 'User Row {1,number,#}: The email address was already used or is already in the system';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.204'] = 'User Row {1,number,#}: Invalid employee classification';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.205'] = 'User Row {1,number,#}: Invalid pay type';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.206'] = 'User Row {1,number,#}: Invalid hourly pay rate';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.207'] = 'User Row {1,number,#}: Invalid salary pay rate';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.208'] = 'User Row {1,number,#}: Invalid phone number';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.209'] = 'User Row {1,number,#}: Invalid date of hire';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.210'] = 'User Row {1,number,#}: Invalid review date';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.211'] = 'User Row {1,number,#}: Invalid date of birth';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.212'] = 'User Row {1,number,#}: Invalid supervisor ID';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.213'] = 'User Row {1,number,#}: Invalid feature setting';
lang_en['UIMessages.Validation.UserImportFile.ErrorCode.300'] = 'User Row {1,number,#}: User creation failed';
lang_en['UIMessages.Validation.UserImportFile.ErrorXOnLine'] = 'UserID {1,number,#} on line {2,number,#}: {3}';
lang_en['UIMessages.Validation.UserLoginDisabledByAdministrator'] = 'Login to this account has been disabled by an adminstrator.';
lang_en['UIMessages.Validation.UserNotFound'] = 'User not found.';
lang_en['UIMessages.Validation.UserSelectionIsRequired'] = 'A user selection is required.';
lang_en['UIMessages.Validation.ValidAccountCodeIDRequiredForAction'] = 'A valid Account Code ID is required for this action.';
lang_en['UIMessages.Validation.ValidBillUnbillValueRequired'] = 'A valid billable/unbillable value is required.';
lang_en['UIMessages.Validation.ValidCustomerIDRequiredForAction'] = 'A valid Customer ID is required for this action.';
lang_en['UIMessages.Validation.ValidIP'] = 'Please enter a valid IP address.';
lang_en['UIMessages.Validation.ValidProjectIDRequiredForAction'] = 'A valid Project ID is required for this action.';
lang_en['UIMessages.Validation.ValidUserIDRequiredForAction'] = 'A valid user ID is required for this action.';
lang_en['UIMessages.Validation.ViolateTimeEntryCutoff'] = 'This action cannot be completed because it will violate the time entry cutoff.';
lang_en['UIMessages.Validation.ViolateTimeFutureDates'] = 'This action cannot be completed because it will violate time of future dates.';
lang_en['JavaScript.Account.CancelConfirmation'] = 'You are about to cancel your Timesheets.com account. Are you sure you want to continue?';
lang_en['JavaScript.Account.DeleteConfirmation'] = 'You are about to delete your Timesheets.com account. Are you sure you want to continue?';
lang_en['JavaScript.AccountIsBeingCreated'] = 'The account is being created.';
lang_en['JavaScript.AccrualLabelChange'] = 'You are about to change the label of a time-off category.\n\nThis will affect all time previously saved under this category including time from previous payroll periods.';
lang_en['JavaScript.AcknowledgeModifiedRecords'] = 'This will acknowledge and clear the recently modified indicators from the records in view.';
lang_en['JavaScript.AlertsAllowAndApproveSelectedRecords'] = 'This will allow all selected records and mark them as approved.';
lang_en['JavaScript.AlertsAllowSelectedRecords'] = 'This will allow all selected records.';
lang_en['JavaScript.AlertsDeleteSelectedRecords'] = 'This will delete all selected records.';
lang_en['JavaScript.AlertsDenyDeleteRequest'] = 'Are you sure you want to deny and delete this request?';
lang_en['JavaScript.AlertsDenyDeleteSelectedRecords'] = 'This will deny and delete all selected records.';
lang_en['JavaScript.AlertsDenyRecordRemains'] = 'Are you sure you want to deny this request? (The current record will remain as it is.)';
lang_en['JavaScript.AllAccrualBalancesWillBeUpdated'] = 'All accrual balances for users will be updated. Ensure all accrual amounts are correct before proceeding.';
lang_en['JavaScript.ArchiveThisRecord'] = 'Are you sure you want to archive this record?';
lang_en['JavaScript.ArizonaNoDST'] = 'Arizona does not use Daylight Saving Time.';
lang_en['JavaScript.Btn.CopyLink'] = 'Copy Link';
lang_en['JavaScript.Btn.EmailLink'] = 'Email Link';
lang_en['JavaScript.Btn.No'] = 'No';
lang_en['JavaScript.Btn.NoThanks'] = 'No Thanks';
lang_en['JavaScript.Btn.RemindMeLater'] = 'Remind Me Later';
lang_en['JavaScript.Btn.Yes'] = 'Yes';
lang_en['JavaScript.Camera.CameraUnavailable'] = 'The camera appears to be unavailable.';
lang_en['JavaScript.Camera.ClockPhotoCameraProblem'] = 'There is a problem with the camera and video. If video sharing was previously disallowed, it may have to be allowed for this website using the browser\'s settings.';
lang_en['JavaScript.Camera.ConfirmSubmissionOfPhoto'] = 'Are you sure you want to submit this photo?';
lang_en['JavaScript.Camera.ProcessingClockPhoto'] = 'Processing clock photo.';
lang_en['JavaScript.ChangesNotSaved'] = 'Changes have not been saved!';
lang_en['JavaScript.ClearAllRecentlyModifiedIndicators'] = 'Clear recently modified indicators from all records.';
lang_en['JavaScript.ClockOutLink.EmailDisabled.Description'] = 'This account does not have a valid email address. Add an email address to to enable this feature.';
lang_en['JavaScript.ClockOutLink.EmailDisabled.Title'] = 'Email Clock Out Link Disabled';
lang_en['JavaScript.ClockOutLink.FailedToSend.Description'] = 'The clock out link failed to send. This could be because one or more security features were enabled preventing clock out links. Please clock out through your account.';
lang_en['JavaScript.ClockOutLink.FailedToSend.Title'] = 'Clock Out Link Failed To Send';
lang_en['JavaScript.ClockOutLink.SMSDisabled.Description'] = 'This account does not have a valid SMS phone number. Add a SMS phone number to enable this feature.';
lang_en['JavaScript.ClockOutLink.SMSDisabled.Title'] = 'SMS Clock Out Link Disabled';
lang_en['JavaScript.ClockOutLink.SentSuccessfully.Description'] = 'The clock out link was sent successfully.';
lang_en['JavaScript.ClockOutLink.SentSuccessfully.Title'] = 'Clock Out Link';
lang_en['JavaScript.CompanyWall'] = 'Company Wall';
lang_en['JavaScript.ContactSupport'] = 'Please contact support for assistance.';
lang_en['JavaScript.ContinueAnyway'] = 'Continue anyway?';
lang_en['JavaScript.ContinueWithThisAction'] = 'Continue with this action?';
lang_en['JavaScript.ContinueWithoutSaving'] = 'Continue without saving?';
lang_en['JavaScript.Copied'] = 'Copied';
lang_en['JavaScript.CopyCustomerFirst'] = 'Please copy the customer first.';
lang_en['JavaScript.Day.FRI'] = 'FRI';
lang_en['JavaScript.Day.FRIDAY'] = 'FRIDAY';
lang_en['JavaScript.Day.MON'] = 'MON';
lang_en['JavaScript.Day.MONDAY'] = 'MONDAY';
lang_en['JavaScript.Day.SAT'] = 'SAT';
lang_en['JavaScript.Day.SATURDAY'] = 'SATURDAY';
lang_en['JavaScript.Day.SUN'] = 'SUN';
lang_en['JavaScript.Day.SUNDAY'] = 'SUNDAY';
lang_en['JavaScript.Day.THU'] = 'THU';
lang_en['JavaScript.Day.THURSDAY'] = 'THURSDAY';
lang_en['JavaScript.Day.TUE'] = 'TUE';
lang_en['JavaScript.Day.TUESDAY'] = 'TUESDAY';
lang_en['JavaScript.Day.WED'] = 'WED';
lang_en['JavaScript.Day.WEDNESDAY'] = 'WEDNESDAY';
lang_en['JavaScript.DeletePost'] = 'Delete this post?';
lang_en['JavaScript.DeleteQBOExport'] = 'Deleting previous exports also deletes the export history and cannot be undone. This action will allow you to import the same data more than once causing duplicate entries in QuickBooks Online. Instead it is recommended to fix the errors in QuickBooks Online and retry the import until all records are successful.';
lang_en['JavaScript.DisconnectFromQBO'] = 'This will disconnect your account from QuickBooks Online.';
lang_en['JavaScript.ErrorOccured'] = 'An error occurred.';
lang_en['JavaScript.ErrorSendingStatusReport'] = 'Error sending status report. Please try again or contact support.';
lang_en['JavaScript.FileBeingUploaded'] = 'The file is being uploaded. Please be patient.';
lang_en['JavaScript.FileDownload.EmailBody.FileAvailability'] = 'Files are available for download for 30 days.';
lang_en['JavaScript.FileDownload.EmailBody.LinkToFile'] = 'Here is the link to download the file:';
lang_en['JavaScript.FileDownload.EmailSubject'] = 'Your Timesheets.com Download File Link';
lang_en['JavaScript.FileError'] = 'There was an error downloading the file.';
lang_en['JavaScript.FileGeneratingPleaseBePatient'] = 'Generating the file. Please be patient.';
lang_en['JavaScript.FileIsBeingGenerated'] = 'The file is being generated.';
lang_en['JavaScript.FileReadyForDownloadClickHere'] = 'Your file is ready to download, please <strong>click here</strong>.';
lang_en['JavaScript.FutureRecords'] = 'Future Records';
lang_en['JavaScript.Geolocation.Error.Addendum'] = 'Please ensure that your browser is set to allow location sharing for this site and that your browser and device allow the site access to GPS coordinates.';
lang_en['JavaScript.Geolocation.Error.LocationInfoUnavailable'] = 'Location information is unavailable.';
lang_en['JavaScript.Geolocation.Error.RequestTimedOut'] = 'The request to get your location has timed out.';
lang_en['JavaScript.Geolocation.Error.RequestToShareDenied'] = 'The request to share location was denied.';
lang_en['JavaScript.Geolocation.Error.UnknownError'] = 'An unknown geolocation error has occurred.';
lang_en['JavaScript.Geolocation.GeocodeFailed'] = 'Geocode was not successful for the following reasons:';
lang_en['JavaScript.Geolocation.GeocodeFailedNoResults'] = 'Geocode was not successful. Zero results found.';
lang_en['JavaScript.Geolocation.Instructions.AccuracyIsPoor'] = 'Geolocation accuracy rating is poor.';
lang_en['JavaScript.Geolocation.Instructions.AccuracyRefinementClose'] = 'seconds.';
lang_en['JavaScript.Geolocation.Instructions.AccuracyRefinementOpen'] = 'Accuracy refinement ends in';
lang_en['JavaScript.Geolocation.Instructions.AcquiringLocation'] = 'Acquiring Geolocation';
lang_en['JavaScript.Geolocation.Instructions.ClickToCancel'] = 'Click Cancel to cancel this clock punch.';
lang_en['JavaScript.Geolocation.Instructions.ClickToPerformWithoutGeo'] = 'Click OK to perform this clock punch without your geolocation.';
lang_en['JavaScript.Geolocation.Instructions.ForFasterCapture'] = 'For faster geolocation, set your browser to &quot;Always&quot; share location with this site.';
lang_en['JavaScript.Geolocation.Instructions.GeoTimeout'] = 'If you don\'t see a prompt to share your location, or are waiting for a long time with no location icon on your device, you may have denied location access for this site. Please set your browser to allow location sharing for this site, then reload the page and try again.';
lang_en['JavaScript.Geolocation.Instructions.NoBrowserSupport'] = 'Your browser does not support geolocation.';
lang_en['JavaScript.Geolocation.Instructions.ProceedAnyway'] = 'Proceed anyway?';
lang_en['JavaScript.Geolocation.Instructions.ReceivingData'] = 'Receiving geolocation data...';
lang_en['JavaScript.Geolocation.Instructions.WaitingForData'] = 'Waiting for geolocation data...';
lang_en['JavaScript.HR.CreatingNewDocument'] = 'Creating new document...';
lang_en['JavaScript.HawaiiNoDST'] = 'Hawaii does not use Daylight Saving Time.';
lang_en['JavaScript.ImportItemsWait'] = 'Importing items.\n\nThis may take a minute to complete.\nPlease be patient.';
lang_en['JavaScript.Information.AccessToSecurityMakesExempt'] = 'You have access to security settings which makes you exempt.';
lang_en['JavaScript.Information.AccountIsExemptFromIPLock'] = 'Your account is exempt from this IP lock.';
lang_en['JavaScript.Information.AddIPToWhitelist'] = 'Add this IP address to the company whitelist to make it available for all users.';
lang_en['JavaScript.Information.BooleanSearchFormatted'] = '<strong>{1} Boolean Full Text Search</strong>\n\nThe following examples demonstrate some search strings that use boolean full-text operators:\n<ul class=&quot;spaced&quot;>\n<li>Find records that contain at least one of the two words:<br /><strong>apple banana</strong></li>\n<li>Find records that contain both words:<br /><strong><strong>+apple +juice</strong></strong></li>\n<li>Find records that contain the word &quot;apple&quot; but not &quot;macintosh&quot;:<br /><strong>+apple -macintosh</strong></li>\n<li>Find records that contain words such as &quot;apple&quot;, &quot;apples&quot;, &quot;applesauce&quot;, or &quot;applet&quot;:<br /><strong>apple*</strong></li>\n<li>Find records that contain the exact phrase &quot;some words&quot;:\n<strong>&quot;some words&quot;</strong></li></ul>';
lang_en['JavaScript.Information.ContactSuperToAddIPToWhitelist'] = 'Contact your supervisor or administrator to add the IP address of your location to the system.';
lang_en['JavaScript.Information.CustomNameReport'] = 'This report can be named for easier identification and retrieval in Previously Generated Reports accessible from the Reports page. The report will remain available for four hours or until you log out, whichever comes first.\n\nOnly letters, numbers, and the following characters are allowed: . _ - ( ) [ ] { }';
lang_en['JavaScript.Information.LikeTextSearchFormatted'] = '<strong>{1} Like-Text Searching</strong>\n\nUsing like-text search strings will search for any text that matches or contains the search string exactly.\n\nThe following examples demonstrate search with a like-text string:\n\nGiven the following records:\n\n<ol><li>The slow red fox jumped over the sleeping turtle.</li><li>The quick brown fox jumped over the lazy dog.</li><li>The small reddish squirrel is jumping over the brown fox.</li></ol>\nA search string of &quot;jumped over&quot; would return one and two.\n\nA search string of &quot;jump&quot; would return one and two and three.\n\nA search string of &quot;brown&quot; would return one and two and three.\n\nA search string of &quot;over the&quot; would return one and two and three.\n\nA search string of &quot;red&quot; would return one and three.';
lang_en['JavaScript.Information.ProjectDescriptionsNotViewableByEmps'] = 'Project descriptions are currently not viewable to all users when entering or editing time. Visit Company Settings to change permission settings.';
lang_en['JavaScript.Information.ProjectDescriptionsViewableByEmps'] = 'Project descriptions are currently viewable to all users when entering or editing time. Visit Company Settings to change permission settings.';
lang_en['JavaScript.Information.RunInBackgroundInfo'] = 'When you run a report in the background, you may continue to use your account while the report is processing. You will receive a notification when the report is ready.';
lang_en['JavaScript.Information.SearchRecordDescriptions'] = 'The work description notes on records can be searched with boolean full-text operators.';
lang_en['JavaScript.Information.TimeClassHasBeenDisabled'] = 'Hourly and Salaried time classes have been disabled by an administrator.';
lang_en['JavaScript.Information.TimeClassNotPermittedForLocation'] = 'Hourly and Salaried time entry is not permitted from this location.';
lang_en['JavaScript.Information.TimeClassNotUsuallyPermitted'] = 'Hourly and Salaried time entry is not usually permitted from this location. However:';
lang_en['JavaScript.InsertGlobalEntry'] = 'You are about to save a time entry for the selected users.\nThis action can only be undone one user timesheet at a time.\n\nContinue only if you are certain the new entry is completely correct.';
lang_en['JavaScript.InvalidCreditCard'] = 'Please check the credit card number. It must be 15 or 16 digits long with no spaces.';
lang_en['JavaScript.Item.ArchiveAllItems'] = 'Archive All Items?';
lang_en['JavaScript.Item.ArchiveAllItemsDetail'] = 'List items can be archived when they are not used in any open records. This action will archive all eligible list items in the account. To archive specific list items use the checkboxes.';
lang_en['JavaScript.Item.DeleteAllItems'] = 'Delete All Items?';
lang_en['JavaScript.Item.DeleteAllItemsDetail'] = '<span style=\'color:red;font-weight:bold;font-size:18px\'>Warning</span>\nThis action deletes ALL available list items. To select specific items use the <span style=\'font-weight:bold\'>\'Delete Selected\'</span> button at the bottom of the list.\n\nOnly items that have never been used are eligible for deletion.';
lang_en['JavaScript.Item.RestoreAllItems'] = 'Restore All Items?';
lang_en['JavaScript.Item.RestoreAllItemsDetail'] = 'This action will restore all archived list items. To restore specific list items, use the checkboxes.';
lang_en['JavaScript.LastUpdated'] = 'Last Updated';
lang_en['JavaScript.Link.LessEllipsis'] = 'less...';
lang_en['JavaScript.Link.MoreEllipsis'] = 'more...';
lang_en['JavaScript.LoadingRecords'] = 'Loading records...';
lang_en['JavaScript.LoadingRecordsForWeekStarting'] = 'Loading records for the week starting {1}';
lang_en['JavaScript.MergeItemsWarning'] = 'This action will merge records assigned from one item to another. This action cannot be undone.';
lang_en['JavaScript.MessageCenter.Admin.DeleteAccountAlertConfirm'] = 'You are attempting to delete a custom account alert. This will delete the alert for ALL users it was sent to before deleting the alert itself. Generally custom account alerts which have been previously sent should not be deleted unless there is a specific reason.';
lang_en['JavaScript.MessageCenter.Admin.DeleteSentAccountAlertConfirm'] = 'This will permanently delete this message from all users it was sent to.';
lang_en['JavaScript.MessageCenter.Admin.SendAccountAlertConfirm'] = 'This will send the selected alert to all selected users from the selected send by. Proceed with caution.';
lang_en['JavaScript.MessageCenter.Admin.SendUserAccountAlertConfirm'] = 'You are about to send a message directly to a user as an unread account alert. This message cannot be deleted or undone once it is sent. Proceed with caution when sending alerts directly to users.';
lang_en['JavaScript.NewUpdates'] = 'New Updates!';
lang_en['JavaScript.NoChangesFound'] = 'No edits or changes were found. Make an edit to submit the form.';
lang_en['JavaScript.NoMatches'] = 'No matches...';
lang_en['JavaScript.NotifySupervisorOfPayrollClose'] = 'This will notify your direct supervisor that you have closed a payroll period.';
lang_en['JavaScript.NotifySupervisorOfRecordsApproved'] = 'This will notify your direct supervisor that you have approved records.';
lang_en['JavaScript.NotifySupervisorOfRecordsArchived'] = 'This will notify your direct supervisor that you have archived records.';
lang_en['JavaScript.NotifySupervisorOfRecordsOpened'] = 'This will notify your direct supervisor that you have opened records.';
lang_en['JavaScript.NotifySupervisorOfRecordsReconciled'] = 'This will notify your direct supervisor that you have reconciled records.';
lang_en['JavaScript.NotifySupervisorTimesheetIsReady'] = 'This will send a message to your direct supervisor to let them know that your timesheet is ready.';
lang_en['JavaScript.Or'] = 'Or';
lang_en['JavaScript.Payroll.ArchivedUsersWillBeRecactivated'] = 'Any archived users in the payroll report will be reactivated.';
lang_en['JavaScript.Payroll.RecordsWillBeReturnedToTimesheet'] = 'All records will return to the user\'s timesheet.';
lang_en['JavaScript.Payroll.ReopeningPayrollPeriod'] = 'Reopening payroll period...';
lang_en['JavaScript.Payroll.ReopeningWillDeleteReport'] = 'Reopening this payroll period will delete the closed report.';
lang_en['JavaScript.PermanentlyDeleteRecordHistoryWarning'] = 'This will permanently delete all history of the selected records.\n\nProceed with caution.';
lang_en['JavaScript.PleaseTryAgain'] = 'Please try again or contact customer support if you\'re still having trouble.';
lang_en['JavaScript.ProblemCommunicatingWithServer'] = 'Problem communicating with the server!';
lang_en['JavaScript.ProcessingPleaseBePatient'] = 'Processing... Please be patient.';
lang_en['JavaScript.ProcessingPleaseWait'] = 'Processing. Please Wait...';
lang_en['JavaScript.RecordEditsNotSaved'] = 'The record edits have not been saved.';
lang_en['JavaScript.RecordNotSaved'] = 'The record has not been saved.';
lang_en['JavaScript.RefreshingFileList'] = 'Refreshing file list...';
lang_en['JavaScript.ReportIsBeingGenerated'] = 'The report is being generated.';
lang_en['JavaScript.Saving'] = 'Saving...';
lang_en['JavaScript.SavingExpenseEntry'] = 'Saving expense entry...';
lang_en['JavaScript.SelectedRecordWillBeDeleted'] = 'All selected records will be deleted. This action is permanent and cannot be undone.';
lang_en['JavaScript.SelectedRecordWillBeMarkedAsApproved'] = 'All selected records will be marked as approved.';
lang_en['JavaScript.SelectedRecordWillBeMarkedAsArchived'] = 'All selected records will be marked as archived.';
lang_en['JavaScript.SelectedRecordWillBeMarkedAsBillable'] = 'All selected records will be marked as billable.';
lang_en['JavaScript.SelectedRecordWillBeMarkedAsOpen'] = 'All selected records will be marked as open.';
lang_en['JavaScript.SelectedRecordWillBeMarkedAsReconciled'] = 'All selected records will be marked as reconciled.';
lang_en['JavaScript.SelectedRecordWillBeMarkedAsUnapproved'] = 'All selected records will be marked as unapproved.';
lang_en['JavaScript.SelectedRecordWillBeMarkedAsUnarchived'] = 'All selected records will be marked as unarchived.';
lang_en['JavaScript.SelectedRecordWillBeMarkedAsUnbillable'] = 'All selected records will be marked as unbillable.';
lang_en['JavaScript.SelectionArchived'] = 'This record may be saved with this selection but this selection has been archived and is no longer valid for use in new records.';
lang_en['JavaScript.SelectionNotValid'] = 'This record may be saved with this selection but this selection is no longer valid based on user access permissions.';
lang_en['JavaScript.Session.SessionAboutToExpire'] = 'Your session is about to expire.';
lang_en['JavaScript.Session.SessionExpiringTitle'] = 'Session Expiring...';
lang_en['JavaScript.Session.SessionHasExpired'] = 'Your session has expired. You have been logged out.';
lang_en['JavaScript.Support.GuidedTourIconDesc'] = 'Use the {1} for a guided tutorial.';
lang_en['JavaScript.Support.HelpfulInformation'] = 'You can find helpful information anytime in the top right of most pages.';
lang_en['JavaScript.Support.PageSupportIconDesc'] = 'Use the {1} to view the support documentation.';
lang_en['JavaScript.Support.WouldYouLikeQuickTourOfAccount'] = 'Would you like a quick tour of your account before you start?';
lang_en['JavaScript.Timer.Pausing'] = 'Pausing timer...';
lang_en['JavaScript.Timer.PausingAll'] = 'Pausing all timers...';
lang_en['JavaScript.Timer.Restarting'] = 'Restarting timer...';
lang_en['JavaScript.Timer.RestartingAll'] = 'Restarting all timers...';
lang_en['JavaScript.Timer.Starting'] = 'Starting timer...';
lang_en['JavaScript.Timer.StartingAll'] = 'Starting all timers...';
lang_en['JavaScript.Timer.Stopping'] = 'Stopping timer...';
lang_en['JavaScript.Timer.StoppingAll'] = 'Stopping all timers...';
lang_en['JavaScript.Title.Attention'] = 'Attention';
lang_en['JavaScript.Title.ConfirmationRequired'] = 'Confirmation Required';
lang_en['JavaScript.Title.FileError'] = 'File Error';
lang_en['JavaScript.Title.FileReady'] = 'File Ready';
lang_en['JavaScript.Title.Geolocation'] = 'Geolocation';
lang_en['JavaScript.Title.GeolocationNotSupported'] = 'Geolocation Not Supported';
lang_en['JavaScript.Title.Information'] = 'Information';
lang_en['JavaScript.Title.MakeASelection'] = 'Make A Selection';
lang_en['JavaScript.Title.MileageFormat'] = 'Mileage Format';
lang_en['JavaScript.Title.PageOrientation'] = 'Page Orientation';
lang_en['JavaScript.Title.ProTip'] = 'Pro Tip!';
lang_en['JavaScript.Title.ProcessingPleaseBePatient'] = 'Processing... Please be patient.';
lang_en['JavaScript.Title.ProcessingPleaseWait'] = 'Processing. Please Wait...';
lang_en['JavaScript.Title.ThisIsTheDashboard'] = 'This is the Dashboard';
lang_en['JavaScript.Title.Unsupported'] = 'Unsupported';
lang_en['JavaScript.ToolTip.TableSortLabel'] = 'Table Sorting';
lang_en['JavaScript.ToolTip.TableSortListOne'] = 'Click on any column header to sort all of the tables on this page at once. To only sort on one table, go to the user icon in the top right corner > Display Options > Table Sorting > Individual.';
lang_en['JavaScript.ToolTip.TableSortListTwo'] = 'To sort on more than one column at a time, click the first column to sort, hold down the shift key, then click the next column.';
lang_en['JavaScript.TryAgain'] = 'Please try again.';
lang_en['JavaScript.UnarchiveThisRecord'] = 'Are you sure you want to unarchive this record?';
lang_en['JavaScript.UpdateItem'] = 'Updating the item name or description will update all records that have used this item.';
lang_en['JavaScript.UpdateItemRate'] = 'If updating the default rate, existing records will not be updated to the new rate.';
lang_en['JavaScript.Updating'] = 'Updating...';
lang_en['JavaScript.UserPhoto.DeleteThisPhoto'] = 'Delete this photo?';
lang_en['JavaScript.UserPhoto.PhotoCameraProblem'] = 'There is a problem with the camera and video. If video sharing was previously disallowed, it may have to allowed for this website using the browser\'s settings.';
lang_en['JavaScript.UserPhoto.ProblemDeletingPhoto'] = 'There was a problem deleting the photo. Please try again, or contact us for assistance.';
lang_en['JavaScript.UserPhoto.ProblemUploadingPhoto'] = 'There was a problem uploading the photo. Please try again, or contact us for assistance.';
lang_en['JavaScript.UserPhoto.UseThisPhoto'] = 'Use this photo?';
lang_en['JavaScript.Validation.ErrorValidatingNumber'] = 'Error attempting to validate the phone number. Please try again or contact support.';
lang_en['JavaScript.Validation.HoursMustBeBetween0and24'] = 'Hours must be between 0 and 24.';
lang_en['JavaScript.Validation.HoursMustBeBetween1and12'] = 'Hours must be between 1 and 12.';
lang_en['JavaScript.Validation.HoursMustBeNumeric'] = 'Hours must be numeric.';
lang_en['JavaScript.Validation.MileageFormat'] = 'Mileage must be entered as whole numbers only.';
lang_en['JavaScript.Validation.MinutesMustBeBetween0and59'] = 'Minutes must be between 0 and 59.';
lang_en['JavaScript.Validation.MinutesMustBeNumeric'] = 'Minutes must be numeric.';
lang_en['JavaScript.Validation.PhoneIsNotValid'] = 'The phone number is NOT valid.';
lang_en['JavaScript.Validation.PhoneIsValid'] = 'The number is valid.';
lang_en['JavaScript.Validation.ValidEarlierStartTime'] = 'Please select a valid earlier record end time.';
lang_en['JavaScript.Validation.ValidLaterStartTime'] = 'Please select a valid later record start time.';
lang_en['JavaScript.Validation.ValueHasBeenReset'] = 'The value has been reset.';
lang_en['JavaScript.XMinuteIncrements'] = '{1} minute increments';
lang_en['Calendar.Action.add'] = 'Add Event';
lang_en['Calendar.Action.allCalendars'] = 'Select All';
lang_en['Calendar.Action.cancel'] = 'Cancel';
lang_en['Calendar.Action.click'] = 'Click';
lang_en['Calendar.Action.close'] = 'Close';
lang_en['Calendar.Action.copy'] = 'Copy';
lang_en['Calendar.Action.copyTo'] = 'Copy To';
lang_en['Calendar.Action.copyToDate'] = 'Copy to date';
lang_en['Calendar.Action.delete'] = 'Delete';
lang_en['Calendar.Action.edit'] = 'Edit';
lang_en['Calendar.Action.login'] = 'Login';
lang_en['Calendar.Action.moveTo'] = 'Move To';
lang_en['Calendar.Action.moveToDate'] = 'Move to date';
lang_en['Calendar.Action.paste'] = 'Paste';
lang_en['Calendar.Action.publish'] = 'Publish';
lang_en['Calendar.Action.refresh'] = 'Refresh';
lang_en['Calendar.Action.reset'] = 'Reset';
lang_en['Calendar.Action.save'] = 'Save';
lang_en['Calendar.Action.selectFullWeek'] = 'Select Week';
lang_en['Calendar.Action.unpublish'] = 'Unpublish';
lang_en['Calendar.Action.update'] = 'Update';
lang_en['Calendar.CalendarMetaData.Birthdays.AlternateName'] = 'Birthdays';
lang_en['Calendar.CalendarMetaData.Birthdays.Description'] = 'A calendar of all upcoming users birthdays';
lang_en['Calendar.CalendarMetaData.Birthdays.Name'] = 'Birthdays';
lang_en['Calendar.CalendarMetaData.HireDates.AlternateName'] = 'Hires & Anniversaries';
lang_en['Calendar.CalendarMetaData.HireDates.Description'] = 'A calendar of all users hiring anniversaries';
lang_en['Calendar.CalendarMetaData.HireDates.Name'] = 'Hires and Anniversaries';
lang_en['Calendar.CalendarMetaData.PayrollPeriods.AlternateName'] = 'Payroll Periods';
lang_en['Calendar.CalendarMetaData.PayrollPeriods.Description'] = 'A calendar of payroll periods';
lang_en['Calendar.CalendarMetaData.PayrollPeriods.Name'] = 'Payroll Periods';
lang_en['Calendar.CalendarMetaData.ReviewDates.AlternateName'] = 'Annual Reviews';
lang_en['Calendar.CalendarMetaData.ReviewDates.Description'] = 'A calendar of all upcoming users review dates';
lang_en['Calendar.CalendarMetaData.ReviewDates.Name'] = 'Annual Reviews';
lang_en['Calendar.CalendarMetaData.Terminations.AlternateName'] = 'Terminations';
lang_en['Calendar.CalendarMetaData.Terminations.Description'] = 'A calendar of all archived users termination dates';
lang_en['Calendar.CalendarMetaData.Terminations.Name'] = 'Terminations';
lang_en['Calendar.CalendarMetaData.USHolidays.AlternateName'] = 'US Holidays';
lang_en['Calendar.CalendarMetaData.USHolidays.Description'] = 'A calendar of all official US holidays';
lang_en['Calendar.CalendarMetaData.USHolidays.Name'] = 'US Holidays';
lang_en['Calendar.Header.attention'] = 'Attention';
lang_en['Calendar.Header.copyHeader'] = 'Copy selected events:';
lang_en['Calendar.Header.delete'] = 'Delete selected events:';
lang_en['Calendar.Header.noneSelected'] = 'No events selected';
lang_en['Calendar.Header.pleaseWait'] = 'Please wait...';
lang_en['Calendar.Header.publish'] = 'Publish selected events:';
lang_en['Calendar.Header.targetDate'] = 'Target Date:';
lang_en['Calendar.Header.unpublish'] = 'Unpublish selected events:';
lang_en['Calendar.Label.Birthday'] = ' (Birthday)';
lang_en['Calendar.Label.Day'] = 'Day';
lang_en['Calendar.Label.DragItemsToSelect'] = 'Drag Items To Select';
lang_en['Calendar.Label.DragToSelectEnabled'] = 'Drag To Select Enabled';
lang_en['Calendar.Label.Hired'] = ' (Hired)';
lang_en['Calendar.Label.Info'] = 'Info';
lang_en['Calendar.Label.Landscape'] = 'Landscape';
lang_en['Calendar.Label.Loading'] = 'Loading...';
lang_en['Calendar.Label.LoadingComplete'] = 'All Events Fetched';
lang_en['Calendar.Label.NoResultsFound'] = 'No Results Found';
lang_en['Calendar.Label.Payroll'] = ' (Payroll)';
lang_en['Calendar.Label.Portrait'] = 'Portrait';
lang_en['Calendar.Label.Processing'] = 'Processing';
lang_en['Calendar.Label.SortBySupervisor'] = 'Sort by Supervisor';
lang_en['Calendar.Label.StartOn'] = 'Start On';
lang_en['Calendar.Label.ThisDay'] = 'This Day';
lang_en['Calendar.Label.USHoliday'] = ' (US Holiday)';
lang_en['Calendar.Label.addEvent'] = 'Calendars';
lang_en['Calendar.Label.allDay'] = 'All Day';
lang_en['Calendar.Label.copyFrom'] = 'Copy from:';
lang_en['Calendar.Label.details'] = 'Details';
lang_en['Calendar.Label.ends'] = 'Ends';
lang_en['Calendar.Label.exitDarkModeBeforePrinting'] = 'Exit dark mode before printing.';
lang_en['Calendar.Label.filterBy'] = 'Filter By';
lang_en['Calendar.Label.month'] = 'Month';
lang_en['Calendar.Label.moreEvents'] = 'more events';
lang_en['Calendar.Label.noFilter'] = 'No Filter';
lang_en['Calendar.Label.printMonth'] = 'Print Month';
lang_en['Calendar.Label.printSchedule'] = 'Print Schedule';
lang_en['Calendar.Label.printWeek'] = 'printWeek';
lang_en['Calendar.Label.published'] = 'Published';
lang_en['Calendar.Label.requested'] = 'Requested';
lang_en['Calendar.Label.schedule'] = 'Schedule';
lang_en['Calendar.Label.search'] = 'Search';
lang_en['Calendar.Label.selectCalendar'] = 'Select Calendar';
lang_en['Calendar.Label.showDescription'] = 'Show Description';
lang_en['Calendar.Label.showNames'] = 'Show Names';
lang_en['Calendar.Label.starts'] = 'Starts';
lang_en['Calendar.Label.thisMonth'] = 'This Month';
lang_en['Calendar.Label.thisWeek'] = 'This Week';
lang_en['Calendar.Label.title'] = 'Title';
lang_en['Calendar.Label.updating'] = 'Updating...';
lang_en['Calendar.Label.week'] = 'Week';
lang_en['Calendar.Legend.allDay'] = 'All Day';
lang_en['Calendar.Legend.legendTitle'] = 'Legend';
lang_en['Calendar.Legend.multiDay'] = 'Multi-day';
lang_en['Calendar.Legend.ptoRequests'] = 'PTO Requests';
lang_en['Calendar.Legend.published'] = 'Published';
lang_en['Calendar.Legend.publishedAvailable'] = 'Published Available';
lang_en['Calendar.Legend.publishedUnavailable'] = 'Published Unavailable';
lang_en['Calendar.Legend.timeOff'] = 'Time Off (all categories)';
lang_en['Calendar.Legend.timeOffLegend'] = 'Time Off';
lang_en['Calendar.Legend.timeOffOrUnavail'] = 'Time Off Or Unavailable';
lang_en['Calendar.Legend.title'] = 'Legend';
lang_en['Calendar.Legend.unavailable'] = 'Unavailable';
lang_en['Calendar.Legend.unpublished'] = 'Unpublished';
lang_en['Calendar.Legend.unpublishedAvailable'] = 'Unpublished Available';
lang_en['Calendar.Legend.unpublishedUnavailable'] = 'Unpublished Unavailable';
lang_en['Calendar.Menu.title'] = 'Menu';
lang_en['Calendar.Msg.AddPeopleAndCalendarsToSchedule'] = 'Click to add people and calendars to the schedule';
lang_en['Calendar.Msg.PleaseTryAgain'] = 'Please try again';
lang_en['Calendar.Msg.bulkCopyError'] = 'events failed to copy.';
lang_en['Calendar.Msg.bulkCopySuccess'] = 'events successfully copied.';
lang_en['Calendar.Msg.bulkDeleteError'] = 'events failed to delete.';
lang_en['Calendar.Msg.bulkDeleteSuccess'] = 'events successfully deleted.';
lang_en['Calendar.Msg.bulkPublishError'] = 'events failed to publish.';
lang_en['Calendar.Msg.bulkPublishSuccess'] = 'events successfully published.';
lang_en['Calendar.Msg.bulkUnpublishError'] = 'events failed to unpublish.';
lang_en['Calendar.Msg.bulkUnpublishSuccess'] = 'events successfully unpublished.';
lang_en['Calendar.Msg.calendarsSelected'] = 'calendars selected';
lang_en['Calendar.Msg.confirmWait'] = 'This may take several minutes. Would you like to continue?';
lang_en['Calendar.Msg.continueQuestion'] = 'Would you like to continue?';
lang_en['Calendar.Msg.copySuccess'] = 'event successfully copied.';
lang_en['Calendar.Msg.copyingEvents'] = 'Copying Events';
lang_en['Calendar.Msg.deleteError'] = 'event failed to delete.';
lang_en['Calendar.Msg.deleteSuccess'] = 'event successfully deleted.';
lang_en['Calendar.Msg.deletingEvents'] = 'Deleting Events';
lang_en['Calendar.Msg.duplicateEventsNotAllowed'] = 'Duplicate events are not permitted on the same day.';
lang_en['Calendar.Msg.error'] = 'Error';
lang_en['Calendar.Msg.eventsCannotBeCopiedAcrossCalendars'] = 'Events may not be copied across calendars';
lang_en['Calendar.Msg.eventsCopied'] = 'Events copied';
lang_en['Calendar.Msg.eventsDeleted'] = 'Events deleted';
lang_en['Calendar.Msg.eventsFailedCopy'] = 'Events failed to copy';
lang_en['Calendar.Msg.eventsFailedDelete'] = 'Events failed to delete';
lang_en['Calendar.Msg.eventsFailedPublish'] = 'Events failed to publish';
lang_en['Calendar.Msg.eventsFailedUnpublish'] = 'Events failed to unpublish';
lang_en['Calendar.Msg.eventsPublished'] = 'Events published';
lang_en['Calendar.Msg.eventsToCopy'] = 'Events to copy';
lang_en['Calendar.Msg.eventsToDelete'] = 'Events to delete';
lang_en['Calendar.Msg.eventsToPublish'] = 'Events to publish';
lang_en['Calendar.Msg.eventsToUnpublish'] = 'Events to unpublish';
lang_en['Calendar.Msg.eventsUnpublished'] = 'Events unpublished';
lang_en['Calendar.Msg.gettingAllCalendaras'] = 'Getting all calendars';
lang_en['Calendar.Msg.gettingAllEvents'] = 'Getting all events';
lang_en['Calendar.Msg.noEditableCalendarsSelected'] = 'There are no editable calendars selected.';
lang_en['Calendar.Msg.noEventsForPeriod'] = 'There are no events to display for this date period.';
lang_en['Calendar.Msg.okay'] = 'Okay';
lang_en['Calendar.Msg.publishSuccess'] = 'event successfully published.';
lang_en['Calendar.Msg.publishingEvents'] = 'Publishing Events';
lang_en['Calendar.Msg.refreshToLoginAgain'] = 'Please refresh to log in again.';
lang_en['Calendar.Msg.selectTargetDate'] = 'Select target date to copy to';
lang_en['Calendar.Msg.sessionTimedCount'] = 'Your session has timed out.';
lang_en['Calendar.Msg.severalMinutes'] = 'This could take several minutes.';
lang_en['Calendar.Msg.somethingWrongTryAgain'] = 'Something went wrong.<br/>Please try again.';
lang_en['Calendar.Msg.success'] = 'Success';
lang_en['Calendar.Msg.titleRequiredForAllDayEvents'] = 'Required for all day events.';
lang_en['Calendar.Msg.unpublishSuccess'] = 'event successfully unpublished.';
lang_en['Calendar.Msg.unpublishingEvents'] = 'Unpublishing Events';
lang_en['Calendar.Validation.ApprovalError'] = 'Approval Error:';
lang_en['Calendar.Validation.CopiedEventOverlapsExistingEvent'] = 'The event copied overlaps with an existing event on day:';
lang_en['Calendar.Validation.duplicateEventMsg'] = 'Duplicate events are not permitted on the same day.';
lang_en['Calendar.Validation.endDateRequired'] = 'End date is required';
lang_en['Calendar.Validation.endDateTimeRequired'] = 'End date/time is required';
lang_en['Calendar.Validation.startDateRequired'] = 'Start date is required';
lang_en['Calendar.Validation.startDateTimeRequired'] = 'Start date/time is required';
