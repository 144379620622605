/**
* Create html/css string which can be toggle a numeric decimal value between decimal and hours/minutes formats
*
* @author agalano / mrast
* @originalTime time to convert
* @activeState active state, default decimal
* @decimalFormat decimal or no_format, default decimal
* @returns string, html to toggle classes
**/
function toggleHrsMins(originalTime,activeState="decimal",decimalFormat="decimal"){
  // console.log('toggleHrsMins(originalTime, activeState, decimalFormat): ', originalTime, activeState, decimalFormat);

  // decimal version
  var decimalVersion = formatHrsMins(originalTime, decimalFormat);
  var decimalClass = ( activeState === 'decimal' ) ? "" : "hide";

  // human-readable format --->
  var formattedVersion = formatHrsMins(originalTime, "human");
  var formattedClass = ( activeState === "human" ) ? "" : "hide";

  // return
  return '<span class="'
          + decimalClass
          + ' toggleToHuman">'
          + decimalVersion + '</span><span class="hide">|</span><span class="'
          + formattedClass + ' toggleToDecimal hand">'
          + formattedVersion + '</span> ';
  // Note: you may need to "register" your element in _Main.js in $("body").on("click", ".toggleHumanDecimal"...
}

/**
* Convert a decimal to formatted decimal string or hours/mins string
*
* @author agalano / mrast
* @originalTime time to convert
* @version active decimal or human or no_format, default decimal
* @returns string of formatted value
**/
function formatHrsMins(originalTime,version){

  var newTime = originalTime;
  var decFormat = originalTime;
  var humanFormat = "";
  var noFormat = "";
  var isNeg = originalTime < 0;
  var h = "";
  var m = "";

  // no time, bounce out
  if ( isNaN(newTime) )
    return "-";

  //if ( version === 'no_format')
  //  return newTime.toString();

  // take the abs val of the time, neg values will be dealt with later
  newTime = Math.abs(newTime);

  // conversion
  newTime = toFixedRoundedTwo(newTime);
  newTimeParts = newTime.toString().split(".");

  // if newTimeParts[1] is undefined due to split, set to "00"
  if (newTimeParts[1] === undefined) {
    newTimeParts[1] = "00";
  }

	// here's where the negative of a value like -0 of -0.5 gets dropped
  // and why we saved if the original value was negative
  h = Number(newTimeParts[0]);
  m = Number(newTimeParts[1]);

  // calculate the minutes from the decimal
  m = Math.round((newTime % 1) * 60);

  if ( h === 0 && m > 0 )
    humanFormat = m + ' ' + getTranslation('Content.Label.MinutesAbbrev'); // 5 mins
  else if ( m === 0 )
    humanFormat = h + ' ' + getTranslation('Content.Label.HoursAbbrev'); // 8 hrs
  else
    humanFormat = h + ' ' + getTranslation('Content.Label.HoursAbbrev') + ' ' + m + ' ' + getTranslation('Content.Label.MinutesAbbrev'); // 8 hrs 5 mins
  // if the original value was negative, add the sign
	if ( isNeg )
  	humanFormat = '-' + humanFormat;

  // format decimal
  if ( h === 0 && m === 0 )
    decFormat = '0.00'; // 0 hrs
  else if ( m === 0 && h > 0 )
    decFormat = h & ".00"; // 8.00 hrs
  else
    decFormat = h + "." + newTimeParts[1]; // 8.55 hrs
  if ( isNeg )
    decFormat = decFormat * (-1);
  // convert the decimal to a string
  decFormat = decFormat.toString();

  // 'noformat' which is really rounded
  noFormat = h + '.' + newTimeParts[1];
  if ( isNeg )
    noFormat = "-" + noFormat;

  if ( version === 'no_format')
    return noFormat;
  else if ( version === 'decimal' )
    return decFormat;
  else if ( version === 'decimallabel' )
    return decFormat + ' ' + getTranslation('Content.Label.HoursAbbrev');
  else
    return humanFormat;
}

// Fixes ToFixed browser number-rounding issues
function toFixedRounded(num, length) {
  var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
  return number;
}

// Fixes ToFixed browser number-rounding issues (2 decimal places)
function toFixedRoundedTwo(num) {
  return toFixedRounded(num, 2);
}