/* ------------------------------------------ Functions------------------------------ */

// calls windowOpen()
function openTrainingWindow(url) {
	windowOpen({
		url: url,
		name: "NickelledTimesheetsPop",
		width: Math.max(1280, Math.floor(screen.width * 0.75)),
		height: Math.max(768, Math.floor(screen.height * 0.75))
	});
}

// function and extension
function windowOpen(opts) {
	opts = $.extend({}, {
		url: "javascript:void(0);",
		name: "_blank",
		width: Math.floor(screen.width * 0.75),
		height: Math.floor(screen.height * 0.75),
		channelmode: false,
		directory: false,
		menubar: false,
		resizeable: true,
		scrollbars: true,
		status: false,
		titlebar: false,
		toolbar: false,
		req: false
	}, opts || {} );

	opts = $.extend({}, {
		left: Math.floor((screen.width - opts.width) / 2),
		top: Math.floor((screen.height - opts.height) / 4)
	}, opts || {} );

	var specs =
		"channelmode=" + (opts.channelmode ? "yes" : "no")
		+ ",directory=" + (opts.directory ? "yes" : "no")
		+ ",left=" + opts.left
		+ ",height=" + opts.height
		+ ",menubar=" + (opts.menubar ? "yes" : "no")
		+ ",resizeable=" + (opts.resizeable ? "yes" : "no")
		+ ",scrollbars=" + (opts.scrollbars ? "yes" : "no")
		+ ",status=" + (opts.status ? "yes" : "no")
		+ ",titlebar=" + (opts.titlebar ? "yes" : "no")
		+ ",toolbar=" + (opts.toolbar ? "yes" : "no")
		+ ",top=" + opts.top
		+ ",width=" + opts.width;

	if (opts.url !== "")
		window.open(opts.url, opts.name, specs, opts.req);
}