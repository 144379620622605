// Load up a few variables we need in several steps
let video;
let canvas = document.querySelector("#canvas");
let videoStream; // current video stream

$(document).ready(function(){
	if (!"mediaDevices" in navigator || !"getUserMedia" in navigator.mediaDevices) {
		$("#camera-error-message").append(getTranslation("JavaScript.Camera.ClockPhotoCameraProblem"));
		$("#camera-problem").show();
		return;
	}
	else {
		video = document.querySelector("#video");	// html <video> tag
		canvas = document.querySelector("#canvas");
	}
	// Camera button in Snapshot.cfm 
	$("#btnScreenshot").on("click", function(){
		takeSnapshot();
	});
});

/**
 * Close the modal with the cancel button/link
 */
function cancelSnapshot(){
	closeModal();
}

/**
 * Take a snapshot of the user with the system camera inside a modal
*/
function takeSnapshot(){
	$("#btnScreenshot").hide();		// no need to keep showing the buttons now that we're underway
	$("#cancelBtn").hide();
	$(".close").hide();
	$("#snapshot-mask").hide();
	$("#screenshots").empty();	// clean up any previous snapshots
	$(".status").append(getTranslation("JavaScript.Camera.ProcessingClockPhoto"));
	$(".status").show();

	// find out if we need to do geo location (just like old Ford)
	// var doGeo = ($("input[name=GeolocationRequired]").val() == "true");
	// console.log("takeSnapshot(), even though doGeo = ", doGeo);
	const img = document.createElement("img");
	canvas.width = video.videoWidth;
	canvas.height = video.videoHeight;
	canvas.getContext("2d").drawImage(video, 0, 0);
	img.src = canvas.toDataURL("image/jpeg", 0.5);	// 2nd arg is quality, which can go from 0 to 1.0

	$("#video").hide();

	// show the captured imaged if the src is greater than 6 ie 'data;,' meaning there is probably a valid image
	if ( img.src.length > 6 ){
		$("#screenshots").show();
		$("#screenshots").prepend(img);
	}

	let imageData = img.src.replace("data:image/jpeg;base64,","");
	$("input[name=base64data]").val(imageData);		// we set the image data to the hidden form element
	$("#PerformClockActionForm").submit();	// then submit the form where the data is attached to an element

	// TODO: (maybe) convert this to an API-based action as this is pretty old-school and just weird
}

/**
* Stop the video stream
*/
function stopVideoStream() {
	if (videoStream) {
		videoStream.getTracks().forEach((track) => {
		 	track.stop();
		});
	}
}
/**
 * Put a red square in the center of the camera view so people can center their faces
 * 	Just a guide, has no effect on anything
 */
function showCameraMask(){
	$("#snapshot-mask").hide();
	let vWidth = $("#video").width();
	let vHeight = $("#video").height();
	let maskWidth = $("#snapshot-mask").width();
	let marginLeft = (vWidth / 2) - (maskWidth / 2);
	let marginTop = (vHeight / 2) - (maskWidth / 2);
	$("#snapshot-mask").css("margin-left",marginLeft);
	$("#snapshot-mask").css("margin-top",marginTop);
	$("#snapshot-mask").fadeIn();
}

/**
* Initialize the camera/videostream
*/
async function initializeCamera() {
	$("#btnScreenshot").show();
	$("#cancelBtn").show();
	$(".close").show();
	stopVideoStream();
	try {
		// video constraints
		const constraints = { video: {}};	// some browsers require this, but FF won't allow sizing here, fyi
		videoStream = await navigator.mediaDevices.getUserMedia(constraints);
		video.srcObject = videoStream;
		$("#btnScreenshot").removeAttr('disabled');
		$("#cameraLoading").hide();
		$("#snapshotContainer").show();
		// wait a second before loading the mask to get the final size of the video
		setTimeout(function(){
			showCameraMask();
		}, 1000)

	}
	catch (err) {
		$("#cameraLoading").hide();
		$("#snapshotContainer").show();
		$("#camera-error-message").append(getTranslation("JavaScript.Camera.ClockPhotoCameraProblem"));
		$("#camera-problem").show();
	}
}

/**
* Close the modal and update & clean up the contents
*/
function closeModal() {
	$("#screenshots").hide();
	$("#snapshot-mask").hide();
	stopVideoStream();;
	$("#video").empty();
	$("#video").show();
	$(".status").hide();
	$(".status").empty();
	$("#camera-problem").hide();
	$("#tsModal").hide();
}

/**
* When the user clicks on <span> (x), close the modal
*/
function closeSnapshotModal(){
	closeModal();
}

var modal = document.getElementById("tsModal");
/**
* Close the modal when the user clicks anywhere outside it - not currently activated!
*/
window.onclick = function(event) {
	if (event.target == modal) {
		// console.log("clicked outside modal - dismis or not????????");
		// closeModal();
	}
}

/**
 * Launch the snapshot modal, then initialize the camera
 */
function snapshotLaunch(){
	$("#tsModal").show();
	$("#btnScreenshot").attr("disabled", "disabled");
	$("#cameraLoading").show();
	$("#snapshotContainer").hide();
	initializeCamera();
}
