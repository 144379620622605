/* ------------------------------------------ Start ------------------------------ */

/*
	The three methods of the navigator.geolocation api:
	getCurrentPosition( success [, error, options ] )
	watchPosition( success [, error, options ] )
	clearWatch( watchTimerID )
*/

var msgProceedAnyway = getTranslation("JavaScript.Geolocation.Instructions.ProceedAnyway");
var msgClickToPerformWithoutGeo = getTranslation("JavaScript.Geolocation.Instructions.ClickToPerformWithoutGeo");
var msgClickToCancel = getTranslation("JavaScript.Geolocation.Instructions.ClickToCancel");
var msgAcquiringLocation = getTranslation("JavaScript.Geolocation.Instructions.AcquiringLocation");
var msgWaitingForData = getTranslation("JavaScript.Geolocation.Instructions.WaitingForData");
var msgReceivingData = getTranslation("JavaScript.Geolocation.Instructions.ReceivingData");
var msgAccuracyRefinementOpen = getTranslation("JavaScript.Geolocation.Instructions.AccuracyRefinementOpen");
var msgAccuracyRefinementClose = getTranslation("JavaScript.Geolocation.Instructions.AccuracyRefinementClose");
var msgGeoTimeout = getTranslation("JavaScript.Geolocation.Instructions.GeoTimeout");
var msgForFasterCapture = getTranslation("JavaScript.Geolocation.Instructions.ForFasterCapture");
var msgAccuracyIsPoor = getTranslation("JavaScript.Geolocation.Instructions.AccuracyIsPoor");
var msgNoBrowserSupport = getTranslation("JavaScript.Geolocation.Instructions.NoBrowserSupport");

var geoCap,
		geoCapture,
		clockGeoCheckInt,
		clockGeoPromptInt,
		isMobileSite,
		dialogWidth = 640,
		dialogHeight = 270,
		htmlProceedAppend = "<h1>" + msgProceedAnyway + "</h1>"
											+ "<ul style='margin-top:10px;'><li style='margin-bottom:5px;'>" + msgClickToPerformWithoutGeo + "</li>"
											+ "<li>" + msgClickToCancel + "</li></ul>";
		htmlPleaseWait = "<h1>" + msgAcquiringLocation + "</h1>"
										+ '<div class="geoPreTimeoutText" style="margin-bottom:10px;">' + msgWaitingForData + '</div>'
										+ '<div id="geoTimeoutText" style="display:none;margin-bottom:10px;">' + msgAccuracyRefinementOpen
										+ '<div style="height:10px;"></div><strong>' + msgAccuracyRefinementOpen + ' <span id="geoCountdown" style="font-weight:bold;font-size:18px;"></span> ' + msgAccuracyRefinementClose + '</strong></div>'
										+ '<div class="geoPreTimeoutText" style="margin-bottom:10px;">' + msgGeoTimeout +'</div>'
										+ '<div style="margin-bottom:10px;"><strong><em>' + msgForFasterCapture + '</em></strong></div>',
		htmlTimeout = "<div class='errorMsg' style='margin-bottom:10px;'>" + msgAccuracyIsPoor + "</div>" + htmlProceedAppend,
		htmlDisabled = "<div class='errorMsg' style='margin-bottom:10px;'>" + msgNoBrowserSupport +"</div>" + htmlProceedAppend;
var dialogPosition = {
			my: "center",  // center the dialog to accomodate mobile better
			at: "center", 
			of: window 
		};


/* ------------------------------------------ Ready and Init ------------------------------ */

$(document).ready(function(){
	geoCap = new geoCapture();
	isMobileSite = $("div.mobile-version").length > 0;

	if (isMobileSite){
		dialogWidth = dialogHeight = "auto";
	}
});

/* ------------------------------------------ Main Functions ------------------------------ */

// main function
geoCapture = function geoCapture() {
	var lat = "",
			lng = "",
			acc = "",
			checkID,
			watchID,
			error,
			firstRun = true;

	this.isSupported = function() {
		if (error == null) {
			if (!navigator.geolocation)
				error = true;
			else
				error = false;
		}
		return !error;
	};

	this.getCoordinates = function() {
		var ret = new Array();
		if ( lat != "" && lng != "" && !isNaN(lat) && !isNaN(lng) ) {
			ret.push(lat);
			ret.push(lng);
		}
		return ret;
	};

	this.getAccuracy = function() {
		return Math.floor(acc);
	};

	this.getCoordinatesWithAccuracy = function() {
		var ret = "";
		if ( lat != "" && lng != "" && !isNaN(lat) && !isNaN(lng) ) {
			var cooArr = this.getCoordinates();
			ret += lat + "," + lng;
		}
		if (!isNaN(acc))
			ret += ":" + Math.floor(acc);
		return ret;
	}

	this.getPositions = function(position) {
		var o = position.coords;
		lat = o.latitude;
		lng = o.longitude;
		acc = o.accuracy;
		//console.dir(position);
	}

	this.initialize = function() {
		// Get the location of the user's browser using the native geolocation service. Only the first callback is required.
		// The second callback - the error handler - and third argument - configuration options - are optional.
		setTimeout(function(){
			navigator.geolocation.getCurrentPosition(
				geoCap.getPositions,
				function(err) {
					this.showGeolocationError(err,"getCurrentPosition");
				},
				{
					enableHighAccuracy: true,
					timeout: 100,
					maximumAge: 60000
				}
			);
			watchID = navigator.geolocation.watchPosition(
				geoCap.getPositions,
				function(err) {
					this.showGeolocationError(err,"watchPosition");
				},
				{
					enableHighAccuracy: false,
					timeout: 5000,
					maximumAge: 60000
				}
			);
		},250); // delay start of initialization to allow devices to load proper support
	};

	this.clearWatch = function() {
		navigator.geolocation.clearWatch(watchID);
	};

}

// called from clockIn functionality
// these functions require the geoCapture object to be already initialized
// inOut: true = Clock In, false = Clock Out
function clockGeo(callback) {
	// console.log("clockGeo(callback) ", callback);
	if (geoCap.isSupported()) {
		clockGeoAction(callback);
	}
	else {
		confirmDialog({
			title: "Geolocation Not Supported",
			msg: htmlDisabled,
			modal: true,
			width: dialogWidth,
			height: dialogHeight,
			position: dialogPosition,
			okay: function() {
				callback.apply();
			},
			cancel: function() {
				endClockGeo();
				$loc.val("");
			}
		});
	}
}

// called from many functions
function endClockGeo() {
	geoCap.clearWatch();
	window.clearInterval(clockGeoCheckInt);
	window.clearInterval(clockGeoPromptInt);
	closeDialog();
}

// called from clockGeo()
function clockGeoAction(callback) {
	var accMin = 30,
			maxSec = 5,
			coords = "",
			cooArr,
			acc = "",
			count = 0,
			$loc = $("#LocationCoordinates"),
			clockGeoCheck;

	var defaultTitle = getTranslation("JavaScript.Title.Geolocation");
	var btnCancel = getTranslation("Content.Btn.Cancel");

	$("body")
		.on("click","#geoCancel",endClockGeo)
		.on("click","#geoOverride",function(){
			skipClockGeo(acc,cooArr,$loc,callback);
		})
		.on("click","button.ui-dialog-titlebar-close",function() {
			endClockGeo();
			$loc.val("");
		});

	geoCap.initialize();

	pleaseWait({
		title: defaultTitle,
		msg: htmlPleaseWait,
		modal: true,
		width: dialogWidth,
		height: dialogHeight,
		show: false,
		position: dialogPosition,

		callback: function() {

			$("#dialog").dialog("option", "buttons",[/*{
					text: "Skip Geolocation",
					click: function() {
						skipClockGeo(acc,cooArr,$loc,callback);
					}
				},*/{
					text: btnCancel,
					click: endClockGeo
				}
			]);

			var clockGeoCheck = function() {
				cooArr = geoCap.getCoordinates();
				acc = geoCap.getAccuracy();

				if (!isNaN(acc) && acc != "" && typeof(cooArr[0]) != "undefined" && typeof(cooArr[1]) != "undefined") {
					$loc.val(cooArr[0] + "," + cooArr[1] + ":" + acc);
				}
				$("#geoCountdown").text(maxSec-count);

				if (count == 0) {
					$(".geoPreTimeoutText").effect({
						effect: "fade",
						duration: 100,
						complete: function(){
							$("#geoTimeoutText").fadeIn(100);
						},
					});
				}

				if (count > 0 && (acc != "" && acc <= accMin)) {
					endClockGeo();
					callback.call();	
					// callback from _Clock.js, 
					// either submitClockInOut() when we do geo only 
					// or snapshotLauncher() when we proced to snapshot, which will then call submitClockInOut()
				}
				else if (count >= maxSec) {
					endClockGeo();
					callback.call();

					/* commented so that there is no warning about posting a poor accuracy
					confirmDialog({
						title: "Geolocation Accuracy Poor",
						msg: htmlTimeout,
						modal: true,
						width: dialogWidth,
						height: dialogHeight,
						position: {
							my: "left top",
							at: "left top",
							of: $(".clockInOutButton")
						},
						okay: function() {
							endClockGeo();
							callback.call();
						},
						cancel: function() {
							endClockGeo();
							$loc.val("");
						}
					});
					*/
				}
				count++;
			};
			clearInterval(clockGeoPromptInt);
			clockGeoPromptInt = window.setInterval(function(){
				acc = geoCap.getAccuracy();
				if (acc != "" && !isNaN(acc)) {
					clearInterval(clockGeoPromptInt);
					clockGeoCheck();
					clockGeoCheckInt = window.setInterval(clockGeoCheck,1000);
				}
			},200);
		}
	});
}

/* ------------------------------------------ Sub Functions ------------------------------ */

// called from geoCapture()
function showGeolocationError(error,geolocationMethod) {
	var msg, addendum;
	switch(error.code) {
		case error.PERMISSION_DENIED:
			msg = getTranslation("JavaScript.Geolocation.Error.RequestToShareDenied"); break;
		case error.POSITION_UNAVAILABLE:
			msg = getTranslation("JavaScript.Geolocation.Error.LocationInfoUnavailable"); break;
		case error.TIMEOUT:
			msg = getTranslation("JavaScript.Geolocation.Error.RequestTimedOut"); break;
		default: // error.UNKNOWN_ERROR or other
			msg = getTranslation("JavaScript.Geolocation.Error.UnknownError"); break;
	}
	addendum = "\n\n" + getTranslation("JavaScript.Geolocation.Error.Addendum");
	if (error.code != error.TIMEOUT) {
		if (geolocationMethod == "getCurrentPosition")
			window.alert(msg + addendum);
	}
	console.log("geolocation method: " + geolocationMethod + " error : " + msg);
};

// called from clockGeoAction()
function skipClockGeo(acc,cooArr,$loc,callback) {
	endClockGeo();
	callback.call();
}