/* ------------------------------------------ Ready and Init ------------------------------ */

// init Firefox browser fix (repopulation for browser-back issue)
function initCalendars() {
	$(window).on("unload", function(){
		$(".hasDatePicker").html("");
	});
}

// init calls time dropdown
function initTimeDropDowns() {
	$("div.timeDropDown > a")
		.on("mouseenter",function(event){
			clearTimeout(g.timeControlTimeout);
		})
		.on("mouseleave",function(event){
			clearTimeout(g.timeControlTimeout);
			startTimeControlHide();
		});

	$("div.timeDropDown,body")
		.on("click",function(event){
			$("div.timeDropDown").filter("input.timeField").each(function(){
				if (!$(this).is(":hidden"))
					$(this).hide(100);
			});
		});
}

// Required but blank
function calendarOnchange() {
	// Save this blank function! (function is required and is overwritten elsewhere as needed)
}

/* ------------------------------------------ Functions ------------------------------ */

// sets picker (keyboard-mode)
function dateArrowChange(id, dirInc, keyboardMode ) {
	var stat = $('#'+id+"static").length > 0 ? "static" : "",
			d = getTimeDiffObj($('#'+id).val(),0,0,'AM',dirInc).d,
			$dp = $('#'+id+stat),
			vis = false;
	$('#'+id).val(d);

	if (typeof keyboardMode === "boolean" && keyboardMode)
		$dp.datepicker('setDate',d)
	else {
		$dp
			.datepicker('disable')
			.datepicker('setDate',d)
			.datepicker('enable');
	}
}

// sets startDateArrowTimer()
function dateArrowChangeCheck(id, dirInc)  {
	var ms = 500;
	dateArrowChange(id,dirInc)
	if (g.dateArrowN > 8)
		ms = 100;
	else if (g.dateArrowN > 4)
		ms = 150;
	else if (g.dateArrowN > 2)
		ms = 300;
	g.dateArrowNms = ms;
	startDateArrowTimer(id,dirInc);
}

// sets dateArrowChangeCheck()
function startDateArrowTimer(id,dirInc) {
	clearInterval(g.dateArrowInt);
	g.dateArrowInt = setInterval(function(){
		g.dateArrowN++;
		dateArrowChangeCheck(id,dirInc);
	},g.dateArrowNms)
}

// sets 12 vs 24 hour
function hour24Change(HourName,MinuteName) {
	if ($("#"+HourName+"Input").val()=="24") {
		$("#divMinutes"+MinuteName).hide();
		$("#divMinutes"+MinuteName+"00").show();
		$("#"+MinuteName+"Input").val("00");
		$("form input[name="+MinuteName+"]").val("0");
	}
	else {
		$("#divMinutes"+MinuteName).show();
		$("#divMinutes"+MinuteName+"00").hide();
	}
}

/* ------------------------------------------ Major Functions ------------------------------ */

// sets handler events for #MinuteName (input+control)
function setMinuteEvents(MinuteName, disabled, TimeOnChangeJS){
	var targetInput = "#"+MinuteName+"Input",
		targetControl = "#"+MinuteName+"Control";

	if (!disabled) {
		$(targetInput)
		.on("mouseenter",{tc:targetControl},function(ev){
			if(!g.hrsSelected){
				stopTimeControlHide();
				$('.timeDropDown').not(ev.data.tc).hide();
				$(ev.data.tc).showTimeControlDelayed();
			}
		})
		.on("mouseleave",function(){
			startTimeControlHide();
		})
		.on("keypress",{tc:targetControl},function(ev){
			$(ev.data.tc).hide(250);
			if (noenter(ev))
				return true;
			else {
				$(this).trigger("blur");
				return false;
			}
		})
		.on("click",function(){
			$(this).select();
			g.hrsSelected=false;
		})
		.on("mouseup",function(){
			return false;
		})
		.on("focus",{tc:targetControl},function(ev){
			stopTimeControlHide();
			g.inputFocusValue=$(this).val();
			g.inputFocusName=$(this).attr("name");
			$(this).select();
			$('.timeDropDown').not(ev.data.tc).hide();
			$(ev.data.tc).show();
		})
		.on("blur",{mn:MinuteName,tocjs:TimeOnChangeJS},function(ev){
			validateTime($(this).get(0),'minutes',ev.data.mn,ev.data.tocjs);
			startTimeControlHide();
		});
	}

	$(".MinuteLink"+MinuteName).on("click",{mn:MinuteName,tocjs:TimeOnChangeJS},function(ev){
		$("#"+ev.data.mn+"Input").val($(this).html()).trigger("change");
		$("#"+ev.data.mn).val(parseFloat($(this).html())).trigger("change");
		$("#"+ev.data.mn+"Control").hide(250);
		//ignore:eval
		eval(ev.data.tocjs);
	});
}

// sets handler events for #HourName (input+control)
function setHourEvents(HourName,MinuteName,disabled,TimeOnChangeJS,NonStandardEntry){
	var targetInput = "#"+HourName+"Input",
		targetControl = "#"+HourName+"Control";

	if (!disabled) {
		$(targetInput)
			.on("select",function(){
				g.hrsSelected=true;
			})
			.on("mouseenter",{tc:targetControl},function(ev){
				stopTimeControlHide();
				$('.timeDropDown').not(ev.data.tc).hide();
				$(ev.data.tc).showTimeControlDelayed();
			})
			.on("mouseleave",function(){
				startTimeControlHide();
			})
			.on("keypress",{tc:targetControl},function(ev){
				$(ev.data.tc).hide(250);
				if (noenter(ev))
					return true;
				else {
					$(this).trigger("blur");
					return false;
				}
			})
			.on("click",function(){
				$(this).on("select");
			})
			.on("mouseup",function(){
				return false;
			})
			.on("focus",{tc:targetControl},function(ev){
				stopTimeControlHide();
				g.inputFocusValue=$(this).val();
				g.inputFocusName=$(this).attr("name");
				$(this).select();
				$('.timeDropDown').not(ev.data.tc).hide();
				$(ev.data.tc).show();
			})
			.on("blur",{nse:NonStandardEntry,hn:HourName,tocjs:TimeOnChangeJS},function(ev){
				g.hrsSelected=false;
				validateTime($(this).get(0),ev.data.nse,ev.data.hn,ev.data.tocjs);
				startTimeControlHide();
			});
	}

	$(".HourLink" + HourName).on("click",{hn:HourName,mn:MinuteName,tocjs:TimeOnChangeJS},function(ev){
		$("#"+ev.data.hn+"Input").val($(this).html()).trigger("change");
		if ($(this).is(".nonStandardHourLink")) {
			$("#"+ev.data.hn).val(parseFloat($(this).html()));
			hour24Change(ev.data.hn,ev.data.mn);
		}
		else {
			$("#"+ev.data.hn).val(convert12to0(parseFloat($(this).html())));
		}
		$("#"+ev.data.hn+"Control").hide(250);
		//ignore:eval
		eval(ev.data.tocjs);
	});
}

// sets handler events for #DaysOfWeekName (input+control)
function setDayOfWeekEvents(DaysOfWeekName,disabled){
	var targetInput = "#" + DaysOfWeekName + "Input",
		targetControl = "#" + DaysOfWeekName + "Control";

	$(targetInput).on("keypress",function(ev){
		return noenter(ev);
	});

	if (!disabled) {
		$(targetInput)
			.on("mouseenter",{tc:targetControl},function(ev){
				stopTimeControlHide();
				$('.timeDropDown').not(ev.data.tc).hide();
				$(ev.data.tc).showTimeControlDelayed();
			})
			.on("mouseleave",function(){
				startTimeControlHide();
			});
	}

	$(".DayOfWeekLink"+DaysOfWeekName).on("click",function(){
		$("#"+DaysOfWeekName+"Input").val($(this).html());
		$("#"+DaysOfWeekName).val(dayNameToNumber($(this).html())).trigger("change");
		$("#"+DaysOfWeekName+"Control").hide(250);
	});
}

// create datepicker
function setDatePicker(obj) {
	var isStatic = obj.isStatic,
			twoMonths = obj.twoMonths,
			icon = obj.icon,
			inputID = obj.inputID,
			inputName = obj.inputName,
			y = obj.y,
			m = obj.m,
			d = obj.d,
			lastDate = new Object(),
			startYear = obj.startYear,
			endYear = obj.endYear,
			calendarOnChange = obj.calendarOnChange,
			currentDate = new Date(),
			obj = new Object(),
			staticObj = new Object(),
			twoMonthsObj = new Object();

	lastDate = {y:y,m:m,d:d};
	currentDate.setFullYear(y,m-1,d);

	if (!isStatic) {
		staticObj = {
			onClose: function(str,i){
				var $div = $(i.dpDiv);
				if ($div.length > 0)
					$div.toggleClass("above", false);
			}
		}
	}

	if (twoMonths) {
		twoMonthsObj = {
			numberOfMonths: 2,
			showButtonPanel: true
		}
	}

	obj = {
		duration: "fast",
		changeMonth: true,
		changeYear: true,
		prevText:"",
		nextText:"",
		dateFormat: "mm-dd-yy",
		yearRange: startYear + ":" + endYear,
		gotoCurrent: true,
		currentText: 'MONTH',
		defaultDate: currentDate,
		showAnim: '',
		showStatus: true,
		dayStatus: 'DD',
		beforeShow: function(inp, obj) {
			closeToolTips();
		},

		onChangeMonthYear: function(y, m, i) {
			var sel = "#ui-datepicker-div a.ui-state-default.ui-state-active,#"+inputID+"static table.ui-datepicker-calendar a.ui-state-default.ui-state-active",
					d = parseInt(i.currentDay,10),
					ld = new Date(new Date(y,m,1)-1).getDate(),
					$inp = $("input[name="+inputName+"]"),
					$div = $(i.dpDiv),
					aboveIt = $div.offset().top < $inp.offset().top,
					newDate;
			if (d > ld) {
				d = ld;
				window.setTimeout(function(){
					$inp.lastDayWarning(d,sel);
				},100);
			}
			if ($inp.val() == "")
				newDate = new Date(y, m-1, 1);
			else
				newDate = new Date(y, m-1, d);
			$(this).datepicker('setDate', newDate);
			if (isStatic)
				$inp.val(formatDate(newDate));
			if (!isStatic && $div.length > 0) {
				$div.toggleClass("above", aboveIt);
				if (aboveIt)
					$("#ui-datepicker-div").css({height:"218px"});
				else
					$("#ui-datepicker-div").css({height:""});
				$(this).datepicker("hide");
				$(this).datepicker("show");
			}
			calendarOnChange();
		},

		onSelect: function(str,i) {
			var $inp = $("input[name="+inputName+"]"),
					$div = $(i.dpDiv),
					aboveIt = $div.offset().top < $inp.offset().top;
			$inp.val(str);
			if (isStatic) {
				if ($inp.val() != '')
					$("#arrowControls"+inputID).css({visibility:'visible'});
				if ($inp.is(":not(:hidden)")) {
					$("#"+inputID+"static .ui-state-hover").effect( "transfer", { to: $inp, className: "ui-effects-transfer", easing: "easeInOutSine" }, 250, function(){
						calendarOnChange();
					});
				}
				else
					calendarOnChange();
			}
			else {
				if (aboveIt)
					$("#ui-datepicker-div").css({height:"218px"});
				if ($inp.is(":not(:hidden)")) {
					$("#ui-datepicker-div .ui-state-hover").effect( "transfer", { to: $inp, className: "ui-effects-transfer", easing: "easeInOutSine" }, 250, function(){
						calendarOnChange();
						$("#ui-datepicker-div").hide();
					})
				}
				else {
					calendarOnChange();
					$("#ui-datepicker-div").hide();
				}
			}
		}
	}

	if (!isStatic)
		$.extend(obj, staticObj);

	if (twoMonths)
		$.extend(obj, twoMonthsObj);

	if (isStatic) {
		$dp = $("#"+inputID+"static");
		$dp.attr({
			"data-static": "true",
			"data-inputid": $("#"+inputID)
		});
		$("#"+inputID).attr({
			"data-staticid": $("#"+inputID+"static")
		});
	}
	else {
		$dp = $("#"+inputID);
	}

	if (twoMonths) {
		$("#"+inputID)
			.children("div")
			.css({
				width: "370px",
				"float": "left"
			});
	}

	$("#"+inputID).closest("table.datePickerTable").setCalendarArrows();

	$dp.datepicker(obj);

	if (isStatic)
		$dp.find("td[data-handler=selectDay] > a").attr("tabindex","-1");

	$("#"+inputID)
		.attr("autocomplete","off")
		.on("keydown keyup",function(ev){
			var dirInc = (ev.which == $.ui.keyCode.UP || ev.which == $.ui.keyCode.RIGHT) ? 1 : ((ev.which == $.ui.keyCode.DOWN || ev.which == $.ui.keyCode.LEFT) ? -1 : 0),
					$table, id, js;
			if (dirInc != 0) {
				ev.preventDefault();
				$table = $(this).closest("table.datePickerTable");
				id = $table.attr("data-id");
				js = $table.attr("data-onchangejs");
				switch(ev.type) {
					case "keyup":
						clearInterval(g.dateArrowInt);
						g.dateArrowNms = 500;
						g.dateArrowN = 0;
						//ignore:eval
						eval(js);
						setTimeout($.proxy(function(){
							$(this).trigger("focus");
						},this),1);
						break;
					case "keydown":
						startDateArrowTimer(id,dirInc);
						dateArrowChange(id,dirInc,true);
						$(this).trigger("focus");
						break;
				}
			}
		})
		.on("blur",function(ev){
			var $table = $(this).closest("table.datePickerTable"),
					id = $table.attr("data-id"),
					js = $table.attr("data-onchangejs");
			clearInterval(g.dateArrowInt);
			g.dateArrowNms = 500;
			g.dateArrowN = 0;
			//ignore:eval
			eval(js);
		});
}

/* ------------------------------------------ Extensions ------------------------------ */

$.fn.extend({

	lastDayWarning: function(day,sel) {
		$(this)
			.switchClass("none","invalid", 100, 'easeInCirc')
			.delay(200)
			.switchClass("invalid","none", 100, 'easeOutCirc')
			.switchClass("none","invalid", 100, 'easeInCirc')
			.switchClass("invalid","none", 500, 'easeOutCirc');
		$(this).effect("transfer",{ to:$(sel), className: "ui-effects-transfer", easing: "easeInOutSine", queue:false, complete:function(){
			$(sel).addClass("spinitFast");
			setTimeout(function(){
				$(sel).removeClass("spinitFast");
			},500);
		}}, 250)
	},

	setCalendarArrows: function() {
		// set Calendar Arrow handlers
		return this.each(function(){
			$(this)
				.find("a.dateBackward,a.dateForward")
				.each(function() {
					var $table = $(this).closest("table.datePickerTable"),
							id = $table.attr("data-id"),
							js = $table.attr("data-onchangejs");
					$(this)
						.on("mousedown", function() {
							var dirInc = $(this).is(".dateBackward") ? -1 : 1;
							startDateArrowTimer(id,dirInc);
							dateArrowChange(id,dirInc);
							//ignore:eval
							eval(js);
						})
						.on("mouseup mouseout", function() {
							clearInterval(g.dateArrowInt);
							g.dateArrowNms = 500;
							g.dateArrowN = 0;
							//ignore:eval
							eval(js);
						});
				});
		});
	},

	showTimeControlDelayed: function() {
		var id = $(this).attr("id");
		clearTimeout(g.timeControlShowTimeout);
		g.timeControlShowTimeout = setTimeout(function(){
			$("#"+id).show();
		},100);
	}

});