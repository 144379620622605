/* ------------------------------------------ Ready and Init ------------------------------ */

$(document).ready(function(){

	// on(change) function handlers
	$("select[name=Country]").on("change", USIntSwitch ).trigger("change");
	$("select[name=ShippingCountry]").on("change", ShippingUSIntSwitch ).trigger("change");

	// on(click) function handlers
	$("input[name=CopyBillingAddressButton]").on("click", copyBillingAddress );

	// on(keyup) & on(change)
	$("input[name=StateInt]").on("keyup",function(){
		$("input[name=State]").val( $(this).val() );
	});

	$("input[name=ShippingStateInt]").on("keyup",function(){
		$("input[name=ShippingState]").val( $(this).val() );
	});

	$("select[name=ShippingStateUS]").on("change",function(){
		$("input[name=ShippingState]").val( $(this).find("option:selected").val() );
	});

	$("select[name=StateUS]").on("change",function(){
		$("input[name=State]").val( $(this).find("option:selected").val() );
	});

});


/* ------------------------------------------ Copy Functions ------------------------------ */

// sets shipping address inputs from billing address inputs
// called from handler - select[name=ShippingCountry]
// returns ShippingUSIntSwitch()
function copyBillingAddress() {
	var $form = $("form[name=PaymentForm]");

	$form
		.find("input[name=ShippingFirstName]").val( $form.find("input[name=FirstName]").val() ).end()
		.find("input[name=ShippingLastName]").val( $form.find("input[name=LastName]").val() ).end()
		.find("input[name=ShippingAddress]").val( $form.find("input[name=Address]").val() ).end()
		.find("input[name=ShippingAddress2]").val( $form.find("input[name=Address2]").val() ).end()
		.find("input[name=ShippingCity]").val( $form.find("input[name=City]").val() ).end()
		.find("input[name=ShippingZip]").val( $form.find("input[name=Zip]").val() ).end()
		.find("input[name=ShippingState]").val( $form.find("input[name=State]").val() ).end()
		.find("select[name=ShippingCountry]").val( $form.find("select[name=Country] option:selected").val() );

	if ( $form.find( "select[name=Country] option:selected" ).val() == "United States")
		$form.find( "select[name=ShippingStateUS]").val( $form.find("select[name=StateUS] option:selected").val() );
	else
		$form.find( "input[name=ShippingStateInt]" ).val( $form.find("input[name=StateInt]").val() );

	ShippingUSIntSwitch();
}

/* ------------------------------------------ Switch Functions ------------------------------ */

// checks country & toggles (usa and international) options
// called from handler - select[name=Country]
function USIntSwitch() {
	if ($("select[name=Country]").length > 0
			&& $(".USDiv").length > 0
			&& $(".IntDiv").length > 0
			&& $("input[name=StateInt]").length > 0
			&& $("select[name=StateUS]").length > 0) {
		if ( IsUnitedStatesOrBlank( $("select[name=Country] option:selected").val()) ) {
			$(".USDiv").show();
			$(".IntDiv").hide();
			$("input[name=State]").val($("select[name=StateUS] option:selected").val());
		}
		else {
			$(".USDiv").hide();
			$(".IntDiv").show();
			$("input[name=State]").val($("input[name=StateInt]").val());
		}
	}
}

// checks country & toggles (usa and international) shipping options
// called from ShippingUSIntSwitch()
// called from handler - select[name=ShippingCountry]
function ShippingUSIntSwitch() {
	if ($("select[name=ShippingCountry]").length > 0
			&& $(".ShippingUSDiv").length > 0
			&& $(".ShippingIntDiv").length > 0
			&& $("input[name=ShippingState]").length > 0
			&& $("select[name=ShippingStateUS]").length > 0) {
		if (IsUnitedStatesOrBlank($("select[name=ShippingCountry] option:selected").val())) {
			$(".ShippingUSDiv").show();
			$(".ShippingIntDiv").hide();
			$("input[name=ShippingState]").val($("select[name=ShippingStateUS] option:selected").val());
		}
		else {
			$(".ShippingUSDiv").hide();
			$(".ShippingIntDiv").show();
			$("input[name=ShippingState]").val($("input[name=ShippingStateInt]").val());
		}
	}
}

/* ------------------------------------------ Utilities ------------------------------ */

// called from ShippingUSIntSwitch()
function IsUnitedStatesOrBlank(v) {
	if (v == "United States" || v == "")
		return true;
	else
		return false;
}