/* ------------------------------------------ Variables ------------------------------ */

/* ------------------------------------------ Ready and Init Functions ------------------------------ */

// main setup fo tooltips
function initTooltips() {

// TODO: v1.1 FUTURE CLEANUP
// Find, review and remove HTML tags
// var ttItems = "*[data-tooltip],*[title]:not(.noToolTip):not(div:parent(div.gm-style)),*[data-disabled-title],div[title],div[rel],span[rel],textarea[rel],a[href].ToolTip,a[rel].ToolTip,img.PhotoTip,div.notesControl,div.notesControlAll,input.ui-autocomplete-input,td.decHrs,.tooltipOverflow,.overflow,table[data-lock-tables] > * > tr:not(.notes) > *";

	var ttItems = ".ToolTip, .ToolTipPop";

	var ttObj = {
		items: ttItems,
		track: true,
		tooltipClass: "custom-tooltip-override",
		show: {
			duration: 250
		},
		hide: false,
		position: {
			collision: "flipfit"
		},
		content: function (event, ui) {
			var r;

			if ($(this).is(".tooltipOverflow,.overflow")) // overflow content
				r = $(this).overflown() ? $(this).html() : false;
			else {
				// for Safari
				if (g.isIOSSafari	&& $(this).is("[title]:not(.ToolTip),div.notesControl,div.notesControlAll,span.ToolTip:has(input.dateInput)") )
					r = false;
				else
					r = $(this).getToolTipContent();
			}
			return r;
		}
	};

	$("#pageWrapper")
		.tooltip( ttObj )
		.off("focusin focusout");

	$("body").on( "click mouseover",".ui-tooltip", closeToolTips );
	$(window).on( "beforeunload", closeToolTips );
}

/* ------------------------------------------ Extensions ------------------------------ */

// called from initTooltips()
// returns placement for tooltip content
$.fn.extend({
	getToolTipContent: function() {
		var e = $(this);

		if (e.is("[data-tooltip]"))
			return e.attr("data-tooltip");
		else if (e.is("a[href][rel].ToolTip"))
			return $(e.attr("rel")).html();
		else if (e.is("a[href].ToolTip"))
			return $(e.attr("href")).html();
		else if (e.is("div[rel],span[rel],textarea[rel]")) {
			if ($(e.attr("rel")).is("textarea"))
				return $(e.attr("rel")).html().replace(/\r?\n|\r/g,"<br />");
			else
				return $(e.attr("rel")).html();
		}
		else if (e.is("img[alt]:not(.PhotoTip)"))
			return e.attr("alt");
		else if (e.is("img.PhotoTip"))
			return $("<img/>").attr("src", e.attr("src"));
		else if (e.is("label[for]"))
			return $("#"+e.attr("for")).getToolTipForChecked();
		else if (e.is(".numeric"))
			return decHrs(e.text());
		else if (e.is("[data-disabled][data-disabled-title]"))
			return e.attr("data-disabled-title");
		else if (e.is("[title]") && e.attr("title") !== "")
			return e.attr("title");
		else if (e.is("table[data-lock-tables] > * > tr > *") && e.overflown())
			return e.html();
		else
			return false;
	},
	getToolTipForChecked: function() {
		var e = $(this);
		if (e.is(":radio")) {
			if (e.is(":checked"))
				return "Selected";
			else
				return "Not Selected";
		}
		else if (e.is(":checkbox")) {
			if (e.is(":checked"))
				return "Checked";
			else
				return "Unchecked";
		}
	}
});

/* ------------------------------------------ Functions ------------------------------ */

// called from initTooltips()
function closeToolTips() {
	$(".ui-tooltip").each(function(){
		var $el = $("[aria-describedby=" + $(this).attr("id") + "]");
		$el.trigger("mouseout");
	});
}

/* ------------------------------------------ Single Pages ------------------------------ */

// called from single-page ProjectWeekView.js
function globalTooltips(b) {
	$("#pageWrapper").tooltip("option","disabled",!b)
};
