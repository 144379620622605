/* ------------------------------------------ Ready and Init ------------------------------ */


/* ------------------------------------------ Extensions ------------------------------ */
$.fn.extend({

	// download file
	// creates token and preps file
	// returns onDownloadFile()
	downloadFile: function(opts) {
		var token = getUUID();
		opts = $.extend({}, {
			callback: null,
			pdf: false,
			$form: $(this).closest("form")
		}, opts || {} );

		if (opts.callback !== null) {
			$btn = $( this );

			// generate download token for submission and form
			injectNewDownloadToken( opts.$form, token );

			// sets data attribute
			$btn.attr("data-download-token", token);

			if (opts.pdf) {
				chooseOrientation({
					callback: function() {
						onDownloadFile( $btn );
						opts.callback.call();
					}
				});
			}
			else {
				onDownloadFile( $btn );
				opts.callback.call();
			}
		}
	}
});

/* ------------------------------------------ Ready ------------------------------ */

// called from extension downloadFile:
// generates and injects new token
function injectNewDownloadToken($form,token) {
	token = token || getUUID();
	$form
		.find( "input[name=DownloadToken]" ).remove().end()
		.prepend(
			$("<input />")
				.attr({
					type: "hidden",
					name: "DownloadToken",
					value: token
				})
		);
	return token;
}

// called from extension downloadFile:
function onDownloadFile($btn) {

	// grabs token and clears interval
	window.clearInterval(g.pleaseWaitInt);

	// restore download buttons
	$("*[data-download-token]")
		.enableButton()
		.toggleClass( "loading", false );

	// set interval from global pleaseWaitInt
	onDownloadFileInterval($btn);

	g.pleaseWaitInt = window.setInterval($.proxy(function(){
		onDownloadFileInterval($btn);
	}, this ), 1000);

	// disables button
	$btn.enableButton( false ).toggleClass( "loading", true );

}

// called from onDownloadFile
// uses COOKIE library
function onDownloadFileInterval($btn) {
	// check cookie token
	if ($btn.is( "[data-download-token]" ) && $.cookie( "DOWNLOADTOKEN" ) == $btn.attr( "data-download-token" )) {
		// remove token and re-enable
		$btn.removeAttr("data-download-token");
		window.clearInterval(g.pleaseWaitInt);
		clearDownloadToken();
		$btn.enableButton().toggleClass("loading",false);
	}
}

// called from onDownloadFileInterval()
// updates cookie
function clearDownloadToken() {
	var isSecure = false;
	if ( $.cookie("DOWNLOADTOKEN") !== null ) {
		if ( g.domainName.slice( 0, 8) == "https://" )
			isSecure = true;
		$.cookie( "DOWNLOADTOKEN", null, {
			expires: getDateForCookieExpirationNow(),
			path: '/',
			secure: isSecure
		});
	}
}

/* ------------------------------------------ Utilities ------------------------------ */

// called from clearDownloadToken()
function getDateForCookieExpirationNow() {
	var date = new Date();
	date.setTime( date.getTime() - ( 24 * 60 * 60 * 1000 )); //minus 1 day (24hrs * 60sec * 60min * 1000ms)
	return date;
}