/* ------------------------------------------ Extensions ------------------------------ */
$.fn.extend({
	notesControlAllClick: function() {
		var $parentTable = $(this).closest("table");
		if (!$(this).is(".expanded")) {
			$(this).toggleClass("expanded",true);
			$parentTable
				.find("tr > td > div.notesControl:not(.expanded)").toggleClass("expanded",true).end()
				.find("tr.payrollExpandRow:not(.notesHistory)").toggleClass("notesHistory",true).end()
				.find("tr.notes:not(.expanded,.hiddenRow),tr.hideShowAttached:not(.expanded)").show().toggleClass("expanded",true).end()
				.find("tr > td span.hideOnCollapse.noDisplay").toggleClass("noDisplay",false).end()
				.find("tr > td > div.notesControl[data-ajax-open]").each(function(){
					//ignore:eval
					eval( $(this).attr("data-ajax-open") );
				});
		}
		else {
			$(this).removeClass("expanded");
			$parentTable
				.find("tr > td > div.notesControl.expanded").toggleClass("expanded",false).end()
				.find("tr.notes:not(.hiddenRow),tr.hideShowAttached").hide().toggleClass("expanded",false).end()
				.find("tr.payrollExpandRow.notesHistory").toggleClass("notesHistory",false).end()
				.find("tr > td span.hideOnCollapse:not(.noDisplay)").toggleClass("noDisplay",true);
		}
		$(this).removeClass("processing");
		setZebraStripes();
		return true;
	},

	notesControlClick: function(event) {

		var msgChangesNotSaved = getTranslation("JavaScript.ChangesNotSaved");
		var msgContinueAnyway = getTranslation("JavaScript.ContinueAnyway");

		var $thisRow = $(this).closest("tr"),
				tempID = $(this).attr("id").replace("notesControl",""),
				$noteID = $("#note"+tempID),
				openConfirmed = true,
				$table;
		$thisRow
			.next("tr.notes")
			.toggleClass("even",$thisRow.is(".even"))
			.toggleClass("odd",!$thisRow.is(".even"));
		if ($noteID.is(".openOne")) {
			$table = $thisRow.closest("table");
			if ($table.find("tr.notes.openOne.expanded.confirm").length > 0) {
				var confirmMsg = msgChangesNotSaved+"\n\n"+msgContinueAnyway;
				if (confirmDialog({msg:confirmMsg,stayOpen:false})) {
					$table.find("tr.notes.openOne.expanded.confirm").removeClass("confirm");
				}
				else {
					$.scrollTo("tr.notes.openOne.expanded.confirm:eq(0)",100,{
						offset: -100,
						callback: function(){
							$table
								.find("tr.notes.openOne.expanded.confirm:eq(0)")
								.find("textarea:eq(0)")
								.trigger("focus")
								.end()
								.find("input:button")
								.floatBorder();
						}
					});
					openConfirmed = false;
				}
			}
			if (openConfirmed) {
				$thisRow.siblings().find("td div.notesControl.expanded").each(function(){
					$(this).trigger("click");
				});
			}
		}
		if (openConfirmed) {

			$noteID.unwrapNotes();

			if ($noteID.is(":hidden")) {
				$(this)
					.toggleClass("expanded",true);
				$thisRow
					.find("td span.hideOnCollapse")
					.toggleClass("noDisplay",false);
				$noteID
					.wrapNotes()
					.show()
					.toggleClass("expanded",true)
					.find("td > div.nwrap")
					.slideDown({
						duration: 50,
						easing: "easeOutCirc",
						complete: function() {
							setZebraStripes();
							$noteID.unwrapNotes();
						}
					});
				$("tr.hideShowAttached"+tempID).show();
				if ($noteID.next("tr").is(".payrollTableHeader"))
					$noteID.next("tr.payrollTableHeader").show();
				if ($thisRow.is(".payrollExpandRow"))
					$thisRow.toggleClass("notesHistory",true);
				if ($(this).is("[data-ajax-open]")) {
					//ignore:eval
					eval($(this).attr("data-ajax-open"));
				}
			}
			else {
				$(this)
					.toggleClass("expanded",false);
				$thisRow
					.find("td span.hideOnCollapse")
					.toggleClass("noDisplay",true);
				$noteID
					.wrapNotes()
					.find("td > div.nwrap")
					.show()
					.slideUp({
						duration: 50,
						easing: "easeInCirc",
						complete: function(){
							$noteID
								.hide()
								.toggleClass("expanded",false);
							$noteID.unwrapNotes();
						}
					});
				$("tr.hideShowAttached"+tempID).hide();
				var sel= "tr.payrollTableHeader:first";
				if ($noteID.prevAll(sel).length > 0 && !$noteID.prevAll("tr.notes:first").is(":hidden"))
					$noteID.prevAll(sel).show();
				if ($noteID.nextAll(sel).length > 0 && $noteID.nextAll("tr.notes:first").is(":hidden"))
					$noteID.nextAll(sel).hide();
				if ($thisRow.is(".payrollExpandRow"))
					$thisRow.toggleClass("notesHistory",false);
			}
			return true;
		}
		else {
			event.preventDefault();
			event.stopPropagation();
		}
		return false;
	},
	unwrapNotes: function() {
		return this.each(function() {
			$(this).find("div.nwrap").contents().unwrap();
		});
	},
	wrapNotes: function() {
		return this.each(function() {
			$(this).children("td").wrapInner("<div class='nwrap' />");
		});
	},
	overflown: function(){
		var e = this.get(0);
		return e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth;
	}
});


function initNotesControls() {
	$("#pageWrapper")
		.on("click","table tr div.notesControlAll",function(event) {
			var showPleaseWait = false,
					$parentTableParent = $(this).closest("table").parent(),
					txt = "";
			event.preventDefault();
			if ($("table.dataTable tr.notes").length > 100) {
				txt = $(this).is(".expanded") ? "Contracting" : "Expanding";
				showPleaseWait = true;
			}
			$(this)
				.addClass("firstClicked")
				.data("offset", -parseFloat($(this).offset().top - $(window).scrollTop()) + 1 );
			if (showPleaseWait) {
				pleaseWait({
					msg:txt + " rows...",
					callback: function() {
						setTimeout(function(){
							$parentTableParent
								.find("table tr div.notesControlAll:not(.processing)")
								.addClass("processing")
								.trigger("doclick");
							},10);
					}
				});
			}
			else {
				$parentTableParent
					.find("table tr div.notesControlAll:not(.processing)")
					.addClass("processing")
					.trigger("doclick");
			}
		})
		.on("doclick","table tr div.notesControlAll",function(){
			if ($(this).notesControlAllClick()) {
				if ($("table tr div.notesControlAll.processing").length == 0) {
					$("table tr div.notesControlAll.firstClicked").removeClass("firstClicked").removeData("offset");
					closeDialog();
				}
			}
		})
		.on("click","table.dataTable div.notesControl[id],table.smallData div.notesControl[id]",function(event){
			$(this).notesControlClick(event);
		});
}