/* ------------------------------------------ Start ------------------------------ */
var clock;

/* ------------------------------------------ Ready and Init ------------------------------ */

$(document).ready(function(){
	startClock();
});

// inits clock
function startClock(){
	if ($("#clock .display .hours").length > 0) {
		clock = new Clock();
		clock.start();
	}
}

/* ------------------------------------------ Clock Object ------------------------------ */
// info: internal time in military hours
var Clock = function Clock() {

	var h, m, s, secInt = 0;

	// sets display for sync
	$("#clock .display").css("display", "none");

	/* ---------------- (clock object) inner functions ------------- */
	this.start = function() {
		var o = new Object();
		o = getDisplay();
		h = o.H;
		m = o.M;
		s = o.S;
		o.HUp = true;
		o.MUp = true;
		putDisplay(o);

		// update
		secInt = setInterval( advance, 1000 );
	}

	this.update = function(o) {
		if (!isNaN(parseInt(o.H,10)))
			h = parseInt(o.H);
		if (!isNaN(parseInt(o.M,10)))
			m = parseInt(o.M);
		if (!isNaN(parseInt(o.S,10)))
			s = parseInt(o.S);
		showSync(o);
	}

	/* ---------------- (clock object) sync utilities ------------- */
	// sync function
	// sets clock hide during sync
	function hideSync() {
		$("#clock .loading").fadeOut(100);
		$("#clock .display").fadeIn(100);
	}

	// sync function
	// updates display from update()
	// calls putsDisplay()
	function showSync(o) {
		if (typeof(o.LOGOUT) != "undefined")
			$("#clock").find( ".loading, .display" ).hide();
		else {
			$("#clock .loading").fadeIn(100);
			$("#clock .display").fadeOut(100,function(){
				o.HUp = true;
				o.MUp = true;
				putDisplay(o);
				window.setTimeout( hideSync, 250);
			});
		}
	}

	// updates time
	// calls putDisplay()
	function advance() {
		var mup = false;
		var hup = false;
		s++;
		if (s > 59) { s -= 60; m += 1; mup = true; }
		if (m > 59) { m -= 60; h += 1; hup = true; }
		if (h > 23) { h -= 24; }

		var hd = parseInt(h);
		var md = parseInt(m);
		var sd = parseInt(s);

		if (md < 10) { md = formatAsTwoDigits(md); }
		if (sd < 10) { sd = formatAsTwoDigits(sd); }

		putDisplay({
			H: hd,
			M: md,
			S: sd,
			HUp: hup,
			MUp: mup
		});
	}

	/* ---------------- (clock object) display functionality ------------- */

	// creates display
	// returns clock object
	function getDisplay() {
		var o = new Object();
		$clock = $("#clock");

		var ht = parseInt($clock.find("div.hours").html().replace("&nbsp;",""),10);
		var mt = parseInt($clock.find("div.minutes").html(),10);
		var st = parseInt($clock.find("div.seconds").html(),10);

		o.H = ht;
		o.M = mt;
		o.S = st;

		return o;
	}

	// sets display (am-pm)
	function putDisplay(o) {
		var ampm = "AM";

		if (typeof(o.HUp) == "undefined")
			o.HUp = false;
		if (typeof(o.MUp) == "undefined" || isNaN(o.MUp))
			o.MUp = false;
		if (o.H > 11) {
			o.H -= 12;
			ampm = "PM";
		}

		if (o.H == 0) { o.H = 12; }

		if (o.HUp) {
			$("#clock")
				.find(".hours").html(o.H).end()
				.find(".ampm").html(ampm);
			$("#clock").find("div.ampm").css({
				lineHeight: (ampm == "AM") ? "16px" : "23px"
			});
		}

		if (o.MUp) {
			$("#clock").find(".minutes").html(formatAsTwoDigits(o.M));
		}

		$("#clock").find(".seconds").html(formatAsTwoDigits(o.S));

	}
}

/* ------------------------------------------ Utilities ------------------------------ */

// called from ajaxIntervalUpdate()
function updateClock(o) {

	// ensure clock has loaded
	if( typeof clock == 'undefined' || clock == null){
		startClock();
	}

	// updated only (if) needed
	if ( updateClockOkay() ){
		clock.update(o);
	}

	// sets UTC time
	g.lastUTCms = getUTC_ms();
}

// checks clock update timeframe
function updateClockOkay() {
	var ms = 3 * 60 * 1000; // 3mins: 3mins * 60seconds * 1000ms = 180000ms
	ms -= 30 * 1000; // take it 30 seconds back to ensure updates during the 3 minute time interval
	return ( ( getUTC_ms() - g.lastUTCms ) > ms );
}

// checks UTC time
function getUTC_ms() {
	var d = new Date();
	return d.getTime();
}

/* ------------------------------------------ Clock Submit Functionality ------------------------------ */

/* ------------------------------------------ Ready and Init ------------------------------ */

// sets clocking in and clocking out
// calls showsnapshot(), clockGeo(), submitClockInOut()
function initClockInOut() {
	var $form = $("form[name=PerformClockActionForm]"),
		$location = $("#LocationCoordinates"),
		doSnap = $form.find("input[name=TakeSnapshot]").val() == "true",
		doGeo = $form.find("input[name=GeolocationRequired]").val() == "true";
	$("#large-button-clockin,#large-button-clockout")
		.on( "click", function(ev) {
			if ( !$form.is("[data-disabled]") ) {
				$location.val("");

				if(doGeo && doSnap){
					clockGeo(snapshotLauncher);	// launch the camera after geo
				}
				else if (doGeo){
					clockGeo(submitClockInOut);	// geo only
				}
				else if (doSnap){
					snapshotLaunch();	// photo only
				}
				else {
					submitClockInOut();
				}
			}
		});
}

/* ------------------------------------------ Functions ------------------------------ */

/**
 * Shortcut to launch snapshot tool, used as a callback
 */
function snapshotLauncher(){
	snapshotLaunch();
}

/**
 * Submit clock in/out form after we go through logic for geo/photo/none
 * Used as a callback in initClockInOut()
 * Fired by either _GeoLocation.js or _Snapshot.js
 */
function submitClockInOut() {
	$(".clockInOutButton").toggleClass("noDisplay",false).show().attr("data-disabled","true");
	$("#large-button-clocked").toggleClass("noDisplay",true).hide();
	$("form[name=PerformClockActionForm]").trigger("submit");
}

// function computeDuration, returns a time string in decimal format with hrs added - (ie. "7.54 hrs")
// takes values for dateStart/dateEnd in format xx-xx-xxxx
// takes values for hourStart,hourEnd,minuteStart,minuteEnd
// takes values for ampmStart,ampmEnd as "AM" or "PM"
function computeDuration(dateStart,hourStart,minuteStart,ampmStart,dateEnd,hourEnd,minuteEnd,ampmEnd,returnRaw) {
	var days;
	var hours;
	var minutes;
	hourStart = parseInt(hourStart,10);
	hourEnd = parseInt(hourEnd,10);
	minuteStart = parseInt(minuteStart,10);
	minuteEnd = parseInt(minuteEnd,10);
	ampmStart = ampmStart+'';
	ampmEnd = ampmEnd+'';
	if (typeof(returnRaw) == "undefined")
		returnRaw = false;

	if (ampmStart=="PM"){hourStart += 12}
	if (ampmEnd=="PM"){hourEnd += 12}

	var tempStartDate = dateStart.split("-");
	tempStartMonth = parseInt(tempStartDate[0],10);
	tempStartDay = parseInt(tempStartDate[1],10);
	tempStartYear = parseInt(tempStartDate[2],10);

	var tempEndDate = dateEnd.split("-");
	tempEndMonth = parseInt(tempEndDate[0],10);
	tempEndDay = parseInt(tempEndDate[1],10);
	tempEndYear = parseInt(tempEndDate[2],10);

	// NOTE: Months in javascript are 0-11!!! (thus the -1)
	startDateConverted = Date.UTC(tempStartYear, tempStartMonth-1, tempStartDay, hourStart, minuteStart, 0);
	EndDateConverted = Date.UTC(tempEndYear, tempEndMonth-1, tempEndDay, hourEnd, minuteEnd, 0);

	daysDifferenceInMiliseconds = (EndDateConverted - startDateConverted);
	minutesDifference  = (daysDifferenceInMiliseconds / 60000);
	rawHours = Math.round((minutesDifference/60)*100)/100;

	var x = daysDifferenceInMiliseconds / 1000;
	if (x < 0) {
		minutes = Math.ceil((x/60) % 60);
		hours = Math.ceil((x/60/60) % 24);
		days = Math.ceil(x/60/60/24);
	}
	else {
		minutes = Math.floor((x/60) % 60);
		hours = Math.floor((x/60/60) % 24);
		days = Math.floor(x/60/60/24);
	}

	var s = rawHours + plural(" hr",rawHours);
	if (rawHours != 0) {
		s += " (";
		if (days != 0)
			s += days + plural(" day",days) + " ";
		if (hours != 0)
			s += hours + plural(" hr",hours) + " ";
		if (minutes != 0)
			s += minutes + plural(" min",minutes);
		s += ")";
	}

	if (returnRaw)
		return rawHours;
	else
		return s;
}

// called from openClockInMiniForm()
function closeClockInOutMiniForm() {
	$("div.ClockInOutMiniForm:visible")
		.hide()
		.html('');
}

function openClockInOutMiniForm(userid,action,returnto,continueto) {

	var action = action.toLowerCase();
	action = ( action === 'in' || action === 'out' ) ? action : 'in';
	var url = g.baseApiUrl + '/clock/' + action + '/form/' + userid;
	var $e = $("#ClockInOutMiniForm"+ userid);

	closeClockInOutMiniForm();

	$e.html('<div class="clockInOutContainer"><div class="ajaxLoader ajaxLoaderIcon"></div></div>')
		.show();

	$.ajax({
		type: "GET",
		url: url,
		cache: false,
		dataType: 'html',
		headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
		data: {
			"ReturnTo": returnto,
			"ContinueTo": continueto
		},
		success: function(data) {
			$e.html(JSON.parse(data));
			$("#clockInOutFloatingDiv")
				.position({
					my: "center top-56",
					at: "center middle",
					of: $e.closest("td"),
					collision: "fit"
				});
			$("#clockInOutFloatingDivArrow")
				.position({
					my: "left middle",
					at: "left middle",
					of: $e.closest("td"),
					within: $e.closest("td"),
					collision: "none"
				})
				.css({
					left: (parseInt($("#clockInOutFloatingDiv").css("left"),10) - 22) + "px"
				});
		},
		error: function(jqXHR, textStatus, errorThrown){ processAjaxError(jqXHR, textStatus, errorThrown); }
	});
}